import AddIcon from "@mui/icons-material/Add";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { Avatar, Box, Button, Card as MyCard, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  favoriteUnfavoriteMenu,
  GetAllCategories,
  GetAllMenu,
  getCustomizationOption,
} from "../Api/API";
import SnackbarPopUp from "../Common/SnackBar";
import {
  openPopUps,
  setBestSellerCheck,
  setCart,
  setCartDetailes,
  setCheckBestSellerId,
  setCustomizeOpen,
  setCustomizeOptions,
  setEditTitles,
  setFavoriteMenuStatus,
  setIsLoading,
  setMenuServingDetails,
  setOpenPopUps,
  setSelectedCupSizes,
  setTabValue,
  setTitles,
  setUpdateCart,
} from "../Redux/CartSlice";
import { setFav } from "../Redux/StoreSlice";
import AlertPopup from "./AlertPopup";
import ButtonLoader from "./ButtonLoader";
import { errorToastMessage } from "./utils";
import { setUserFavItems } from "../Redux/CartSlice";
import { useNavigate } from "react-router-dom";
import { findMatchingCartItem, updateCartWithItem } from "./utils/cartUtils";

toast.configure();
const Card = ({
  bestSeller,
  setState,
  title,
  isDisabled,
  state,
  likeItem,
  props,
  fav,
  setFavItem,
  categoryId,
  categoryName,
  favItem,
  menuId,
  favItemsDetail,
  setLoading,
}) => {
  const useStyles = makeStyles({
    item: {
      boxShadow: "0 6px 20px 0 #dbdbe8",
      transition: "transform 0.15s ease-in-out",

      "&:hover":
        bestSeller && props?.menuExists == false
          ? ""
          : { transform: "scale3d(1.05, 1.05, 1)" },
    },
    favItem: {
      boxShadow: "0 6px 20px 0 #dbdbe8",
      transition: "transform 0.15s ease-in-out",

      "&:hover":
        favItemsDetail?.menuExists == false
          ? ""
          : { transform: "scale3d(1.05, 1.05, 1)" },
    },
    favImgTran: {
      width: fav?"30%":'35%',
      objectFit: "contain",
      transition: "transform 0.15s ease-in-out",
    },
    imgTran: {
      width: "25%",
      objectFit: "contain",
      transition: "transform 0.15s ease-in-out",
      "&:hover":
        bestSeller && props?.menuExists == false
          ? ""
          : { transform: "scale3d(1.15, 1.15, 1)" },
    },
    root: {
      "& .MuiAvatar-img": {
        width: "auto",
      },
    },
  });
  const styles = useStyles();
  const navigate =  useNavigate();
  const [openAlert, setOpenAlert] = useState(false);
  const userDetail = useSelector((state) => state.user.user);
  const nearestStore = useSelector((state) => state.store.nearestStore);
  const counter = 1;
  const [buttonLoader, setButtonLoader] = useState(false);
  const [updateBtnFlag, setUpdateBtnFlag] = useState(false)
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const cartItem = useSelector((state) => state.cart.cart);
  const dispatch = useDispatch();
  const [removeFav, setRemoveFav] = useState(false);
  const [favState, setFavState] = useState(false);
  const store = useSelector((state) => state.store.nearestStore);
  const userFavItems = useSelector((state) => state.cart.userFavItems);
  const checkBestSellerId = useSelector(
    (state) => state.cart.checkBestSellerId
  );
  const [checkBestSeller, setCheckBestSeller] = useState();
  const [selectedFav, setSelectedFav] = useState();
  const [loading, setLocalLoading] = useState(false); 
  const openPopup = useSelector((state) => state.cart.openPopUps);
  const isLoading = useSelector((state) => state.cart.isLoading);
  const [toppingsLimitAlert, setToppingsLimitAlert] = useState(false);
  const [cartDetailForConfirm, setCartDetailForConfirm] = useState(null);
  const [toppingsLimit, setToppingsLimit] = useState(0);

  useEffect(() => {
    if (!openPopup) {
      setLocalLoading(false); 
      dispatch(setIsLoading(false)); 
      setUpdateBtnFlag(false);
    }
  }, [openPopup]);

  const indexs = cartItem?.findIndex(
    (item) =>
      item?.id === (menuId ? menuId : props?.id) &&
      item?.categoryId === (categoryId ? categoryId : props.category_id)
  );

  let cartDetail = {
    id: fav ? favItemsDetail?.menu_id : props?.id,
    categoryId: fav
      ? favItemsDetail?.category_id
      : categoryId
      ? categoryId
      : props?.category_id,
    customizationOpt: [],
    quantity: counter,
    price: props?.price,
    image: props?.image,
    title: props?.title,
    sku_code: props?.sku_code||props?.skuCode,
    h_sku_code:props?.h_sku_code,
    categoryName: fav
      ? favItemsDetail?.menu_details?.category_name
      : categoryName
      ? categoryName
      : props?.category_name,
    totalPrice: props?.price * counter,
    btnModifierId: [],
    btnModifier: [],
    custom: [],
    selectedCupSize: fav
      ? [favItemsDetail?.menu_details?.menu_serving_detail[0]]
      : [],
    menuServingDetail: fav ? favItemsDetail?.menu_serving_detail : [],
    orderItemPrice: fav ? favItemsDetail?.order_item_price : "",
    selectedOptions: fav ? favItemsDetail?.selectedOption : [],
    cold: false,
    caffine: false,
    max_calories: props?.max_calories,
    min_calories: props?.min_calories,
    is_favourite: 0,
    description: props?.description,
  };
  let data = {
    customerId: userDetail?.customer_id
      ? userDetail?.customer_id
      : userDetail?.person_id,
    menuId: menuId ? menuId : props?.id,
    storeId: store?.id ? store?.id : 1,
    appType: "web",
  };

  const handelfavOrder = () => {
    setUpdateBtnFlag(true)
    setSelectedFav(cartDetail);
    if (store.id) {
      let data = {
        appType: "web",
        customerId: userDetail?.customer_id,
        menuId: favItemsDetail.menu_id,
        storeId: store.id,
      };
      setButtonLoader(true);
      getCustomizationOption(data)
        .then((res) => {
          let result = res.result[0][0];
          let modifierDetail = [];
          let isRequiredChecked = false;
          cartDetail.sku_code= result?.sku_code||result?.skuCode;
          cartDetail.h_sku_code=result?.h_sku_code;
          cartDetail.customizationOpt = result?.customizationOpt; 
          let toppingCounterLimit = result?.customizationOpt?.filter((e)=>e?.modifier_group_name === "Toppings")
          cartDetail.toppings_counter_limit = toppingCounterLimit?.length>0?toppingCounterLimit[0]?.counter_limit:0         
          result?.customizationOpt.forEach((element, index) => {
            let selectedCustomizationOption = cartDetail.selectedOptions.filter(
              (ele) => element.modifier_group_id == ele.modifier_group_id
            );         
            if (selectedCustomizationOption.length === 0) {
              if (element.is_required) {
                isRequiredChecked = true;
                modifierDetail.push(element.modifier_group_name);
              }
            }
          });
          cartDetail.selectedCupSize=result?.menu_serving_detail?.filter((e)=>Number(e.serving_actual_id)===Number(cartDetail.selectedCupSize.length>0?cartDetail.selectedCupSize[0].serving_actual_id:0))
        const toppingsModifier = result?.customizationOpt?.find(
          (element) => element.modifier_group_name === "Toppings"
        );

        if (toppingsModifier) {
          const selectedToppings = cartDetail.selectedOptions?.find(
            (option) => option.modifier_group_name === "Toppings"
          );

          if (selectedToppings && selectedToppings.option) {
            const nonOreoCrumbCount = selectedToppings.option.reduce((sum, topping) => {
              if (topping?.modifier_name === 'Oreo Crumb' || topping?.name === 'Oreo Crumb') {
                return sum;
              }
              return sum + (topping.quantity > 0 ? 1 : 0);
            }, 0);

            if (nonOreoCrumbCount > toppingsModifier.counter_limit) {
              setToppingsLimit(toppingsModifier.counter_limit);
              setCartDetailForConfirm({...cartDetail});
              setToppingsLimitAlert(true);
              setButtonLoader(false);
              setUpdateBtnFlag(false);
              return;
            }
          }
        }

          if (isRequiredChecked) {
            toast.error(
              `${modifierDetail[0]} modifier is reqired for this item`,
              {
                position: "bottom-left",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
              }
            );
            setButtonLoader(false);
            setUpdateBtnFlag(false)
          } else {
            cartDetail.selectedOptions = result?.customizationOpt?.filter(
              (e, index) => {
                cartDetail.selectedOptions
                  .filter((cup) => cup.modifier_group_name !== "Cup Size")
                  ?.map((modifiers) => {
                    if (
                      modifiers.modifier_group_name === e.modifier_group_name
                    ) {
                      if (modifiers.option.length >= 1) {
                        let x = [];
                        modifiers.option.forEach((toppings) => {
                          let singleTopping = e?.option.filter(
                            (newStoreTopping) =>
                              Number(newStoreTopping.modifier_id) ===
                                Number(toppings.modifier_id) &&
                              newStoreTopping.modifier_group_description !== ""
                          )[0];
                          if (singleTopping) {
                            singleTopping.quantity = toppings.quantity;
                          } else {
                            setButtonLoader(false);
                            toast.error(
                              `${toppings.modifier_name} ${e.modifier_group_name} is not present for this item`,
                              {
                                position: "bottom-left",
                                autoClose: 1000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: false,
                                draggable: true,
                                progress: undefined,
                                theme: "dark",
                              }
                            );
                            setButtonLoader(false);
                            setUpdateBtnFlag(false)
                          }

                          let selected_modifier_serving =
                            singleTopping?.modifier_group_description

                              .filter(
                                (topServing) =>
                                  topServing?.serving_name ==
                                  cartDetail?.selectedCupSize[0].serving_name
                              )
                              .map((e) => {
                                return {
                                  price: e?.price ? e?.price?.toString() : "0",
                                  serving_name: e?.serving_name
                                    ? e.serving_name
                                    : cartDetail?.selectedCupSize[0]
                                        .serving_name,
                                };
                              })[0];
                          singleTopping.selected_modifier_serving =
                            selected_modifier_serving
                              ? selected_modifier_serving
                              : {
                                  price: "0",
                                  serving_name:
                                    cartDetail?.selectedCupSize[0].serving_name,
                                };
                          singleTopping.price =
                            singleTopping?.selected_modifier_serving?.price;
                          singleTopping.modifier_price =
                            singleTopping?.selected_modifier_serving?.price;
                          singleTopping.modifier_prefix_name = 0;
                          x.push(singleTopping);
                        });
                        let isRequired = e.is_required;
                        if (isRequired && x.length == 0) {
                        } else {
                          e.option = x;
                        }
                      }
                    }
                  });
                return cartDetail?.selectedOptions?.some((f) => {
                  return f.modifier_group_name === e.modifier_group_name;
                });
              }
            );
            let modfier_group_price = 0;
            cartDetail.selectedOptions.map((e) => {
              e?.option?.map((toppingPrice) => {
                modfier_group_price += isNaN(
                  Number(toppingPrice?.price || toppingPrice.modifier_price) *
                    toppingPrice.quantity
                )
                  ? 0
                  : Number(
                      (toppingPrice?.price || toppingPrice.modifier_price) *
                        toppingPrice.quantity
                    );
              });
            });
            cartDetail.orderItemPrice =
              cartDetail.selectedCupSize[0]?.price + modfier_group_price;
            cartDetail.totalPrice =
              cartDetail.orderItemPrice * cartDetail.quantity;
            let itemAddedToCartStatus = hadleAddToCart();
            if (itemAddedToCartStatus) {
              toast.success(`${cartDetail.title} Added to the cart`, {
                position: "bottom-left",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
              setButtonLoader(false);
              setUpdateBtnFlag(false)
            } else {
            }
          }
        })

        .catch((err) => {
          setButtonLoader(false);
          setUpdateBtnFlag(false)
          console.log(err);
        });
    } else {
      setState(true);
      setUpdateBtnFlag(false)
    }
  };

  const handleAddWithCupSizeOnly = () => {
    if (!cartDetailForConfirm) return;
    
    let simplifiedCartDetail = {
      ...cartDetailForConfirm,
      selectedOptions: cartDetailForConfirm.selectedOptions.filter(
        option => option.modifier_group_name !== "Toppings"
      )
    };
    
    let modifierPrice = 0;
    simplifiedCartDetail.selectedOptions.forEach(option => {
      option.option.forEach(modifier => {
        modifierPrice += isNaN(
          Number(modifier.price || modifier.modifier_price) * modifier.quantity
        )
          ? 0
          : Number((modifier.price || modifier.modifier_price) * modifier.quantity);
      });
    });
    
    simplifiedCartDetail.orderItemPrice = (simplifiedCartDetail.selectedCupSize[0]?.price || 0) + modifierPrice;
    simplifiedCartDetail.totalPrice = simplifiedCartDetail.orderItemPrice * simplifiedCartDetail.quantity;
    
    const matchIndex = findMatchingCartItem(simplifiedCartDetail, cartItem);
    
    if (matchIndex >= 0) {
      const updatedCart = [...cartItem];
      const updatedItem = {...updatedCart[matchIndex]};
      updatedItem.quantity = updatedItem.quantity + simplifiedCartDetail.quantity;
      updatedItem.totalPrice = updatedItem.orderItemPrice * updatedItem.quantity;
      updatedCart[matchIndex] = updatedItem;
      
      dispatch(setUpdateCart(updatedCart));
    } else {
      let arr = [simplifiedCartDetail];
      dispatch(setCart(arr));
    }
    
    toast.success(`${simplifiedCartDetail.title} Added to the cart`, {
      position: "bottom-left",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    
    setToppingsLimitAlert(false);
    setButtonLoader(false);
    setUpdateBtnFlag(false);
    return true;
  };

  const hadleAddToCart = () => {    
    setSelectedFav(cartDetail);
    if (store?.id) {
      let arr = [];
      arr.push(cartDetail);
      let selectedItemCupSize = favItemsDetail?.menu_serving_detail?.filter(
        (e) =>
          e?.serving_name?.toLowerCase() ===
          cartDetail?.selectedCupSize[0]?.serving_name?.toLowerCase()
      );       
      if (selectedItemCupSize?.length > 0 || !fav) {
        const toppingsModifier = cartDetail.selectedOptions?.find(
          (option) => option.modifier_group_name === "Toppings"
        );
        
        if (toppingsModifier) {
          const nonOreoCrumbToppings = toppingsModifier.option.filter(
            topping => topping.modifier_name !== 'Oreo Crumb'
          );
          
          const totalToppingsCount = nonOreoCrumbToppings.reduce(
            (sum, topping) => sum + (topping.quantity > 0 ? 1 : 0),
            0
          );
          
          if (totalToppingsCount > toppingsModifier.counter_limit) {
            toast.error(
              `You can select up to ${toppingsModifier.counter_limit} toppings (excluding Oreo Crumb)`,
              {
                position: "bottom-left",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
              }
            );
            setButtonLoader(false);
            return false;
          }
        }
        
        const matchIndex = findMatchingCartItem(cartDetail, cartItem);
        
        if (matchIndex >= 0) {
          
          const updatedCart = [...cartItem];
          const updatedItem = {...updatedCart[matchIndex]};
          updatedItem.quantity = updatedItem.quantity + counter;
          updatedItem.totalPrice = updatedItem.orderItemPrice * updatedItem.quantity;
          updatedCart[matchIndex] = updatedItem;          
          dispatch(setUpdateCart(updatedCart));
          setButtonLoader(false);
          setSelectedFav("");
          return true;
        } else {
          if (counter !== 0) {
            dispatch(setCart(arr));
            
            if (cartDetail.customizationOpt?.length > 0) {
              dispatch(setCustomizeOptions(cartDetail.customizationOpt));
            }
            
            if (cartDetail.menuServingDetail?.length > 0) {
              dispatch(setMenuServingDetails(cartDetail.menuServingDetail));
            }
            
            setButtonLoader(false);
            setSelectedFav("");
            return true;
          }
        }
      } else {
        toast.error(
          `${cartDetail.selectedCupSize[0]?.serving_name} serving is not present for ${cartDetail?.title}`,
          {
            position: "bottom-left",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
        setButtonLoader(false);
        return false;
      }
    } else {
      setState(true);
      return false;
    }
    setButtonLoader(false);
    return false;
  };
  useEffect(() => {
    if (selectedFav) {
      setButtonLoader(true);
      let catData = {
        storeId: nearestStore?.id,
        appType: "web",
      };      
      GetAllCategories(catData)
        .then((res) => {
          let categoryExist = res?.result?.filter(
            (element) =>
              Number(element?.category_id) === Number(selectedFav?.categoryId)
          );
          if (categoryExist?.length > 0) {
            let menuItem = {
              appType: "web",
              categoryId: selectedFav?.categoryId,
              customerId: 0,
              requestDate: "",
              storeId: nearestStore?.id,
            };
            GetAllMenu(menuItem)
            .then((res) => {
                let menuExist = res?.result.filter(
                  (element) => Number(element?.id) === Number(selectedFav?.id)
                );
                if (menuExist.length > 0) {
                  const matchIndex = findMatchingCartItem(selectedFav, cartItem);
                  if (matchIndex >= 0) {                    
                    const updatedCart = [...cartItem];
                    const updatedItem = {...updatedCart[matchIndex]};
                    updatedItem.quantity = updatedItem.quantity + counter;
                    updatedItem.totalPrice = updatedItem.orderItemPrice * updatedItem.quantity;
                    updatedCart[matchIndex] = updatedItem;
                                        
                    dispatch(setUpdateCart(updatedCart));
                    setButtonLoader(false);
                    toast.success(`${selectedFav.title} Added to the cart`, {
                      position: "bottom-left",
                      autoClose: 1000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: false,
                      draggable: true,
                      theme: "dark",
                    });
                    setSelectedFav("");
                  } else {
                    let arr = [selectedFav];
                    dispatch(setCart(arr));
                    setButtonLoader(false);
                    toast.success(`${selectedFav.title} Added to the cart`, {
                      position: "bottom-left",
                      autoClose: 1000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: false,
                      draggable: true,
                      theme: "dark",
                    });
                    setSelectedFav("");
                  }
                } else {
                  toast.error(
                    `This ${selectedFav.categoryName} is not available for selected store`,
                    {
                      position: toast.POSITION.TOP_RIGHT,
                    }
                  );
                  setButtonLoader(false);
                  setSelectedFav("");
                }
              })
              .catch((e) => {
                setButtonLoader(false);
                console.log(e);
              });
          } else {
            toast.error(
              `This ${selectedFav.title} is not available for selected store`,
              {
                position: toast.POSITION.TOP_RIGHT,
              }
            );
            setLoading(false);
            setSelectedFav("");
          }
        })
        .catch((e) => {
          setButtonLoader(false);
          console.log(e);
        });
    }
  }, [store?.id]);

const handleCustomization = (favorite) => {
  if (isLoading) return;
  setLocalLoading(true);
  dispatch(setIsLoading(true)); 
  if (bestSeller) {
    if (props.menuExists != false) {
      setCheckBestSeller(props);
      dispatch(setCheckBestSellerId(props));
      if (store?.id) {
        let catData = {
          storeId: store?.id,
          appType: "web",
        };
        GetAllCategories(catData)
          .then((res) => {
            let categoryExist = res?.result?.filter(
              (element) =>
                Number(element?.category_id) === Number(props?.category_id)
            );
            if (categoryExist?.length > 0) {
              let menuItem = {
                appType: "web",
                categoryId: props?.category_id,
                customerId: 0,
                requestDate: "",
                storeId: store?.id,
              };
              GetAllMenu(menuItem).then((res) => {
                let menuExist = res?.result.filter(
                  (element) => Number(element?.id) === Number(props?.id)
                );
                if (menuExist.length > 0) {
                  getCustomizationOption(data).then((res) => {
                    if (res.result && res) {
                      let cartDetailCopy = Object.assign({}, cartDetail);
                      
                      cartDetailCopy.customizationOpt =
                        res?.result[0][0]?.customizationOpt;
                      cartDetailCopy.cold = res?.result[0][0]?.cold;
                      cartDetailCopy.caffine = res?.result[0][0]?.caffeine;
                      cartDetailCopy.max_calories =
                        res?.result[0][0]?.max_calories;
                      cartDetailCopy.min_calories =
                        res?.result[0][0]?.min_calories;
                      cartDetailCopy.selectedCupSize = fav
                        ? [favItemsDetail?.menu_details?.menu_serving_detail[0]]
                        : [res?.result[0][0]?.menu_serving_detail[0]];
                      cartDetailCopy.description = res?.result[0][0]?.description;
                      let toppingCounterLimit = res?.result[0][0]?.customizationOpt?.filter((e)=>e?.modifier_group_name === "Toppings")
                      cartDetailCopy.toppings_counter_limit = toppingCounterLimit?.length>0?toppingCounterLimit[0]?.counter_limit:0
                      dispatch(
                        setSelectedCupSizes(
                          cartDetailCopy?.selectedCupSize?.length > 1
                            ? cartDetailCopy?.selectedCupSize?.filter(
                                (e) => e.tier_id > 0
                              )
                            : cartDetailCopy?.selectedCupSize
                        )
                      );

                      cartDetailCopy.menuServingDetail =
                        res?.result[0][0]?.menu_serving_detail;
                      dispatch(setCartDetailes(cartDetailCopy));
                      dispatch(setOpenPopUps(true));
                      dispatch(setBestSellerCheck(true));
                      dispatch(setTitles(""));
                      dispatch(setEditTitles(""));
                      dispatch(setCheckBestSellerId(""));
                      dispatch(setTabValue(1));
                      dispatch(setCustomizeOpen(true));
                    }
                  });
                } else {
                  toast.error(
                    `This ${props.title} is not available for selected store`,
                    {
                      position: toast.POSITION.TOP_RIGHT,
                    }
                  );
                  setLocalLoading(false);
                  dispatch(setIsLoading(false));
                }
              });
            } else {
              toast.error(
                `This ${props.title} is not available for selected store`,
                {
                  position: toast.POSITION.TOP_RIGHT,
                }
              );
              setLocalLoading(false);
              dispatch(setIsLoading(false));
            }
          })
          .catch((e) => {
            console.log(e);
            setLocalLoading(false);
            dispatch(setIsLoading(false));
          });
      } else {
        setState(true);
        setLocalLoading(false);
        dispatch(setIsLoading(false));
      }
    }
  } else {
    if (store?.id) {
      getCustomizationOption(data)
        .then((res) => {
          let servingName = "";
          if (fav) {
            servingName = cartDetail.selectedCupSize[0].serving_name;
          }
          if (res.result && res) {
            let cartDetailCopy = Object.assign({}, cartDetail);
            
            cartDetailCopy.customizationOpt = res?.result[0][0]?.customizationOpt;
            cartDetailCopy.cold = res?.result[0][0]?.cold;
            cartDetailCopy.caffine = res?.result[0][0]?.caffeine;
            cartDetailCopy.max_calories = res?.result[0][0]?.max_calories;
            cartDetailCopy.min_calories = res?.result[0][0]?.min_calories;
            cartDetailCopy.is_favourite = res?.result[0][0]?.is_favourite;
            let toppingCounterLimit = res?.result[0][0]?.customizationOpt?.filter((e)=>e?.modifier_group_name === "Toppings")
            cartDetailCopy.toppings_counter_limit = toppingCounterLimit?.length>0?toppingCounterLimit[0]?.counter_limit:0
            cartDetailCopy.selectedCupSize = fav
              ? res?.result[0][0].menu_serving_detail.filter(
                  (e) =>
                    e.serving_name.toLowerCase() ===
                    cartDetail.selectedCupSize[0].serving_name.toLowerCase()
                )[0]
                ? res?.result[0][0].menu_serving_detail.filter(
                    (e) =>
                      e.serving_name.toLowerCase() ===
                      cartDetail.selectedCupSize[0].serving_name.toLowerCase()
                  )
                : ""
              : [res?.result[0][0]?.menu_serving_detail[0]];
            cartDetailCopy.description = res?.result[0][0]?.description;

            if (fav) {
              dispatch(setTitles(""));
              dispatch(
                setSelectedCupSizes(
                  cartDetailCopy?.selectedCupSize?.length > 1
                    ? cartDetailCopy?.selectedCupSize?.filter(
                        (e) => e.tier_id > 0
                      )
                    : cartDetailCopy?.selectedCupSize
                )
              );
              dispatch(setEditTitles("FromCart"));
            } else {
              dispatch(setSelectedCupSizes(""));
              dispatch(setTitles(""));
              dispatch(setEditTitles(""));
            }
            cartDetailCopy.menuServingDetail = 
              res?.result[0][0]?.menu_serving_detail;
            
            if (favorite && favorite.selectedOption) {
              const selectedOptionsCopy = JSON.parse(JSON.stringify(favorite.selectedOption));
              
              const toppingsModifier = res?.result[0][0]?.customizationOpt?.find(
                element => element.modifier_group_name === "Toppings"
              );
              
              if (toppingsModifier) {
                const toppingsIndex = selectedOptionsCopy.findIndex(
                  option => option.modifier_group_name === "Toppings"
                );
                
                if (toppingsIndex !== -1) {
                  const toppingsOption = selectedOptionsCopy[toppingsIndex];
                  
                  const nonOreoCrumbCount = toppingsOption.option.reduce((sum, topping) => {
                    if (topping?.modifier_name === 'Oreo Crumb' || topping?.name === 'Oreo Crumb') {
                      return sum;
                    }
                    return sum + (topping.quantity > 0 ? 1 : 0);
                  }, 0);
                  
                  if (nonOreoCrumbCount > toppingsModifier.counter_limit) {
                    selectedOptionsCopy.splice(toppingsIndex, 1);
                  }
                }
              }
              
              cartDetailCopy.selectedOptions = selectedOptionsCopy;
            } else {
              cartDetailCopy.selectedOptions = [];
            } 

          if (cartDetailCopy.selectedCupSize[0] == null) {
            toast.error(
              `${servingName} serving size is not available for selected store`,
              {
                position: toast.POSITION.TOP_RIGHT,
              }
            );
            setLocalLoading(false);
            dispatch(setIsLoading(false));
          }
          dispatch(setCartDetailes(cartDetailCopy));
          dispatch(setOpenPopUps(true));
          dispatch(setCheckBestSellerId(""));
          if (
            cartDetailCopy.customizationOpt.length === 0 &&
            cartDetailCopy.menuServingDetail.length === 1
          ) {
              dispatch(setCustomizeOpen(false));
              let arr = [];
              let obj = Object.assign({}, cartDetailCopy);
              obj.btnModifierId = [];
              obj.btnModifier = [];
              obj.customizationOpt = [];
              obj.selectedOptions = [];
              obj.menuServingDetail = cartDetailCopy.menuServingDetail;
              obj.selectedCupSize = cartDetailCopy.menuServingDetail;
              obj.orderItemPrice =
                cartDetailCopy.menuServingDetail[0].price;
              const itemIndex = cartItem.findIndex(
                (item) => item.id === obj.id
              );
              if (itemIndex !== -1) {
                // If item exists, update its quantity
                const updatedCart = cartItem.map((item, index) =>
                  item.id === cartDetailCopy.id
                    ? { ...item, quantity: item.quantity + 1 }
                    : // Update quantity
                      item
                );
                dispatch(setUpdateCart(updatedCart));
              } else {
                arr.push(obj);
                dispatch(setCart(arr));
              }             
              toast.success(`${props?.title} added to the cart`);
              setLocalLoading(false);
              dispatch(setIsLoading(false));
              navigate(`/cart`);
            }else{
              dispatch(setCustomizeOpen(true));
            }             
          }
        })
        .catch((e) => {
          console.log(e);
          setLocalLoading(false);
          dispatch(setIsLoading(false));
          errorToastMessage();
        });
    } else {
      setState(true);
      setLocalLoading(false);
      dispatch(setIsLoading(false));
    }
  }
};
useEffect(() => {
    if (bestSeller) {
      if (
        store?.id &&
        checkBestSeller?.id &&
        checkBestSellerId?.id &&
        checkBestSeller?.id == checkBestSellerId?.id
      ) {
        setLoading(true);
        let catData = {
          storeId: store?.id,
          appType: "web",
        };
        GetAllCategories(catData)
          .then((res) => {
            let categoryExist = res?.result?.filter(
              (element) =>
                Number(element?.category_id) ===
                Number(checkBestSellerId?.category_id)
            );
            if (categoryExist?.length > 0) {
              let menuItem = {
                appType: "web",
                categoryId: checkBestSellerId?.category_id,
                customerId: 0,
                requestDate: "",
                storeId: store?.id,
              };
              GetAllMenu(menuItem).then((res) => {
                let menuExist = res?.result.filter(
                  (element) =>
                    Number(element?.id) === Number(checkBestSellerId?.id)
                );
                if (menuExist.length > 0) {
                  getCustomizationOption(data).then((res) => {
                    if (res.result && res) {
                      setLoading(false);

                      cartDetail.customizationOpt =
                        res?.result[0][0]?.customizationOpt;
                      cartDetail.cold = res?.result[0][0]?.cold;
                      cartDetail.caffine = res?.result[0][0]?.caffeine;
                      cartDetail.max_calories = res?.result[0][0]?.max_calories;
                      cartDetail.min_calories = res?.result[0][0]?.min_calories;
                      cartDetail.selectedCupSize = fav
                        ? [favItemsDetail?.menu_details?.menu_serving_detail[0]]
                        : [res?.result[0][0]?.menu_serving_detail[0]];
                      cartDetail.description = res?.result[0][0]?.description;
                      let toppingCounterLimit = res?.result[0][0]?.customizationOpt?.filter((e)=>e?.modifier_group_name === "Toppings")
                      cartDetail.toppings_counter_limit = toppingCounterLimit?.length>0?toppingCounterLimit[0]?.counter_limit:0
                      dispatch(
                        setSelectedCupSizes(
                          cartDetail?.selectedCupSize?.length > 1
                            ? cartDetail?.selectedCupSize?.filter(
                                (e) => e.tier_id > 0
                              )
                            : cartDetail?.selectedCupSize
                        )
                      );

                      cartDetail.menuServingDetail =
                        res?.result[0][0]?.menu_serving_detail;
                      dispatch(setCartDetailes(cartDetail));
                      dispatch(setOpenPopUps(true));
                      dispatch(setBestSellerCheck(true));
                      dispatch(setTitles(""));
                      dispatch(setEditTitles(""));
                      dispatch(setCheckBestSellerId(""));
                      dispatch(setTabValue(1));
                      dispatch(setCustomizeOpen(true));
                    }
                  });
                } else {
                  toast.error(
                    `This ${checkBestSellerId.title} is not available for selected store`,
                    {
                      position: toast.POSITION.TOP_RIGHT,
                    }
                  );
                  setLoading(false);
                }
              });
            } else {
              toast.error(
                `This ${checkBestSellerId.title} is not available for selected store`,
                {
                  position: toast.POSITION.TOP_RIGHT,
                }
              );
              setLoading(false);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }
  }, [store]);

  const handleFav = () => {
    if (favState == false) {
      setRemoveFav(true);
    } else {
      let fav = {
        cart_id: props?.cart_id,
        category_id: props?.category_id,
        customer_id: userDetail?.customer_id
          ? userDetail?.customer_id
          : userDetail?.person_id,
        fav_id: 0,
        fav_type: "menujson",
        is_favourite: 0,
        selectedOptions: favItemsDetail?.selectedOption,
        orderItemPrice: favItemsDetail?.order_item_price,
        menu_details: {
          cart_id: props?.cart_id,
          category_id: props?.category_id,
          category_name: props?.category_name,
          created_at: new Date()?.toISOString(),
          customizationOpt: props.customizationOpt,
          description: props?.Carddescription,
          discount_details: props?.discount_details,
          id: props.id,
          image: props?.image,
          is_active: props?.is_active,
          is_deleted: props?.is_deleted,
          is_favourite: props?.is_favourite,
          menu_serving_detail: props?.menu_serving_detail,
          price: props?.price,
          status: props?.status,
          store_id: props?.store_id,
          title: props.title,
          updated_at: new Date().toISOString(),
          min_calories: props?.min_calories,
          max_calories: props?.max_calories,
        },
        menu_id: menuId ? menuId : props?.id,
        order_id: "0",
      };
      dispatch(setFavoriteMenuStatus({
        menuId: menuId ? menuId : props?.id,
        isFavorite: false
      }));
  
      favoriteUnfavoriteMenu(fav)
        .then((res) => {
          if (res?.responseCode == 1) {
            dispatch(setFav(true));
            setFavItem(favItem ? false : true);
            dispatch(
              setUserFavItems(
                userFavItems.filter(
                  (item) => item.menu_id !== (menuId ? menuId : props?.id)
                )
              )
            );
            toast.success("Removed from your favorites", {
              position: toast.POSITION.TOP_RIGHT,
              pauseOnFocusLoss: false,
              autoClose: 1500,
              pauseOnHover: false,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: true,
            });
          } else {
            dispatch(setFavoriteMenuStatus({
              menuId: menuId ? menuId : props?.id,
              isFavorite: true
            }));
            errorToastMessage();
          }
        })
        .catch((e) => {
          dispatch(setFavoriteMenuStatus({
            menuId: menuId ? menuId : props?.id,
            isFavorite: true
          }));
          errorToastMessage();
          console.log(e);
        });
    }
  };

  useEffect(() => {
    if (fav && favState) {
      handleFav();
    }
  }, [favState]);

  // const isEditableItem = (item) => {
  //   const hasMultipleServingSizes = item?.menu_details?.menu_serving_detail?.length > 1;
  //   const hasModifiers = item?.menu_details?.customizationOpt?.length > 0;
  //   return hasMultipleServingSizes || hasModifiers;
  // };

  return (
    <>
      {
        (title = "MenuPage" && !likeItem && !fav && (
        <MyCard
        onClick={bestSeller && props?.menuExists === false ? undefined : handleCustomization}
        className={styles.item}
        style={{
          cursor:
          bestSeller && props?.menuExists === false ? "not-allowed" : "pointer",
          borderRadius: "10px",
          opacity: bestSeller && props?.menuExists === false ? "0.5" : "",
          position: "relative",
          pointerEvents: bestSeller && props?.menuExists === false ? "none" : "auto"
        }}
      >
          {loading && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "rgba(255, 255, 255, 0.7)",
                zIndex: 1
              }}
            >
              <ButtonLoader props={"4.5rem"} />
            </Box>
          )}
            <Box p={1} sx={{ display: "flex", alignItems: "center" }}>
              <Box
                className={styles.imgTran}
                component="img"
                sx={{
                  height: { lg: "12rem", xs: "9rem" },
                  transition: 'opacity 0.3s ease-in-out'
                }}
                loading="lazy" 
                alt="menuImage"
                src={props?.image ? props?.image : "./logos.png"}
              />
              <div>
                <Typography
                  sx={{
                    marginLeft: { lg: "2.3rem", xs: "1rem" },
                    color: "#39302B",
                    fontSize: { lg: "23px", xs: "18px" },
                    width: "20rem",
                    wordBreak: "break-word",
                    "@media (max-width: 600px)": {
                      width: props?.title?.length > 25 ? "13rem" : "18rem",
                      fontSize: "18px",
                    },
                  }}
                >
                  {props?.title}
                </Typography>
                {bestSeller && props?.menuExists === false ? (
                  <Typography
                    sx={{
                      marginLeft: { lg: "2.3rem", xs: "1rem" },
                      fontWeight: "bold",
                    }}
                  >
                    Unavailable at selected store
                  </Typography>
                ) : (
                  <br></br>
                )}
              </div>
            </Box>
          </MyCard>
        ))
      }

      {fav && !likeItem && (
        <MyCard
          className={styles.favItem}
          onClick={() => {
            if (favItemsDetail?.menuExists === false) {
              return undefined;
            } else {
              return handleCustomization(favItemsDetail);
            }
          }}
          // onClick={() => favItemsDetail?.menuExists === false ? undefined : handleCustomization(favItemsDetail)}
          style={{
            cursor: favItemsDetail?.menuExists === false ? "not-allowed" : "default",
            borderRadius: "10px",
            opacity: favItemsDetail?.menuExists === false ? "0.5" : "",
            position: "relative",
            pointerEvents: favItemsDetail?.menuExists === false ? "none" : "auto"
          }}
        >
          {loading && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "rgba(255, 255, 255, 0.7)",
                zIndex: 1
              }}
            >
              <ButtonLoader props={"4.5rem"} />
            </Box>
          )}
          <Box
            pt={2}
            pb={2}
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "row",
                lg: "row",
              },
              pointerEvents: favItemsDetail?.menuExists === false ? "none" : "auto",
              opacity: favItemsDetail?.menuExists === false ? 0.5 : 1
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",

                textAlign: "left",
                // paddingBottom: { xs: "0.1rem", lg: "0rem" },
                width: { xs: "100%", sm: "100%", md: "83%", lg: "83%" },
              }}
            >
              <Box
                className={styles.favImgTran}
                component="img"
                sx={{
                  height: { lg: "12rem", xs: "6rem" },
                }}
                alt="menuImage"
                src={props?.image ? props?.image : "./logos.png"}
              />
              <Box sx={{ width: "70%" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      color: "#39302B",
                      fontSize: { lg: "23px", xs: "18px" },
                      width: "auto",  
                      marginRight: "8px", 
                      display: "inline-block" ,
                    }}
                  >
                    {props?.title}
                  </Typography>

                  <FavoriteIcon
                    fontSize="small"
                    sx={{
                      fill: "#C30E2F",
                      marginLeft: "5px",
                      // marginTop: "-50px",
                      position: "relative",
                      left: props?.title.split(' ')[0].length > 15 ? "auto" : "-8px" 
                    }}
                    // onClick={handleFav}
                    onClick={(event) => {
                              event.stopPropagation();
                              handleFav();
                            }}
                  />
                </div>
                <Typography variant="subtitle2">
                  {
                    favItemsDetail?.menu_details?.menu_serving_detail[0]
                      ?.serving_name
                  }
                </Typography>
                <Typography variant="subtitle2" sx={{marginRight: '.8rem'}}>
                  {favItemsDetail?.selectedOption?.map(
                    (elm, index) =>
                      (elm?.option?.length > 0
                        ? elm?.option?.map((item) => {
                            return (
                              " " +
                              item?.modifier_name +
                              (item?.quantity > 1
                                ? " (" + item?.quantity + ") "
                                : "")
                            );
                          })
                        : " ") +
                      (favItemsDetail?.selectedOption?.length - 1 !== index
                        ? " | "
                        : " ")
                  )}
                </Typography>
                {favItemsDetail?.menuExists === false ? (
                  <Typography sx={{ fontWeight: "bold", marginTop: '0.5rem', marginBottom: '.8rem'}}>
                    Unavailable at selected store
                  </Typography>
                ) : (
                  <>
                  <div style={{ 
                    cursor: "pointer",
                    display: "inline-block",
                    visibility: favItemsDetail?.menuExists === false ? "hidden" : "visible"
                  }}>
                    <Button
                      variant="text"
                      sx={{
                        textDecoration: "underline",
                        backgroundColor: "#FFFFFF",
                        marginBottom: "0.5rem",
                        fontSize: ".8rem",
                        color: "#C60734",
                        padding: "0",
                        minWidth: "10px",
                        border: "none"
                      }}
                      onClick={() => handleCustomization(favItemsDetail)}
                    >
                      Edit
                    </Button>
                  </div>
                  <br></br>
                </>
                )}
                <Box sx={{display:{xs:"block",sm:"none"}, visibility: favItemsDetail?.menuExists === false ? "hidden" : "visible"}}>
                  {favItemsDetail?.menuExists === false?(<Button
                  disabled={true}
                  style={{
                    borderRadius: "15px",
                    padding: "0.2rem 1.6rem",
                    backgroundColor: "white",
                    color: "#C60734",
                    textTransform: "none",
                    border: "1px solid #C60734",
                  }}
                >
                {buttonLoader ? <ButtonLoader props={"4.5rem"} /> : "Add to Cart"}
            </Button>
            ) : (
              <Button
              disabled={updateBtnFlag}
              style={{
                borderRadius: "15px",
                padding: "0.2rem 1.6rem",
                backgroundColor: "white",
                color: "#C60734",
                textTransform: "none",
                border: "1px solid #C60734",
              }}
              onClick={(e) => {
                e.stopPropagation();
                handelfavOrder();
              }}
            >
              {buttonLoader ? <ButtonLoader props={"4.5rem"} /> : "Add to Cart"}
              </Button>
            )}
            </Box>
              </Box>
            </Box>
            <Box sx={{display:{xs:"none",sm:"block"}, visibility: favItemsDetail?.menuExists === false ? "hidden" : "visible"}}>
              {favItemsDetail?.menuExists === false?(<Button
                disabled={true}
                style={{
                  borderRadius: "15px",
                  padding: "0.2rem 1.6rem",
                  backgroundColor: "white",
                  color: "#C60734",
                  textTransform: "none",
                  border: "1px solid #C60734",
                }}
              >
                {buttonLoader ? <ButtonLoader props={"4.5rem"} /> : "Add to Cart"}
              </Button>
              ) : (
                <Button
                // disabled={favItemsDetail?.menuExists === false ? true : false}
                disabled={updateBtnFlag}
                style={{
                  borderRadius: "15px",
                  padding: "0.2rem 1.6rem",
                  backgroundColor: "white",
                  color: "#C60734",
                  textTransform: "none",
                  border: "1px solid #C60734",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handelfavOrder();
                }}
              >
                {buttonLoader ? <ButtonLoader props={"4.5rem"} /> : "Add to Cart"}
              </Button>
              )}
            </Box>
          </Box>
        </MyCard>
      )}

      {likeItem && (
        <div>
          <Box>
            <Avatar
              onClick={!isDisabled ? handleCustomization : undefined}
              sx={{
                mt: 3,
                bgcolor: isDisabled ? "#ccc" : "#BA0C2F", // Gray out when disabled
                cursor: isDisabled ? "not-allowed" : "pointer", // Change cursor style
                float: "right",
                width: 20,
                height: 20,
                marginBottom: "-1.5rem",
                zIndex: 1,
              }}
            >
              <AddIcon />
            </Avatar>
          </Box>
          <MyCard
            onClick={!isDisabled ? handleCustomization : undefined}
            sx={{
              display: "flex",
              flexDirection: "column",
              cursor: isDisabled ? "not-allowed" : "pointer",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              height: {xs:"6.5rem",sm:"6.5rem",md:"6.5rem",lg:"8rem"},
              width: {xs:"6.5rem",sm:"6.5rem",md:"6.5rem",lg:"8rem"},
              opacity: isDisabled ? 0.5 : 1,
              position: "relative"
            }}
          >
            {loading && (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "rgba(255, 255, 255, 0.7)",
                  zIndex: 1,
                  borderRadius: "50%"
                }}
              >
                <ButtonLoader props={"4.5rem"} />
              </Box>
            )}
            <Box
              className={styles.favImgTran}
              component="img"
              sx={{
                height: {xs:"3rem",sm:"3rem",md:"3rem",lg:"5rem"},
                width: {xs:"3rem",sm:"3rem",md:"3rem",lg:"5rem"},
              }}
              alt="menuImage"
              loading="lazy"
              src={props?.image}
            />
            <Box sx={{ textAlign: "center" }}>
              <Typography
                sx={{
                  color: "#39302B",
                  fontSize: 12,
                  overflow: "hidden", // Ensure text does not overflow
                  textOverflow: "ellipsis", // Add ellipsis if text is too long
                  display: "-webkit-box", // Display as a webkit box for line clamping
                  WebkitLineClamp: 2, // Limit the component to 2 lines
                  WebkitBoxOrient: "vertical",
                  lineHeight: 1.1,
                  flexWrap: "wrap",
                  pl: "10px",
                  pr: "10px",
                }}
              >
                {props?.title}
              </Typography>
              <Typography sx={{ fontSize: 12 }}>
                <b>${Number(props?.menu_serving_detail[0].price)?.toFixed(2)
                }</b>
              </Typography>
            </Box>
          </MyCard>
        </div>
      )}

      {fav && (
        <AlertPopup
          open={removeFav}
          setOpen={setRemoveFav}
          setFavState={setFavState}
          title={"removefavoritesItem"}
          buttonType1={true}
          buttonType2={true}
          displayTitle={
            "Are you sure want to remove this item from your favorites?"
          }
        />
      )}
      <AlertPopup
        open={openAlert}
        setOpen={setOpenAlert}
        setState={setState}
        state={state}
        title={"noStoreBestSeller"}
        displayTitle={"Please select store to order from Best sellers"}
        buttonType1={true}
        buttonType2={true}
      />
      <AlertPopup
        open={toppingsLimitAlert}
        setOpen={setToppingsLimitAlert}
        title="toppingsLimit"
        displayTitle={`Toppings have been changed. Would you like to proceed with the item?`}
        buttonType1={true}
        buttonType2={true}
        onConfirm={handleAddWithCupSizeOnly}
      />
      {openSnackBar && (
        <SnackbarPopUp
          key={indexs}
          open={openSnackBar}
          setopenpopup={setOpenSnackBar}
          messageInfo={`${cartDetail.title} Added to the cart`}
        />
      )}
    </>
  );
};

export default Card;
