import React from 'react'
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import EditIcon from "@mui/icons-material/Edit";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Typography } from '@mui/material';
import { setUserFlag } from '../Redux/StoreSlice';

const Footer = ({ state, setState }) => {
  const storeName = useSelector((state) => state.store.nearestStore);
  const dispatch = useDispatch();
  const location = useLocation();
  const storeOpen = () => {
    dispatch(setUserFlag(true));
    setState(true);
  }
  return (
    <>
      <AppBar position="fixed" sx={{ bgcolor: "white", borderTop: "1px solid #FFFFFF", height:"50px",borderBottom: "1px solid #C60734", top: 'auto', bottom: 0, 
        display: { 
          xs: location.pathname === "/checkout" || 
              location.pathname === "/payment" || 
              location.pathname === "/payment/success" 
              ? "none" 
              : "block",
          sm: "none" 
        } 
      }}>
        <Toolbar>
          {storeName ?
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }} 
            onClick={ 
               location.pathname == "/checkout" || location.pathname == "/payment" || location.pathname == "/payment/success"
               
              ? " "
              : storeOpen} >
              <div style={{ display: "flex"}}>
                <LocationOnIcon style={{ fill: "black" ,marginBottom:"1vh"}} />
                <Typography variant='subtitle2' color="black" style={{marginTop:"0.5vh",marginLeft:"2px"}} >{storeName ? `${(storeName.address).replace("~", " ")} ${storeName.zip_code}` : ""}</Typography>
              </div>
              {/* <div > */}
              <EditIcon sx={{ fill: "black" ,marginBottom:"1vh"}} />
              {/* </div> */}
            </div>
            :
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }} 
             onClick={ 
               location.pathname == "/checkout" || location.pathname == "/payment" || location.pathname == "/payment/success"
              ? " "
              : storeOpen} 
              >
              <div style={{ display: "flex" }}>
                <LocationOnIcon style={{ fill: "black" }} />
                <Typography variant='h6' color="black" >Select store</Typography>
              </div>
              <EditIcon style={{ fill: "black" }} />

            </div>}
        </Toolbar>
      </AppBar>
    </>
  )
}

export default Footer