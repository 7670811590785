import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles } from "@mui/styles";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUpdateCart } from "../Redux/CartSlice";
import { setNearestStore, setStoreCoordinate } from "../Redux/StoreSlice";

import { Box, Typography } from "@mui/material";
import Slide from "@mui/material/Slide";
import {
  setAutoDiscountDetail,
  setDiscountDetail,
  setFiveStarIndex,
  setFiveStarResponse,
  setFiveStarRewardDetail,
  setLogout,
} from "../Redux/UserSlice";

const useStyles = makeStyles((theme) => ({
  paper: { borderRadius: 15 },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} timeout={500} />;
});

const AlertPopup = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    open,
    setOpen,
    title,
    store,
    setState,
    setCounter,
    displayTitle,
    setTotalFivestar,
    buttonType1,
    buttonType2,
    setCupSizeState,
    setFavState,
    changedServing,
    setCurrentLocation,
    rewardObject,
    setCombineDiscount,
    handleFivestarReward,
    applyPromo,
    setCustmizationSomething,
    setAutoDisc,
    promoApply,
    setAlwaysPromoApply,
    onAgree,
    setServingDetail,
    setDisc,
    handleReorder,
    setStorePopup,
    onConfirm
  } = props;
  let navigate = useNavigate();

  const handleDisagree = () => {
    if (title === "previousOrder") {
      setOpen(false);
      setState(false);
    } else if (title === "pickUpLater") {
      setOpen(false);
    } else if (title === "noStoreBestSeller") {
      setOpen(false);
    } else if (title === "removeDiscount") {
      setOpen(false);
      setCounter("");
    } else if (title === "paymentCondition") {
      setOpen(false);
      navigate("/cart");
    } else if (title === "selectStore") {
      setOpen(false);
      setState(true);
    } else if (title === "combineDiscountPopup") {
      setOpen(false);
      if (rewardObject?.elm) {
        props.handleRemoveFiveStarLoyalty();
        if (props.setSelectedExpiringCardIndex) {
          props.setSelectedExpiringCardIndex(null);
        }
        if (props.setSelectedAvailableCardIndex) {
          props.setSelectedAvailableCardIndex(null);
        }
        if (props.setSelectedAutopilotCardIndex) {
          props.setSelectedAutopilotCardIndex(null);
        }
      }
    } else if (title === "removeStore") {
        setOpen(false);
        setStorePopup(false);
    } else {
      setOpen(false);
    }
  };

  const handleAgree = () => {
    if (onConfirm) {
      onConfirm(); 
    } else if (title === "logout") {
      window.sessionStorage.setItem("lastTab", "2");
      dispatch(setLogout());
      dispatch(setUpdateCart([]));
      setOpen(false);
      localStorage.clear();
      setTimeout(() => {
        navigate("/");
        window.location.reload();
      }, 100);
    } else if (title === "storeChange") {
      setOpen(false);
      dispatch(setUpdateCart([]));
      dispatch(setNearestStore(store));
      dispatch(
        setStoreCoordinate({
          lat: Number(store?.latitude),
          lng: Number(store?.longitude),
        })
      );
      setState(false);
    } else if (title === "removeItem") {
      setCounter(0);
      setOpen(false);
    } else if (title === "previousOrder") {
      setOpen(false);
      handleReorder();
    } else if (title === "locationAccess") {
      setOpen(false);
      dispatch(setState(true));
    } else if (title === "noStoreBestSeller") {
      setOpen(false);
      setState(true);
    } else if (title === "removeStore") {
      setCurrentLocation(true);
      setOpen(false);
      dispatch(setNearestStore(""));
      dispatch(setUpdateCart([]));
      navigate("/")
      if (onAgree) {
        onAgree();
      }
      if (setState) {
        setState(false);
      }
      return; 
    } else if (title === "removefavoritesItem") {
      setOpen(false);
      setFavState(true);
    } else if (title === "noReorder") {
      setOpen(false);
      setState(null);
    } else if (title === "customization") {
      setOpen(false);
      setCounter(true);
      setServingDetail(changedServing.servingDetail);
      setCupSizeState(changedServing.cupSize);
      setCustmizationSomething([]);
    } else if (title === "pickUpLater") {
      setOpen(false);
    } else if (title === "combineDiscountPopup") {
      if (promoApply) {
        applyPromo("from AlertPopup");
      } else {
        setAutoDisc(0);
        setAlwaysPromoApply(false);
        dispatch(setAutoDiscountDetail(""));
      }
      handleFivestarReward(
        rewardObject.perk,
        rewardObject.elm,
        rewardObject.index
      );
      setCombineDiscount(false);
      setOpen(false);
    } else if (title === "removeDiscount") {
      setOpen(false);
      dispatch(setFiveStarIndex(Number.MAX_VALUE));
      dispatch(setFiveStarRewardDetail(""));
      dispatch(setFiveStarResponse(""));
      dispatch(setDiscountDetail(""));
      setDisc(0)
      setCurrentLocation("");
      setCupSizeState();
      setFavState("");
      setTotalFivestar(0);
    }
    //  else if (title === "priceChange") {
    //   if (store.updatedCartItems) {
    //     dispatch(setUpdateCart(store.updatedCartItems));
    //   }

    //   dispatch(setNearestStore(store));
    //   dispatch(
    //     setStoreCoordinate({
    //       lat: Number(store.latitude),
    //       lng: Number(store.longitude),
    //     })
    //   );
    // }
    setOpen(false);
    if (setState) setState(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        classes={{
          paper: classes.paper,
        }}
        TransitionComponent={Transition}
      >
        <Box p={1} sx={{ background: "#E7E7E7" }}>
          <DialogTitle textAlign="center">
            <img src="./NewLogoG.png" alt="" width="50%" />
          </DialogTitle>
          <Typography sx={{ paddingX: "1rem" }} textAlign="center">
            {displayTitle}
          </Typography>
          <DialogActions>
            <div>
              {buttonType1 && (
                <Button onClick={handleDisagree}>
                  {title === "storeUnavailable" ||
                  title === "selectStore" ||
                  title === "pickUpLater" ||
                  title === "paymentCondition"
                    ? "OK"
                    : "Cancel"}
                </Button>
              )}
              {buttonType2 && (
                <Button onClick={handleAgree} autoFocus>
                  {" "}
                  Continue{" "}
                </Button>
              )}
            </div>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
};
export default AlertPopup;
