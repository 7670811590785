import {
  Container,
  Typography,
  Card,
  Grid,
  Button,
  Backdrop,
} from "@mui/material";
import { Divider } from "@mui/material";
import { Box } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Checkout.css";
import React from "react";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import CheckoutItem from "./CheckoutItem";
import {
  creditCardRefundPayment,
  givexCardRefundPayment,
  updateOrderPaymentStatus,
} from "../../Api/API";
import {
  AmPmFormat,
  errorToastMessage,
  pickupTime,
  uiDate,
} from "../../Common/utils";
// import { useHistory } from 'react-router-dom';
import { setPlacedOrder, setTabValue, setUpdateCart } from "../../Redux/CartSlice";
import { useNavigate } from "react-router-dom";
import {
  setCheckoutFlag,
  setDiscountDetail,
  setFiveStarRewardDetail,
  setClickActiveOrders,
  setCheckActive,
  setAutoDiscountDetail,
} from "../../Redux/UserSlice";
import { numberWithCommas } from "../../Common/utils";
import { setFiveStarResponse } from "../../Redux/UserSlice";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { QRCodeSVG } from "qrcode.react";
import { encode } from "base-64";

const Checkout = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isOrderUpdated, setIsOrderUpdated] = useState(false);
  const isGuestLogin = useSelector((state) => state.user.isGuestLogin);
  const defaultCard = useSelector((state) => state.user.defaultCardd);
  const checkoutFlag = useSelector((state) => state.user.checkoutFlag);
  const cartData = useSelector((state) => state.cart.cartData);
  const paymentData = useSelector((state) => state.cart.paymentData);
  const givexPaymentDetail = useSelector(
    (state) => state.store.givexPaymentDetail
  );
  const pickUpLaterTime = useSelector((state) => state.cart.pickUpLaterTime);
  const placedOrder = useSelector((state) => state.cart.placedOrder);
  const storeDetails = useSelector((state) => state.store.nearestStore);
  const [qrCodeData, setQrCodeData] = useState("");

  const userDetail = useSelector((state) => state.user.user);
  const checkActive = useSelector((state) => state.user.checkActive);

  const paymentMethodImage = (paymentMethod, cardType) => {
    let imageUrl;

    switch (paymentMethod?.toLowerCase()) {
      case "visa":
        imageUrl = "./visa.png";
        break;
      case "mastercard":
      case "mc":
        imageUrl = "./mastercard.png";
        break;
      case "amex":
      case "american-express":
        imageUrl = "./Amex.png";
        break;
      case "maestro":
        imageUrl = "./maestro.png";
        break;
      case "dinersclub":
        imageUrl = "./dinersclub.png";
        break;
      case "discover":
        imageUrl = "./discover.png";
        break;
      case "jcb":
        imageUrl = "./jcb.png";
        break;
      case "gift_card":
        imageUrl = "./giftcard.jpg";
        break;
      default:
        imageUrl = "./Amex.png"; // Default to American Express for unknown card types
    }

    return imageUrl;
  };

  useEffect(() => {
    if (placedOrder?.order_id) {
      let endodedUrl = encode(
        `deviceType=kiok&orderId=${placedOrder?.order_id}`
      );
      setQrCodeData(
        `https://d66qze9tdwrtl.cloudfront.net/kiosk/downloadReceipt?${endodedUrl}`
      );
    }
  }, []);

  const handleRefund = () => {
    let flag = false;
    if (givexPaymentDetail?.type === "givex") {
      flag = true;
      let givexPaymentData = {
        cardNumber: givexPaymentDetail?.cardNumber,
        clientId: givexPaymentDetail?.clientId,
        description: givexPaymentDetail?.description,
        originalRequestId: givexPaymentDetail?.originalRequestId,
        requestId: givexPaymentDetail?.requestId,
        transactionRefNumber: givexPaymentDetail?.transactionRefNumber,
        storeId: givexPaymentDetail?.storeId,
      };
      givexCardRefundPayment(givexPaymentData)
        .then((res) => {
          if (res?.responseCode === 0) {
            toast.error(
              "Oops Something Went Wrong ,Your refund has been initiated",
              {
                position: toast.POSITION.TOP_RIGHT,
                pauseOnFocusLoss: false,
                autoClose: 1500,
                pauseOnHover: false,
                hideProgressBar: false,
                closeOnClick: true,
                draggable: true,
              }
            );
          }
        })
        .catch((e) => console.log(e));
    }

    if (givexPaymentDetail?.type === "credit_card") {
      flag = true;
      let creditCardDetail = {
        requestId: givexPaymentDetail?.requestId,
        transactionRefNumber: givexPaymentDetail?.transactionRefNumber,
        storeId: givexPaymentDetail?.storeId,
      };
      creditCardRefundPayment(creditCardDetail)
        .then((res) => {
          if (res?.responseCode === 0) {
            toast.error(
              "Oops Something Went Wrong ,Your refund has been initiated",
              {
                position: toast.POSITION.TOP_RIGHT,
                pauseOnFocusLoss: false,
                autoClose: 1500,
                pauseOnHover: false,
                hideProgressBar: false,
                closeOnClick: true,
                draggable: true,
              }
            );
          }
        })
        .catch((e) => console.log(e));
    }

    if (flag === false && givexPaymentDetail?.length === 2) {
      let creditCardDetail = {
        requestId: givexPaymentDetail[0]?.requestId,
        transactionRefNumber: givexPaymentDetail[0]?.transactionRefNumber,
        storeId: givexPaymentDetail[0]?.storeId,
      };
      creditCardRefundPayment(creditCardDetail)
        .then((res) => {
          if (res?.responseCode == 0) {
            toast.error(
              "Oops Something Went Wrong ,Your refund has been initiated",
              {
                position: toast.POSITION.TOP_RIGHT,
                pauseOnFocusLoss: false,
                autoClose: 1500,
                pauseOnHover: false,
                hideProgressBar: false,
                closeOnClick: true,
                draggable: true,
              }
            );
          }
        })
        .catch((e) => console.log(e));
      let givexPaymentData = {
        cardNumber: givexPaymentDetail[1]?.cardNumber,
        clientId: givexPaymentDetail[1]?.clientId,
        description: givexPaymentDetail[1]?.description,
        originalRequestId: givexPaymentDetail[1]?.originalRequestId,
        requestId: givexPaymentDetail[1]?.requestId,
        transactionRefNumber: givexPaymentDetail[1]?.transactionRefNumber,
        storeId: givexPaymentDetail[1]?.storeId,
      };
      givexCardRefundPayment(givexPaymentData)
        .then((res) => {
          if (res?.responseCode === 0) {
            toast.error(
              "Oops Something Went Wrong,Your refund has been initiated",
              {
                position: toast.POSITION.TOP_RIGHT,
                pauseOnFocusLoss: false,
                autoClose: 1500,
                pauseOnHover: false,
                hideProgressBar: false,
                closeOnClick: true,
                draggable: true,
              }
            );
          }
        })
        .catch((e) => console.log(e));
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!checkoutFlag) {
      navigate("/");
    } else {
      window.history.replaceState(null, "", "/");
    }

    if (checkoutFlag) {
      setLoading(true);
      let updatedPaymentData = {
        authCode: "",
        cardLastNumber:
          paymentData[0].paymentMethod === "gift_card"
            ? paymentData[0].givex_num
            : defaultCard,
        orderStatus: "pending",
        paymentMethod: paymentData[0].paymentMethod,
        paymentStatus: "success",
        referenceNumber: paymentData[0].trans_num,
        saleId: placedOrder.order_id,
        payType: paymentData[0].payType,
        givexCode: paymentData[0].givex_code,
      };

      updateOrderPaymentStatus(updatedPaymentData)
        .then((res) => {
          if (res.responseCode === 1) {
            setLoading(false);
            setIsOrderUpdated(true);
            dispatch(setUpdateCart([]));
            dispatch(setFiveStarRewardDetail(""));
            dispatch(setFiveStarResponse(""));
            dispatch(setDiscountDetail(""));
            dispatch(setAutoDiscountDetail(""));
          } else {
            setLoading(false);
            setIsOrderUpdated(false);
            handleRefund();
            dispatch(setDiscountDetail(""));
            dispatch(setAutoDiscountDetail(""));
            dispatch(setPlacedOrder(""));
            dispatch(setFiveStarRewardDetail(""));
            dispatch(setFiveStarResponse(""));
          }
        })
        .catch(() => {
          setLoading(false);
          setIsOrderUpdated(false);
          handleRefund();
          dispatch(setDiscountDetail(""));
          dispatch(setAutoDiscountDetail(""));
          dispatch(setFiveStarRewardDetail(""));
          dispatch(setFiveStarResponse(""));
          dispatch(setPlacedOrder(""));
          console.log("error");
        });

      dispatch(setCheckoutFlag(false));
    }
  }, []);

  window.onpopstate = () => {
    navigate("/");
  };
  return (
    <>
      {/* <div style={{ marginBottom: "1rem", marginTop: ".4rem" }}>
        <Typography
          sx={{
            color: "#C60734",
            marginLeft: {
              lg: "10.3rem",
              md: "1.6rem",
              sm: "1.6rem",
              xs: "1rem",
            },
          }}
          variant="h5"
        >
          Order Summary
        </Typography>
        
      </div> */}
      <div
        style={{
          marginBottom: "1rem",
          cursor: "pointer",
          // marginTop: "1rem",
          margin: "4%"
        }}
        onClick={() => {
          dispatch(setTabValue(2));
          let path = `/`;
          navigate(path);
        }}
      >
     <Typography
          sx={{
            color: "#C60734",
            fontSize: { xs: "0.9rem", sm: "1.2rem", md: "1.5rem" },
            display: "flex",
            alignItems: "center",
            // border:"1px solid blue",
            "@media (max-width: 450px)": {
              marginTop:"-1vh",
              marginBottom:"-1vh"
            },
            "@media (min-width: 451px) and (max-width:1000px)":{
               marginTop:"-2vh",
              marginBottom:"-2vh",
            },"@media (min-width: 1001px) and (max-width:1050px)":{
               marginTop:"-2.5vh",
              marginBottom:"-2.5vh",
            },
            "@media (min-width: 1051px)and (max-width:1200px)": {
              marginTop:"-7vh",
              marginBottom:"-7vh",
              marginLeft:"9vw"
           },
           "@media (min-width: 1200px)and (max-width:1300px) ": {
              marginTop:"-5.5vh",
              marginBottom:"-5.5vh",
              marginLeft:"1vw"
           },
           "@media (min-width: 1301px) ":{
            marginTop:"-5vh",
            marginBottom:"-5vh",
            marginLeft:"9vw"
           }

            // gap: { xs: "4px", sm: "6px", md: "2px" }, // Spacing between icon and text
          }}
          variant="h5"
        >
          <ArrowBackIosIcon
            sx={{
              fontSize: { xs: 12, sm: 16, md: 24 },
              fontFamily: "Lusitana",
              fill: "#C60734",
              marginTop:"-2px"
            }}
          />
          Order Summary
        </Typography>

      </div>
      <Box
        sx={{
          backgroundImage: { md: `url("skyline.png")`, xs: " " },
          backgroundSize: "100% 35%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center bottom",
          paddingBottom: "1rem",
          height: "88vh",
        }}
      >
        <Backdrop sx={{ color: "#fff", zIndex: 1000 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>

        {loading === false ? (
          <Container
            sx={{
              marginTop: "1vh",
              paddingBottom: { lg: 0, md: 0, sm: "1rem", xs: "62px" },
            }}
          >
            {isOrderUpdated ? (
              <section>
                <div>
                  <Card>
                    <div
                      style={{
                        textAlign: "center",
                        margin: "0.8rem auto",
                        justifyContent: "center",
                        border: "1px solid black",
                        borderRadius: "25px",
                        paddingBottom: "20px",
                        paddingTop: "20px",
                        width: "90%",
                        maxWidth: "700px",
                        minWidth: "200px",
                        boxSizing: "border-box",
                      }}
                    >
                      <Typography variant="subtitle1" fontWeight="600">
                        Your order has been placed!
                      </Typography>

                      <Typography gutterBottom variant="subtitle2">
                        {!pickUpLaterTime
                          ? "We will notify you when your order is ready"
                          : `Your order will be ready at ${pickUpLaterTime}`}
                      </Typography>

                      {isGuestLogin === true ? (
                        ""
                      ) : (
                        <Button
                          variant="contained"
                          sx={{
                            marginTop: "1rem",
                            marginBottom: "1.5rem",
                            padding: {
                              lg: "0.2rem 3rem",
                              md: "0.2rem 3rem",
                              sm: "0.2rem 3rem",
                              xs: "0.2rem 2rem",
                            },
                          }}
                          style={{
                            borderRadius: 20,
                            backgroundColor: "white",
                            color: "#C60734",
                            textTransform: "none",
                            border: "1px solid #C60734",
                            fontWeight: "600",
                          }}
                          onClick={() => {
                            navigate(`/orderHistory`, {
                              state: {
                                title: "Order Summary",
                                id: placedOrder?.custom_order_number
                              }
                            });
                            dispatch(setClickActiveOrders(true));
                            dispatch(setCheckActive(true));
                          }}
                        >
                          View Order
                        </Button>
                      )}
                      <Typography
                        style={{ paddingRight: "5px", paddingLeft: "5px" }}
                      >
                        <LocationOnIcon
                          style={{ fill: "#C60734", marginTop: "-5px" }}
                        />
                        <span
                          style={{ fontWeight: "600" }}
                          sx={{ marginLeft: { xs: "-20px" } }}
                        >
                          {storeDetails?.store_name} , {storeDetails?.address}
                        </span>
                      </Typography>

                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <img
                          src="./pickup_icon.png"
                          height="20px"
                          style={{ marginRight: "10px", marginTop: "8px" }}
                        />
                        <h6 style={{ fontWeight: "600", marginTop: "10px" }}>
                          Pickup ASAP
                        </h6>
                      </div>

                      <Typography>
                        <div
                          style={{ marginTop: "10px", marginBottom: "10px" }}
                        >
                          Order No. :{" "}
                          <span style={{ fontWeight: "600" }}>
                            {placedOrder?.custom_order_number}
                          </span>
                        </div>
                      </Typography>

                      <Typography>
                        <div
                          style={{
                            marginBottom: "10px",
                            paddingLeft: "15px",
                            paddingRight: "15px",
                          }}
                        >
                          Order Date & Time :{" "}
                          <span style={{ fontWeight: "600" }}>
                            {uiDate(placedOrder?.pickup_date)}
                          </span>
                        </div>
                      </Typography>
                    </div>
                    {/* ==============================================================================            */}

                    {isOrderUpdated ? (
                      <div
                        style={{
                          maxWidth: "650px",
                          justifyContent: "center",
                          margin: "auto",
                          minWidth: "200px",
                        }}
                      >
                        <div
                          sx={{
                            borderRadius: "10px",
                            border: "1px solid blue",
                            height: { lg: "16rem", md: "16rem" },
                          }}
                        >
                          {placedOrder
                            ? placedOrder?.result[0]?.orderDetails?.map((e) => {
                              return <CheckoutItem Detail={e} />;
                            })
                            : ""}
                        </div>
                      </div>
                    ) : (
                      " "
                    )}

                    {/* ========================================================================= */}

                    {isOrderUpdated ? (
                      <div
                        style={{
                          maxWidth: "650px",
                          minWidth: "200px",
                          margin: "auto",
                        }}
                      >
                        <Container
                          style={{ marginBottom: "-25px", marginTop: "-18px" }}
                          sx={{
                            padding: { xs: "1rem", sm: "2rem", md: "3rem" },
                            maxWidth: "100%",
                            paddingLeft: { md: "105px", sm: "-20px" },
                            paddingRight: { md: "25px", xs: "05px" }, 
                            "@media (max-width: 435px)": {
                              // marginLeft: "45px",
                              width: "81%",
                              fontSize: "70%",
                            },
                            "@media (min-width: 435px) and (max-width: 750px)":
                            {
                              // marginLeft: "70px",
                              width: "75%",
                              fontSize: "80%",
                            },
                            "@media (min-width: 750px) and (max-width: 900px)":
                            {
                              // marginLeft: "75px",
                              width: "87%",
                              fontSize: "85%",
                            },
                            "@media (min-width: 900px) and (max-width: 1050px)":
                            {
                              // marginLeft: "35px",
                              width: "98%",
                              fontSize: "90%",
                            },
                            "@media (min-width: 1050px) and (max-width: 1400px)":
                            {
                              // marginLeft: "35px",
                              width: "98%",
                              fontSize: "90%",
                            },
                            "@media(min-width:2000px)": {
                              // marginLeft: "45px",
                              width: "98%",
                              fontSize: "90%",
                            },
                          }}
                        >
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              color: "#969696",
                            }}
                          >
                            <Typography variant="subtitle1" gutterBottom>
                              Sub-Total:
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              gutterBottom
                              style={{ marginRight: { xs: "10px" } }}
                            >{`$${numberWithCommas(
                              (cartData.subTotal
                                ? Number(cartData?.subTotal)
                                : 0.0
                              ).toFixed(2)
                            )}`}</Typography>
                          </Box>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              color: "#969696",
                            }}
                          >
                            <Typography variant="subtitle1" gutterBottom>
                              Discount:
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                              {`-$${cartData.totalDiscount
                                ? Number(cartData.totalDiscount).toFixed(2)
                                : "0.00"
                                }`}
                            </Typography>
                          </Box>
                          {cartData?.webFeeValue !== 0 ? (
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                color: "#969696",
                              }}
                            >
                              <Typography variant="subtitle1" gutterBottom>
                                {" "}
                                Taxes & Fees:
                              </Typography>
                              <Typography
                                variant="subtitle1"
                                gutterBottom
                              >{`$${numberWithCommas(
                                Number(
                                  cartData.taxValue
                                    ? Number(cartData.taxValue).toFixed(2)
                                    : "0.00"
                                ).toFixed(2)
                              )}`}</Typography>
                            </Box>
                          ) : (
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                color: "#969696",
                              }}
                            >
                              <Typography variant="subtitle1" gutterBottom>
                                {" "}
                                Tax:
                              </Typography>
                              <Typography
                                variant="subtitle1"
                                gutterBottom
                              >{`$${numberWithCommas(
                                Number(
                                  cartData.taxValue
                                    ? Number(cartData.taxValue).toFixed(2)
                                    : "0.00"
                                ).toFixed(2)
                              )}`}</Typography>
                            </Box>
                          )}
                          <Divider
                            style={{ marginTop: "1vh", marginBottom: "1vh" }}
                          />
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography variant="subtitle1" gutterBottom>
                              Total:
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>{`$${cartData.total
                              ? numberWithCommas(
                                Number(cartData.total).toFixed(2)
                              )
                              : "0.00"
                              }`}</Typography>
                          </Box>
                          <Divider
                            style={{ marginTop: "1vh", marginBottom: "1vh" }}
                          />

                          {/* <div
                         style={{
                           display: "flex",
                           flexDirection: "row",
                           justifyContent: "end",
                           alignItems: "center",
                           paddingRight:"10px"

                         }} > 
                                            
                       {paymentData[0]?.paymentMethod === "credit_card" && (
                     <>
                       <Box
                         component="img"
                         sx={{ width: "3.3rem", verticalAlign: "middle" }}
                         src={`/${paymentData[0]?.card_type?.toLowerCase()}.png`}
                       />
                       <Typography sx={{ pl: "1rem", fontSize: { xs: '0.75rem', sm: '0.875rem', md: '1rem' } }}>
                         {`****${paymentData[0]?.card_last_no?.slice(-4)}`}
                       </Typography>
                     </>
                   )}
                   {paymentData[0]?.paymentMethod === "gift_card" && (
                     <>
                       <Box
                         component="img"
                         sx={{ width: "3.3rem", verticalAlign: "middle" }}
                         src="/giftcard.jpg"
                       />
                       <Typography sx={{ pl: "1rem", fontSize: { xs: '0.75rem', sm: '0.875rem', md: '1rem' } }}>
                         {`****${paymentData[0]?.givex_num?.slice(-4)}`}
                       </Typography>
                     </>
                   )}
                 </div> */}

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "end",
                              alignItems: "center",
                              marginBottom: "15px",
                              maxWidth: "100%",
                              // paddingRight: "10px",
                            }}
                          >
                            {paymentData && paymentData[0] && (
                              <>
                                {paymentData[0]?.paymentMethod ===
                                  "credit_card" ? (
                                  <>
                                    <Box
                                      component="img"
                                      sx={{
                                        width: "3.3rem",
                                        verticalAlign: "middle",
                                      }}
                                      src={paymentMethodImage(
                                        paymentData[0]?.paymentMethod,
                                        paymentData[0]?.card_type
                                      )}
                                    />
                                    <Typography
                                      sx={{
                                        pl: "1rem",
                                        fontSize: {
                                          xs: "0.75rem",
                                          sm: "0.875rem",
                                          md: "1rem",
                                        },
                                      }}
                                    >
                                      {paymentData[0]?.card_last_no
                                        ? `****${paymentData[0].card_last_no.slice(
                                          -4
                                        )}`
                                        : `****${defaultCard?.slice(-4) || "****"
                                        }`}
                                    </Typography>
                                  </>
                                ) : paymentData[0]?.paymentMethod ===
                                  "gift_card" ? (
                                  <>
                                    <Box
                                      component="img"
                                      sx={{
                                        width: "3.3rem",
                                        verticalAlign: "middle",
                                      }}
                                      src={paymentMethodImage(
                                        paymentData[0]?.paymentMethod
                                      )} // Use gift card image for gift card
                                    />
                                    <Typography
                                      sx={{
                                        pl: "1rem",
                                        fontSize: {
                                          xs: "0.75rem",
                                          sm: "0.875rem",
                                          md: "1rem",
                                        },
                                      }}
                                    >
                                      {paymentData[0]?.givex_num
                                        ? `****${paymentData[0].givex_num.slice(
                                          -4
                                        )}`
                                        : "****"}
                                    </Typography>
                                  </>
                                ) : null}
                              </>
                            )}
                          </div>
                        </Container>
                        {/* </Card> */}
                      </div>
                    ) : (
                      " "
                    )}

                    {/* ========================================================================= */}

                    <div>
                      <Box />
                      {userDetail?.card_last_no ? (
                        <Typography
                          sx={{
                            pl: "1rem",
                            fontSize: {
                              xs: "0.75rem",
                              sm: "0.875rem",
                              md: "1rem",
                            },
                          }}
                        >{` ****${paymentData?.card_last_no} `}</Typography>
                      ) : (
                        ""
                      )}
                    </div>

                    {placedOrder?.accumlated_point > 0 && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginBottom: "5px",
                        }}
                      >
                        <Typography
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            textAlign: "center",
                            marginTop: "10px",
                            marginBottom: "40px",
                          }}
                        >
                          <b>
                            Congratulations! You earned{" "}
                            {placedOrder?.accumlated_point} points
                          </b>
                        </Typography>
                        <img
                          src="./newCupImg.png"
                          height="60rem"
                          style={{
                            marginLeft: "10px",
                            marginBottom: "40px",
                            paddingRight: "10px",
                          }}
                        />
                      </div>
                    )}

                    {/* QR Code and Digital Receipt Link */}
                    {/* 
                    {qrCodeData && (
                      <Box sx={{ mt: 7, textAlign: "center", marginBottom: "20px" }}>
                        <Typography fontSize="15px" gutterBottom>
                          Scan for Digital Receipt
                          <br />
                        </Typography>
                        <QRCodeSVG
                          value={qrCodeData}
                          sx={{
                            height: { xs: "40px", sm: "60px", md: "80px" }
                          }}
                          bgColor="none"
                          fgColor="black"
                        />
                        <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                          <br />
                          <a href={qrCodeData}>
                            <u>www.scanmefordigitalreceipt.gongchausa.com</u>
                          </a>
                        </div>
                      </Box>
                    )}
                  */}
                  </Card>
                </div>
              </section>
            ) : (
              <div style={{ textAlign: "center", marginTop: "2rem" }}>
                <Typography
                  sx={{ color: "#C60734" }}
                  variant="h6"
                  fontWeight="350"
                >
                  Your order is failed and refund initiated.
                </Typography>
              </div>
            )}
          </Container>
        ) : (
          ""
        )}
      </Box>
    </>
  );
};

export default Checkout;
