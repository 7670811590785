import {
  Backdrop,
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Slide,
  Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  GetAllCategories,
  GetAllMenu,
  getAllOrderByCustomersec,
  getCustomerOrderById,
  getCustomizationOption
} from "../../Api/API";
import AlertPopup from "../../Common/AlertPopup";
import ButtonLoader from "../../Common/ButtonLoader";
import { uiDate } from "../../Common/utils";
import {
  setCart,
  setCustomerOrderList,
  setCustomizeOptions,
  setFavoriteMenuStatus,
  setMenuServingDetails,
  setUpdateCart,
} from "../../Redux/CartSlice";
import PreviousOrderCard from "./PreviousOrderCard";
import { findMatchingCartItem, updateCartWithItem } from "../../Common/utils/cartUtils";

toast.configure();
const useStyle = makeStyles((theme) => ({
  btnLogin: {
    backgroundColor: "#B7273B",
    fontFamily: "Lusitana",
    textTransform: "none",
  },
}));

const PreviousOrder = ({ setState,totalRecord,setTotalRecord }) => {
  const classes = useStyle();
  const [page, setPage] = useState(0);
  const [buttonLoader, setButtonLoader] = useState(false);
  const dispatch = useDispatch();
  const [indexState, setIndexState] = useState(0);
  const [loading, setLoading] = useState(false);
  const cartItem = useSelector((state) => state.cart.cart);
  const store = useSelector((state) => state.store.nearestStore);
  const userDetail = useSelector((state) => state.user.user);
  const [displayTitle, setDisplayTitle] = useState("");
  const [title, setTitle] = useState("");
  const [remove, setRemove] = useState(false);
  const [reorderClicked, setReorderClicked] = useState();
  const [viewMoreLoader, setViewMoreLoader] = useState(false);
  const [dataAvailability, setDataAvailability] = useState(true);
  const [getFavorite, setFavorite] = useState(false);
  // const [previousOrderMenu, setPreviousOrderMenu] = useState([]);
  const [updateBtnFlag, setUpdateBtnFlag] = useState(false)
  const customerOrderList = useSelector((state) => state.cart.customerOrderList);
  const [toppingsLimitAlert, setToppingsLimitAlert] = useState(false);
  const [cartDetailForConfirm, setCartDetailForConfirm] = useState(null);
  const [toppingsLimit, setToppingsLimit] = useState(0);

  let data = {
    orderType: "",
    storeId: store ? store?.id : 1,
    type: "all",
    userId: userDetail.customer_id
      ? userDetail.customer_id
      : userDetail.person_id,
  };
  useEffect(() => {
    dispatch(setFavoriteMenuStatus({}));
    if(customerOrderList?.length===0){
      setLoading(true);
      getAllOrderByCustomersec( data, 0, 5, "created_at")
        .then((res) => {
          dispatch(setCustomerOrderList(res?.result))
          setTotalRecord(Math.ceil(res?.totalRecord / 5));
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }

  }, []);

  let index;
  let cartDetail = {};
  const handleReorder = (orders, ele) => {
    setButtonLoader(true);
    let totalOrdersCount = orders?.length;
    let catBool = true;
    let unavailableItems = [];
    let processedCount = 0;
  
    orders?.forEach((element) => {
      let catData = {
        storeId: store?.id,
        appType: "web",
      };
      GetAllCategories(catData)
        .then((res) => {
          let categoryExist = res?.result?.filter(
            (individual) =>
              Number(individual?.category_id) === Number(element?.category_id)
          );
          if (categoryExist?.length > 0) {
            let menuItem = {
              appType: "web",
              categoryId: element?.category_id,
              customerId: 0,
              requestDate: "",
              storeId: store?.id,
            };
            GetAllMenu(menuItem).then((res) => {
              let menuExist = res?.result.filter(
                (individual) =>
                  Number(individual?.id) === Number(element?.menu_id)
              );
              if (menuExist.length > 0) {
                let data = {
                  appType: "web",
                  customerId: userDetail?.customer_id,
                  menuId: element.menu_id,
                  storeId: store.id,
                };
                getCustomizationOption(data)
                  .then((res) => {
                    let result = res.result[0][0];
                    cartDetail = {
                      id: element.menu_id,
                      categoryId: result.category_id,
                      customizationOpt: result.customizationOpt
                        ? result.customizationOpt
                        : [],
                      quantity: Number(element?.quantity_purchased),
                      price: result?.price?.toString(),
                      image: result.image,
                      categoryName: result?.category_name,
                      title: result.title,
                      sku_code: result?.sku_code||result?.skuCode,
                      h_sku_code:result?.h_sku_code,
                      servingName: element?.serving_name,
                      totalPrice:
                        element.item_customized_price *
                        element?.quantity_purchased,
                      btnModifierId: [],
                      btnModifier: [],
                      menuServingDetail: result?.menu_serving_detail,
                      selectedCupSize: result?.menu_serving_detail.filter(
                        (e) => e?.serving_name === element?.serving_name
                      ),
                      orderItemPrice: Number(
                        element?.item_customized_price /
                          element?.quantity_purchased
                      ),
                      selectedOptions: result?.customizationOpt?.filter(
                        (e, index) => {
                          element.order_details
                            .filter(
                              (cup) =>
                                cup.modifier_group_name !== "Cup Size"
                            )
                            .map((modifiers) => {
                              if (
                                modifiers.modifier_group_name ===
                                e.modifier_group_name
                              ) {
                                if (modifiers.option.length === 0) {
                                  e.option = e.option
                                    .filter(
                                      (singleModi) =>
                                        singleModi.modifier_id ===
                                        modifiers.option[0].modifier_id
                                    )
                                    .map((change) => {
                                      change.modifier_price =
                                        change.modifier_group_description
                                          .filter(
                                            (servPrice) =>
                                              servPrice?.serving_name ===
                                              element?.serving_name
                                          )[0]
                                          ?.price?.toString();
                                      change.quantity = 1;
                                      change.selected_modifier_serving =
                                        change.modifier_group_description
                                          .filter(
                                            (serving) =>
                                              serving?.serving_name ===
                                              element?.serving_name
                                          )
                                          .map((ele) => {
                                            return {
                                              price:
                                                ele?.price?.toString(),
                                              serving_name:
                                                ele?.serving_name,
                                            };
                                          })[0];
                                      return change;
                                    });
                                } else if (modifiers.option.length >= 1) {
                                  let x = [];
                                  modifiers.option.forEach((toppings) => {
                                    let singleTopping = e.option.filter(
                                      (newStoreTopping) =>
                                        Number(
                                          newStoreTopping.modifier_id
                                        ) ==
                                          Number(toppings.modifier_id) &&
                                        newStoreTopping.modifier_group_description !==
                                          ""
                                    )[0];
                                    if (singleTopping) {
                                      singleTopping.quantity =
                                        toppings.quantity;
                                    } else {
                                      toast.error(
                                        `${toppings.modifier_name} ${e.modifier_group_name} is not present for this item`,
                                        {
                                          position: "bottom-left",
                                          autoClose: 2000,
                                          hideProgressBar: true,
                                          closeOnClick: true,
                                          pauseOnHover: false,
                                          draggable: true,
                                          progress: undefined,
                                          theme: "dark",
                                        }
                                      );
                                    }
                                    let selected_modifier_serving =
                                      singleTopping?.modifier_group_description
                                        .filter(
                                          (topServing) =>
                                            topServing?.serving_name ===
                                            element?.serving_name
                                        )
                                        .map((e) => {
                                          return {
                                            price: e?.price
                                              ? e?.price?.toString()
                                              : "0",
                                            serving_name: e?.serving_name
                                              ? e.serving_name
                                              : element.serving_name,
                                          };
                                        })[0];
                                    singleTopping.selected_modifier_serving =
                                      selected_modifier_serving
                                        ? selected_modifier_serving
                                        : {
                                            price: "0",
                                            serving_name:
                                              element.serving_name,
                                          };
  
                                    singleTopping.price =
                                      singleTopping?.selected_modifier_serving?.price;
                                    singleTopping.modifier_price =
                                      singleTopping?.selected_modifier_serving?.price;
                                    singleTopping.modifier_prefix_name = 0;
                                    x.push(singleTopping);
                                  });
                                  e.option = x;
                                }
                              }
                            });
                          return element.order_details.some((f) => {
                            return (
                              f.modifier_group_name ===
                              e.modifier_group_name
                            );
                          });
                        }
                      ),
                    };
                    let toppingCounterLimit = result?.customizationOpt?.filter((e)=>e?.modifier_group_name === "Toppings")
                    cartDetail.toppings_counter_limit = toppingCounterLimit?.length>0?toppingCounterLimit[0]?.counter_limit:0
                    let modfier_group_price = 0;
                    cartDetail.selectedOptions.map((e) => {
                      e?.option?.map((toppingPrice) => {
                        modfier_group_price += isNaN(
                          Number(
                            toppingPrice?.price ||
                              toppingPrice?.modifier_price
                          ) * toppingPrice?.quantity
                        )
                          ? 0
                          : Number(
                              (toppingPrice?.price ||
                                toppingPrice?.modifier_price) *
                                toppingPrice?.quantity
                            );
                      });
                    });
                    cartDetail.orderItemPrice =
                      cartDetail.selectedCupSize[0]?.price +
                      modfier_group_price;
                    cartDetail.totalPrice =
                      cartDetail.orderItemPrice * cartDetail.quantity;
  
                    let modifierDetail = [];
                    let isRequiredChecked = false;
                    result?.customizationOpt.forEach((element, index) => {
                      let selectedCustomizationOption =
                        cartDetail.selectedOptions.filter(
                          (ele) =>
                            element.modifier_group_id ==
                            ele.modifier_group_id
                        );
                      if (selectedCustomizationOption.length == 0) {
                        if (element.is_required) {
                          isRequiredChecked = true;
                          modifierDetail.push(
                            element.modifier_group_name
                          );
                        }
                      }
                    });
  
                    if (isRequiredChecked) {
                      toast.error(
                        `${modifierDetail[0]} modifier is required for this item`,
                        {
                          position: "bottom-left",
                          autoClose: 1000,
                          hideProgressBar: true,
                          closeOnClick: true,
                          pauseOnHover: false,
                          draggable: true,
                          progress: undefined,
                          theme: "dark",
                        }
                      );
                    } else {
                      let itemAddedToCartStatus = hadleAddToCart();
                      if (itemAddedToCartStatus) {
                        toast.success(
                          `${cartDetail.title} Added to the cart`,
                          {
                            position: "bottom-left",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                            theme: "dark",
                          }
                        );
                      }
                    }
                  })
                  .catch((err) => {
                    console.log(err.message);
                  });
              } else {
                unavailableItems.push(element.menu_name);
              }
  
              processedCount++;
              if (processedCount === orders.length) {
                if (unavailableItems.length > 0) {
                  toast.error(
                    `${unavailableItems.join(", ")} ${unavailableItems.length > 1 ? "are" : "is"} not available at selected store`,
                    {
                      position: toast.POSITION.TOP_RIGHT,
                      autoClose: 3000
                    }
                  );
                }
                setButtonLoader(false);
                setUpdateBtnFlag(false);
              }
            });
          } else {
            unavailableItems.push(element.menu_name);
            processedCount++;
            if (processedCount === orders.length) {
              if (unavailableItems.length === orders.length) {
                toast.error("This Order is not available for selected store", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
              setButtonLoader(false);
              setUpdateBtnFlag(false);
            }
          }
        })
        .catch((e) => {
          console.log(e);
          processedCount++;
          if (processedCount === orders.length) {
            setButtonLoader(false);
            setUpdateBtnFlag(false);
          }
        });
    });
  };
  const hadleAddToCart = () => {
    if (cartDetail.selectedCupSize.length > 0 && Object.keys(cartDetail.selectedCupSize[0]).length !== 0) {
      const toppingsModifier = cartDetail.selectedOptions?.find(
        (option) => option.modifier_group_name === "Toppings"
      );
  
      if (toppingsModifier) {
        const nonOreoCrumbToppings = toppingsModifier.option.filter(
          topping => topping.modifier_name !== 'Oreo Crumb'
        );
  
        const totalToppingsCount = nonOreoCrumbToppings.reduce(
          (sum, topping) => sum + (topping.quantity > 0 ? 1 : 0),
          0
        );
  
        if (totalToppingsCount > toppingsModifier.counter_limit) {
          setToppingsLimit(toppingsModifier.counter_limit);

          setCartDetailForConfirm({
            ...cartDetail,
          });
          setToppingsLimitAlert(true);
          setButtonLoader(false);
          setUpdateBtnFlag(false);
          setTimeout(() => {
            setUpdateBtnFlag(false);
            setButtonLoader(false);
          }, 100);
          return false;
        }
      }
  
      const result = updateCartWithItem(cartDetail, cartItem, dispatch, setCart, setUpdateCart);

      if (result) {
        dispatch(setCustomizeOptions(cartDetail?.customizationOpt));
        dispatch(setMenuServingDetails(cartDetail?.menuServingDetail));
        setButtonLoader(false);
        return true;
      }
    } else {
      setButtonLoader(false);
      toast.error(
        `${cartDetail.servingName} serving is not present for ${cartDetail?.title}`,
        {
          position: "bottom-left",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
      setTimeout(() => {
        setUpdateBtnFlag(false);
        setButtonLoader(false);
      }, 100);
      return false;
    }
    setButtonLoader(false);
    return false;
  };

  const handleAddWithCupSizeOnly = () => {
    if (!cartDetailForConfirm) return;
    
    let simplifiedCartDetail = {
      ...cartDetailForConfirm,
      selectedOptions: cartDetailForConfirm.selectedOptions.filter(
        option => option.modifier_group_name !== "Toppings"
      )
    };
    
    let modifierPrice = 0;
    simplifiedCartDetail.selectedOptions.forEach(option => {
      option.option.forEach(modifier => {
        const price = Number(modifier.price || modifier.modifier_price) * modifier.quantity;
        modifierPrice += isNaN(price) ? 0 : price;
      });
    });
    
    simplifiedCartDetail.orderItemPrice = (simplifiedCartDetail.selectedCupSize[0]?.price || 0) + modifierPrice;
    simplifiedCartDetail.totalPrice = simplifiedCartDetail.orderItemPrice * simplifiedCartDetail.quantity;
    
    const existingItemIndex = cartItem.findIndex(item => {
      if (item.id !== simplifiedCartDetail.id) return false;
      if (item.selectedCupSize[0]?.serving_name?.toLowerCase() !== 
          simplifiedCartDetail.selectedCupSize[0]?.serving_name?.toLowerCase()) return false;
      
      const itemToppings = item.selectedOptions?.find(opt => opt.modifier_group_name === "Toppings");
      if (itemToppings) return false;
      

      const itemNonToppingOptions = item.selectedOptions?.filter(opt => opt.modifier_group_name !== "Toppings") || [];
      const newItemNonToppingOptions = simplifiedCartDetail.selectedOptions || [];
      
      if (itemNonToppingOptions.length !== newItemNonToppingOptions.length) return false;

      for (const newOption of newItemNonToppingOptions) {
        const matchingOption = itemNonToppingOptions.find(opt => 
          opt.modifier_group_name === newOption.modifier_group_name
        );
        
        if (!matchingOption) return false;
        
        if (!areModifiersEqual(newOption.option, matchingOption.option)) return false;
      }
      
      return true;
    });
    
    if (existingItemIndex !== -1) {
      const updatedCart = [...cartItem];
      updatedCart[existingItemIndex] = {
        ...updatedCart[existingItemIndex],
        quantity: updatedCart[existingItemIndex].quantity + simplifiedCartDetail.quantity,
        totalPrice: updatedCart[existingItemIndex].orderItemPrice * 
          (updatedCart[existingItemIndex].quantity + simplifiedCartDetail.quantity)
      };
      dispatch(setUpdateCart(updatedCart));
    } else {
      let arr = [...cartItem, simplifiedCartDetail];
      dispatch(setUpdateCart(arr));
    }
    
    setButtonLoader(false);
    
    toast.success(`${simplifiedCartDetail.title} Added to the cart`, {
      position: "bottom-left",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    
    setToppingsLimitAlert(false);
    setUpdateBtnFlag(false);
    return true;
  };

  const areModifiersEqual = (modifiers1, modifiers2) => {
    if (modifiers1.length !== modifiers2.length) return false;
    
    const sortedMods1 = [...modifiers1].sort((a, b) => 
      String(a.modifier_id).localeCompare(String(b.modifier_id))
    );
    const sortedMods2 = [...modifiers2].sort((a, b) => 
      String(a.modifier_id).localeCompare(String(b.modifier_id))
    );
    
    return sortedMods1.every((mod, index) => 
      String(mod.modifier_id) === String(sortedMods2[index].modifier_id) &&
      mod.quantity === sortedMods2[index].quantity
    );
  };

  useEffect(() => {
    if (!remove && reorderClicked) {
      handleReorder(reorderClicked.ordermenu, reorderClicked);
    }
  }, [remove]);

  const handleViewMore = () => {
    setViewMoreLoader(true);
    let pageForData = page + 1;
    setPage(pageForData);
    getAllOrderByCustomersec(data, pageForData, 5, "created_at")
      .then((res) => {
        let isDataExist = res?.result?.length > 0;
        setDataAvailability(isDataExist);
        dispatch(setCustomerOrderList([...customerOrderList, ...res?.result]))
        setViewMoreLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setViewMoreLoader(false);
      });
  };

  return loading === true ? (
    <Backdrop sx={{ color: "#fff", zIndex: 1000 }} open={customerOrderList?.length>0?false:loading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : customerOrderList?.length === 0 ? (
    <Typography
      variant="h6"
      sx={{
        fontSize: "3rem",
        textAlign: "center",
        marginTop: "3rem",
        color: "#868686",
      }}
    >
      No Previous Order Available
    </Typography>
  ) : (
    <>
      <Box
        sx={{
          paddingLeft: { lg: "13vw", xs: "3vw" },
          paddingRight: { lg: "13vw", xs: "3vw" },
          paddingTop: "3vh",
        }}
      >
        {customerOrderList?.map((element, index) => {
          return (
            <Slide key={index} direction="right" in={true} mountOnEnter unmountOnExit timeout={400}>
            <Card
              sx={{ borderRadius: "10px", marginBottom: {xs:"1rem",sm:"3rem"} }}
              key={index}
            >
              <div
                style={{
                  backgroundColor: "#D9D9D9",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "0.3rem 1rem",
                }}
              >
                <Typography
                  style={{
                    fontSize: window.innerWidth < 600 ? "15px" : "inherit",
                  }}
                >
                  {uiDate(element?.pickup_date)}
                </Typography>
                <Button
                  variant="contained"
                  disabled={updateBtnFlag}
                  onClick={() => {
                    setIndexState(index);
                    if (store) {
                      setTitle("");
                      if (store.id === element.store_id) {
                        setUpdateBtnFlag(true)
                        getCustomerOrderById(element.id, "all").then((res) => {
                          let propss = res.result[0];
                          handleReorder(propss?.ordermenu, propss);
                        });
                      } else {
                        setDisplayTitle(
                          `Pricing may change based on selected store`
                        );
                        setRemove(true);
                        getCustomerOrderById(element.id, "all").then((res) => {
                          let propss = res.result[0];
                          setReorderClicked(propss);
                        });
                      }
                    } else {
                      setState(true);
                    }
                  }}
                  style={{
                    color: "black",
                    textTransform: "none",
                    backgroundColor: "#D9D9D9",
                    padding: "0.1rem 1.7rem",
                    fontWeight: "550",
                    padding:
                      window.innerWidth < 600 ? "0.1rem 1rem" : "0.1rem 1.7rem",
                    fontSize: window.innerWidth < 600 ? "12px" : "inherit",
                  }}
                >
                  {buttonLoader && index == indexState ? (
                    <ButtonLoader props={"3rem"} />
                  ) : element?.ordermenu?.length > 1 ? (
                    `Reorder All`
                  ) : (
                    `Reorder`
                  )}
                </Button>
              </div>
              <div>
                {element?.ordermenu.map((e, index) => {
                  return (
                    <>
                      <PreviousOrderCard
                        key={Number(e.menu_id)}
                        props={e}
                        orderId={element.id}
                        storeId={element.store_id}
                        storeName={element.store_name}
                        setState={setState}
                        is_Item_fav={element.ordermenu[index].is_favourite}
                        selectOrderItemIndex={index}
                        setFavorite={setFavorite}
                        getFavorite={getFavorite}
                        setUpdateBtnFlag = {setUpdateBtnFlag}
                        updateBtnFlag = {updateBtnFlag}
                      />
                      <Divider variant="middle" />
                    </>
                  );
                })}
              </div>
            </Card>
            </Slide>
          );
        })}
        {dataAvailability ? (
          viewMoreLoader ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "3vh 0",
              }}
            >
              {" "}
              <CircularProgress />{" "}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "3vh 0",
              }}
            >
              {page < totalRecord - 1 ? (
                <Button
                  sx={{
                    padding: {
                      xs: "0.2rem 4rem",
                      sm: "0.2rem 5rem",
                      md: "0.2rem 5rem",
                      lg: "0.2rem 5rem",
                    },
                    marginBottom: {
                      xs: "3.5rem",
                      sm: "0rem",
                      md: ".5rem",
                      lg: ".5rem",
                    },
                  }}
                  className={classes.btnLogin}
                  variant="contained"
                  onClick={handleViewMore}
                >
                  View More
                </Button>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "3vh 0",
                    marginBottom: "2rem",
                  }}
                >
                  No more data to display
                </div>
              )}
            </div>
          )
        ) : (
          ""
        )}
        {dataAvailability == false ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "3vh 0",
            }}
          >
            No more data to display
          </div>
        ) : (
          ""
        )}
      </Box>

      <AlertPopup
        open={remove}
        setOpen={setRemove}
        setState={setReorderClicked}
        title={title ? title : "previousOrder"}
        displayTitle={displayTitle}
        buttonType1={title ? false : true}
        buttonType2={true}
        onConfirm={() => {
          if (reorderClicked) {
            handleReorder(reorderClicked.ordermenu, reorderClicked);
          }
        }}
      />
      
      <AlertPopup
        open={toppingsLimitAlert}
        setOpen={setToppingsLimitAlert}
        title="toppingsLimit"
        displayTitle={`Toppings have been changed. Would you like to proceed with the item?`}
        buttonType1={true}
        buttonType2={true}
        onConfirm={handleAddWithCupSizeOnly}
      />
    </>
  );
};

export default PreviousOrder;
