import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
  Typography,
  Slide
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Zoom from "@mui/material/Zoom";
import { makeStyles } from "@mui/styles";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { forgetPassword } from "../../Api/API";
import { errorToastMessage } from "../../Common/utils";
import { setUserId } from "../../Redux/UserSlice";

export function convertPlusToBFormat(data) {
  if (!data) return "";
  return data.replace("+", "%2B");
}

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: 15,
    width: "70%", // Set the desired width
    maxHeight: "90vh",
    "@media (max-width: 600px)": {
      width: "100vw",
      maxHeight: "100vh",
      borderRadius: 0,
    },
  },
  noBorder: {
    border: "none",
  },
  errorP: {
    color: "#bf1650",
    " &::before": {
      display: "inline",
      content: "⚠ ",
    },
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} timeout={500} />;
});

function EmailVerify(props) {
  const {
    checkUserPhone,
    code,
    setLoading,
    checkUserEmail,
    setshowNewPass,
    setshowPopup,
    setEmailVerifyOpen,
    emailVerifyOpen,
    setTitle,
    setShowAd,
  } = props;
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const theme = useTheme();
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const defaultValues = {
    isOldPasswordRequired: false,
    newPassword: "",
    password: "",
    userId: "",
  };
  const { control, handleSubmit } = useForm({
    defaultValues,
  });
  const classes = useStyles();
  const closeAd = () => {
    setshowNewPass(false);
    setshowPopup(false);
  };

  const onSubmit = () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    setLoading(false);
    setTitle("ForgetPassword");
    setLoading(true);
    forgetPassword(
      "website",
      checkUserEmail,
      checkUserPhone,
      convertPlusToBFormat(code)
    )
      .then((res) => {
        if (res?.responseCode == 1) {
          setShowAd(false);
          dispatch(setUserId(res?.userId));
          setshowPopup(true);
          setLoading(false);
          setEmailVerifyOpen(false);
          toast.success("Your verification code has been sent", {
            position: toast.POSITION.TOP_RIGHT,
            pauseOnFocusLoss: false,
            autoClose: 1500,
            pauseOnHover: false,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
          });
        } else {
          setLoading(false);
          toast.error(res?.responseMessage, {
            position: toast.POSITION.TOP_RIGHT,
            pauseOnFocusLoss: false,
            autoClose: 1500,
            pauseOnHover: false,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        errorToastMessage();
      });
  };

  const handleBackonLogin = () => {
    setEmailVerifyOpen(false);
    setShowAd(true);
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={emailVerifyOpen}
        classes={{
          paper: classes.paper,
        }}
        onKeyDown={(e) => e.key == "Escape" && closeAd()}
        // TransitionComponent={Transition}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle textAlign="center">
          <div
            style={{
              display: "flex",
              cursor: "pointer",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex" }} onClick={handleBackonLogin}>
              <ArrowBackIosIcon style={{ fill: "#C60734" }} />
              <Typography sx={{ color: "#C60734", mt: -0.5 }} variant="h5">
                Forgot Password
              </Typography>
            </div>
            {/* <Box textAlign="right" sx={{ cursor: "pointer", mt: -1 }}>
              <CancelIcon
                fontSize="large"
                onClick={() => {
                  setEmailVerifyOpen(false);
                  setTitle("");
                }}
              />
            </Box> */}
          </div>
        </DialogTitle>

        <Box p={2}>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Grid container spacing={3} sx={{}}>
              <Grid item xs={12} sm={12} md={5} lg={5}>
                <Box></Box>
                <Box
                sx={{mt:-5}}
                  display="flex"
                  justifyContent={{
                    xs: "center",
                    md: "end",
                  }}
                >
                  <img src="./VerifyEmail.png" alt="" width="100%" />
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                sx={{
                  p: { sm: 0, md: 2 },
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography textAlign={"center"} color={"#BDBDBD"}>
                  A verification code will be sent to the phone number below
                </Typography>
                <br />
                <FormControl fullWidth>
                  <Controller
                    name="Email"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="email"
                        size="large"
                        value={checkUserPhone}
                        variant="outlined"
                        fullWidth
                        style={{
                          border: "2px solid lightgrey",
                          borderRadius: "5px",
                        }}
                        InputProps={{
                          readOnly: true,
                          classes: { notchedOutline: classes.noBorder },
                        }}
                      />
                    )}
                    control={control}
                  />
                </FormControl>
                <br />
                <Box width="100%" maxWidth="400px" textAlign={"center"}>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    sx={{
                      padding: "0.5rem 2rem",
                      backgroundColor: "#B7273B",
                      fontFamily: "Lusitana",
                      borderRadius: ".5rem",
                      width: "80%",
                      "&:disabled": {
                        backgroundColor: "#d67280",
                        color: "white",
                      },
                    }}
                    variant="contained"
                    style={{ textTransform: "none" }}
                  >
                    Next
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={1} lg={1}></Grid>
            </Grid>
          </form>
        </Box>
      </Dialog>
    </>
  );
}

export default EmailVerify;
