import { Backdrop, Box, CircularProgress, Grid, Slide, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { GetAllCategories, GetAllMenu, getCustomizationOption } from "../../Api/API";
import { 
  setAdvCheck, 
  setCartDetailes, 
  setCustomizeOpen, 
  setCustomizeOptions, 
  setEditTitles, 
  setMenuServingDetails, 
  setOpenPopUps, 
  setSelectedCupSizes, 
  setTabValue, 
  setTitles, 
  setValueSet,
  setSelectedItem, 
  setIsLoading
} from "../../Redux/CartSlice";
import Popup from "../Categories/CategoryPopUp";
import New from "./New";
const Feature = ({ setValue, setState, state, loading }) => {
  const [loader, setLoader] = useState(true); 
  const [adv, setAdv] = useState();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const storeName = useSelector((state) => state.store.nearestStore);
  const userDetail = useSelector((state) => state.user.user);
  const customizeOpen = useSelector((state) => state.cart.customizeOpen);
  const featuredList = useSelector((state) => state.cart.featuredList);
  const [updateBtnFlag, setUpdateBtnFlag] = useState(false)
  const [isNewComponentCall, setIsNewComponentCall] = useState(false);  
  const isButtonClick = useRef(false);

  useEffect(() => {
    if (!customizeOpen) {
      dispatch(setIsLoading(false));
      isButtonClick.current = false; 
    }
  }, [customizeOpen, dispatch]);

  useEffect(() => {
    if (isButtonClick.current) return;
    const advIndex = featuredList?.indexOf(adv);
    if (advIndex >= 0 && storeName?.id && !isNewComponentCall) {
      let catData = {
        storeId: storeName?.id,
        appType: "web",
      };
      if (adv?.buttonType === "Order Now") {
        setLoader(true);
        GetAllCategories(catData).then((response) => {
          let categoryExist = response?.result?.filter(
            (element) =>
              Number(element?.category_id) === Number(adv?.categoryId)
          );
          if (categoryExist?.length > 0 && adv?.menuId == null) {
            dispatch(setSelectedItem({ 
              type: 'menu',
              item: categoryExist[0]
            }));
            dispatch(setValueSet(response?.result.indexOf(categoryExist[0])));
            dispatch(setTabValue(null)); 
            navigate(`/menu`);
            window.scrollTo(0, 0);
            setLoader(false);
          } else if (categoryExist?.length > 0) {
            let menuItem = {
              appType: "web",
              categoryId: adv?.categoryId,
              customerId: 0,
              requestDate: "",
              storeId: storeName?.id,
            };
            GetAllMenu(menuItem).then((responses) => {
              let menuExist = responses?.result.filter(
                (element) => Number(element?.id) === Number(adv?.menuId)
              );
              if (menuExist?.length > 0) {
                let data = {
                  customerId: userDetail?.customer_id
                    ? userDetail?.customer_id
                    : userDetail?.person_id,
                  menuId: adv?.menuId,
                  storeId: storeName?.id ? storeName?.id : 1,
                  appType: "web",
                };
                getCustomizationOption(data).then((res) => {
                  setLoader(false);

                  let cartDetail = {
                    id: menuExist[0]?.id,
                    price: menuExist[0]?.price,
                    image: menuExist[0]?.image,
                    title: menuExist[0]?.title,
                    quantity: 1,
                    sku_code: res?.result[0][0]?.sku_code||res?.result[0][0]?.sku_code,
                    h_sku_code:res?.result[0][0]?.h_sku_code,
                    categoryId: res?.result[0][0].category_id,
                    selectedCupSize: [],
                    customizationOpt: res?.result[0][0].customizationOpt,
                    categoryName: res?.result[0][0].category_name,
                    is_favourite: res?.result[0][0].is_favourite,
                    btnModifierId: [],
                    btnModifier: [],
                    custom: [],
                    menuServingDetail: res?.result[0][0].menu_serving_detail,
                    cold: res?.result[0][0].caffeine,
                    caffine: res?.result[0][0].cold,
                    max_calories: res?.result[0][0]?.max_calories,
                    min_calories: res?.result[0][0]?.min_calories,
                  };
                  let toppingCounterLimit = res?.result[0][0]?.customizationOpt?.filter((e)=>e?.modifier_group_name === "Toppings")
                  cartDetail.toppings_counter_limit = toppingCounterLimit?.length>0?toppingCounterLimit[0]?.counter_limit:0
                  cartDetail.selectedCupSize = [
                    res?.result[0][0]?.menu_serving_detail[0],
                  ];
                  cartDetail.description = res?.result[0][0]?.description;
                  dispatch(
                    setSelectedCupSizes(
                      cartDetail?.selectedCupSize?.length > 1
                        ? cartDetail?.selectedCupSize?.filter(
                          (e) => e.tier_id > 0
                        )
                        : cartDetail?.selectedCupSize
                    )
                  );
                  let menuServingDetail = res?.result[0][0]?.menu_serving_detail;
                  dispatch(setCustomizeOptions(res?.result[0][0]?.customizationOpt));
                  dispatch(setCartDetailes(cartDetail));
                  dispatch(setMenuServingDetails(menuServingDetail));
                  dispatch(setOpenPopUps(true));
                  dispatch(setTitles(""));
                  dispatch(setEditTitles(""));
                  dispatch(setAdvCheck(true));
                  dispatch(setTabValue(3));
                  dispatch(setCustomizeOpen(true));
                  dispatch(setSelectedItem({ 
                    type: 'advertisement',
                    item: {
                      menuId: adv?.menuId,
                      categoryId: adv?.categoryId,
                      cartDetail: cartDetail
                    }
                  }));
                });
              } else {
                toast.error(
                  `This menu item is not available for selected store`,
                  {
                    position: toast.POSITION.TOP_RIGHT,
                  }
                );
                setLoader(false);
              }
            });
          } else {
            toast.error(`This category is not available for selected store`, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setLoader(false);
          }
        });
      }
    }
    setIsNewComponentCall(false);
  }, [storeName, adv, featuredList, isNewComponentCall]);

  const handleSetAdv = (newAdv) => {
    isButtonClick.current = true; 
    setIsNewComponentCall(true);
    setAdv(newAdv);
  };

  return (
    <>
      <Box
        sx={{
          paddingBottom: { lg: "3vh", xs: "15vh" },
          paddingTop: "5vh",
        }}
      >
        {featuredList?.length === 0 && !loading && !loader ? ( 
          <Typography
            variant="h6"
            sx={{
              fontSize: "3rem",
              textAlign: "center",
              marginTop: "3rem",
              color: "#868686"
            }}
          >
            No Featured Available
          </Typography>
        ) : (
          ""
        )}
        <Grid container spacing={2} >
          {featuredList?.map((e,index) => {
            return (
            <Slide key={index} direction="right" in={true} mountOnEnter unmountOnExit timeout={400}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <New
                  deals={e}
                  setState={setState}
                  // setAdv={setAdv}
                  loading={loader}
                  setAdv={handleSetAdv}
                />
              </Grid>
              </Slide>
            );
          })}
        </Grid>
      </Box>
      {loading || loader ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: 1000 }}
          open={featuredList?.length > 0 ? false : loading || loader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : null}
      {customizeOpen && (
        <Popup
          customizeOpen={customizeOpen}
          setCustomizeOpen={setCustomizeOpen}
          flag={"featureFlag"}
          setUpdateBtnFlag = {setUpdateBtnFlag}
          updateBtnFlag = {updateBtnFlag}
        />
      )}
    </>
  );
};

export default Feature;
