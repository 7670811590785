import React, { useState } from "react";
import { Avatar } from "@mui/material";
const Image = ({ data , Images}) => (
  Images ? <img src = {Images} style = {{width: "100%" ,height:"100%"}} alt = "Preview not available" /> :
  <img style = {{width: "100%" ,height:"100%"}} src={`data:image/jpeg;base64,${data}`} alt="Preview not available" /> 
  
);

const Upload = ({imageUpload , selectedImage}) => {
  async function readImage(e, func) {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = function(e) {
      let binaryData = e.target.result;
      let base64String = window.btoa(binaryData);
      func(base64String);
    };

    let image = reader.readAsBinaryString(file);
    return image;
  }
  

  const [image, setImage] = useState("");

React.useEffect(()=>{
  if(imageUpload !== "none" && imageUpload?.target?.files[0]){
  readImage(imageUpload, setImage);
  }else if(imageUpload == "none"){
    setImage(null)
  }
},[imageUpload])
 

  return (
    <label htmlFor="file">
    <Avatar style = {{width :"12rem" , height :"12rem" ,cursor: "pointer" }}>
     {selectedImage ? ( <Image Images={selectedImage} />  ):
     ( image ? <Image data={image} /> : <div style = {{textAlign:"center"}}>Add Image</div>)
     }
    </Avatar>
    </label>
  );
};

export default Upload

