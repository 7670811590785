import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Zoom from "@mui/material/Zoom";
import { makeStyles } from "@mui/styles";
import * as React from "react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ChangePassword } from "../../Api/API";
import {
  validateConfirmPassword,
  validatePassword,
} from "../../Common/FormVailidation";
import { errorToastMessage } from "../../Common/utils";

toast.configure();
const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: 15,
    width: "70%", // Set the desired width
    maxHeight: "90vh",
    "@media (max-width: 600px)": {
      width: "100vw",
      maxHeight: "100vh",
      borderRadius: 0,
    },
  },
  noBorder: {
    border: "none",
  },
  errorP: {
    color: "#bf1650",
    " &::before": {
      display: "inline",
      content: "⚠ ",
    },
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} timeout={500} />;
});
const NewPassword = (props) => {
  const { showNewPass, setshowNewPass, setshowPopup, setTitle, setShowAd } =
    props;

  const navigate = useNavigate();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const UserId = useSelector((state) => state.user.UserId);
  const defaultValues = {
    isOldPasswordRequired: false,
    newPassword: "",
    password: "",
    userId: "",
  };
  const { control, handleSubmit, formState } = useForm({
    defaultValues,
  });
  const { errors } = formState;
  const classes = useStyles();
  
  const closeAd = () => {
    setshowNewPass(false);
    setshowPopup(false);
  };
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const [valuesCN, setValuesCN] = React.useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPasswordCN = () => {
    setValuesCN({ ...valuesCN, showPassword: !valuesCN.showPassword });
  };

  const handlePasswordChangeCN = (prop) => (event) => {
    setValuesCN({ ...valuesCN, [prop]: event.target.value });
  };
  const onSubmit = (data) => {
    data.newPassword = values.password;
    data.userId = UserId;
    delete data.confirmPassword;
    ChangePassword(data)
      .then((res) => {
        if (res?.response_code == 1) {
          toast.success(res?.message, {
            position: toast.POSITION.TOP_RIGHT,
            pauseOnFocusLoss: false,
            autoClose: 1500,
            pauseOnHover: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
            setshowNewPass(false);
            setshowPopup(false);
            navigate("/");
        } else {
          toast.error(res?.message, {
            position: toast.POSITION.TOP_RIGHT,
            pauseOnFocusLoss: false,
            autoClose: 1500,
            pauseOnHover: false,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        errorToastMessage();
      });
  };

  const handleBackonLogin = () => {
    setShowAd(true);
    setshowNewPass(false);
    setshowPopup(false);
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={showNewPass}
        classes={{
          paper: classes.paper,
        }}
        onKeyDown={(e) => e.key == "Escape" && closeAd()}
        TransitionComponent={Transition}
        maxWidth="lg"
        fullWidth
      >
          <div>
            <DialogTitle textAlign="center">
              <div
                style={{
                  display: "flex",
                  cursor: "pointer",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex" }} onClick={handleBackonLogin}>
                  <ArrowBackIosIcon style={{ fill: "#C60734" }} />
                  <Typography sx={{ color: "#C60734", mt: -0.5 }} variant="h5">
                    Reset Password
                  </Typography>
                </div>
                <Box textAlign="right" sx={{ cursor: "pointer", mt: -1 }}>
                  <CancelIcon
                    fontSize="large"
                    onClick={() => {
                      setshowNewPass(false);
                      setshowPopup(false);
                      setTitle("");
                    }}
                  />
                </Box>
              </div>
            </DialogTitle>
            <Box p={5}>
              <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <Grid container spacing={0} sx={{}}>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Box
                      display="flex"
                      justifyContent={{
                        xs: "center",
                        md: "end",
                      }}
                    >
                      <img src="./PasswordImg.png" alt="" width="70%" />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={7}
                    lg={7}
                    sx={{ p: { sm: 0, md: 5 } }}
                  >
                    <FormControl fullWidth>
                      <Controller
                        name="newPassword"
                        rules={{
                          required: true,
                          validate: (value) => {
                            if (!value) {
                              return validatePassword(value);
                            } else if (value.length > 0 && value.length < 8) {
                              return validatePassword(value);
                            } else if (value.length >= 8) {
                              return value.length > 20
                                ? "More than 20 characters are not Allowed"
                                : validatePassword(value);
                            }
                          },
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            id="newPassword"
                            size="large"
                            placeholder="New Password"
                            type={values.showPassword ? "text" : "password"}
                            onInput={handlePasswordChange("password")}
                            value={values.password}
                            variant="outlined"
                            fullWidth
                            inputProps={{ maxLength: 20 }}
                            style={{
                              border: "2px solid lightgrey",
                              borderRadius: "5px",
                            }}
                            InputProps={{
                              classes: { notchedOutline: classes.noBorder },
                              endAdornment: (
                                <InputAdornment position="end">
                                  <img
                                    src={
                                      values.showPassword
                                        ? "/EyeOpen.png"
                                        : "/EyeClosed.png"
                                    }
                                    alt="Toggle Password Visibility"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      cursor: "pointer",
                                    }}
                                    onClick={handleClickShowPassword}
                                  />
                                </InputAdornment>
                              ),
                              autocomplete: "new-password",
                              form: {
                                autocomplete: "off",
                              },
                            }}
                          />
                        )}
                        control={control}
                      />
                    </FormControl>
                    {errors?.newPassword ? (
                      <p className={classes.errorP}>
                        {errors.newPassword.message}
                      </p>
                    ) : (
                      <p>
                        <br />
                      </p>
                    )}
                    <FormControl fullWidth>
                      <Controller
                        name="confirmPassword"
                        rules={{
                          required: true,
                          validate: (value) => {
                            if (values.password) {
                              return validateConfirmPassword(
                                value,
                                values.password
                              );
                            } else if (!value) {
                              return "Confirm password is required";
                            } else if (value.length > 20) {
                              return "More than 20 characters are not Allowed";
                            }
                          },
                        }}
                        render={({ field }) => (
                          <TextField
                            id="confirmPassword"
                            {...field}
                            placeholder="Confirm New Password"
                            type={valuesCN.showPassword ? "text" : "password"}
                            onInput={handlePasswordChangeCN("password")}
                            value={valuesCN.password}
                            variant="outlined"
                            inputProps={{ maxLength: 20 }} // Added maxLength here
                            style={{
                              border: "2px solid lightgrey",
                              borderRadius: "5px",
                            }}
                            InputProps={{
                              classes: { notchedOutline: classes.noBorder },
                              endAdornment: (
                                <InputAdornment position="end">
                                  <img
                                    src={
                                      valuesCN.showPassword
                                        ? "/EyeOpen.png"
                                        : "/EyeClosed.png"
                                    }
                                    alt="Toggle Password Visibility"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      cursor: "pointer",
                                    }}
                                    onClick={handleClickShowPasswordCN}
                                  />
                                </InputAdornment>
                              ),
                              autocomplete: "new-password",
                              form: {
                                autocomplete: "off",
                              },
                            }}
                            fullWidth
                          />
                        )}
                        control={control}
                      />
                    </FormControl>
                    {errors?.confirmPassword ? (
                      <p className={classes.errorP}>
                        {errors.confirmPassword.message}
                      </p>
                    ) : (
                      <p>
                        <br />
                      </p>
                    )}

                    <Box display="flex" justifyContent="center" mt={2}>
                      <Button
                        type="submit"
                        sx={{
                          padding: "0.5rem 2rem",
                          backgroundColor: "#B7273B",
                          fontFamily: "Lusitana",
                          borderRadius: ".5rem",
                          width: "80%",
                        }}
                        variant="contained"
                        style={{ textTransform: "none" }}
                      >
                        Reset
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} lg={2}></Grid>
                </Grid>
              </form>
            </Box>
          </div>
      </Dialog>
    </>
  );
};

export default NewPassword;
