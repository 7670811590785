import ClearIcon from '@mui/icons-material/Clear';
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  Card,
  Container,
  Divider,
  FormControl,
  InputAdornment,
  Popover,
  TextField,
  Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import React, { useEffect, useRef, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  getStoreByCustomerLocation
} from "../../Api/API";
import {
  setActiveMarker,
  setCheckScroll,
  setCoordinate,
  setDeny,
  setMarkerSize,
  setNearestStore,
  setRouteError,
  setStoreCoordinate,
  setStoreData,
  setStoreIndexValue,
  setStoreMarkers,
  setUserCoordinate,
} from "../../Redux/StoreSlice";
import AlertPopup from "../AlertPopup";
import { detectMobileDevice, handleStoreStartEndTime } from "../utils";
import StoreDistance from "./Sidebar/StoreDistance";
import "./StoreLocation.css";
const useStyles = makeStyles((theme) => ({
  noBorder: {
    border: "none",
  },
}));
const MapContainer = ({ setState, currentLocation, setCurrentLocation, setStoreLoading, storeLoading }) => {
  const dispatch = useDispatch();
  let locationn = useLocation();
  const classes = useStyles();
  const abc = useRef(null);
  const textRef = useRef();
  const deny = useSelector((state) => state.store.deny);
  const storeCoordinate = useSelector((state) => state.store.storeCoordinate);
  const userCoordinate = useSelector((state) => state.store.userCoordinate);
  const [address, setAddress] = React.useState("");
  const [removeAddress, setRemoveAddress] = React.useState(false);
  const [coordinates, setCoordinates] = React.useState();
  const [userCoordenates, setUserCoordinates] = useState({
    lat: Number("40.7128"),
    lng: Number("-74.0060"),
  });
  const [userAddress, setUserAddress] = useState();
  const [select, setSelect] = useState(false);
  const [current, setCurrent] = useState(false);
  const markers = useSelector((state) => state.store.storeMarkers);
  const [geolocationError, setGeoLocationError] = useState("");
  const nearestStoreData = useSelector((state) => state.store.nearestStore);
  const [popOver, setPopOver] = useState(false);
  const storeData = useSelector((state) => state.store.storeData);
  const markerSize = useSelector((state) => state.store.markerSize);
  const flag = useSelector((state) => state.store.flag);
  const activeMarker = useSelector((state) => state.store.activeMarker);
  const [storeClose, setStoreClose] = useState(false);
  const [mapref, setMapRef] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState({});
  const [areaClickCords, setAreaClickCords] = useState();
  const [activeMarkerName, setActiveMarkerName] = useState(nearestStoreData?.store_name);
  const [activeMarkerId, setActiveMarkerId] = useState(nearestStoreData?.id);
  const [markerList, setMarkerList] = useState(false)
  const storeIndexValue = useSelector((state) => state.store.storeIndexValue)
  const checkScroll = useSelector((state) => state.store.checkScroll);
  const cartItem = useSelector((state) => state.cart.cart);
  const handleActiveMarker = (id, name) => {
    let selectedStore = storeData.filter((e) => e.id === id)[0]
    setSelectedMarker(selectedStore)
    setActiveMarkerId(id);
    setActiveMarkerName(name)
    dispatch(setActiveMarker(id))
    let markedStoreIndex = storeData.findIndex(
      (item) => item.store_name === name
    );
    abc?.current?.childNodes[markedStoreIndex]?.scrollIntoView({ behavior: "smooth" })
  };
  useEffect(() => {
    if (checkScroll == true) {
      setTimeout(() => {
        let activeStoreIndex = storeData?.findIndex(
          (item) => item.store_name == nearestStoreData?.store_name
        );
        abc?.current?.childNodes[activeStoreIndex]?.scrollIntoView({ behavior: "smooth" })
        dispatch(setCheckScroll(false))
      }, 1000);
    }
  }, [checkScroll])

  const mapStyles = {
    height: "50vh",
    zIndex: "1",
    borderRadius: "1rem"
  };
  const handleUserInputs = () => {
    dispatch(setDeny(false));
    location(userCoordenates.lat, userCoordenates.lng);
  };

  const handleClose = () => {
    setPopOver(false);
  };
  useEffect(() => {
    const searchParams = new URLSearchParams(locationn.search);
    let qlat = searchParams.get("latitude")
    let qlng = searchParams.get("longitude")
    let pathname = {
      coords: {
        latitude: qlat ? Number(qlat) : null,
        longitude: qlng ? Number(qlng) : null
      }
    }
    if (pathname.coords.latitude && pathname.coords.longitude && flag === true) {
      success(pathname)
    }
    else {
      if (currentLocation) {
        navigator.geolocation.getCurrentPosition(success, error);
        setCurrentLocation(false);
      }
    }
  }, [currentLocation]);

  const remove = () => {
    setAddress("")
    setRemoveAddress(false)
  };

  const curLocation = () => {
    navigator.geolocation.getCurrentPosition(success, error);
    dispatch(setActiveMarker(""))
    setCurrent(true);
    setRemoveAddress(true)
    if (markerList == false) {
      setMarkerList(true)
    } else {
      setMarkerList(false)
    }
  };

  const error = (err) => {
    setRemoveAddress(false)
    setGeoLocationError(
      "You'll need to enable location sharing to use this feature."
    );
    setPopOver(true);
  };
  const success = (position) => {
    const coordinates = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    };
    dispatch(setUserCoordinate(coordinates));
    // coordinates of user \\
    /* eslint-disable no-undef */
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode({ latLng: coordinates }, (results, status) => {
      if (status !== google.maps.GeocoderStatus.OK) {
        alert(status);
      }
      // This is checking to see if the Geoeode Status is OK before proceeding
      if (status == google.maps.GeocoderStatus.OK) {
        var address = results[0].formatted_address;
        if (deny) {
          setUserAddress(address);
          setUserCoordinates(coordinates);
          setSelect(true);
        } else {
          dispatch(setStoreCoordinate(coordinates));
          dispatch(setStoreMarkers([]));
          setAddress(address);
          setCoordinates(coordinates);
        }
      }
    });
    dispatch(setCoordinate(coordinates));
  };

  const handleSelect = async (value) => {
    textRef.current.blur()
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddress(value);
    dispatch(setCoordinate(latLng));
    setCoordinates(latLng);
  };
  const buttonClick = async (value) => {
    textRef.current.blur()
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddress(value);
    dispatch(setCoordinate(latLng));
    setCoordinates(latLng);
  };
  useEffect(() => {
    if (coordinates == userCoordinate && current == true) {
      setTimeout(() => {
        location(coordinates?.lat, coordinates?.lng);
      }, 400)

    }
  }, [markerList]);

  useEffect(() => {
    if (coordinates) {
      setTimeout(() => {
        location(coordinates?.lat, coordinates?.lng);
      }, 400)
    }
  }, [coordinates?.lat, coordinates?.lng]);

  const handleUserLocation = async (value) => {
    setSelect(true);
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setUserAddress(value);
    let coordinates = {
      lat: latLng.lat,
      lng: latLng.lng,
    };
    dispatch(setCoordinate(coordinates));
    setUserCoordinates(coordinates);
  };

  const location = (lat, lng, text) => {
    setStoreLoading(true)

    const fetchStoreData = async () => {
      try {
        const res = await getStoreByCustomerLocation(lat, lng);
        if (!res?.result || res.result.length === 0) return;
    
        let storeDetail = res.result
          .filter(store => store.store_name && store.id) // Only valid stores
          .map(store => ({
            ...store,
            lat: Number(store.latitude),
            lng: Number(store.longitude),
            distance: store?.distance?.toFixed(2).toString(),
            duration: formatDuration(store.distance / 0.5),
          }))
    
        if (storeDetail?.length === 0) {
          setStoreLoading(false);
          return;
        }
        const markers = storeDetail.map((store) => ({
          id: store.id,
          name: store.store_name,
          position: { lat: Number(store.latitude), lng: Number(store.longitude) },
          icon: "./red_dot.png",
        }));
        dispatch(setStoreMarkers(markers));
        dispatch(setStoreData(storeDetail));
        dispatch(setRouteError(""));
        abc?.current?.childNodes[0]?.scrollIntoView({ behavior: "smooth" })
        if (storeDetail.length > 0) {
          const firstStore = storeDetail[0];
          const storeStatus = handleStoreStartEndTime(firstStore, firstStore.time_zone);
    
          if (
            !storeStatus?.status?.[storeStatus.timeZoneWeekDay]?.storeDayStatus &&
            storeStatus?.status?.[storeStatus.timeZoneWeekDay]?.onLineStatus === "on" &&
            storeStatus?.timeRange && !text
          ) {
            if (cartItem.length === 0) {
              dispatch(setNearestStore(firstStore));
            }
            setActiveMarkerId(firstStore.id);
            setActiveMarkerName(firstStore.store_name);
            dispatch(setActiveMarker(firstStore.id));
          }    
          dispatch(setStoreIndexValue(storeDetail.length - 1));
          dispatch(setStoreCoordinate({ lat: firstStore.lat, lng: firstStore.lng }));
          setStoreLoading(false);
        }
      } catch (error) {
        console.error("Error fetching store data:", error);
        setStoreLoading(false);
      }
    };
          
    // function for formatting duration
    const formatDuration = (time) => {
      let minutes = Math.round(time);
      let hours = Math.floor(minutes / 60);
      return hours > 0 
      ? `${hours} hour${hours > 1 ? 's' : ''}` + (minutes % 60 > 0 ? ` ${minutes % 60} min` : '') 
      : `${minutes} min`;
    };
    
    fetchStoreData()
  };

  const onError = (status, clearSuggestions) => {
    clearSuggestions();
  };
  const onMarkerDragEnd = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    let coordinates = {
      lat: lat,
      lng: lng,
    };



    var geocoder = new google.maps.Geocoder();
    geocoder.geocode({ latLng: coordinates }, (results, status) => {
      if (status !== google.maps.GeocoderStatus.OK) {
        alert(status);
      }
      // This is checking to see if the Geoeode Status is OK before proceeding
      if (status === google.maps.GeocoderStatus.OK) {
        var address = results[0].formatted_address;
        if (deny) {
          setUserAddress(address);
          dispatch(setCoordinate(coordinates));
          setUserCoordinates(coordinates);
          setSelect(true);
          dispatch(
            setRouteError(
              "Please Select Location to get nearest Gongcha store "
            )
          );
        } else {
          setAddress(address);
          location(lat, lng);
        }
      }
    });
  };

  const handleMarkerState = (id) => {
    dispatch(setMarkerSize({ width: 55, height: 81 }));
    dispatch(setActiveMarker(id));
  };
  const handleMarkerStateSec = (name) => {
    dispatch(setMarkerSize({ width: 35, height: 52 }));
    dispatch(setActiveMarker(name));
  };

  const handleOnLoad = map => {
    setMapRef(map);
  };

  const handleAreaClick = () => {
    location(areaClickCords.lat, areaClickCords.lng, "search")
  }

  const handleCenterChanged = () => {
    if (mapref) {
      const newCenter = mapref.getCenter();
      let Areacoordinates = {
        lat: newCenter.lat(),
        lng: newCenter.lng(),
      };
      setAreaClickCords(Areacoordinates)
      dispatch(setCoordinate(Areacoordinates));
    }
  };
  useEffect(() => {
    if (mapref) {
      if (userCoordinate?.lat != null && userCoordinate?.lng != null && !storeLoading) {
        var latlngbounds = new google.maps.LatLngBounds();
        let bounds = [userCoordinate, storeCoordinate]
        for (var i = 0; i < bounds.length; i++) {
          var data = bounds[i]
          var myLatlng = new google.maps.LatLng(data.lat, data.lng);
          var markerr = new google.maps.Marker({
            position: myLatlng,
          });
          latlngbounds.extend(markerr.position);
        }
        mapref.fitBounds(latlngbounds);

      }
    }
  }, [storeCoordinate, userCoordinate, storeLoading, mapref])

  let myLatLng = userCoordinate;
  const userImage = "./userImage.png"

  return (
    <>
      <PlacesAutocomplete
        value={!deny ? address : userAddress}
        onChange={!deny ? setAddress : setUserAddress}
        onSelect={!deny ? handleSelect : handleUserLocation}
        onError={onError}
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <FormControl fullWidth>
            <TextField
              inputRef={textRef}
              variant="outlined"
              size="small"
              sx={{
                pt: 0.5, backgroundColor: "#E7E7E7", borderRadius: "0.5rem",
                boxShadow: "0 6px 20px 0 #dbdbe8",
              }}
              InputLabelProps={{
                shrink: true,
              }}
              {...getInputProps({
                placeholder: "Enter City/State/Zip",
              })}
              InputProps={{
                inputMode: "none",
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      fontSize="small"
                      style={{ fill: "#C60732" }}
                    />
                  </InputAdornment>),
                endAdornment: (
                  <InputAdornment position="start" sx={{ cursor: "pointer" }}>
                    {removeAddress && <ClearIcon onClick={() => remove()} style={{ fill: "black", marginRight: "0.5rem" }} />}
                    <MyLocationIcon onClick={() => curLocation()} style={{ fill: "#C60732" }} />
                  </InputAdornment>
                ),
                classes: { notchedOutline: classes.noBorder },
              }}
              onKeyDown={(e) => {
                e.key === "Enter"
                  && buttonClick(suggestions[0].description);
              }}
              onInput={(e) => {
                if (e.target.value.length >= 1) {
                  setRemoveAddress(true)
                }if(e.target.value === ""){
                  setRemoveAddress(false)
                }
              }}
            />
            <Box
              mt={1}
              className="autocomplete-dropdown-container"
              key={suggestions.description}
              style={{ minWidth: "100%" }}
            >
              {loading && (
                <Card
                  sx={{
                    padding: "1rem",
                    textAlign: "center",
                    variant: "outlined",
                    fontWeight: "600",
                  }}
                >
                  Loading...
                </Card>
              )}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                const style = suggestion.active
                  ? { backgroundColor: "#fafafa", cursor: "pointer" }
                  : { backgroundColor: "#ffffff", cursor: "pointer" };
                return (
                  <Card
                    variant="outlined"
                    sx={{ height: "100%", padding: "1rem" }}
                    {...getSuggestionItemProps(suggestion, {
                      className: "input-suggestion",
                      style,
                    })}
                  >
                    <LocationOnIcon style={{ color: "brown" }} />
                    <div>{suggestion.description}</div>
                  </Card>
                );
              })}
            </Box>
          </FormControl>
        )}
      </PlacesAutocomplete>
      {/* } */}
      <div style={{
        border: "1px solid #C60734", marginTop: "2vh",
        borderRadius: "1rem",
      }}>
        <GoogleMap
          apiKey={"AIzaSyBVNeXhVJbD9mHKqwMRIWZuwR8tBhthIus"}
          mapContainerStyle={mapStyles}
          zoom={12}
          options={{
            clickableIcons: false,
            // zoomControl: true,
            // streetViewControl: true,
            gestureHandling: "greedy",
            // mapTypeControl: true,
            // fullscreenControl: true,
          }}
          // onBoundsChanged={handleBoundChanged}
          center={!deny ? storeCoordinate : userCoordenates}
          onLoad={handleOnLoad}
          onCenterChanged={handleCenterChanged}

        >
          {markers?.length === 0 ? (
            <Marker
              position={!deny ? storeCoordinate : userCoordenates}
              onDragEnd={(e) => onMarkerDragEnd(e)}
              defaultDraggable={true}
              draggable={true}
            />
          ) : (
            markers?.map(({ id, name, position, icon }) => (

              <Marker
                key={id}
                position={position}
                clickable={true}
                animation={2}
                onClick={(e) => handleActiveMarker(id, name)}
                onMouseOver={() =>
                  detectMobileDevice() ? "" : handleMarkerState(id)
                }
                onMouseOut={() =>
                  detectMobileDevice() ? "" : handleMarkerStateSec(name)
                }
                icon={{
                  url: (nearestStoreData.id === id || activeMarker === id) ? "./pin1.png" : icon,
                  fillOpacity: 1.0,
                  strokeWeight: 0,
                  scaledSize: {
                    width: activeMarker === id ? markerSize.width : (nearestStoreData.id === id ? 35 : 18),
                    height: activeMarker === id ? (markerSize.height) : (nearestStoreData.id === id ? 52 : 18),
                  }
                }}
              >
                {(activeMarker === id) && position ? (
                  <InfoWindow position={position}>
                    <div>{name}</div>
                  </InfoWindow>
                ) : null}
              </Marker>

            ))
          )}
          <Marker position={myLatLng}
            icon={{
              url: userImage,
              fillOpacity: 1.0,
              strokeWeight: 0,
              scaledSize: {
                width: 19,
                height: 19,
              },
            }}
          />
        </GoogleMap>
        {!deny ?
          <Typography textAlign="center" color="#C60734" style={{ cursor: "pointer", margin: "0.5rem 0rem" }}
            onClick={handleAreaClick}
          >Search this area</Typography> : " "}
      </div>
      <Divider style={{ margin: "0.5rem" }} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between"
        }}
      >
        {deny && (
          <div>
            <Button
              color="primary"
              variant="contained"
              onClick={handleUserInputs}
              sx={{ width: "100%" }}
              disabled={select ? false : true}
            >
              Confirm Location
            </Button>
          </div>
        )}
        {!deny && (
          <Container sx={{ pt: 1, overflow: "auto", height: "31vh" }}>
            {storeData.length > 0 ?
              <div ref={abc}>
                <StoreDistance setState={setState} setStoreLoading={setStoreLoading}
                  activeMarkerName={activeMarkerName} activeMarkerId={activeMarkerId}
                  setActiveMarkerName={setActiveMarkerName} setActiveMarkerId={setActiveMarkerId}
                  selectedMarker={selectedMarker}
                />
              </div>
              :
              (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      fontSize: "1.5rem",
                      fontWeight: "600",
                      textAlign: "center",
                    }}
                  >{storeData.length === 0 ?
                    "Please search by City/State/Zip to find the closest location to you" : ""}
                  </div>
                  <Divider />
                </div>
              )
            }
          </Container>
        )}
        {deny && (
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                border: "1px dotted black",
                height: "7rem",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <MyLocationIcon
                fontSize="large"
                onClick={() => setCurrentLocation(true)}
              />
              <div style={{ padding: "1rem" }}>
                Tap the target to find the closest Gong cha store
              </div>
            </div>
          </div>
        )}
      </div>
      <Popover
        open={popOver}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography
          sx={{
            p: 3,
            width: "100vw",
            textAlign: "center",
            backgroundColor: "#786259",
            color: "white",
          }}
        >
          {geolocationError}
        </Typography>
      </Popover>
      <AlertPopup
        open={storeClose}
        setOpen={setStoreClose}
        title={"selectStore"}
        displayTitle={
          "This store is currently closed.  Please select another store"
        }
        buttonType1={true}
      />
    </>
  );
};
export default MapContainer;
