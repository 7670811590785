import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Headerbar from "./Common/Components/Navbar/HeaderBar.js";
import TabBar from "./Common/Components/Navbar/TabBar";
import Cart from "./pages/Cart/Cart";
import Checkout from "./pages/Checkout/Checkout";
import Footer from "./pages/Footer.js";
// import IdleTime from "./IdleTime";
import { decode } from "base-64";
import { getAllAddonItems, getStoreByCustomerLocation } from "./Api/API";
import AlertPopup from "./Common/AlertPopup";
import SettingTabBar from "./Common/Components/Navbar/SettingTabBar";
import { handleStoreStartEndTime } from "./Common/utils";
import MenuScreen from "./pages/Categories/MenuScreen";
import Favorites from "./pages/Favorites/Favorites";
import KioskReceipt2 from "./pages/KioskReceipt/KioskReceipt2.js";
import Login from "./pages/Login/Login";
import SocialButton from "./pages/Login/SocialButton";
import OrderHistory from "./pages/OrderHistory/OrderHistory";
import Payment from "./pages/Payment/Payment";
import PosCardEntry from "./pages/Payment/PosCardEntry";
import Rewards from "./pages/Rewards/Rewards";
import AddCardMobile from "./pages/Setting.js/AddCardMobile";
import CardDetail from "./pages/Setting.js/CardDetail";
import SignUp from "./pages/SignUp/SignUp";
import { setAddonItemList, setOpenPopUps, setTabValue } from "./Redux/CartSlice";
import {
  setCheckScroll,
  setCoordinate,
  setDeny,
  setFlag,
  setNearestStore,
  setRouteError,
  setStoreCoordinate,
  setStoreData,
  setStoreIndexValue,
  setStoreMarkers,
  setUserCoordinate,
  setUserFlag,
} from "./Redux/StoreSlice";
function App() {
  const dispatch = useDispatch();

  // const timer = IdleTime(1800);
  const flag = useSelector((state) => state.store.flag);
  const userFlag = useSelector((state) => state.store.userFlag);
  const [state, setState] = useState(false);
  const [storeLoading, setStoreLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const isLogin = useSelector((state) => state.user.isLogin);
  const isGuestLogin = useSelector((state) => state.user.isGuestLogin);
  const cartItem = useSelector((state) => state.cart.cart);
  const isUsercheckout = useSelector((state) => state?.user.isCheckout);
   const nearestStore = useSelector((state) => state.store.nearestStore);
  let location = useLocation();
  let search = useLocation().search;
  const deviceType = new URLSearchParams(search).get("deviceType")
  let kioskDeviceType=deviceType;
  if(location.pathname==="/kiosk/downloadReceipt"&&!deviceType){
     kioskDeviceType = new URLSearchParams(decode(search.substring(1))).get("deviceType")
  }

  useEffect(() => {
    dispatch(setOpenPopUps(false));
    dispatch(setTabValue(2));
  }, [dispatch]);

  useEffect(() => {
    if(kioskDeviceType !== "kiosk"||deviceType !== "pos"&&location.pathname!=="/kiosk/downloadReceipt"){
  const searchParams = new URLSearchParams(location.search);
   let qlat = searchParams.get("latitude")
   let qlng = searchParams.get("longitude")
    let pathname = {coords :{latitude:qlat ? Number(qlat) : null,
    longitude: qlng ? Number(qlng) : null}}
    if(pathname.coords.latitude && pathname.coords.longitude && flag === true){
      success(pathname)
      dispatch(setFlag(false));
    }
    else if(userFlag === true){
      navigator.geolocation.getCurrentPosition(success, error);
      dispatch(setUserFlag(false));
    }
    else{
    if (flag === true && deviceType !== "mobile") {
      navigator.geolocation.getCurrentPosition(success, error);      
    }
    dispatch(setFlag(false));  
  }
}
  }, [userFlag]);

  const error = (err) => {
    if(((!deviceType || deviceType === "web") && flag ===true )){
      setOpenAlert(true);
    }
  };

  const success = (position) => {
    const coordinates = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    };
    //  coordinates of user
    dispatch(setDeny(false));
    setState(true)
    dispatch(setCoordinate(coordinates));
    dispatch(setUserCoordinate(coordinates));

    if (coordinates) {
      setStoreLoading(true)
      const fetchStoreData = async () => {
        try {
          const res = await getStoreByCustomerLocation(coordinates.lat, coordinates.lng);
          if (!res?.result) return;
      
          let storeDetail = res.result
            .filter((store) => store.store_name && store.id) // Filter valid stores
            .map((store) => ({
              ...store,
              distance: store?.distance?.toFixed(2).toString(),
              duration: formatDuration(store.distance / 0.5),
            }))
      
          if (storeDetail.length === 0) {
            setStoreLoading(false);
            return;
          }
     
          const markers = storeDetail.map((store) => ({
            id: store.id,
            name: store.store_name,
            position: { lat: Number(store.latitude), lng: Number(store.longitude) },
            icon: "./red_dot.png",
          }));
          setStoreLoading(false);
          dispatch(setStoreMarkers(markers));
          dispatch(setStoreData(storeDetail));
          dispatch(setStoreCoordinate({ lat: Number(storeDetail[0].latitude), lng: Number(storeDetail[0].longitude) }));
          dispatch(setRouteError(""));     
          const firstStore = storeDetail[0];
          const storeStatus = handleStoreStartEndTime(firstStore, firstStore.time_zone);
          if (
            !storeStatus?.status?.[storeStatus.timeZoneWeekDay]?.storeDayStatus &&
            storeStatus?.status?.[storeStatus.timeZoneWeekDay]?.onLineStatus === "on" &&
            storeStatus?.timeRange&&nearestStore===""
          ) {
            dispatch(setNearestStore(firstStore));
          }     
          dispatch(setStoreIndexValue(storeDetail.length - 1));
          dispatch(setCheckScroll(true)) 
        } catch (error) {
          console.error("Error fetching store data:", error);
          setStoreLoading(false);
        }
      };     
      fetchStoreData()
    }
  };
    // Helper function for formatting duration
    const formatDuration = (time) => {
      let minutes = Math.round(time);
      let hours = Math.floor(minutes / 60);
      return hours > 0 
      ? `${hours} hour${hours > 1 ? 's' : ''}` + (minutes % 60 > 0 ? ` ${minutes % 60} min` : '') 
      : `${minutes} min`;
    };

  useEffect(()=>{
      const handleFetchAddons = async (storeId) => {
           getAllAddonItems(storeId)
                .then((res) => {
                 const availableAddons=  res?.result?.filter(
                    (item) => item.menuExists === true && item.is_active === true
                  ); 
                  dispatch(setAddonItemList(availableAddons));        
                })
                .catch((err) => {
                  console.error("Error fetching add-ons:", err);
                  dispatch(setAddonItemList([])); 
                })
      };
      if(nearestStore){
        handleFetchAddons(nearestStore.id)
      }     
  },[nearestStore])

  return (
    <>
      <div className="root1">
        {location.pathname === "/payment" ||
        location.pathname.indexOf("/payment") !== -1 ||
        location.pathname === "/addCard" ||
        location.pathname.indexOf("/addCard") !== -1 ||
        location.pathname === "/manualCardEntry" ||
        location.pathname.indexOf("/manualCardEntry") !== -1 ||
        location.pathname === "/kiosk/downloadReceipt" ||
        location.pathname.indexOf("/kiosk/downloadReceipt") !== -1 ||
        location.pathname.indexOf("/generateCoupon") !== -1 ? (
          deviceType === "mobile" ||
          deviceType === "pos" ||
          kioskDeviceType === "kiosk" ||
          location.pathname === "/generateCoupon" ? (
            ""
          ) : !deviceType || location.pathname === "/generateCoupon" ? (
            ""
          ) : (
            <>
              <Headerbar
                setState={setState}
                state={state}
                storeLoading={storeLoading}
                setStoreLoading={setStoreLoading}
              />
              {location.pathname === "/menu" && (
                <TabBar setState={setState} />
              )}
            </>
          )
        ) : (
          <>
            <Headerbar
              setState={setState}
              state={state}
              storeLoading={storeLoading}
              setStoreLoading={setStoreLoading}
            />
            {location.pathname === "/menu" && (
              <TabBar setState={setState} />
            )}
          </>
        )}

    {/* PROD CODE */}
    {/* {(deviceType === "mobile"||deviceType === "pos"||kioskDeviceType === "kiosk"||location.pathname==="/kiosk/downloadReceipt")? " " :
            <Footer setState={setState} /> } */}


        {deviceType === "mobile" ||
        deviceType === "pos" ||
        kioskDeviceType === "kiosk" ||
        location.pathname === "/generateCoupon" ||
        location.pathname.indexOf("/kiosk/downloadReceipt") !== -1 ? (
          " "
        ) : (
          <Footer setState={setState} />
        )}
        <Routes>
          <Route exact path="/" element={<TabBar setState={setState} />} />
            <Route
            exact
            path="/kiosk/downloadReceipt"
            element={<KioskReceipt2 />}
          />
          <Route
            exact
            path="/menu"
            element={<MenuScreen setState={setState} />}
          />
          <Route
            exact
            path="/setting"
            element={isLogin ? <SettingTabBar /> : <TabBar />}
          />
          <Route exact path="/cart" element={<Cart />} />
          <Route exact path="/login" element={<Login />} />
          <Route
            exact
            path="/checkout"
            element={
              (isLogin || isGuestLogin) && isUsercheckout ? (
                <Checkout />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route exact path="/payment" element={<Payment />} />
          <Route exact path="/payment/success" element={<Payment />} />
          <Route exact path="/payment/failure" element={<Payment />} />
          <Route exact path="/rewards" element={<Rewards />} />
          <Route exact path="/signup" element={<SignUp />} />
          <Route exact path="/orderHistory" element={<OrderHistory />} />
          <Route exact path="/favorites" element={<Favorites />} />
          <Route exact path="/saveCard" element={<CardDetail />} />
          <Route exact path="/addCard" element={<AddCardMobile />} />
          <Route exact path="/addCard/success" element={<AddCardMobile />} />
          <Route exact path="/addCard/failure" element={<AddCardMobile />} />
          <Route exact path="/manualCardEntry" element={<PosCardEntry />} />
        </Routes>
      </div>
      <div style={{ display: "none" }}>
        <SocialButton
          provider="google"
          appId="582546805022-cua7jqr50ar1s334p914oso1patlk192.apps.googleusercontent.com"
        ></SocialButton>
      </div>
      <AlertPopup
        open={openAlert}
        setOpen={setOpenAlert}
        setState={setDeny}
        title={"locationAccess"}
        displayTitle={
          "Location access is blocked. Change your location settings in browser or select location manually"
        }
        buttonType2={true}
      />
    </>
  );
}

export default App;
