import {
  Switch,
  styled,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyle = makeStyles((theme) => ({
  modalBackdrop: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  pointerCursor: {
    cursor: "pointer",
  },
  receipt: {
    margin: "30px 40px",
    border: "2px dotted red",
    width: "200px",
  },
  switchGrid: {
    margin: "10px 50px",
  },
  topTable: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #e6e6fa",
    margin: "10px 0px",
  },
  Greetings: {
    textAlign: "center",
    marginTop: "10px",
    color: "#b22222",
  },
  defaultMessage: {
    textAlign: "center",
    marginTop: "2px",
    fontWeight:"bolder"
  },
  switches: {
    margin: "20px",
  },
}));

export const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 50,
  height: 27,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "500ms",
    "&.Mui-checked": {
      transform: "translateX(25px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#b22222",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

