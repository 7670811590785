import {
  Container,
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
  Stack,
} from "@mui/material";
import rewardss from "./rewardss.gif";
import { useSelector } from "react-redux";
import { Box, Slider } from "@mui/material";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { makeStyles, styled } from "@mui/styles";
import { LoyalityPoint, RewardList } from "../../Api/API";
import { errorToastMessage } from "../../Common/utils";
import InfoIcon from "@mui/icons-material/Info";
import MuiSlider from "@mui/material/Slider";
import { width } from "@mui/system";
import { useDispatch } from "react-redux";
import { setTabValue, setUpdateCart } from "../../Redux/CartSlice";
import TabBar from "../../Common/Components/Navbar/TabBar";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#E7E7E7",
  },
  header: {
    marginTop: "2rem",
    height: "21.6vh",
    display: "flex",
    justifyContent: "space-around",
  },
  points: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
  },
  btnLogin: {
    borderRadius: 5,
    backgroundColor: "brown",
    color: "white",
    textTransform: "none",
    marginTop: "1rem",
  },
  btn: {
    // backgroundColor: "transparent",
    fontFamily: "Lusitana",
    color: "white",
    width: "100%",
    // height: "8vh",
    lineHeight: "0.9rem",
    justifyContent: "space-between",
    textTransform: "none",
    minHeight: "7vh",
    cursor: "default",
    marginTop: "-1rem",
    // border:"1px solid #E7E7E7",
    "&:hover": {
      background: "none",
    },
  },
  // mark1: {
  //   background: "#B61B31",
  //   width: "0.7rem",
  //   // height:"0.7rem",
  //   borderRadius: "0.7rem",
  // },
  // valueLabel: {
  //   "&>*": {
  //     background: "black"
  //   }
  // }
  tabBarContainer: {
    width: "100%",
    backgroundColor: "#fff",
  },

}));

const Sliders = styled(MuiSlider)({
  '& .MuiSlider-mark[data-index="0"]': {
    background: "white",
    // width: "160px",
    // maxWidth:"200px",
    minWidth: "120px",
    borderRadius: "0.7rem",
  },
  '& .MuiSlider-mark[data-index="1"]': {
    background: "white",
    // paddingLeft: "178px",
    maxWidth: "218px",
    minWidth: "96px",
    // height:"0.7rem",
    borderRadius: "0.7rem",

    // marginLeft:"2rem",
  },
  '& .MuiSlider-mark[data-index="2"]': {
    background: "white",
    // paddingLeft: "172px",
    maxWidth: "172px",
    minWidth: "100px",
    // height:"0.7rem",
    borderRadius: "0.7rem",

    // marginLeft:"2rem",
  },
  '& .MuiSlider-mark[data-index="3"]': {
    background: "white",
    // paddingLeft: "168px",
    maxWidth: "200px",
    minWidth: "105px",
    // height:"0.7rem",
    borderRadius: "0.7rem",

    // marginLeft:"2rem",
  },
  '& .MuiSlider-mark[data-index="4"]': {
    background: "white",
    // paddingLeft: "170px",
    maxWidth: "180px",
    minWidth: "120px",
    // height:"0.7rem",
    borderRadius: "0.7rem",

    // marginLeft:"2rem",
  },
  '& .MuiSlider-mark[data-index="5"]': {
    background: "white",
    // paddingLeft: "187px",
    maxWidth: "200px",
    minWidth: "135px",
    // height:"0.7rem",
    borderRadius: "0.7rem",
    // marginLeft:"2rem",
  },
  '& .MuiSlider-mark[data-index="6"]': {
    background: "white",
    // paddingLeft: "205px",
    maxWidth: "220px",
    minWidth: "142px",
    // height:"0.7rem",
    borderRadius: "0.7rem",

    // marginLeft:"2rem",
  },
});

const Rewards = () => {
  const classes = useStyles();
  const location = useLocation();
  let navigate = useNavigate();
  let dispatch = useDispatch()
  const nearestStore = useSelector((state) => state.store.nearestStore);
  const isLogin = useSelector((state) => state.user.isLogin);
  const user = useSelector((state) => state.user.user);
  const [loyalityPnt, setLoyalityPnt] = useState();
  const [rewardlst, setrewardlst] = useState([]);
  const [click, setClick] = useState();
  const [rewardValue, setrewardValue] = useState();
  const [rewardMax, setrewardMax] = useState(0);
  const date = new Date().toLocaleDateString();
  const [state, setState] = useState();
  let phoneNumber = user?.phone_no ? user?.phone_no : user?.phone_number;
  let personId = user?.person_id ? user?.person_id : user?.customer_id;

  useEffect(() => {
    if (location.pathname === '/rewards' && !nearestStore?.id) {
        dispatch(setUpdateCart([]));
    }
}, [location.pathname, dispatch, nearestStore]);

  useEffect(() => {
    if (isLogin) {
      LoyalityPoint(phoneNumber, personId)
        .then((res) => {
          setLoyalityPnt(res?.userDetails);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [isLogin]);

  useEffect(() => {
    if (isLogin && loyalityPnt?.loyalityPoint >= 0) {
      RewardList("", personId)
        .then((res) => {
          res.result.unshift({
            point_cost: 0,
            perk: "EARN 1 POINT PER DOLLAR SPENT!",
          });
          
          setrewardlst(res?.result);
          let rewardPartition = [];
          let rewardPartitionForSmall = [];
          let length = res?.result?.length;
          let partition = 100 / (length - 1);
          let initialPartition = 0;
          res?.result.forEach((item, index) => {
            rewardPartition.push({
              value: initialPartition,
              label: (
                <div
                  style={{
                    display: "flex",
                    // textAlign:"center"
                  }}
                >
                  <Typography
                  sx={{
                    fontFamily : "Montserrat !important",margin:{lg:"6px",md:"6px",sm:"6px",xs:"4px"},
                    fontSize:{lg:"16px",md:"16px",sm:"16px",xs:"14px"},
                    fontWeight:900,
                    marginTop:{lg:"4.5px",md:"5px",sm:"5px",xs:"3px"}}}
                  
                  >
                    {item?.point_cost}
                  </Typography>
                  <div>
                    <Typography
                      sx={{
                        fontFamily : "Montserrat !important",
                        color:
                          loyalityPnt?.loyalityPoint >= item?.point_cost
                            ? "white"
                            : "#bdbdbd",
                        marginLeft: {
                          lg: "0.8rem",
                          md: "0.8rem",
                          sm: "0.8rem",
                          xs: "0.5rem",
                        },
                        fontSize: {
                          lg: "13px",
                          md: "13px",
                          sm: "13px",
                          xs: "10px",
                        },
                        // fontFamily: "sans-serif",
                        // textTransform:"",
                        textAlign: "justify",
                        width: {
                          lg: "145px",
                          md: "145px",
                          sm: "145px",
                          xs: "105px",
                        },
                        whiteSpace: "pre-line",
                        wordWrap: "break-word",
                      }}
                    >
                      {item?.perk?.split("(")[0].substring(0, 1).toUpperCase()+item?.perk?.split("(")[0].substring(1,item?.perk?.split("(")[0].length).toLowerCase()}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily : "Montserrat !important",
                        color:
                          loyalityPnt?.loyalityPoint >= item?.point_cost
                            ? "white"
                            : "#bdbdbd",
                        marginLeft: {
                          lg: "0.8rem",
                          md: "0.8rem",
                          sm: "0.8rem",
                          xs: "0.5rem",
                        },
                        fontSize: {
                          lg: "13px",
                          md: "13px",
                          sm: "13px",
                          xs: "10px",
                        },
                        // fontFamily: "sans-serif",
                        textTransform: "lowercase",
                        textAlign: "justify",
                        opacity: "0.6",
                        // width: {
                        //   lg: "210px",
                        //   md: "170px",
                        //   sm: "200px",
                        //   xs: "94px",
                        // },
                        // whiteSpace: "pre-line",
                        // wordWrap: "break-word",
                      }}
                    >
                      {item?.perk?.split("(")[1]
                        ? "(" + item?.perk?.split("(")[1]
                        : ""}
                    </Typography>
                  </div>
                </div>
              ),
              // label: item?.perk,
              selected: item?.point_cost,
            });

            rewardPartitionForSmall.push({ value: initialPartition });
            initialPartition = initialPartition + Number(partition);

            let maxReward = Math.max(...rewardPartition.map((o) => o.value));
            setrewardMax(maxReward);
            setrewardValue(rewardPartition);
          });
        })
        .catch((err) => {
          errorToastMessage();
          console.log(err);
        });
    }
  }, [isLogin, loyalityPnt?.loyalityPoint]);

  const selected = rewardValue?.find(
    (ele) => ele?.label > loyalityPnt?.loyalityPoint
  );
  let val = 0;
  let par = 0;
  let vall = 0;
  let abc = 0;
  let selectedVAL = 0;

  if (selected?.value == 0) {
    selectedVAL = 0;
  } else {
    val = selected?.value - 100 / (rewardValue?.length - 1);
    par = 100 / (rewardValue?.length - 1);
    vall = rewardValue?.find((ele) => ele?.value == val);
    abc = par / (selected?.label - vall?.label);
    selectedVAL =
      abc * (loyalityPnt?.loyalityPoint - vall?.label) + vall?.value;
  }

  return (
    <div>
      <div className={classes.tabBarContainer}>
        <TabBar setState={setState} />
      </div>
      <Container style={{ marginTop: "6vh", paddingBottom: "3vh" }}>
      <div
            style={{
              display: "flex",
              padding: "0.7rem 0rem",
              marginBottom: "1rem",
              marginTop: "-2.8rem"
            }}
           
          >
          <div style={{display:"flex",cursor: "pointer",}} onClick={() => {
              dispatch(setTabValue(2))
              let path = `/`;
              navigate(path);
            }}>
            <ArrowBackIosIcon style={{ fill: "#C60734", marginTop: '1rem' }} />
            <Typography sx={{ color: "#C60734", marginTop: '1rem' }} variant="h5">
             Rewards
            </Typography>
          </div>
          </div>
        <Card
          sx={{
            minWidth: "275px",
            borderRadius: "1rem",
            // background: "linear-gradient(260deg, darkorchid 50%, turquoise 50%)",
            background: {
              lg: "linear-gradient(105deg, #B61B31 60%, white 60.1%)",
              md: "linear-gradient(461deg, #B61B31 60%, white 60.1%)",
              sm: "linear-gradient(172deg, #B61B31 53%, white 53.1%)",
              xs: "linear-gradient(170deg, #B61B31 50%, white 50.1%)",
            },
            borderImageSource: "linear-gradient(to left, #00C853, #B2FF59)",
          }}
        >
          <Grid container>
            <Grid
              item
              md={6}
              xs={12}
              sm={12}
              lg={6}
              sx={{
                backgroundColor: "#B61B31",
                height: "33.5rem",
                paddingTop: { lg: "7vh", md: "7vh", sm: "7vh", xs: "12vh" },
                // paddingBottom: "7vh",
                position: "relative",
              }}
            >
              <div style={{ position: "inherit" }}>
                <Box
                  sx={{
                    marginTop: "-2rem",
                    marginLeft: "23vw",
                    position: "relative",
                  }}
                >
                  <img
                    src="./stars.png"
                    alt="abc"
                    style={{
                      height: "6.5rem",
                      maxWidth: { xs: "100%" },
                      // position:"absolute",
                      objectFit: "contain",
                    }}
                  />
                  <Typography
                  // fontFamily="Arial"
                  // style={{fontFamily : "Montserrat !important"}}
                    variant="h4"
                    textAlign="center"
                    
                    sx={{
                      fontFamily : "Montserrat !important",
                      fontSize: {
                        lg: "40px",
                        md: "40px",
                        sm: "35px",
                        xs: "35px",
                      },
                      border: "white",
                      position: "absolute",
                      // borderRadius: "0.7rem",
                      color: "white",
                      fontWeight: "bolder",
                      left: "95px",
                      top: "32%",
                    }}
                  >
                    {loyalityPnt?.loyalityPoint}

                    <Typography
                    // fontFamily="Montserrat"
                      sx={{
                        fontFamily : "Montserrat !important",
                        color: "white",
                        position: "absolute",
                        fontSize: {
                          lg: "22px",
                          md: "22px",
                          sm: "22px",
                          xs: "20px",
                        },
                        top: "80%",
                        left: "15%",
                      }}
                    >
                      points
                    </Typography>
                  </Typography>
                  <img
                    src="./stars.png"
                    alt="abc"
                    style={{
                      height: "6.5rem",
                      maxWidth: { xs: "100%" },
                      objectFit: "contain",
                      position: "absolute",
                      top: "65px",
                      left: "145px",
                    }}
                  />
                </Box>
              </div>
              <Box
                sx={{ position: "absolute", left: "0%", top: { lg: "1.8%" } }}
              >
                <Box
                  component="img"
                  style={{
                    zIndex: "100",
                    position: "relative",
                  }}
                  sx={{
                    maxWidth: { lg: "100%", md: "100%", sm: "100%", xs: "92%" },
                    height: {
                      lg: "32rem",
                      md: "27.5rem",
                      sm: "27.5rem",
                      xs: "23rem",
                    },
                  }}
                  src="./img_rewards_list.png"
                />
                <Stack
                  sx={{
                    height: {
                      lg: "17.9rem",
                      md: "16rem",
                      sm: "15.6rem",
                      xs: "13.7rem",
                    },
                    marginTop: {
                      lg: "-19.2rem",
                      md: "-16.6rem",
                      sm: "-16.5rem",
                      xs: "-14rem",
                    },
                  }}
                >
                  <Sliders
                    sx={{
                      margin: "0 0 2rem 0",
                      marginLeft: {
                        lg: "180px",
                        md: "145px",
                        sm: "150px",
                        xs: "105px",
                      },
                      zIndex: "0",
                      '& .MuiSlider-markLabel[data-index="0"]': {
                        lg: {
                          // top : "28px",rewardlst .point_cost
                          //                           color:
                          //                           loyalityPnt?.loyalityPoint >= rewardlst?.map((e) =>e.
                          // e
                          //                           )
                          //                             ? "white"
                          //                             : "#bdbdbd",
                          // fontWeight: 900,
                          fontSize: "14px",
                          color: "#B61B31",
                          width: "2rem",
                          height: "2rem",
                          borderRadius: "50%",
                          backgroundColor: "white",
                          marginLeft: "1.6rem",
                          paddingTop:"1px",
                          paddingLeft:"5px"
                        },
                        md: {
                          fontSize: "14px",
                          color: "#B61B31",
                          width: "2rem",
                          height: "2rem",
                          borderRadius: "50%",
                          backgroundColor: "white",
                          marginLeft: "1.6rem",
                          paddingTop:"1px",
                          paddingLeft:"5px"
                        },
                        sm: {
                          fontSize: "14px",
                          color: "#B61B31",
                          width: "2rem",
                          height: "2rem",
                          borderRadius: "50%",
                          backgroundColor: "white",
                          marginLeft: "1.6rem",
                          paddingTop:"0.5px",
                          paddingLeft:"5px"
                        },
                        xs: {
                          fontSize: "11px",
                          color: "#B61B31",
                          width: "1.7rem",
                          height: "1.7rem",
                          borderRadius: "50%",
                          backgroundColor: "white",
                          marginLeft: "0.8rem",
                          paddingTop:"0px",
                          paddingLeft:"4px"
                        },
                      },
                      '& .MuiSlider-markLabel[data-index="1"]': {
                        lg: {
                          fontSize: "14px",
                          color: "#B61B31",
                          width: "2rem",
                          height: "2rem",
                          borderRadius: "50%",
                          backgroundColor: "white",
                          marginLeft: rewardlst?.length<3?"4rem":"2.6rem",
                          // textAlign: "center",
                          // alignItems:"center"
                        },
                        md: {
                          fontSize: "14px",
                          color: "#B61B31",
                          width: "2rem",
                          height: "2rem",
                          borderRadius: "50%",
                          backgroundColor: "white",
                          marginLeft:  rewardlst?.length<3?"4rem":"2.6rem",
                        },
                        sm: {
                          fontSize: "14px",
                          color: "#B61B31",
                          width: "2rem",
                          height: "2rem",
                          borderRadius: "50%",
                          backgroundColor: "white",
                          marginLeft:  rewardlst?.length<3?"4rem":"2.6rem",
                        },
                        xs: {
                          fontSize: "11px",
                          color: "#B61B31",
                          width: "1.7rem",
                          height: "1.7rem",
                          borderRadius: "50%",
                          backgroundColor: "white",
                          marginLeft:  rewardlst?.length<3?"2.5rem":"1.3rem",
                        },
                      },
                      '& .MuiSlider-markLabel[data-index="2"]': {
                        lg: {
                          fontSize: "14px",
                          color: "#B61B31",
                          width: "2rem",
                          height: "2rem",
                          borderRadius: "50%",
                          backgroundColor: "white",
                          marginLeft: "3.6rem",
                        },
                        md: {
                          fontSize: "14px",
                          color: "#B61B31",
                          width: "2rem",
                          height: "2rem",
                          borderRadius: "50%",
                          backgroundColor: "white",
                          marginLeft: "3.6rem",
                        },
                        sm: {
                          fontSize: "14px",
                          color: "#B61B31",
                          width: "2rem",
                          height: "2rem",
                          borderRadius: "50%",
                          backgroundColor: "white",
                          marginLeft: "3.6rem",
                        },
                        xs: {
                          fontSize: "11px",
                          color: "#B61B31",
                          width: "1.7rem",
                          height: "1.7rem",
                          borderRadius: "50%",
                          backgroundColor: "white",
                          marginLeft: "1.8rem",
                        },
                      },
                      '& .MuiSlider-markLabel[data-index="3"]': {
                        lg: {
                          fontSize: "14px",
                          color: "#B61B31",
                          width: "2rem",
                          height: "2rem",
                          borderRadius: "50%",
                          backgroundColor: "white",
                          marginLeft: "4.6rem",
                        },
                        md: {
                          fontSize: "14px",
                          color: "#B61B31",
                          width: "2rem",
                          height: "2rem",
                          borderRadius: "50%",
                          marginLeft: "4.6rem",
                        },
                        sm: {
                          fontSize: "14px",
                          color: "#B61B31",
                          width: "2rem",
                          height: "2rem",
                          borderRadius: "50%",
                          backgroundColor: "white",
                          marginLeft: "4.6rem",
                        },
                        xs: {
                          fontSize: "11px",
                          color: "#B61B31",
                          width: "1.7rem",
                          height: "1.7rem",
                          borderRadius: "50%",
                          backgroundColor: "white",
                          marginLeft: "2.3rem",
                        },
                      },
                      '& .MuiSlider-markLabel[data-index="4"]': {
                        lg: {
                          color: "#B61B31",
                          width: "2rem",
                          height: "2rem",
                          textAlign: "center",
                          borderRadius: "50%",
                          backgroundColor: "white",
                          marginLeft: "5.6rem",
                        },
                        md: {
                          fontSize: "14px",
                          color: "#B61B31",
                          width: "2rem",
                          height: "2rem",
                          borderRadius: "50%",
                          backgroundColor: "white",
                          marginLeft: "5.6rem",
                        },
                        sm: {
                          fontSize: "14px",
                          color: "#B61B31",
                          width: "2rem",
                          height: "2rem",
                          borderRadius: "50%",
                          backgroundColor: "white",
                          marginLeft: "5.6rem",
                        },
                        xs: {
                          fontSize: "11px",
                          color: "#B61B31",
                          width: "1.7rem",
                          height: "1.7rem",
                          borderRadius: "50%",
                          backgroundColor: "white",
                          marginLeft: "2.61rem",
                        },
                      },
                      '& .MuiSlider-markLabel[data-index="5"]': {
                        lg: {
                          fontSize: "14px",
                          color: "#B61B31",
                          width: "2rem",
                          height: "2rem",
                          borderRadius: "50%",
                          backgroundColor: "white",
                          marginLeft: "6.6rem",
                        },
                        md: {
                          // top : "28px",
                          fontWeight: 900,
                          fontSize: "14px",
                          color: "#B61B31",
                          width: "2rem",
                          height: "2rem",
                          borderRadius: "50%",
                          backgroundColor: "white",
                          marginLeft: "6.6rem",
                        },
                        sm: {
                          fontWeight: 900,
                          fontSize: "14px",
                          color: "#B61B31",
                          width: "2rem",
                          height: "2rem",
                          borderRadius: "50%",
                          backgroundColor: "white",
                          marginLeft: "6.6rem",
                        },
                        xs: {
                          fontWeight: 900,
                          fontSize: "11px",
                          color: "#B61B31",
                          width: "1.7rem",
                          height: "1.7rem",
                          borderRadius: "50%",
                          backgroundColor: "white",
                          marginLeft: "2.9rem",
                        },
                      },
                      '& .MuiSlider-markLabel[data-index="6"]': {
                        lg: {
                          // top : "28px",
                          fontWeight: 900,
                          fontSize: "14px",
                          color: "#B61B31",
                          width: "2rem",
                          height: "2rem",
                          // textAlign: "center",
                          borderRadius: "50%",
                          // display: "flex",
                          // alignItems: "center",
                          backgroundColor: "white",
                          marginLeft: "7.8rem",
                        },
                        md: {
                          fontWeight: 900,
                          fontSize: "14px",
                          color: "#B61B31",
                          width: "2rem",
                          height: "2rem",

                          borderRadius: "50%",
                          backgroundColor: "white",
                          marginLeft: "7.8rem",
                        },
                        sm: {
                          fontWeight: 900,
                          fontSize: "14px",
                          color: "#B61B31",
                          width: "2rem",
                          height: "2rem",
                          borderRadius: "50%",
                          backgroundColor: "white",
                          marginLeft: "7.8rem",
                        },
                        xs: {
                          fontWeight: 900,
                          fontSize: "11px",
                          color: "#B61B31",
                          width: "1.7rem",
                          height: "1.7rem",
                          borderRadius: "50%",
                          backgroundColor: "white",
                          marginLeft: "3.2rem",
                        },
                      },
                      '& .MuiSlider-mark[data-index="0"]': {
                        lg: {
                          background: "white",
                          width: "155px",
                          borderRadius: "0.7rem",
                        },
                        md: {
                          background: "white",
                          width: "160px",
                          borderRadius: "0.7rem",
                        },
                        sm: {
                          background: "white",
                          width: "160px",
                          borderRadius: "0.7rem",
                        },
                        xs: {
                          background: "white",
                          width: "120px",
                          borderRadius: "0.7rem",
                        },
                      },
                      '& .MuiSlider-mark[data-index="1"]': {
                        lg: {
                          background: "white",
                          width: "178px",
                          borderRadius: "0.7rem",
                        },
                        md: {
                          background: "white",
                          width: "178px",
                          borderRadius: "0.7rem",
                        },
                        sm: {
                          background: "white",
                          width: "178px",
                          borderRadius: "0.7rem",
                        },
                        xs: {
                          background: "white",
                          width: rewardlst?.length<3? "125px":"96px",
                          borderRadius: "0.7rem",
                        },
                      },
                      '& .MuiSlider-mark[data-index="2"]': {
                        lg: {
                          background: "white",
                          width: "172px",
                          borderRadius: "0.7rem",
                        },
                        md: {
                          background: "white",
                          width: "172px",
                          borderRadius: "0.7rem",
                        },
                        sm: {
                          background: "white",
                          width: "172px",
                          borderRadius: "0.7rem",
                        },
                        xs: {
                          background: "white",
                          width: "115px",
                          borderRadius: "0.7rem",
                        },
                      },
                      '& .MuiSlider-mark[data-index="3"]': {
                        lg: {
                          background: "white",
                          width: "200px",
                          borderRadius: "0.7rem",
                        },
                        md: {
                          background: "white",
                          width: "200px",
                          borderRadius: "0.7rem",
                        },
                        sm: {
                          background: "white",
                          width: "200px",
                          borderRadius: "0.7rem",
                        },
                        xs: {
                          background: "white",
                          width: "134px",
                          borderRadius: "0.7rem",
                        },
                      },
                      '& .MuiSlider-mark[data-index="4"]': {
                        lg: {
                          background: "white",
                          width: "175px",
                          borderRadius: "0.7rem",
                        },
                        md: {
                          background: "white",
                          width: "175px",
                          borderRadius: "0.7rem",
                        },
                        sm: {
                          background: "white",
                          width: "180px",
                          borderRadius: "0.7rem",
                        },
                        xs: {
                          background: "white",
                          width: "138px",
                          borderRadius: "0.7rem",
                        },
                      },
                      '& .MuiSlider-mark[data-index="5"]': {
                        lg: {
                          background: "white",
                          width: "197px",
                          borderRadius: "0.7rem",
                        },
                        md: {
                          background: "white",
                          width: "197px",
                          borderRadius: "0.7rem",
                        },
                        sm: {
                          background: "white",
                          width: "200px",
                          borderRadius: "0.7rem",
                        },
                        xs: {
                          background: "white",
                          width: "142px",
                          borderRadius: "0.7rem",
                        },
                      },
                      '& .MuiSlider-mark[data-index="6"]': {
                        lg: {
                          background: "white",
                          width: "215px",
                          borderRadius: "0.7rem",
                        },
                        md: {
                          background: "white",
                          width: "215px",
                          borderRadius: "0.7rem",
                        },
                        sm: {
                          background: "white",
                          width: "220px",
                          borderRadius: "0.7rem",
                        },
                        xs: {
                          background: "white",
                          width: "148px",
                          borderRadius: "0.7rem",
                        },
                      },
                    }}
                    orientation="vertical"
                    classes={{
                      mark: classes.mark1,
                    }}
                    value={
                      loyalityPnt?.loyalityPoint < rewardMax
                        ? selectedVAL
                        : rewardMax
                    }
                    max={rewardMax}
                    step={null}
                    marks={rewardValue}
                    // valueLabelDisplay="on"
                  />
                </Stack>
              </Box>
              {/* </Box> */}
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              sm={12}
              lg={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                // marginTop: {sm:"0rem",xs:"0rem"},
                marginBottom: { lg: "0", md: "0", sm: "50px", xs: "60px" },
              }}
            >
              <Box
                component="img"
                sx={{
                  maxWidth: { xs: "80%" },
                  height: { lg: "45vh", md: "45vh", sm: "40vh", xs: "60vh" },
                  objectFit: "contain",
                  // alignItems:"center",
                  marginTop: {
                    lg: "8rem",
                    md: "8rem",
                    sm: "10rem",
                    xs: "10rem",
                  },
                  marginLeft: { lg: "25%", md: "25%", xs: "8%", sm: "8%" },
                }}
                src="./rewardImg.png"
              />
            </Grid>
          </Grid>
          {/* </CardContent> */}
        </Card>
      </Container>
    </div>
  );
};

export default Rewards;
