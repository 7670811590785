import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cart: [],
  cartData :"",
  paymentData : "",
  taxes:"",
  placedOrder : "",
  orderTotalPrice:"",
  pickUpLaterTime : "",
  CategoriesSet: [],
  valueSet:0,
  cartDetailes :"",
  customizeOptions:"",
  menuServingDetails:"",
  selectedCupSizes:"",
  openPopUps: false,
  titles:"",
  cartItemIndexs: 0,
  bestSellerCheck:false,
  advCheck:false,
  tabValue: 2,
  checkBestSellerId:"",
  editTitles:"",
  customizeOpen:false,
  orderId:"",
  promoCode: "",
  bestSellerItems:[],
  userFavItems:[],
  featuredList:[],
  addonItemList:[],
  favoriteMenuIds:[],
  customerOrderList:[],
  selectedItem: null, 
  isLoading: false, 
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setSelectedItem: (state, { payload }) => {
      if (payload === null) {
        state = initialState;
      } else {
        state.selectedItem = payload;
      }
    },
    setUpdateCart: (state, { payload }) => {
      if (payload == null) state = initialState;
      state.cart = payload;
    },
    setOrderTotalPrice: (state, { payload }) => {
      if (payload == null) state = initialState;
      state.orderTotalPrice = payload;
    },
    setCart: (state, { payload }) => {
      if (payload == null) {state = initialState;}
     
      else{
        state.cart = [...state.cart,...payload]
      }
    },
    setCustomizeOpen: (state, { payload }) => {
      if (payload == null) {state = initialState;}
      else{
        state.customizeOpen = payload;
      }
    },
    setCategoriesSet: (state, { payload }) => {
      if (payload == null) {state = initialState;}
      else{
        state.CategoriesSet = payload;
      }
    },
    setValueSet: (state, { payload }) => {
      if (payload == null) {state = initialState;}
      else{
        state.valueSet = payload;
      }
    },
    setCartDetailes: (state, { payload }) => {
      if (payload == null) {state = initialState;}
      else{
        state.cartDetailes = payload;
      }
    },
    setCustomizeOptions: (state, { payload }) => {
      if (payload == null) {state = initialState;}
      else{
        state.customizeOptions = payload;
      }
    },
    setMenuServingDetails: (state, { payload }) => {
      if (payload == null) {state = initialState;}
      else{
        state.menuServingDetails = payload;
      }
    },
    setSelectedCupSizes: (state, { payload }) => {
      if (payload == null) {state = initialState;}
      else{
        state.selectedCupSizes = payload;
      }
    },
    setOpenPopUps: (state, { payload }) => {
      if (payload == null) {state = initialState;}
      else{
        state.openPopUps = payload;
      }
    },
    setTitles: (state, { payload }) => {
      if (payload == null) {state = initialState;}
      else{
        state.titles = payload;
      }
    },
    setEditTitles: (state, { payload }) => {
      if (payload == null) {state = initialState;}
      else{
        state.editTitles = payload;
      }
    },
    setBestSellerCheck: (state, { payload }) => {
      if (payload == null) {state = initialState;}
      else{
        state.bestSellerCheck = payload;
      }
    },
    setAdvCheck: (state, { payload }) => {
      if (payload == null) {state = initialState;}
      else{
        state.advCheck = payload;
      }
    },
    setTabValue: (state, { payload }) => {
      if (payload == null) {state = initialState;}
      else{
        state.tabValue = payload;
      }
    },
    setCartItemIndexs: (state, { payload }) => {
      if (payload == null) {state = initialState;}
      else{
        state.cartItemIndexs = payload;
      }
    },
    setCartData : (state, { payload }) => {
      if (payload == null) state = initialState;
      state.cartData = payload;
    },
    setPaymentData : (state,{payload}) => {
      if(payload == null) state = initialState;
      state.paymentData = payload;
    },
    setTaxes : (state,{payload}) => {
      if(payload == null) state = initialState;
      state.taxes = payload;
    },
    setPlacedOrder : (state,{payload}) => {
      if(payload == null) state = initialState;
      state.placedOrder = payload;
    },
    setPickUpLaterTime : (state,{payload}) => {
      if(payload == null) state = initialState;
      state.pickUpLaterTime = payload;
    },
    setCheckBestSellerId : (state,{payload}) => {
      if(payload == null) state = initialState;
      state.checkBestSellerId = payload;
    },
    setOrderId : (state,{payload}) => {
      if(payload == null) state = initialState;
      state.orderId = payload;
    },
    setPromoCode: (state, { payload }) => {
      if (payload == null) {
        state = initialState;
      } else {
        state.promoCode = payload;
      }
    },
    setBestSellersItems : (state,{payload}) => {
      if(payload == null) state = initialState;
      state.bestSellerItems = payload;
    },
    setUserFavItems : (state,{payload}) => {
      if(payload == null) state = initialState;
      state.userFavItems = payload;
    },
    setFeaturedList : (state,{payload}) => {
      if(payload == null) state = initialState;
      state.featuredList = payload;
    },
    setAddonItemList : (state,{payload}) => {
      if(payload == null) state = initialState;
      state.addonItemList = payload;
    },
    setFavoriteMenuStatus: (state, { payload }) => {
      state.favoriteMenuIds = {
        ...state.favoriteMenuIds,
        [payload.menuId]: payload.isFavorite
      };
    },
    setCustomerOrderList : (state,{payload}) => {
      if(payload == null) state = initialState;
      state.customerOrderList = payload;
    },
  },
});

const { reducer, actions } =  cartSlice;

export const {
  setUpdateCart,
  setCart,
  setCartData,
  setPaymentData,
  setTaxes,
  setPlacedOrder,
  setOrderTotalPrice,
  setPickUpLaterTime,
  setCategoriesSet,
  setValueSet,
  setCartDetailes,
  setCustomizeOptions,
  setMenuServingDetails,
  setSelectedCupSizes,
  setOpenPopUps,
  setTitles,
  setCartItemIndexs,
  setBestSellerCheck,
  setAdvCheck,
  setTabValue,
  setCheckBestSellerId,
  setEditTitles,
  setCustomizeOpen,
  setOrderId,
  setPromoCode,
  setBestSellersItems,
  setUserFavItems,
  setFeaturedList,
  setAddonItemList,
  setFavoriteMenuStatus,
  setSelectedItem,
  setIsLoading,
  setCustomerOrderList
} = actions;

export const cart = (state) => state.cart.cart;
export const CategoriesSet = (state) => state.cart.CategoriesSet;
export const customizeOpen = (state) => state.cart.customizeOpen;
export const valueSet = (state) => state.cart.valueSet;
export const cartDetailes = (state) => state.cart.cartDetailes;
export const customizeOptions = (state) => state.cart.customizeOptions;
export const menuServingDetails = (state) => state.cart.menuServingDetails;
export const selectedCupSizes = (state) => state.cart.selectedCupSizes;
export const openPopUps = (state) => state.cart.openPopUps;
export const titles = (state) => state.cart.titles;
export const editTitles = (state) => state.cart.editTitles;
export const bestSellerCheck = (state) => state.cart.bestSellerCheck;
export const advCheck = (state) => state.cart.advCheck;
export const tabValue = (state) => state.cart.tabValue;
export const cartItemIndexs = (state) => state.cart.cartItemIndexs;
export const cartData = (state)=>state.cart.cartData;
export const paymentData = (state)=> state.cart.paymentData;
export const  taxes= (state)=> state.cart.taxes;
export const  placedOrder= (state)=> state.cart.placedOrder;
export const orderTotalPrice = (state)=> state.cart.placedOrder;
export const pickUpLaterTime = (state)=>state.cart.pickUpLaterTime;
export const checkBestSellerId = (state)=>state.cart.checkBestSellerId;
export const orderId = (state)=>state.cart.orderId;
export const promoCode = (state) => state.cart.promoCode;
export const bestSellerItems = (state) => state.cart.bestSellerItems;
export const userFavItems = (state) => state.cart.userFavItems;
export const featuredList = (state) => state.cart.featuredList;
export const addonItemList = (state) => state.cart.addonItemList;
export const favoriteMenuIds = (state) => state.cart.favoriteMenuIds;
export const selectedItem = (state) => state.cart.selectedItem;
export const isLoading = (state) => state.cart.isLoading;
export const customerOrderList = (state) => state.cart.customerOrderList;
export default reducer;
