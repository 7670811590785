import { Box, Container, Divider, Tab, Tabs } from "@mui/material";
import { tabsClasses } from "@mui/material/Tabs";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getAdvertismentDetail,
  GetAllCategories,
  getAllOrderByCustomersec,
  getBestSellers,
  getfavoriteMenuOfCustomerJson,
} from "../../../Api/API";
import BestSeller from "../../../pages/BestSeller/BestSeller";
import Favorites from "../../../pages/Favorites/Favorites";
import Feature from "../../../pages/Featured/Feature";
import PreviousOrder from "../../../pages/PreviousOrder/PreviousOrder";
import {
  setCategoriesSet,
  setTabValue,
  setOpenPopUps,
  setBestSellersItems,
  setUserFavItems,
  setFeaturedList,
  setIsLoading,
  setCustomerOrderList,
} from "../../../Redux/CartSlice";
import CategoryTabBar from "./CategoryTabBar";
import { errorToastMessage } from "../../utils";
const useStyle = makeStyles((theme) => ({
  root: {
    padding: "0",
  },
}));

const TabBar = ({ setState }) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(
    location.pathname === "/menu" ||
      location.pathname === "/cart" ||
      location.pathname === "/rewards" ||
      location.pathname === "/orderHistory"
      ? false
      : 2
  );
  const isLogin = useSelector((state) => state.user.isLogin);
  const tabValue = useSelector((state) => state.cart.tabValue);
  const isGuestLogin = useSelector((state) => state.user.isGuestLogin);
  const storeName = useSelector((state) => state.store.nearestStore);
  const userDetail = useSelector((state) => state.user.user);
  const fav = useSelector((state) => state.store.fav);
  const initialFeaturedFetch = React.useRef(false);
    const [totalRecord, setTotalRecord] = useState(1);
  const [loading, setLoading] = useState(false);

  const fetchTabData = async () => {
    if (location.pathname !== "/") return;

    try {
      dispatch(setIsLoading(true));
      switch (tabValue) {
        case 1: 
          const bestSellersRes = await getBestSellers(storeName?.id || 0, 2);
          dispatch(setBestSellersItems(bestSellersRes?.result || []));
          break;

        case 2: 
          const categoriesRes = await GetAllCategories({
            storeId: storeName?.id || "",
            appType: "web",
          });
          dispatch(setCategoriesSet(categoriesRes.result || []));
          break;

        case 3: 
        if (!initialFeaturedFetch.current) {
          const featuredRes = await getAdvertismentDetail(2, storeName?.id || "");
          dispatch(setFeaturedList(featuredRes?.data || []));
          initialFeaturedFetch.current = true;
        }
          break;

          case 4: 
          if (isLogin && (userDetail?.customer_id || userDetail?.person_id)) {
            const customerAllOrderList = await getAllOrderByCustomersec(
              {
                orderType: "",
                storeId: storeName?.id ,
                type: "all",
                userId: userDetail?.customer_id || userDetail?.person_id,
              }, 0, 5, "created_at"
            );
            dispatch(setCustomerOrderList(customerAllOrderList?.result|| []));
            setTotalRecord(Math.ceil(customerAllOrderList?.totalRecord / 5))
          }
          break;

        case 5: 
          if (isLogin && (userDetail?.customer_id || userDetail?.person_id)) {
            const favoritesRes = await getfavoriteMenuOfCustomerJson(
              userDetail?.customer_id || userDetail?.person_id,
              storeName?.id,
              2,
              "web"
            );
            dispatch(setUserFavItems(favoritesRes.result || []));
          }
          break;

        default:
          break;
      }
    } catch (error) {
      console.error("Error fetching tab data:", error);
      errorToastMessage();
      switch (tabValue) {
        case 1:
          dispatch(setBestSellersItems([]));
          break;
        case 2:
          dispatch(setCategoriesSet([]));
          break;
        case 3:
          dispatch(setFeaturedList([]));
          break;
        case 5:
          dispatch(setUserFavItems([]));
          break;
        default:
          break;
      }
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  useEffect(() => {
    if (storeName?.id) {
      initialFeaturedFetch.current = false;
    }
  }, [storeName?.id]);

  useEffect(() => {
    fetchTabData();
  }, [tabValue, storeName?.id, isLogin, userDetail, fav]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      dispatch(setOpenPopUps(false));
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [dispatch]);

  const handleChange = (event, newValue) => {
    dispatch(setOpenPopUps(false));
    window.sessionStorage.setItem("lastTab", newValue);
    if (newValue === 2) {
      setValue(newValue);
      dispatch(setTabValue(newValue));
      if (location.pathname !== "/") {
        navigate("/", { replace: true });
      }
    } else {
      if (location.pathname === "/menu") {
        navigate("/", { replace: true });
        setTimeout(() => {
          setValue(newValue);
          dispatch(setTabValue(newValue));
        }, 100);
      } else {
        setValue(newValue);
        dispatch(setTabValue(newValue));
        if (["/cart", "/rewards", "/orderHistory"].includes(window.location.pathname)) {
          navigate("/");
        }
      }
    }
  };

  useEffect(() => {
    if (location.pathname !== "/menu" && !tabValue) {
      dispatch(setTabValue(2));
    }
  }, [dispatch]);

  useEffect(() => {
    if (location.pathname === "/" && tabValue !== value) {
      if (location.pathname !== "/menu") {
        const storedTab = window.sessionStorage.getItem("lastTab");
        if (storedTab) {
          setValue(parseInt(storedTab));
          dispatch(setTabValue(parseInt(storedTab)));
        } else if (tabValue) {
          setValue(tabValue);
          window.sessionStorage.setItem("lastTab", tabValue);
        } else {
          setValue(2);
          dispatch(setTabValue(2));
          window.sessionStorage.setItem("lastTab", "2");
        }
      }
    } else if (location.pathname === "/menu") {
      setValue(false);
      dispatch(setTabValue(false));
      window.sessionStorage.removeItem("lastTab");
    }
  }, [location.pathname, tabValue, value, dispatch]);

  useEffect(() => {
    if (
      location.pathname === "/rewards" ||
      location.pathname === "/orderHistory"
    ) {
      setValue(false);
      dispatch(setTabValue(false));
    } else if (location.pathname === "/" && !tabValue) {
      dispatch(setTabValue(2));
    }
  }, [location.pathname, dispatch]);

  useEffect(() => {
    if (!tabValue) {
      dispatch(setTabValue(2));
    }
  }, []);

  return (
    <>
      <div style={{ backgroundColor: "white" }}>
        <Container maxWidth="xl" sx={{ padding: "0" }} className={classes.root}>
          <Box
            sx={{
              margin: {
                xs: "0 6vw 0 1vw",
                sm: "0 0 0 2.5vw",
                md: "0 0 0 10vw",
                lg: "0 0 0 10vw",
              },
            }}
          >
            <Tabs
              allowScrollButtonsMobile
              value={
                ["/cart", "/menu", "/rewards", "/orderHistory"].includes(location.pathname)
                  ? false
                  : tabValue
              }
              onChange={handleChange}
              textColor="none"
              indicatorColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              TabIndicatorProps={{ style: { background: "#C30E2F" } }}
              sx={{
                [`& .${tabsClasses.scrollButtons}`]: {
                  "&.Mui-disabled": { opacity: 0.3, flexShrink: 0.5 },
                  opacity: 0.8,
                  flexShrink: 0.5,
                },
              }}
            >
              <Tab
                label="Categories"
                className="text-capitalize"
                value={2}
                style={{ fontFamily: "Lusitana", fontSize: 17 }}
                sx={{
                  padding: "0.2rem",
                  minWidth: "0",
                  margin: "0rem 1rem 0rem 0rem",
                  paddingLeft: { xs: "11px" },
                }}
              />
              <Tab
                label="Best Sellers"
                className="text-capitalize"
                value={1}
                style={{ fontFamily: "Lusitana", fontSize: 17 }}
              />

              <Tab
                label="Featured"
                className="text-capitalize"
                value={3}
                style={{ fontFamily: "Lusitana", fontSize: 17 }}
              />
              {isLogin && (
                <Tab
                  label="Re-Order"
                  className="text-capitalize"
                  value={4}
                  style={{ fontFamily: "Lusitana", fontSize: 17 }}
                  sx={{
                    padding: "0.2rem",
                    minWidth: "0",
                    margin: "0rem 1rem 0rem 0rem",
                  }}
                />
              )}
              {!isGuestLogin && (
                <Tab
                  label="Favorites"
                  className="text-capitalize"
                  value={5}
                  style={{ fontFamily: "Lusitana", fontSize: 17 }}
                  sx={{
                    padding: "0.2rem",
                    minWidth: "0",
                    margin: "0rem 1rem 0rem 0rem",
                  }}
                />
              )}
            </Tabs>
          </Box>
        </Container>
        <Divider />
      </div>
      {tabValue === 1 && (
        <BestSeller
          setState={setState}
          loading={loading}
          setLoading={setLoading}
        />
      )}
      {tabValue === 2 && (
        <>
          <CategoryTabBar
            setLoading={setLoading}
            loading={loading}
          />
        </>
      )}
      {tabValue === 5 && <Favorites setState={setState} loading={loading} />}
      {tabValue === 3 && (
        <Feature setValue={setValue} setState={setState} loading={loading} />
      )}
      {tabValue === 4 && <PreviousOrder setState={setState}  totalRecord={totalRecord} setTotalRecord={setTotalRecord}/>}
    </>
  );
};
export default TabBar;
