import React from 'react'
import { Card, CardHeader, Typography } from '@mui/material'
import { Grid } from '@mui/material'
import { Box } from '@mui/material'
import { Avatar } from '@mui/material'
import { Divider } from '@mui/material'
import './Checkout.css'
 
const CheckoutItem = ({Detail}) => {
    return (
        <>
            <Grid container >
                <Grid item md={2} xs={2} sm={2} lg={2} style={{marginTop:"0.5rem", marginBottom:"0.5rem"}} >
                    <Box
                        component="img"
                        sx={{
                        height:{xs:"45px",sm:"60px",md:"80px"} ,
                        width: "90%",
                        objectFit: "contain",
                        maxWidth: "90%",
                        justifyContent:"left",
                        }}
                        alt="menuImage"
                        src={Detail?.menu_image}
                    />
                </Grid>
                <Grid item md={7} xs={7} sm={7} lg={7}
                 display="flex"
                 direction="column"
                 justifyContent="center"
                 sx={{
                   fontSize: {
                     xs: "90%",  
                     sm: "90%",  
                     md: "100%",  
                   }
                 }}
               >
                    <Typography variant='subtitle' fontWeight="600">{Detail?.menu_name}</Typography>
                    <Typography sx={{
                   fontSize: {
                     xs: "80%",  
                     sm: "90%",  
                     md: "100%",  
                   }
                 }}>
                    {Detail?.customizationOpt?.map((elm,index) =>
                (elm?.option?.length > 0 ? elm?.option?.map((item) => {
                   
                    return (" "+item?.modifier_name + (item?.quantity>1 ? " (" + item?.quantity + ") ": ""))}) : "") +
                ((Detail?.customizationOpt?.length - 1) != index ? " | " : " ")
                    ) }</Typography>
                </Grid>
                <Grid 
                 item
                  md={2} 
                 xs={2} 
                 sm={2} 
                 lg={2}
                 display='flex'
                 direction="column"
                 justifyContent="center"
                 alignItems="center"
                 sx={{ marginLeft: { xs: "0px", sm: "50px", md: "50px" } ,fontSize: {
                    xs: "90%",  
                    sm: "90%",  
                    md: "100%",  
                  } }}
                >             
                 <Typography variant='subtitle' >Qty: {Detail?.quantity}</Typography>
                 <Typography style={{ fontWeight: 'bold', fontSize: 'small' }} gutterBottom sx={{
                   fontSize: {
                     xs: "80%",  
                     sm: "90%",  
                     md: "100%",  
                   }
                 }}>
                     {`$${Number(Detail?.costToDisplay ? Detail?.costToDisplay : 0).toFixed(2)}`}
                 </Typography>
             </Grid>
            </Grid>
            <Divider  style={{marginBottom:"10px"}} />
        </>
    )
}
 
export default CheckoutItem