import React, { useEffect } from 'react'
import {
  Container,
  Card,
  CardContent,
  Typography,
  Avatar,
  Divider,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { useState } from 'react';
import { makeStyles } from "@mui/styles";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddCard from './AddCard';
import GiftCard from '../Payment/GiftCard';
import { Box } from '@mui/system';
import { deleteSavedCard, getSavedCardDetail, getGivexCardDetail, deleteGivexCard, getGivexDetails } from '../../Api/API';
import { errorToastMessage } from '../../Common/utils';
import { useSelector, useDispatch } from 'react-redux';
import { setDefaultCardd } from "../../Redux/UserSlice";
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';
const useStyles = makeStyles((theme) => ({

  cancelButton: {
    cursor: "pointer",
    "&:hover": {
      transform: "scale3d(1.1, 1.1, 1)",
    }
  },

  addCardRow: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "1rem",
    marginBottom: "1rem",
    marginLeft: { xs: "0rem", lg: "1rem" },
    marginRight: { xs: "0rem", lg: "1rem" },
    cursor: "pointer",

  },
  giftCardRow: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "1rem",
    marginBottom: "1rem",
    marginLeft: { xs: "0rem", lg: "1rem" },
    marginRight: { xs: "0rem", lg: "1rem" },
    cursor: "pointer",

  },
  savedCardsContainer: {
    maxHeight: "40vh", 
    overflowY: "auto", 
    marginBottom: "1rem",
    position: "relative", 
    paddingRight: "5px", 
    "&::-webkit-scrollbar": {
      width: "6px",
      position: "absolute", 
      right: "0", 
    },
    "&::-webkit-scrollbar-track": {
      background: "#f5f5f5", 
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#E31837", 
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#C41230", 
    },
    scrollbarWidth: "thin", 
    scrollbarColor: "#E31837 #f5f5f5", 
  }
}));

const CardDetail = () => {
  const classes = useStyles()
  const [showCard, setshowCard] = useState(false);
  const [giftCard, setGiftCard] = useState(false);
  const [savedGiftCards, setSavedGiftCard] = useState();
  const [givexCardExist, setGivexCardExist] = useState(false);
  const [givexCardDetails, setGivexCardDetails] = useState();
  const [remainingPayment, setRemainingPayment] = useState(0);
  const [giftCardAmount, setGiftCardAmount] = useState(0);
  const [giftCardPaymentDetail, setGiftCardPaymentDetail] = useState({});
  const [boolBalanceFetched, setBoolBalanceFetched] = useState(false);
  const storeName = useSelector((state) => state.store.nearestStore);
  const userDetail = useSelector((state) => state.user.user);
  const [savedCards, setSavedCard] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteCard, setDeleteCard] = useState(false);
  const [page, setpage] = useState(false);
  const title ="Add Gift Card"

  const dispatch = useDispatch();

  useEffect(() => {
    const handleBackButton = () => {
      if (showCard) {
        setshowCard(false);
        return;
      }
      
      if (giftCard) {
        setGiftCard(false);
        setpage(false);
        return;
      }
    };

    if (showCard || giftCard) {
      window.history.pushState(null, "", window.location.href);
    }

    window.addEventListener('popstate', handleBackButton);
    
    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [showCard, giftCard]);

  useEffect(() => {
    if (storeName?.id) {
      getGivexDetails(storeName?.id)
        .then((res) => {
          setGivexCardExist(res?.givexExist);
        })
        .catch((err) => {
          console.log(err);
          errorToastMessage();
        });
    }
  }, [storeName?.id]);

  useEffect(() => {
    if ((userDetail?.person_id ||userDetail?.customer_id ) && !showCard) {
      setLoading(true);
      getSavedCardDetail(userDetail?.person_id ? userDetail?.person_id : userDetail?.customer_id)
        .then((res) => {
          if (res.responseCode === 1) {
            res.object.map((e)=>{
              let imageUrl
              switch (e.cardType.toLowerCase()) {
                case "visa":
                  imageUrl = './visa.png';
                  break;
                case "mc":
                  imageUrl = "./mastercard.png";
                  break;
                case "mastercard":
                  imageUrl = "./mastercard.png";
                  break;
                case "amex":
                  imageUrl = "./Amex.png";
                  break;
                case "american-express":
                  imageUrl = "./Amex.png";
                  break;
                case "maestro":
                  imageUrl = "./maestro.png";
                  break;
                case "dinersclub":
                  imageUrl = "./dinersclub.png";
                  break;
                case "discover":
                  imageUrl = "./discover.png";
                  break;
                case "disc":
                  imageUrl = "./discover.png";
                  break;
                case "jcb":
                  imageUrl = "./jcb.png";
                  break;
                default:
                  imageUrl = "./creditcard.png";

              }
              return e.imageUrl = imageUrl
            })

            setSavedCard(res.object)

            setLoading(false);
          }

        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          errorToastMessage();
        });
      getGivexDetails(storeName?.id)
        .then((res) => {
          if(res?.givexExist == true){
            getGivexCardDetail(userDetail?.person_id ? userDetail?.person_id : userDetail?.customer_id)
              .then((res) => {
                res =  res.map(object => {
                  return {...object, imageUrl: "./giftcard.jpg"};
                });
                setSavedCard((e) => [...e, ...res]);
                setSavedGiftCard(res[0]?.givexCardNumber);
              }).catch((err) => {
                console.log(err);
                setLoading(false);
                errorToastMessage();
              });
          }
        })
    }
  }, [showCard , deleteCard]);

  const handleDeleteSavedCard = (cardDetail) => {
    let userId = userDetail?.customer_id ? userDetail?.customer_id : userDetail?.person_id;
    let cardId = cardDetail?.multiUseToken
    setLoading(true)
    deleteSavedCard(userId, cardId)
      .then((res) => {
        if (res.code == 200) {
          toast.success(res.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          if(cardDetail?.isDefault==true){
            dispatch(setDefaultCardd(""))
          }
          setDeleteCard(!deleteCard)
          setLoading(false)
        } else {
          toast.success("Something went wroung please try again", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false)
        }
      })
      .catch((err) => {
        console.log(err)
        errorToastMessage()
        setLoading(false)
      })
  }

  const handleDeleteGivexCard = (cardDetail) => {
    let userId = userDetail?.customer_id ? userDetail?.customer_id : userDetail?.person_id;
    setLoading(true)
    deleteGivexCard(savedGiftCards, userId)
      .then((res) => {
        if (res.responseCode == 1) {
          toast.success(res.responseMessage, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setDeleteCard(!deleteCard)
          setLoading(false)
        }
        else {
          toast.error("Something went wroung please try again", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false)
        }
      })
      .catch((err) => {
        console.log(err)
        errorToastMessage()
        setLoading(false)
      })
  }

  const handleSplitPaymentChange = () => {
    setGiftCard(true);
    setpage(true);  
    window.history.pushState(null, "", window.location.href);
  };


  return (
    <>
  <Backdrop
        sx={{ color: '#fff', zIndex: 1000 }}
        open={loading}
      // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div style={{ backgroundColor: "#E7E7E7" }}>
        <Container>
          <Card sx={{ borderRadius: "1rem", marginTop: "4vh" }}>
            <CardContent >
              <div style={{ marginLeft: { xs: "0rem", lg: "3rem" }, marginRight: { xs: "0rem", lg: "3rem" }, marginTop: { xs: "0rem", lg: "3rem" } }}>
                {savedCards.length > 0 && (
                  <Typography style={{ background: "#E8E8E8", padding: "0.5rem" }}>Saved Cards</Typography>
                )}

                <div className={classes.savedCardsContainer}>
                  {savedCards.map((cardDetail) => {
                    return (
                      <>
                        <div style = {{display:"flex" , flexDirection : "row" , justifyContent : "space-between" , alignItems : "center"}}>
                          <Box style={{
                            display: "flex",
                            marginBottom: "0.5rem",
                            marginTop: "0.5rem"
                          }}>
                            <div style={{width:"5rem"}}>
                              <Box  component="img" sx={{ width:"3.3rem", marginTop:"0.2rem" }} src={cardDetail.imageUrl} />
                            </div>
                          {/* <Avatar src={cardDetail.imageUrl} sx={{ height: 36 }} variant="rounded" /> */}
                            <Typography variant="h6" marginLeft="1rem" marginTop="0.3rem" >{cardDetail.lastFourCardDigit ? ` ****${cardDetail.lastFourCardDigit} `  :  ` ****${cardDetail.givexCardNumber.substring(
                              17, 
                              21
                              )} `} </Typography>
                          </Box>
                          <DeleteIcon className={classes.cancelButton} style={{ cursor: "pointer" }} onClick={() => cardDetail.lastFourCardDigit ? handleDeleteSavedCard(cardDetail) : handleDeleteGivexCard(cardDetail) } />
                        </div>
                      </>
                    )
                  })}
                </div>

                {savedCards.length > 0 && (
                  <Divider />
                )}
                <div
                  className={classes.addCardRow}
                  onClick={() => {
                    setshowCard(true);
                    window.history.pushState(null, "", window.location.href);
                  }}
                >
                  <Typography >Add Card</Typography>
                  < AddCircleOutlineIcon className={classes.cancelButton} />
                </div>

                <Divider />

                {givexCardExist && (
                  <div
                  className={classes.giftCardRow}
                  onClick={handleSplitPaymentChange} 
                  // style={{ display: "flex", justifyContent: "space-between", marginTop: "1rem", marginLeft: { xs: "0rem", lg: "1rem" }, marginRight: { xs: "0rem", lg: "1rem" } }}
                  >
                    <Typography>Add Gift Card</Typography>
                    <AddCircleOutlineIcon
                      className={classes.cancelButton}
                      // onClick={handleSplitPaymentChange} 
                    />
                  </div>
                )}
              </div>
            </CardContent>
          </Card>
        </Container>
      </div>

      {showCard && (
        <AddCard
          showCard={showCard}
          setshowCard={setshowCard}
          page="Save"
          setDeleteCard={setDeleteCard}
        />
      )}

      {giftCard && (
        <GiftCard
          setpage={setpage}  
          giftCard={giftCard}
          setGiftCard={setGiftCard}
          setGivexCardDetails={setGivexCardDetails}
          payment={0}
          setRemainingPayment={setRemainingPayment}
          setGiftCardAmount={setGiftCardAmount}
          giftCardPaymentDetail={giftCardPaymentDetail}
          setGiftCardPaymentDetail={setGiftCardPaymentDetail}
          boolBalanceFetched={boolBalanceFetched}
          setBoolBalanceFetched={setBoolBalanceFetched}
          title={title}  // Add this
          savedCards={savedCards}
          storeMobileId={storeName?.id}
          customerId={userDetail?.customer_id ? userDetail?.customer_id : userDetail?.person_id}
          customerPhone={userDetail?.phone_number ? userDetail?.phone_number : userDetail?.phone_no}
          customerName={userDetail?.first_name}
          setDeleteCard={setDeleteCard}
        />
      )}
    </>
  )
}

export default CardDetail