import CardMedia from "@mui/material/CardMedia";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import load from "./load.gif";
import "./Payment.css";
import succGif from "./succGif.gif";

import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import { setDefaultCardd, setIsCheckout } from "../../Redux/UserSlice";
import AddCard from "../Setting.js/AddCard";
import failedPay from "./failedPay.png";

import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  cardPointePaymentByProfileId,
  checkUser,
  getBalance,
  getGivexCardDetail,
  getGivexDetails,
  getSavedCardDetail,
  getStoreStartendTime,
  givexCardPayment,
  makeDefaultCard,
} from "../../Api/API";
import {
  handlePlaceOrderWithTime,
  handleStoreStartEndTimeSec,
} from "../../Common/utils";
import {
  setOrderTotalPrice,
  setPaymentData,
  setPlacedOrder,
} from "../../Redux/CartSlice";
import { setGivexPaymentDetail } from "../../Redux/StoreSlice";
import { setCheckoutFlag } from "../../Redux/UserSlice";
import CreditCardPayment from "./CreditCardPayment";
import GiftCard from "./GiftCard";
const useStyles = makeStyles((theme) => ({
  btnFinalPay: {
    padding: "0.5rem 3rem",
    fontSize: "1.1rem",
    backgroundColor: "#B7273B",
    fontFamily: "Lusitana",
    borderRadius: "1rem",
    textTransform: "none",
    lineHeight: "1.3rem",
  },
  payButton: {
    display: "flex",
    marginTop: "1rem",
    marginBottom: "1rem",
    justifyContent: "space-around",
    flexWrap: "wrap",
  },
  btn: {
    borderRadius: "0.4rem",
    boxShadow: "0 6px 20px 0 #dbdbe8",
    backgroundColor: "#fff",
    transition: "0.4s",
    padding: "0.7rem 3rem ",
    textTransform: "none",
  },
  spacing: {
    marginBottom: "0rem",
  },
  root: {
    "& .Mui-disabled": {
      background: "gray",
      color: "white",
    },
  },
  new: {
    display: "flex",
    padding: "0.5rem",

    cursor: "pointer",
    "&:hover": { backgroundColor: "#f2f2f2", transform: "scale(1.1)" },
  },
  addButton: {
    cursor: "pointer",
    ".info-slide:hover": {
      transform: "scale3d(1.1, 1.1, 1)",
    },
  },
  btnLoginBack: {
    padding: "0.2rem 1rem",
    backgroundColor: "brown",
    color: "white",
    textTransform: "none",
    marginTop: "1rem",
    marginBottom: "0.2rem",
  },
  error: {
    color: "#bf1650",
    display: "flex",
    " &::before": {
      display: "inline",
      content: "⚠ ",
    },
  },
  cardStyling: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": { backgroundColor: "#f2f2f2", transform: "scale(1.1)" },
  },
}));

const Payment = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let location = useLocation();
  let search = useLocation().search;
  const navigate = useNavigate();
  const defaultCard = useSelector((state) => state.user.defaultCardd);
  const [paymentTitle, setPaymentTitle] = useState("");
  const [saveCardDetail, setSaveCardDetail] = useState([]);
  const [givexCardExist, setGivexCardExist] = useState();
  const [creditPopup, setCreditPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showCard, setshowCard] = useState(false);

  const [giftCardPaymentDetail, setGiftCardPaymentDetail] = useState({
    cardNumber: "",
    payment: "",
    requestId: "",
  });
  const isGuestLogin = useSelector((state) => state.user.isGuestLogin);
  const isGivexData = useSelector((state) => state.user.givexDetail);
  const [boolBalanceFetched, setBoolBalanceFetched] = useState(false);
  const amount = new URLSearchParams(search).get("amount");
  const cartCardDetail = new URLSearchParams(search).get("cardDetail");

  const cardType = new URLSearchParams(search).get("cardType");
  const [remainingPayment, setRemainingPayment] = useState(amount);
  const [giftCardAmount, setGiftCardAmount] = useState(0);
  const [givexCardDetails, setGivexCardDetails] = useState(true);
  const [savedCards, setSavedCard] = useState([]);
  const deviceType = new URLSearchParams(search).get("deviceType");
  const storeMobileId = new URLSearchParams(search).get("storeId");
  const [singleCardSelected, setSingleCardSelected] = useState(defaultCard);
  const storeName = useSelector((state) => state.store.nearestStore);
  let customerId = new URLSearchParams(search).get("customerId");
  let customerPhone = new URLSearchParams(search).get("phoneNumber");
  let customerName = new URLSearchParams(search).get("name");
  const userDetail = useSelector((state) => state.user.user);
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [page, setpage] = useState("");
  const [open, setOpen] = React.useState(false);
  let year = [];
  let date = new Date();
  let thisYear = date.getFullYear();
  for (let i = 0; i <= 20; i++) {
    year.push(thisYear);
    thisYear = thisYear + 1;
  }
  const defaultValues = {
    accountNumber: "",
    cardNumber: "",
    amount: 0,
    cardExpiry: "",
    clientId: "AIPRUS",
    currency: "USD",
    customerIdentifier: "amish.dsy@gmail.com",
    customerName: "",
    password: "",
    description: "Demo payment",
    method: "card",
    requestId: "",
    saveCard: 0,
    surcharge: 0,
  };
  const { handleSubmit } = useForm({
    defaultValues,
  });
  const [availableBalance, setAvailableBalance] = useState("");
  const pickUpLaterTime = useSelector((state) => state.cart.pickUpLaterTime);
  const [pickUpLaterPopup, setPickUpLaterPopup] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [flagDefualtCard, setFlagDefualtCard] = useState('');
  
  const handleChange = (event,index) => {
    try {
      setFlagDefualtCard("Default Card Remove")
      setSelectedIndex(index);
      let multiuseToken = event?.multiUseToken;
      let cardType = event?.cardType;
      dispatch(
        setDefaultCardd(
          event.givexCardNumber
            ? event.givexCardNumber.substring(17, 21)
            : event.lastFourCardDigit
        )
      );
      
      setSingleCardSelected(
        event.givexCardNumber
          ? event.givexCardNumber.substring(17, 21)
          : event.lastFourCardDigit
      );
  
      if (event.givexCardNumber) {
        if (deviceType === "mobile") {
          try {
            let newDate = new Date();
            let givexData = {
              amount: amount,
              cardNumber: event.givexCardNumber,
              clientId: "AIPRUS",
              customerIdentifier: customerPhone ? customerPhone : "Guest@gmail.com",
              customerName: customerName ? customerName : "Guest",
              description: "demo payment",
              requestId: (
                newDate.toISOString() + Math.floor(Math.random() * 10000 + 1)
              )
                ?.toString()
                ?.replace(/[T.Z:_-]/g, ""),
              storeId: storeMobileId ? storeMobileId : 0,
              surcharge: 0,
            };
  
            getBalance(givexData)
              .then((ress) => {
                try {
                  if (
                    ress.responseCode === 0 &&
                    ress.responseMessage === "Get Balance Successfully"
                  ) {
                    setAvailableBalance(ress?.response?.balance);
                    if (Number(ress?.response?.balance) < Number(amount)) {
                      toast.error(`Insufficient gift card available balance`, {
                        position: toast.POSITION.TOP_RIGHT,
                        pauseOnFocusLoss: false,
                        autoClose: 1500,
                        pauseOnHover: false,
                        hideProgressBar: false,
                        closeOnClick: true,
                        draggable: true,
                      });
                    } else {
                      let path = `/payment`;
                      let search = `?amount=${Number(amount).toFixed(
                        2
                      )}&deviceType=${deviceType}&customerId=${customerId}&name=${customerName}&phoneNumber=${customerPhone}&storeId=${storeMobileId}&CardAdded=success&paymentWith=${
                        !event?.givexCardNumber ? "CreditCard" : "GiftCard"
                      }&cardNumber=${
                        event.givexCardNumber
                          ? event.givexCardNumber
                          : event.lastFourCardDigit
                      }&cardImage=${event.imageUrl}&giftCardBalance=${
                        ress?.response?.balance
                      }`;
                      navigate({
                        pathname: path,
                        search: search,
                      });
                    }
                  }
                } catch (error) {
                  console.error("Error processing gift card balance response:", error);
                }
              })
              .catch((error) => {
                console.error("Error fetching balance:", error);
              });
          } catch (error) {
            console.error("Error initializing gift card payment:", error);
          }
        }
      } else if (event.lastFourCardDigit?.length === 4) {
        try {
          makeDefaultCard(customerId, cardType, multiuseToken)
            .then((response) => {
              try {
                if (deviceType === "mobile" && response.message === "success") {
                  let path = `/payment`;
                  let search = `?amount=${Number(amount).toFixed(
                    2
                  )}&deviceType=${deviceType}&customerId=${customerId}&name=${customerName}&phoneNumber=${customerPhone}&storeId=${storeMobileId}&CardAdded=success&paymentWith=${
                    !event?.givexCardNumber ? "CreditCard" : "GiftCard"
                  }&cardNumber=${
                    event.givexCardNumber
                      ? event.givexCardNumber
                      : event.lastFourCardDigit
                  }&cardImage=${event.imageUrl}`;
                  navigate({
                    pathname: path,
                    search: search,
                  });
                }
              } catch (error) {
                console.error("Error handling makeDefaultCard response:", error);
              }
            })
            .catch((error) => {
              console.error("Error making card default:", error);
            });
        } catch (error) {
          console.error("Error processing default card logic:", error);
        }
      }
    } catch (error) {
      console.error("Error in handleChange function:", error);
    }
  };
  

  useEffect(() => {
    if (!showCard) {
      setpage("");
    }
  }, [showCard]);

  const handleGivexPayment = async (givexData) => {
    try {
      const ress = await getBalance(givexData);
      if (
        ress.responseCode === 0 &&
        ress.responseMessage === "Get Balance Successfully"
      ) {
        setAvailableBalance(ress?.response?.balance);
        if (Number(ress?.response?.balance) < Number(amount)) {
          setOpen(false);
          navigate(`/payment/failure?deviceType=${deviceType}`);
          toast.error("Insufficient gift card available balance", {
            position: toast.POSITION.TOP_RIGHT,
            pauseOnFocusLoss: false,
            autoClose: 1500,
            pauseOnHover: false,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
          });
        } else {
          setOpen(true);
          try {
            const res = await givexCardPayment(givexData);
            if (res.responseCode === 0 && res.responseMessage === "Payment Success") {
              dispatch(setPlacedOrder(""));
              let paymentDetails = {
                amount: "0",
                amt: "0",
                auth_code: "",
                calculationAmt: "",
                card_last_no: 0,
                givex_code: 0,
                givex_num: givexData.cardNumber,
                givex_trans_num: "",
                method: "gift_card",
                payId: res?.response?.savedId,
                payType: res?.response?.paymentMethod,
                paymentMethod: "gift_card",
                trans_num: 0,
              };
  
              let checkoutPaymentMethodArray = [paymentDetails];
  
              let givexDetailForRefund = {
                type: "givex",
                cardNumber: paymentDetails?.givex_num,
                clientId: "AIPRUS",
                description: "demo payment",
                originalRequestId: givexData?.requestId,
                requestId: givexData?.requestId,
                transactionRefNumber: res?.response?.transactionRefNumber,
                storeId: storeName?.id,
              };
  
              dispatch(setGivexPaymentDetail(givexDetailForRefund));
              setOpen(false);
              dispatch(setPaymentData(checkoutPaymentMethodArray));
              dispatch(setCheckoutFlag(true));
              dispatch(setIsCheckout(true));
  
              navigate(
                `/payment/success?deviceType=${deviceType}&paymentMethod2=${paymentDetails?.paymentMethod}&payId2=${res?.response?.savedId}&givex_code=${res?.response?.givex_code}&payType=${res?.response?.paymentMethod}&requestId=${givexData?.requestId}&cartCardDetail=${paymentDetails?.givex_num}`
              );
            } else {
              handlePaymentFailure(res.responseMessage);
            }
          } catch (err) {
            handlePaymentError(err);
          }
        }
      } else {
        handlePaymentFailure("Oops! Something went wrong.");
      }
    } catch (err) {
      handlePaymentError(err);
    }
  };
  
  const handleCreditCardPayment = async (data) => {
    try {
      setOpen(true);
      const res = await cardPointePaymentByProfileId(data);
      setshowCard(false);
  
      if (res.responseCode === 0) {
        let paymentDetails = {
          amount: "0",
          amt: "0",
          auth_code: "",
          calculationAmt: "",
          card_last_no: 0,
          givex_code: 0,
          givex_num: 0,
          givex_trans_num: "",
          method: res?.response?.paymentMethod || "credit_card",
          payId: res?.response?.savedCardId || 0,
          payType: res?.response?.paymentMethod || "credit_card",
          paymentMethod: res?.response?.paymentMethod || "credit_card",
          trans_num: 0,
        };
  
        let checkoutPaymentMethodArray = [paymentDetails];
  
        let cardRefund = {
          type: "credit_card",
          requestId: data?.requestId,
          transactionRefNumber: res?.response?.paymentRefId,
          storeId: storeName?.id,
        };
  
        dispatch(setGivexPaymentDetail(cardRefund));
        setOpen(false);
        dispatch(setPaymentData(checkoutPaymentMethodArray));
  
        navigate(
          `/payment/success?deviceType=${deviceType}&paymentMethod1=${res?.response?.paymentMethod || "credit_card"}&payId1=${res?.response?.savedCardId || 0}&transactionRefNumber=${res?.response?.paymentRefId}&requestId=${data?.requestId}&payType=${res?.response?.paymentMethod}&cartCardDetail=${cartCardDetail}`
        );
  
        dispatch(setCheckoutFlag(true));
        dispatch(setIsCheckout(true));
      } else {
        handlePaymentFailure(res.responseMessage);
      }
    } catch (err) {
      handlePaymentError(err);
    }
  };
  
  // Common Error Handling Functions
  const handlePaymentFailure = (message) => {
    setOpen(false);
    navigate(`/payment/failure?deviceType=${deviceType}`);
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
      pauseOnFocusLoss: false,
      autoClose: 1500,
      pauseOnHover: false,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
    });
  };
  
  const handlePaymentError = (err) => {
    console.error(err.message);
    setOpen(false);
    navigate(`/payment/failure?deviceType=${deviceType}`);
    toast.error("Oops! Something went wrong.", {
      position: toast.POSITION.TOP_RIGHT,
      pauseOnFocusLoss: false,
      autoClose: 1500,
      pauseOnHover: false,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
    });
  };

  useEffect(() => {
    if (
      (storeMobileId || storeName) &&
      (userDetail?.person_id || userDetail?.customer_id || customerId) &&
      !showCard
    ) {
      setOpen(true);
  
      const fetchCardDetails = async () => {
        try {
          const res = await getSavedCardDetail(
            customerId
              ? customerId
              : userDetail?.customer_id
              ? userDetail?.customer_id
              : userDetail?.person_id
          );
  
          if (res.responseCode === 1) {
            res.object.forEach((e) => {
              let imageUrl;
              switch (e.cardType.toLowerCase()) {
                case "visa":
                  imageUrl = "./visa.png";
                  break;
                case "mc":
                case "mastercard":
                  imageUrl = "./mastercard1.png";
                  break;
                case "amex":
                case "american-express":
                  imageUrl = "./Amex.png";
                  break;
                case "maestro":
                  imageUrl = "./maestro.png";
                  break;
                case "dinersclub":
                  imageUrl = "./dinersclub.png";
                  break;
                case "discover":
                  imageUrl = "./discover.png";
                  break;
                case "jcb":
                  imageUrl = "./jcb1.png";
                  break;
                default:
                  imageUrl = "./creditcard.png";
              }
              e.imageUrl = imageUrl;
            });
  
            setSavedCard(res.object);
  
            let card = res.object.find((e) => e.lastFourCardDigit === cartCardDetail);
  
            if (
              res.object.length > 0 &&
              (giftCardAmount < amount || giftCardAmount === 0) &&
              !defaultCard
            ) {
              dispatch(
                setDefaultCardd(
                  res.object.find((e) => e.isDefault)?.lastFourCardDigit ||
                    res.object[0]?.lastFourCardDigit
                )
              );
            }
  
            setOpen(false);
  
            let newDate = new Date();
            let creditPayment = {
              profileId: card?.multiUseToken || card?.profileId,
              amount: amount || Number(remainingPayment),
              clientId: "AIPRUS",
              currency: "USD",
              customerIdentifier:
                customerPhone ||
                userDetail?.phone_number ||
                userDetail?.phone_no ||
                "Guest@gmail.com",
              customerName: customerName || card?.customerName || "Guest",
              description:
                customerName || `${userDetail?.first_name} ${userDetail?.last_name}`,
              method: "card",
              requestId: (
                newDate.toISOString() + Math.floor(Math.random() * 10000 + 1)
              )
                .toString()
                .replace(/[T.Z:_-]/g, ""),
              surcharge: 0,
              storeId: storeMobileId || storeName?.id || 1,
              zipCode: card?.zip,
              cvv: card?.cvv || card?.cvv,
            };
  
            if (cardType === "credit" && cartCardDetail) {
              handleCreditCardPayment(creditPayment);
            }
          }
  
          if (deviceType === "mobile") {
            try {
              const givexRes = await getGivexDetails(storeMobileId);
              setGivexCardExist(givexRes?.givexExist);
  
              if (givexRes?.givexExist) {
                const givexCardRes = await getGivexCardDetail(
                  customerId ||
                    userDetail?.customer_id ||
                    userDetail?.person_id
                );
  
                const updatedGivexCards = givexCardRes.map((object) => ({
                  ...object,
                  imageUrl: "./giftcard.jpg",
                }));
  
                setSavedCard((prev) => [...prev, ...updatedGivexCards]);
                setLoading(false);
              }
            } catch (givexErr) {
              handlePaymentError(givexErr)
              console.error("Error fetching Givex details:", givexErr);
            }
          }
        } catch (err) {
          handlePaymentError(err)
          console.error("Error fetching saved card details:", err);
        } finally {
          setOpen(false);
        }
      };
  
      fetchCardDetails();
    }
  }, [showCard, givexCardDetails]);
  
  useEffect(() => {
    try {
      let newDate = new Date();
      let givexData = {
        amount: amount || Number(isGivexData?.payment),
        cardNumber: cardType === "gift" ? cartCardDetail : isGivexData?.cardNumber,
        clientId: "AIPRUS",
        customerIdentifier:
          customerPhone ||
          userDetail?.phone_number ||
          userDetail?.phone_no ||
          "Guest@gmail.com",
        customerName:
          customerName ||
          (userDetail?.first_name ? `${userDetail?.first_name} ${userDetail?.last_name}` : "Guest"),
        description: "demo payment",
        requestId: (newDate.toISOString() + Math.floor(Math.random() * 10000 + 1))
          .toString()
          .replace(/[T.Z:_-]/g, ""),
        storeId: storeMobileId || storeName?.id || 0,
        surcharge: 0,
      };
  
      if (cardType === "gift" && cartCardDetail) {
        handleGivexPayment(givexData);
      }
    } catch (error) {
      handlePaymentError(error)
      console.error("Error processing Givex payment:", error);
    }
  }, []);
  

  const onSubmit = async (data) => {
    try {
      if (
        deviceType &&
        deviceType === "mobile" &&
        storeMobileId &&
        customerId &&
        customerName &&
        customerPhone &&
        amount
      ) {
        setOpen(true);
        try {
          const res = await getStoreStartendTime(
            deviceType === "web" ? storeName?.id : storeMobileId
          );
  
          let storeStatus = handleStoreStartEndTimeSec(res, storeName?.time_zone);
          let timeRange = storeStatus?.timeRange;
          let storeActiveStatus = storeStatus?.storeActiveStatus || "Active";
          let statusOnMobile = storeStatus?.statusOnMobile || "Active";
  
          if (
            storeStatus?.status[storeStatus?.timeZoneWeekDay]?.storeDayStatus === false &&
            storeStatus?.status[storeStatus?.timeZoneWeekDay]?.onLineStatus === "on" &&
            timeRange === true &&
            storeActiveStatus === "Active" &&
            statusOnMobile === "Active"
          ) {
            if (isGuestLogin) {
              handlePayments(data);
            } else {
              try {
                const userRes = await checkUser(
                  customerPhone ||
                    userDetail?.phone_no ||
                    userDetail?.phone_number,
                  91
                );
  
                if (userRes.response_code === 1 && userRes.result.otp === false) {
                  handlePayments(data);
                } else {
                  localStorage.clear();
                  navigate("/");
                  window.location.reload();
                  setOpen(false);
                }
              } catch (e) {
                handlePaymentError(e);
                setOpen(false);
              }
            }
          } else {
            setOpen(false);
            toast.warn("This store is closed. Please select another store", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1500,
              pauseOnHover: false,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: true,
            });
          }
        } catch (e) {
          handlePaymentError(e);
          setOpen(false);
        }
      }
    } catch (error) {
      handlePaymentError(error);
    }
  };
    

  const handlePayments = (data) => {
    try {
      if (
        pickUpLaterTime &&
        !handlePlaceOrderWithTime(pickUpLaterTime, storeName?.time_zone)
      ) {
        setPickUpLaterPopup(true);
        return;
      }
  
      dispatch(setOrderTotalPrice(amount));
  
      let newDate = new Date();
      data.requestId = (
        newDate.toISOString() + Math.floor(Math.random() * 10000 + 1)
      )
        .toString()
        .replace(/[T.Z:_-]/g, "");
  
      let givexData = {
        amount: Number(giftCardAmount),
        cardNumber: giftCardPaymentDetail?.cardNumber,
        clientId: "AIPRUS",
        customerIdentifier:
          customerPhone ||
          userDetail?.phone_number ||
          userDetail?.phone_no ||
          "Guest@gmail.com",
        customerName:
          customerName ||
          (userDetail?.first_name
            ? `${userDetail?.first_name} ${userDetail?.last_name}`
            : "Guest"),
        description: "demo payment",
        requestId: (newDate.toISOString() + Math.floor(Math.random() * 10000 + 1))
          .toString()
          .replace(/[T.Z:_-]/g, ""),
        storeId: storeMobileId || storeName?.id || 0,
        surcharge: 0,
      };
  
      if (giftCardAmount === 0) {
        if (defaultCard && page !== "SavePay") {
          let card = savedCards.find((e) => e.lastFourCardDigit === defaultCard);
  
          let creditPayment = {
            profileId: card?.multiUseToken || card?.profileId,
            amount: Number(remainingPayment),
            clientId: "AIPRUS",
            currency: "USD",
            customerIdentifier:
              customerPhone ||
              userDetail?.phone_number ||
              userDetail?.phone_no ||
              "Guest@gmail.com",
            customerName: customerName || card?.customerName || "Guest",
            description:
              customerName ||
              `${userDetail?.first_name} ${userDetail?.last_name}`,
            method: "card",
            requestId: (newDate.toISOString() + Math.floor(Math.random() * 10000 + 1))
              .toString()
              .replace(/[T.Z:_-]/g, ""),
            surcharge: 0,
            storeId: storeMobileId || storeName?.id || 1,
            zipCode: card?.zip,
            cvv: card?.cvv || card?.cvv,
          };
  
          handleCreditCardPayment(creditPayment);
        } else if (!defaultCard) {
          setPaymentTitle("credit_card");
          setpage("Pay");
          if (deviceType === "web") {
            setshowCard(true);
          } else {
            let path = `/addCard?amount=${remainingPayment}&deviceType=mobile&page=Pay&customerPhone=${customerPhone}&customerId=${customerId}&storeId=${storeMobileId}&paymentTitle=credit_card`;
            navigate(path);
          }
        } else if (defaultCard && page === "SavePay") {
          setPaymentTitle("credit_card");
        }
      } else if (giftCardAmount !== 0) {
        if (giftCardAmount === amount) {
          handleGivexPayment(givexData);
        }
      }
    } catch (error) {
      handlePaymentError(error)
    }
  };
  
  

  const [giftCard, setGiftCard] = useState(false);
  const [title, setTitle] = useState("");

  const handleGiftCardEdit = () => {
    if (
      pickUpLaterTime &&
      !handlePlaceOrderWithTime(pickUpLaterTime, storeName?.time_zone)
    ) {
      setPickUpLaterPopup(true);
    } else {
      setGiftCard(true);
      setTitle("edit");
      setBoolBalanceFetched(true);
    }
  };
  const handleSplitPaymentChange = (event) => {
    if (
      pickUpLaterTime &&
      !handlePlaceOrderWithTime(pickUpLaterTime, storeName?.time_zone)
    ) {
      setPickUpLaterPopup(true);
    } else {
      dispatch(setOrderTotalPrice(amount));
      setGiftCard(true);
    }
  };
  const handleGiftCardSelection = (name) => {
    if (name === "edit") {
      if (
        pickUpLaterTime &&
        !handlePlaceOrderWithTime(pickUpLaterTime, storeName?.time_zone)
      ) {
        setPickUpLaterPopup(true);
      } else {
        setGiftCard(true);
        setTitle("edit");
        setBoolBalanceFetched(true);
      }
    } else if (name === "add") {
      if (
        pickUpLaterTime &&
        !handlePlaceOrderWithTime(pickUpLaterTime, storeName?.time_zone)
      ) {
        setPickUpLaterPopup(true);
      } else {
        dispatch(setOrderTotalPrice(amount));
        setGiftCard(true);
      }
    }
  };

  return (
    <>
      {cartCardDetail ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: { lg: "1rem", xs: "0rem" },
          }}
        >
          <CardMedia
            component="img"
            image={load}
            sx={{
              height: "100vh",
              objectFit: "contain",
            }}
          />
        </Box>
      ) : (
        <>
          {deviceType ? (
            <>
              {location.pathname === "/payment" && !paymentRequest ? (
                <div
                  style={{ paddingBottom: "1rem", backgroundColor: "white" }}
                >
                  <Container sx={{ p: { xs: 0, md: 5, sm: 5, lg: 5 } }}>
                    <Card
                      sx={{
                        borderRadius: {
                          xs: "0rem",
                          md: "1rem",
                          sm: "1rem",
                          lg: "1rem",
                        },
                        minHeight: "100vh",
                        maxWidth: {
                          xs: "100vw",
                          sm: "70vw",
                          md: "50vw",
                          lg: "35vw",
                        },
                        marginX: "auto",
                        padding: "0.5rem",
                        boxShadow: "0",
                      }}
                    >
                      <Container>
                        <form
                          onSubmit={handleSubmit(onSubmit)}
                          autoComplete="off"
                        >
                          {deviceType === "web" ? (
                            <Button
                              className={classes.btnLoginBack}
                              onClick={() => {
                                let path = `/cart`;
                                navigate(path);
                              }}
                            >
                              <ArrowLeftIcon /> Back to cart
                            </Button>
                          ) : (
                            ""
                          )}
                          <Typography
                            variant="h6"
                            align="center"
                            sx={{
                              fontWeight: "bold",
                              marginTop: "0.8rem",
                              marginBottom: "2rem",
                            }}
                          >
                            Payment Options
                          </Typography>
                          {savedCards.map((cardDetail,index) => {
                            const isSelected = selectedIndex === index; 
                            return (
                              <>
                                <div
                                  className={classes.cardStyling}
                                  onClick={() => handleChange(cardDetail,index)}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      margin: "0.5rem ",
                                      maxWidth: "2.5rem",
                                      alignItems: "center",
                                      minHeight: "2.19rem",
                                    }}
                                  >
                                    <div style={{ width: "5rem" }}>
                                      <Box
                                        component="img"
                                        sx={{
                                          width: "2.5rem",
                                          marginTop: "-0.2rem",
                                        }}
                                        src={cardDetail.imageUrl}
                                      />
                                    </div>
                                    <Typography
                                      variant="h8"
                                      marginLeft={"2rem"}
                                    >
                                      {" "}
                                      {cardDetail.lastFourCardDigit
                                        ? ` ****${cardDetail.lastFourCardDigit} `
                                        : ` ****${cardDetail.givexCardNumber.substring(
                                            17,
                                            21
                                          )} `}{" "}
                                    </Typography>
                                  </Box>

                                  <span style={{marginRight:"1rem"}}>
                                    {cardDetail.isDefault === true && isSelected == false && flagDefualtCard != 'Default Card Remove' && (
                                      <DoneIcon
                                        sx={{
                                          color: "#B7273B",
                                          cursor: "pointer",
                                        }}
                                      />
                                    )}
                                    {isSelected && (
                                      <DoneIcon
                                        sx={{
                                          color: "#B7273B",
                                          cursor: "pointer",
                                        }}
                                      />
                                    )}
                                  </span>
                                </div>
                                <Divider />
                              </>
                            );
                          })}

                          <Box
                            className={classes.new}
                            onClick={() => {
                              if (deviceType === "web") {
                                setshowCard(true);
                                setpage("SavePay");
                              } else if (deviceType === "mobile") {
                                let path = `/addCard?amount=${remainingPayment}&deviceType=mobile&page=Save&customerName=${customerName}&customerId=${customerId}&storeId=${storeMobileId}&fromPage=payment&customerPhone=${customerPhone}&paymentTitle=${
                                  remainingPayment < amount ? "split" : ""
                                }`;
                                navigate(path);
                              }
                            }}
                          >
                            <AddIcon
                              fontSize="large"
                              className={classes.addButton}
                            />
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <Typography variant="h8" marginLeft="2.2rem">
                                Credit or Debit card
                              </Typography>
                            </div>
                          </Box>
                          {givexCardExist === true && <Divider />}

                          {givexCardExist === true && (
                            <Box
                              className={classes.new}
                              onClick={() =>
                                handleGiftCardSelection(
                                  amount - remainingPayment !== 0
                                    ? "edit"
                                    : "add"
                                )
                              }
                            >
                              {amount - remainingPayment !== 0 ? (
                                <EditIcon
                                  onClick={handleGiftCardEdit}
                                  className={classes.addButton}
                                  fontSize="large"
                                />
                              ) : (
                                <AddIcon
                                  onClick={handleSplitPaymentChange}
                                  className={classes.addButton}
                                  fontSize="large"
                                />
                              )}
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <Typography variant="h8" marginLeft="2.2rem">
                                  Gift card
                                </Typography>
                                {amount - remainingPayment !== 0 && (
                                  <Typography>{`$${giftCardAmount}`}</Typography>
                                )}
                              </div>
                            </Box>
                          )}
                          <Container
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "5vh",
                              marginBottom: "1rem",
                            }}
                          >
                            <div className={classes.root}></div>
                          </Container>
                        </form>
                      </Container>
                    </Card>
                  </Container>
                </div>
              ) : (
                <div>
                  <Container sx={{ p: 2 }}>
                    <div>
                      <form
                        onload={setTimeout(() => {
                          if (
                            location.pathname === "/payment/success" &&
                            deviceType === "web"
                          ) {
                            let path = `/checkout`;
                            navigate(path);
                          } else if (
                            location.pathname === "/payment/failure" &&
                            deviceType === "web"
                          ) {
                            let path = "/cart";
                            navigate(path);
                          }
                          setPaymentRequest();
                        }, 2500)}
                      >
                        {location.pathname === "/payment/success" ? (
                          <>
                            {deviceType === "mobile" ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center", // This will center the image vertically
                                  height: "100vh", // Ensures the container takes full viewport height
                                  padding: 0, // Remove any padding
                                  margin: 0, // Remove any margin
                                }}
                              >
                                <CardMedia
                                  component="img"
                                  image={succGif}
                                  sx={{
                                    // height: { lg: "100vh", md: "50vh", sm: "70vh", xs: "90vh" },
                                    width: "100%", // Ensure the image covers the full width
                                    objectFit: "cover", // Make sure the image covers the container fully
                                    margin: 0, // Remove any margin from the image
                                    padding: 0, // Remove padding if any
                                  }}
                                />
                              </Box>
                            ) : (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <CardMedia
                                  component="img"
                                  image={succGif}
                                  sx={{
                                    height: {
                                      lg: "100vh",
                                      md: "50vh",
                                      sm: "70vh",
                                      xs: "90vh",
                                    },
                                    objectFit: "contain",
                                  }}
                                />
                              </Box>
                            )}
                          </>
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <CardMedia
                              component="img"
                              image={failedPay}
                              sx={{
                                height: "100vh",
                                objectFit: "contain",
                              }}
                            />
                          </Box>
                        )}
                      </form>
                    </div>
                  </Container>
                </div>
              )}
              {showCard && (
                <AddCard
                  showCard={showCard}
                  setshowCard={setshowCard}
                  remainingPayment={remainingPayment}
                  paymentTitle={paymentTitle}
                  giftCardPaymentDetail={giftCardPaymentDetail}
                  page={page}
                  defaultCard={defaultCard}
                />
              )}
              <GiftCard
                giftCard={giftCard}
                setGiftCard={setGiftCard}
                payment={amount}
                setRemainingPayment={setRemainingPayment}
                setGiftCardAmount={setGiftCardAmount}
                giftCardPaymentDetail={giftCardPaymentDetail}
                setGiftCardPaymentDetail={setGiftCardPaymentDetail}
                boolBalanceFetched={boolBalanceFetched}
                setBoolBalanceFetched={setBoolBalanceFetched}
                title={title}
                savedCards={savedCards}
                storeMobileId={storeMobileId}
                customerId={customerId}
                customerPhone={customerPhone}
                customerName={customerName}
                deviceType={deviceType}
                setGivexCardDetails={setGivexCardDetails}
              />
              <CreditCardPayment
                creditPopup={creditPopup}
                setCreditPopup={setCreditPopup}
                saveCardDetail={saveCardDetail}
                creditCardTitle={"payment"}
                remainingPayment={remainingPayment}
              />
            </>
          ) : (
            <div style={{ textAlign: "center", fontSize: "2rem" }}>
              404 Page not found
            </div>
          )}{" "}
        </>
      )}
    </>
  );
};

export default Payment;
