import { Box, Button, Card, Checkbox, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setCart, setFavoriteMenuStatus, setUpdateCart } from "../../Redux/CartSlice";
import {
  favoriteUnfavoriteMenu,
  GetAllCategories,
  GetAllMenu,
  getCustomizationOption,
} from "../../Api/API";
import AlertPopup from "../../Common/AlertPopup";
import SnackbarPopUp from "../../Common/SnackBar";
import ButtonLoader from "../../Common/ButtonLoader";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { errorToastMessage } from "../../Common/utils";
import { findMatchingCartItem, updateCartWithItem } from "../../Common/utils/cartUtils";
const PreviousOrderCard = ({
  props,
  storeId,
  storeName,
  setState,
  setFavorite,
  getFavorite,
  updateBtnFlag,
  setUpdateBtnFlag
}) => {
  const nearestStore = useSelector((state) => state.store.nearestStore);
  const [counter, setCounter] = useState(1);
  const [fav, setFav] = useState(false);
  const cartItem = useSelector((state) => state.cart.cart);
  const [displayTitle, setDisplayTitle] = useState();
  const userDetail = useSelector((state) => state.user.user);
  const [buttonLoader, setButtonLoader] = useState(false);
  const dispatch = useDispatch();
  const [alertPopup, setAlertPopUp] = useState(false);
  const [addCartClicked, setAddCartClicked] = useState(false);
  const [title, setTitle] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [localFav, setLocalFav] = useState(props.is_favourite === 1);
  const favoriteStatuses = useSelector((state) => state.cart.favoriteMenuIds);
  const [toppingsLimitAlert, setToppingsLimitAlert] = useState(false);
  const [cartDetailForConfirm, setCartDetailForConfirm] = useState(null);
  const [toppingsLimit, setToppingsLimit] = useState(0);
 
  toast.configure();
  let index;
  let cartDetail = {};
  const handleReorder = () => {
    setUpdateBtnFlag(true)
    setButtonLoader(true);
    let catData = {
      storeId: nearestStore?.id,
      appType: "web",
    };
    GetAllCategories(catData)
      .then((res) => {
        let categoryExist = res?.result?.filter(
          (element) =>
            Number(element?.category_id) === Number(props?.category_id)
        );
        if (categoryExist?.length > 0) {
          let menuItem = {
            appType: "web",
            categoryId: props?.category_id,
            customerId: 0,
            requestDate: "",
            storeId: nearestStore?.id,
          };

          GetAllMenu(menuItem).then((res) => {
            let menuExist = res?.result.filter(
              (element) => Number(element?.id) === Number(props?.menu_id)
            );
            if (menuExist.length > 0) {
              if (storeId === nearestStore.id) {
                let data = {
                  appType: "web",
                  customerId: userDetail?.customer_id,
                  menuId: props.menu_id,
                  storeId: nearestStore.id,
                };
                getCustomizationOption(data)
                  .then((res) => {
                    let result = res.result[0][0];
                    let modifierDetail = [];
                    let isRequiredChecked = false;
                    cartDetail = {
                      id: props.menu_id,
                      categoryId: result.category_id,
                      customizationOpt: result.customizationOpt
                        ? result.customizationOpt
                        : [],
                      quantity: Number(counter),
                      price: result?.price?.toString(),
                      image: result.image,
                      sku_code: result?.sku_code||result?.skuCode,
                      h_sku_code:result?.h_sku_code,
                      title: result.title,
                      categoryName: result?.category_name,
                      servingName: props?.serving_name,
                      totalPrice:
                        props.item_customized_price * props?.quantity_purchased,
                      btnModifierId: [],
                      btnModifier: [],
                      menuServingDetail: result?.menu_serving_detail,
                      selectedCupSize: result?.menu_serving_detail.filter(
                        (e) => e?.serving_name?.toLowerCase() === props?.serving_name?.toLowerCase()
                      ),
                      orderItemPrice: Number(
                        props?.item_customized_price / props.quantity_purchased
                      ),
                      selectedOptions: result?.customizationOpt?.filter(
                        (e, index) => {
                          props.order_details
                            .filter(
                              (cup) => cup.modifier_group_name !== "Cup Size"
                            )
                            .map((modifiers) => {
                              if (
                                modifiers.modifier_group_name ===
                                e.modifier_group_name
                              ) {
                                if (modifiers.option.length >= 1) {
                                  let x = [];
                                  modifiers.option.forEach((toppings) => {
                                    let singleTopping = e?.option.filter(
                                      (newStoreTopping) =>
                                        Number(newStoreTopping.modifier_id) ===
                                          Number(toppings.modifier_id) &&
                                        newStoreTopping.modifier_group_description !==
                                          ""
                                    )[0];

                                    if (singleTopping) {
                                      singleTopping.quantity =
                                        toppings.quantity;
                                    } else {
                                      setButtonLoader(false);
                                      setUpdateBtnFlag(false)
                                      toast.error(
                                        `${toppings.modifier_name} ${e.modifier_group_name} is not present for this item`,
                                        {
                                          position: "bottom-left",
                                          autoClose: 1000,
                                          hideProgressBar: true,
                                          closeOnClick: true,
                                          pauseOnHover: false,
                                          draggable: true,
                                          progress: undefined,
                                          theme: "dark",
                                        }
                                      );
                                    }

                                    let selected_modifier_serving =
                                      singleTopping?.modifier_group_description

                                        .filter(
                                          (topServing) =>
                                            topServing?.serving_name?.toLowerCase() === props?.serving_name?.toLowerCase()
                                        )
                                        .map((e) => {
                                          return {
                                            price: e?.price
                                              ? e?.price?.toString()
                                              : "0",
                                            serving_name: e?.serving_name
                                              ? e.serving_name
                                              : props?.serving_name,
                                          };
                                        })[0];
                                    singleTopping.selected_modifier_serving =
                                      selected_modifier_serving
                                        ? selected_modifier_serving
                                        : {
                                            price: "0",
                                            serving_name: props?.serving_name,
                                          };
                                    singleTopping.price =
                                      singleTopping?.selected_modifier_serving?.price;
                                    singleTopping.modifier_price =
                                      singleTopping?.selected_modifier_serving?.price;
                                    singleTopping.modifier_prefix_name = 0;
                                    x.push(singleTopping);
                                  });
                                  e.option = x;
                                }
                              }
                            });
                          return props.order_details.some((f) => {
                            return (
                              f.modifier_group_name === e.modifier_group_name
                            );
                          });
                        }
                      ),
                    };
                    let toppingCounterLimit = result?.customizationOpt?.filter((e)=>e?.modifier_group_name === "Toppings")
                    cartDetail.toppings_counter_limit = toppingCounterLimit?.length>0?toppingCounterLimit[0]?.counter_limit:0
                    result?.customizationOpt.forEach((element, index) => {
                      let selectedCustomizationOption =
                        cartDetail.selectedOptions.filter(
                          (ele) =>
                            element.modifier_group_id === ele.modifier_group_id
                        );
                      if (selectedCustomizationOption.length === 0) {
                        if (element.is_required) {
                          isRequiredChecked = true;
                          modifierDetail.push(element.modifier_group_name);
                        }
                      }
                    });
                    if (isRequiredChecked) {
                      setUpdateBtnFlag(false)
                      toast.error(
                        `${modifierDetail[0]} modifier is reqired for this item`,
                        {
                          position: "bottom-left",
                          autoClose: 1000,
                          hideProgressBar: true,
                          closeOnClick: true,
                          pauseOnHover: false,
                          draggable: true,
                          progress: undefined,
                          theme: "dark",
                        }
                      );
                      setButtonLoader(false);
                    } else {
                      let itemAddedToCartStatus = hadleAddToCart();
                      if (itemAddedToCartStatus) {
                        toast.success(`${cartDetail.title} Added to the cart`, {
                          position: "bottom-left",
                          autoClose: 1000,
                          hideProgressBar: true,
                          closeOnClick: true,
                          pauseOnHover: false,
                          draggable: true,
                          progress: undefined,
                          theme: "dark",
                        });
                        setButtonLoader(false);
                        setUpdateBtnFlag(false)
                      }
                    }
                  })

                  .catch((err) => {
                    setButtonLoader(false);
                    console.log(err);
                  });
              } else {
                let data = {
                  appType: "web",
                  customerId: userDetail?.customer_id,
                  menuId: props.menu_id,
                  storeId: nearestStore.id,
                };
                getCustomizationOption(data)
                  .then((res) => {
                    let result = res?.result[0][0];
                    cartDetail = {
                      id: props?.menu_id,
                      categoryId: result?.category_id,
                      customizationOpt: result?.customizationOpt
                        ? result?.customizationOpt
                        : [],
                      quantity: Number(counter),
                      price: result?.price?.toString(),
                      image: result.image,
                      title: result.title,
                      sku_code: result?.sku_code||result?.skuCode,
                      h_sku_code:result?.h_sku_code,
                      servingName: props?.serving_name,
                      totalPrice:
                        props.item_customized_price * props?.quantity_purchased,
                      btnModifierId: [],
                      btnModifier: [],
                      menuServingDetail: result?.menu_serving_detail,
                      selectedCupSize: result?.menu_serving_detail.filter(
                        (e) => e?.serving_name?.toLowerCase() === props?.serving_name?.toLowerCase()
                      ),
                      orderItemPrice: Number(
                        props?.item_customized_price / props.quantity_purchased
                      ),
                      selectedOptions: result?.customizationOpt?.filter(
                        (e, index) => {
                          props.order_details
                            .filter(
                              (cup) => cup.modifier_group_name !== "Cup Size"
                            )
                            .map((modifiers) => {
                              if (
                                modifiers.modifier_group_name ===
                                e.modifier_group_name
                              ) {
                                if (modifiers.option.length === 0) {
                                  e.option = e.option
                                    .filter(
                                      (singleModi) =>
                                        singleModi.modifier_id ===
                                        modifiers.option[0].modifier_id
                                    )
                                    .map((change) => {
                                      change.modifier_price =
                                        change.modifier_group_description
                                          .filter(
                                            (servPrice) =>
                                              servPrice.serving_name ===
                                              props?.serving_name
                                          )[0]
                                          ?.price?.toString();
                                      change.quantity = 1;
                                      change.selected_modifier_serving =
                                        change.modifier_group_description
                                          .filter(
                                            (serving) =>
                                              serving.serving_name ===
                                              props?.serving_name
                                          )
                                          .map((ele) => {
                                            return {
                                              price: ele?.price?.toString(),
                                              serving_name: ele?.serving_name,
                                            };
                                          })[0];
                                      return change;
                                    });
                                } else if (modifiers.option.length >= 1) {
                                  let x = [];
                                  modifiers.option.forEach((toppings) => {
                                    let singleTopping = e?.option.filter(
                                      (newStoreTopping) =>
                                        Number(newStoreTopping.modifier_id) ===
                                          Number(toppings.modifier_id) &&
                                        newStoreTopping.modifier_group_description !==
                                          ""
                                    )[0];

                                    if (singleTopping) {
                                      singleTopping.quantity =
                                        toppings.quantity;
                                    } else {
                                      setButtonLoader(false);
                                      setUpdateBtnFlag(false)
                                      toast.error(
                                        `${toppings.modifier_name} ${e.modifier_group_name} is not present for this item`,
                                        {
                                          position: "bottom-left",
                                          autoClose: 1000,
                                          hideProgressBar: true,
                                          closeOnClick: true,
                                          pauseOnHover: false,
                                          draggable: true,
                                          progress: undefined,
                                          theme: "dark",
                                        }
                                      );
                                    }

                                    let selected_modifier_serving =
                                      singleTopping?.modifier_group_description

                                        .filter(
                                          (topServing) =>
                                            topServing?.serving_name?.toLowerCase() === props?.serving_name?.toLowerCase()
                                        )
                                        .map((e) => {
                                          return {
                                            price: e?.price
                                              ? e?.price?.toString()
                                              : "0",
                                            serving_name: e?.serving_name
                                              ? e.serving_name
                                              : props?.serving_name,
                                          };
                                        })[0];
                                    singleTopping.selected_modifier_serving =
                                      selected_modifier_serving
                                        ? selected_modifier_serving
                                        : {
                                            price: "0",
                                            serving_name: props?.serving_name,
                                          };
                                    singleTopping.price =
                                      singleTopping?.selected_modifier_serving?.price;
                                    singleTopping.modifier_price =
                                      singleTopping?.selected_modifier_serving?.price;
                                    singleTopping.modifier_prefix_name = 0;
                                    x.push(singleTopping);
                                  });
                                  e.option = x;
                                }
                              }
                            });
                          return props.order_details.some((f) => {
                            return (
                              f.modifier_group_name === e.modifier_group_name
                            );
                          });
                        }
                      ),
                    };
                    let toppingCounterLimit = result?.customizationOpt?.filter((e)=>e?.modifier_group_name === "Toppings")
                    cartDetail.toppings_counter_limit = toppingCounterLimit?.length>0?toppingCounterLimit[0]?.counter_limit:0
                    let modfier_group_price = 0;
                    cartDetail.selectedOptions.map((e) => {
                      e?.option?.map((toppingPrice) => {
                        modfier_group_price += isNaN(
                          Number(
                            toppingPrice?.price || toppingPrice.modifier_price
                          ) * toppingPrice.quantity
                        )
                          ? 0
                          : Number(
                              (toppingPrice?.price ||
                                toppingPrice.modifier_price) *
                                toppingPrice.quantity
                            );
                      });
                    });
                    cartDetail.orderItemPrice =
                      cartDetail.selectedCupSize[0]?.price +
                      modfier_group_price;
                    cartDetail.totalPrice =
                      cartDetail.orderItemPrice * cartDetail.quantity;
                    let modifierDetail = [];
                    let isRequiredChecked = false;
                    result?.customizationOpt.forEach((element, index) => {
                      let selectedCustomizationOption =
                        cartDetail.selectedOptions.filter(
                          (ele) =>
                            element.modifier_group_id === ele.modifier_group_id
                        );
                      if (selectedCustomizationOption.length === 0) {
                        if (element.is_required) {
                          isRequiredChecked = true;
                          modifierDetail.push(element.modifier_group_name);
                        }
                      }
                    });
                    if (isRequiredChecked) {
                      toast.error(
                        `${modifierDetail[0]} modifier is reqired for this item`,
                        {
                          position: "bottom-left",
                          autoClose: 1000,
                          hideProgressBar: true,
                          closeOnClick: true,
                          pauseOnHover: false,
                          draggable: true,
                          progress: undefined,
                          theme: "dark",
                        }
                      );
                      setUpdateBtnFlag(false)
                      setButtonLoader(false);
                    } else {
                      let itemAddedToCartStatus = hadleAddToCart();
                      if (itemAddedToCartStatus) {
                        setUpdateBtnFlag(false)
                        toast.success(
                          `${cartDetail?.title} Added to the cart`,
                          {
                            position: "bottom-left",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                            theme: "dark",
                          }
                        );
                      } else {
                      }
                    }
                    // setOpenSnackBar(true)
                  })

                  .catch((err) => {
                    setButtonLoader(false);
                    setUpdateBtnFlag(false)
                    console.log(err);
                  });
              }
            } else {
              toast.error(
                `This ${props.menu_name} is not available for selected store`,
                {
                  position: toast.POSITION.TOP_RIGHT,
                }
              );
              setButtonLoader(false);
              setUpdateBtnFlag(false)
            }
          });
        } else {
          toast.error(
            `This ${props.menu_name} is not available for selected store`,
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          setButtonLoader(false);
          setUpdateBtnFlag(false)
        }
      })
      .catch((e) => {
        setButtonLoader(false);
        setUpdateBtnFlag(false)
        console.log(e);
      });
  };
  useEffect(() => {
    if (props?.is_favourite == 1) {
      setFav(true);
    }
  }, []);

  const isFavorite = favoriteStatuses[props.menu_id] !== undefined 
  ? favoriteStatuses[props.menu_id] 
  : props.is_favourite === 1;

  const handleFavUnfav = (val) => {
    setLocalFav(val);
    let favorite = val ? 1 : 0;
    let arr = [];
    cartDetail = {
      id: props.menu_id,
      categoryId: props.category_id,
      customizationOpt: props.customizationOpt,
      quantity: Number(counter),
      price: props?.item_customized_price / props?.quantity_purchased,
      image: props.menu_image,
      title: props.menu_name,
      servingName: props.serving_name,
      totalPrice: props.price * counter,
      btnModifierId: [],
      btnModifier: [],
      menuServingDetail: props?.order_details,
      orderItemPrice: Number(
        props?.item_customized_price / props?.quantity_purchased
      ),
      selectedOptions: props?.order_details?.filter(
        (e, index) => e.modifier_group_name !== "Cup Size" || index > 0
      ),
    };
    arr.push(cartDetail);
    const favUnfavData = {
      cart_id: 0,
      category_id: props?.category_id,

      customer_id: userDetail?.customer_id
        ? userDetail?.customer_id
        : userDetail?.person_id,
      fav_id: 0,
      fav_type: "menujson",
      is_favourite: favorite,
      selectedOptions: cartDetail?.selectedOptions,
      orderItemPrice: props?.item_customized_price / props?.quantity_purchased,
      menu_details: {
        cart_id: 0,
        category_id: props?.category_id,
        category_name: props?.category_name,
        created_at: new Date().toISOString(),
        customizationOpt: cartDetail?.customizationOpt,
        description: "",
        discount_details: [],
        id: 0,
        image: props.menu_image,
        is_active: true,
        is_deleted: "1",
        is_favourite: favorite,
        menu_serving_detail: [
          {
            created_at: new Date().toISOString(),
            price: props.order_details[0].option[0].serving_price
              ?props.order_details[0].option[0].serving_price
              : props.serving_price,
            serving_actual_id: props.order_details[0].option[0].serving_id,
            // serving_description:
            //   props?.menu_serving_detail[0]?.serving_description,
            serving_icon: props.order_details[0].option[0]?.serving_icon,
            serving_id: props.order_details[0].option[0]?.serving_id
              ? props.order_details[0].option[0]?.serving_id
              : props?.serving_id,
            serving_name: props.order_details[0].option[0]?.serving_name
              ? props.order_details[0].option[0]?.serving_name
              : props.serving_name,
            status: "Active",
            tier_id: props.order_details[0].option[0]?.tier_id,
            updated_at: new Date().toISOString(),
          },
        ],
        modifier_price: props?.serving_price,
        price: props?.serving_price,
        status: "Active",
        store_id: 0,
        title: props?.title,
        updated_at: new Date().toISOString(),
      },
      menu_id: props.id,
      order_id: "",
    };

    dispatch(setFavoriteMenuStatus({ 
      menuId: props.menu_id, 
      isFavorite: val 
    }));

  
    favoriteUnfavoriteMenu(favUnfavData)
      .then((res) => {
        if (val) {
          if (getFavorite == false) {
            setFavorite(true);
          } else {
            setFavorite(false);
          }
          toast.success("Added to your favorites", {
            position: toast.POSITION.TOP_RIGHT,
            pauseOnFocusLoss: false,
            autoClose: 1500,
            pauseOnHover: false,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
          });
        } else {
          if (getFavorite === false) {
            setFavorite(true);
          } else {
            setFavorite(false);
          }
          toast.success("Removed from your favorites", { 
            position: toast.POSITION.TOP_RIGHT,
            pauseOnFocusLoss: false,
            autoClose: 1500,
            pauseOnHover: false,
            hideProgressBar: false,
            closeOnClick: true,
            // pauseOnHover: false,
            draggable: true,
          });
        }
      })
      .catch((e) => {
        dispatch(setFavoriteMenuStatus({ 
          menuId: props.menu_id, 
          isFavorite: !val 
        }));
        errorToastMessage();
        console.log(e);
      });
  };
  const hadleAddToCart = () => {    
    if (cartDetail.selectedCupSize.length > 0 && Object.keys(cartDetail.selectedCupSize[0]).length !== 0) {
      const toppingsModifier = cartDetail.selectedOptions?.find(
        (option) => option.modifier_group_name === "Toppings"
      );
  
      if (toppingsModifier) {
        const nonOreoCrumbToppings = toppingsModifier.option.filter(
          topping => topping.modifier_name !== 'Oreo Crumb'
        );
  
        const totalToppingsCount = nonOreoCrumbToppings.reduce(
          (sum, topping) => sum + (topping.quantity > 0 ? 1 : 0),
          0
        );
  
        if (totalToppingsCount > toppingsModifier.counter_limit) {
          setToppingsLimit(toppingsModifier.counter_limit);
 
          setCartDetailForConfirm({
            ...cartDetail,
          });
          setToppingsLimitAlert(true);
          setButtonLoader(false);
          setTimeout(() => {
            setUpdateBtnFlag(false);
            setButtonLoader(false);
            setAddCartClicked(false);
          }, 100);
          return false;
        }
      }
  
      const result = updateCartWithItem(cartDetail, cartItem, dispatch, setCart, setUpdateCart);
      setAddCartClicked(false);
      setButtonLoader(false);
      
      if (result) {
        return true;
      }
    } else {
      setButtonLoader(false);
      toast.error(
        `${cartDetail.servingName} serving is not present for this item`,
        {
          position: "bottom-left",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
      setTimeout(() => {
        setUpdateBtnFlag(false);
        setButtonLoader(false);
        setAddCartClicked(false);
      }, 100);
      return false;
    }
    setButtonLoader(false);
    return false;
  };

  const handleAddWithCupSizeOnly = () => {
    if (!cartDetailForConfirm) return;
    
    let simplifiedCartDetail = {
      ...cartDetailForConfirm,
      selectedOptions: cartDetailForConfirm.selectedOptions.filter(
        option => option.modifier_group_name !== "Toppings"
      )
    };
    
    let modifierPrice = 0;
    simplifiedCartDetail.selectedOptions.forEach(option => {
      option.option.forEach(modifier => {
        const price = Number(modifier.price || modifier.modifier_price) * modifier.quantity;
        modifierPrice += isNaN(price) ? 0 : price;
      });
    });
    
    simplifiedCartDetail.orderItemPrice = (simplifiedCartDetail.selectedCupSize[0]?.price || 0) + modifierPrice;
    simplifiedCartDetail.totalPrice = simplifiedCartDetail.orderItemPrice * simplifiedCartDetail.quantity;

    const existingItemIndex = cartItem.findIndex(item => {
      if (item.id !== simplifiedCartDetail.id) return false;
      if (item.selectedCupSize[0]?.serving_name?.toLowerCase() !== 
          simplifiedCartDetail.selectedCupSize[0]?.serving_name?.toLowerCase()) return false;
      
      const itemToppings = item.selectedOptions?.find(opt => opt.modifier_group_name === "Toppings");
      if (itemToppings) return false;
      
      const itemNonToppingOptions = item.selectedOptions?.filter(opt => opt.modifier_group_name !== "Toppings") || [];
      const newItemNonToppingOptions = simplifiedCartDetail.selectedOptions || [];
      
      if (itemNonToppingOptions.length !== newItemNonToppingOptions.length) return false;
      
      for (const newOption of newItemNonToppingOptions) {
        const matchingOption = itemNonToppingOptions.find(opt => 
          opt.modifier_group_name === newOption.modifier_group_name
        );
        
        if (!matchingOption) return false;
        
        if (!areModifiersEqual(newOption.option, matchingOption.option)) return false;
      }
      
      return true;
    });
    
    if (existingItemIndex !== -1) {
      const updatedCart = [...cartItem];
      updatedCart[existingItemIndex] = {
        ...updatedCart[existingItemIndex],
        quantity: updatedCart[existingItemIndex].quantity + simplifiedCartDetail.quantity,
        totalPrice: updatedCart[existingItemIndex].orderItemPrice * 
          (updatedCart[existingItemIndex].quantity + simplifiedCartDetail.quantity)
      };
      dispatch(setUpdateCart(updatedCart));
    } else {
      let arr = [...cartItem, simplifiedCartDetail];
      dispatch(setUpdateCart(arr));
    }
    
    setAddCartClicked(false);
    setButtonLoader(false);
    
    toast.success(`${simplifiedCartDetail.title} Added to the cart`, {
      position: "bottom-left",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    
    setToppingsLimitAlert(false);
    setUpdateBtnFlag(false);
    return true;
  };
  
  const areModifiersEqual = (modifiers1, modifiers2) => {
    if (modifiers1.length !== modifiers2.length) return false;
    
    const sortedMods1 = [...modifiers1].sort((a, b) => 
      String(a.modifier_id).localeCompare(String(b.modifier_id))
    );
    const sortedMods2 = [...modifiers2].sort((a, b) => 
      String(a.modifier_id).localeCompare(String(b.modifier_id))
    );
    
    return sortedMods1.every((mod, index) => 
      String(mod.modifier_id) === String(sortedMods2[index].modifier_id) &&
      mod.quantity === sortedMods2[index].quantity
    );
  };

  useEffect(() => {
    if (!alertPopup && addCartClicked) {
      handleReorder();
    }
  }, [alertPopup]);
  return (
    <>
      <Card style={{ cursor: "pointer" }}>
        <Box
          p={{xs:2,sm:4}}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" },
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              textAlign: "left",
              marginBottom: "1rem",
              // width:{xs:"90vw",sm:"100vw",md:"70vw",lg:"60vw"}
            }}
          >
            <Box
              component="img"
              sx={{
                // height: {xs:"4rem",sm:"7rem",md:"9rem"},
                height: "9rem",
                width: "7rem",
                maxWidth: "25%",
                objectFit: "contain",
                transition: "transform 0.15s ease-in-out",
                "&:hover": { transform: "scale3d(1.15, 1.15, 1)" },
              }}
              alt="menuImage"
              src={props?.menu_image ? props?.menu_image : "./logos.png"}
            />
            <Box sx={{ marginLeft: { lg: "4rem", xs: "1rem" } }}>
              <Typography variant="h5" sx={{fontWeight: "bold",fontSize: { lg: "23px", xs: "15px" },marginBottom: { sm: "0px", xs: "-12px" },}}>
                {props.menu_name}
                {nearestStore?.id && (
                  <Checkbox
                    style={{ marginBottom: "0.6rem" }}
                    icon={
                      isFavorite ? (
                        <FavoriteIcon style={{ fill: "#C30E2F" }} />
                      ) : (
                        <FavoriteBorderIcon />
                      )
                    }
                    checkedIcon={<FavoriteIcon style={{ fill: "#C30E2F" }} />}
                    checked={isFavorite}
                    onChange={(e) => handleFavUnfav(e.target.checked)}
              />
                )}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{ width: { lg: "32rem", md: "30rem" } }}
              >
                {props?.order_details?.map(
                  (elm, index) =>
                    (elm?.option?.length > 0
                      ? elm?.option?.map((item) => {
                          return (
                            " " +
                            item?.modifier_name +
                            (item?.quantity > 1
                              ? " (" + item?.quantity + ") "
                              : " ")
                          );
                        })
                      : " ") +
                    (props?.order_details?.length - 1 != index ? "| " : " ")
                )}
              </Typography>
            </Box>
          </div>
          <Button
           disabled={updateBtnFlag}
            style={{
              borderRadius: "15px",
              padding: "0.2rem 1.6rem",
              backgroundColor: "white",
              color: "#C60734",
              textTransform: "none",
              border: "1px solid #C60734",
              // width:{xs:"5vw",sm:"10vw",md:"10vw"},
              // height:"4vh",
              // margin:"auto"
            }}
            onClick={() => {
              if (nearestStore) {
                setTitle("");
                if (storeId === nearestStore.id) {
                  handleReorder();
                } else {
                  setAlertPopUp(true);
                  setDisplayTitle(
                    "Pricing may change based on selected store"
                  );
                  setAddCartClicked(true);
                }
              } else {
                setState(true);
              }
            }}
          >
            {buttonLoader ? <ButtonLoader props={"4.5rem"} /> : "Add to Cart"}
          </Button>
        </Box>
      </Card>

      <AlertPopup
        open={alertPopup}
        setOpen={setAlertPopUp}
        setState={setAddCartClicked}
        title={title ? title : "previousOrder"}
        displayTitle={displayTitle}
        buttonType1={title ? false : true}
        buttonType2={true}
        handleReorder={handleReorder}
      />
      
      <AlertPopup
        open={toppingsLimitAlert}
        setOpen={setToppingsLimitAlert}
        title="toppingsLimit"
        displayTitle={`Toppings have been changed. Would you like to proceed with the item?`}
        buttonType1={true}
        buttonType2={true}
        onConfirm={handleAddWithCupSizeOnly}
      />
      
      {openSnackBar && (
        <SnackbarPopUp
          key={cartDetail.id}
          open={openSnackBar}
          setopenpopup={setOpenSnackBar}
          messageInfo={`${props.menu_name} Added to the cart`}
        />
      )}
    </>
  );
};

export default PreviousOrderCard;
