import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: [],
  isLogin: false,
  isGuestLogin: false,
  isSignInFromGoogle: false,
  userGoogleData: "",
  userFiveStarData: "",
  isUserFiveStarData: false,
  discountDetail: "",
  autoDiscountDetail: "",
  UserId: "",
  checkoutFlag: false,
  popUpTimmer: true,
  fiveStarResponse: "",
  fiveStarRewardDetail: "",
  fiveStarIndex: Number.MAX_VALUE,
  isAlreadyUserChecked: false,
  givexDetail: {},
  clickActiveOrders: false,
  removeReward:"",
  defaultCardd:"",
  checkActive:false,
  isCheckout: false,
};

const userSlice = createSlice({
  name: "user",
  initialState: {
    ...initialState,
    user: JSON.parse(localStorage.getItem("user")) || [], 
    isLogin: localStorage.getItem("user") ? true : false, 
  },
  reducers: {
    setIsAlreadyUserChecked: (state, { payload }) => {
      if (payload == null) state = initialState;
      state.isAlreadyUserChecked = payload;
    },
    setFiveStarIndex: (state, { payload }) => {
      if (payload == null) state = initialState;
      state.fiveStarIndex = payload;
    },
    setUser: (state, { payload }) => {
      if (payload == null) {
        state.user = [];
      } else {
        state.user = payload;
      }
    },
    setGivexDetail: (state, { payload }) => {
      if (payload == null) state = initialState;
      state.givexDetail = payload;
    },
    setFiveStarRewardDetail: (state, { payload }) => {
      if (payload == null) state = initialState;
      state.fiveStarRewardDetail = payload;
    },
    setFiveStarResponse: (state, { payload }) => {
      if (payload == null) state = initialState;
      state.fiveStarResponse = payload;
    },
    setCheckoutFlag: (state, { payload }) => {
      if (payload == null) state = initialState;
      state.checkoutFlag = payload;
    },
    setIsCheckout: (state, { payload }) => {
      if (payload == null) state = initialState;
      state.isCheckout = payload;
    },
    setUserId: (state, { payload }) => {
      if (payload == null) state = initialState;
      state.UserId = payload;
    },
    setLogin: (state, { payload }) => {
      if (payload == null) {
        state.isLogin = false;
      } else {
        state.isLogin = payload;
      }
    },
    setGuestLogin: (state, { payload }) => {
      if (payload == null) state = initialState;
      state.isGuestLogin = payload ?? false;
    },
    setIsSignInFromGoogle: (state, { payload }) => {
      if (payload == null) state = initialState;
      state.isSignInFromGoogle = payload;
    },
    setUserGoogleData: (state, { payload }) => {
      if (payload == null) state = initialState;
      state.userGoogleData = payload;
    },
    setUserFiveStarData: (state, { payload }) => {
      if (payload == null) state = initialState;
      state.userFiveStarData = payload;
    },
    setIsUserFiveStarData: (state, { payload }) => {
      if (payload == null) state = initialState;
      state.isUserFiveStarData = payload;
    },
    setDiscountDetail: (state, { payload }) => {
      if (payload == null) state = initialState;
      state.discountDetail = payload;
    },
    setAutoDiscountDetail: (state, { payload }) => {
      if (payload == null) state = initialState;
      state.autoDiscountDetail = payload;
    },
    setPopUpTimmer: (state, { payload }) => {
      if (payload == null) state = initialState;
      state.popUpTimmer = payload;
    },
    setClickActiveOrders: (state, { payload }) => {
      if (payload == null) state = initialState;
      state.clickActiveOrders = payload;
    },
    setRemoveReward : (state,{payload}) => {
      if(payload == null) state = initialState;
      state.removeReward = payload;
    },
    setDefaultCardd : (state,{ payload }) => {
      if(payload == null) state = initialState
      else{
        state.defaultCardd = payload;
      }
    },
    setCheckActive : (state,{payload}) => {
      if(payload == null) state = initialState;
      state.checkActive = payload;
    },
    setLogout: (state) => {
      state.user = [];
      state.isLogin = false;
      state.isGuestLogin = false;
      state.isSignInFromGoogle = false;
      state.userGoogleData = "";
      state.userFiveStarData = "";
      state.isUserFiveStarData = false;
      state.discountDetail = "";
      state.autoDiscountDetail = "";
      state.UserId = "";
      state.checkoutFlag = false;
      state.popUpTimmer = true;
      state.fiveStarResponse = "";
      state.fiveStarRewardDetail = "";
      state.fiveStarIndex = Number.MAX_VALUE;
      state.isAlreadyUserChecked = false;
      state.givexDetail = {};
      state.clickActiveOrders = false;
      state.removeReward = "";
      state.defaultCardd = "";
      state.checkActive = false;
      state.isCheckout = false;
      
      localStorage.removeItem("user");
      return state;
    },
  },
});

const { reducer, actions } = userSlice;

export const {
  setUser,
  setLogin,
  setGuestLogin,
  setIsSignInFromGoogle,
  setUserGoogleData,
  setUserFiveStarData,
  setIsUserFiveStarData,
  setDiscountDetail,
  setAutoDiscountDetail,
  setUserId,
  setCheckoutFlag,
  setIsCheckout,
  setPopUpTimmer,
  setFiveStarResponse,
  setFiveStarRewardDetail,
  setFiveStarIndex,
  setIsAlreadyUserChecked,
  setGivexDetail,
  setClickActiveOrders,
  setRemoveReward,
  setDefaultCardd,
  setCheckActive,
  setLogout
} = actions;

export const user = (state) => state.user.user;
export const UserId = (state) => state.user.UserId;
export const isLogin = (state) => state.user.isLogin;
export const givexDetail = (state) => state.user.givexDetail;
export const isGuestLogin = (state) => state.user.isGuestLogin;
export const isSignInFromGoogle = (state) => state.user.isSignInFromGoogle;
export const userGoogleData = (state) => state.user.userGoogleData;
export const userFiveStarData = (state) => state.user.userFiveStarData;
export const isUserFiveStarData = (state) => state.user.isUserFiveStarData;
export const discountDetail = (state) => state.user.discountDetail;
export const autoDiscountDetail = (state) => state.user.autoDiscountDetail;
export const checkoutFlag = (state) => state.user.checkoutFlag;
export const popUpTimmer = (state) => state.user.popUpTimmer;
export const fiveStarResponse = (state) => state.user.fiveStarResponse;
export const fiveStarRewardDetail = (state) => state.user.fiveStarRewardDetail;
export const fiveStarIndex = (state) => state.user.fiveStarIndex;
export const isAlreadyUserChecked = (state) => state.user.isAlreadyUserChecked;
export const clickActiveOrders = (state) => state.user.clickActiveOrders;
export const removeReward = (state)=>state.user.removeReward;
export const checkActive = (state)=>state.user.checkActive;
export const defaultCardd = (state)=>state.user.defaultCardd;
export const isCheckout = (state) => state.user.isCheckout;
export default reducer;
