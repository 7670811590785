import Slide from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import * as React from 'react';




const SnackbarPopUp =(props)=> {
  const {open, setopenpopup, messageInfo} = props
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setopenpopup(false);
  };
  function SlideTransition(props) {
    return <Slide {...props} direction="up" />;
  }

  return (
   open &&
      <Snackbar
        open={open}
        style = {{display : open ? "block" : "none"}}
        autoHideDuration={1500}
        TransitionComponent= {SlideTransition}
        onClose={handleClose}
        message={messageInfo}
      />
  );
}

export default SnackbarPopUp