import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Slide,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Card from "../../Common/Card";
import Popup from "../Categories/CategoryPopUp";

const BestSeller = ({ setState,loading,setLoading }) => {
  const [loader,setLoader] =useState(false)
  const customizeOpen = useSelector((state) => state.cart.customizeOpen);
  const bestSellerItems = useSelector((state) => state.cart.bestSellerItems);
  const [updateBtnFlag, setUpdateBtnFlag] = useState(false)
  const isLoading = useSelector((state) => state.cart.isLoading);
  const [isDataInitialized, setIsDataInitialized] = useState(false);

  useEffect(() => {
    if (bestSellerItems?.length > 0) {
      bestSellerItems?.forEach(item => {
        const img = new Image();
        img.src = item?.image || item?.product_image || "./logos.png";
      });
    }
  }, [bestSellerItems]);

  useEffect(() => {
    if (bestSellerItems !== undefined) {
      setIsDataInitialized(true);
    }
  }, [bestSellerItems]);

  const showLoading = isLoading || !isDataInitialized || loader;

  return (
    <>
      <Backdrop
          sx={{ color: "#fff", zIndex: 1000, backgroundColor: 'rgba(0, 0, 0, 0.3)' }}
          open={(bestSellerItems?.length > 0) ? false : showLoading || loader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      <Box
        sx={{
          paddingLeft: { lg: "13vw", xs: "5vw" },
          paddingRight: { lg: "13vw", xs: "5vw" },
          paddingBottom: { lg: "3vh", xs: "12vh" },
          paddingTop: "5vh",
        }}
      >
        {!showLoading && Array.isArray(bestSellerItems) && bestSellerItems?.length === 0 && (
          <Typography
            variant="h6"
            sx={{
              fontSize: "3rem",
              textAlign: "center",
              marginTop: "3rem",
              color: "#868686",
            }}
          >
            No Best Sellers Available
          </Typography>
        )}
        <Grid container spacing={3}>
          {bestSellerItems?.map((e,index) => (
            <Slide key={index} direction="right" in={true} mountOnEnter unmountOnExit timeout={400}>
              <Grid item xs={12} sm={6} md={6} lg={6} key={e?.id}>
                <Card
                  key={e?.id}
                  props={e}
                  fav={false}
                  setState={setState}
                  bestSeller={true}
                  setLoading={setLoader}
                />
              </Grid>
              </Slide>
            ))}
        </Grid>
        {/* )} */}
      </Box>
      {customizeOpen && (
        <Popup
      setUpdateBtnFlag = {setUpdateBtnFlag}
      updateBtnFlag={updateBtnFlag}
      customizeOpen={customizeOpen} 
      flag={"BestSellerFlag"}  
      />
      )}
    </>
  );
};
export default BestSeller;
