import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  checkUser,
  getCustomerDetails,
  Register,
  resendOtp,
} from "../../Api/API";
import {
  validateConfirmPassword,
  validateDob,
  validateEmail,
  validatePassword,
  validatePhoneNumber,
  validateUsername,
} from "../../Common/FormVailidation";
import {
  errorToastMessage,
  formatPhoneNumbers,
  MenuProps,
} from "../../Common/utils";
import {
  setIsUserFiveStarData,
  setLogin,
  setUser,
  setUserGoogleData,
} from "../../Redux/UserSlice";
import OtpPopUp from "../Login/OtpPopUp";
import Upload from "./FileUpload";
toast.configure();
const useStyles = makeStyles((theme) => ({
  error: {
    color: "#bf1650",
    " &::before": {
      display: "inline",
      content: "⚠ ",
    },
  },
  errorP: {
    color: "#bf1650",
    " &::before": {
      display: "inline",
      content: "⚠ ",
    },
  },
  hide: {
    display: "none",
  },
}));

const SignUp = () => {
  const defaultValues = {
    deviceToken: "",
    deviceType: 0,
    dob: "",
    email: "",
    firstName: "",
    isKioskReg: 0,
    lastName: "",
    password: "",
    phoneNumber: "",
    username: "",
    fileName: "",
  };
  const { control, handleSubmit, formState, reset, getValues } = useForm({
    defaultValues,
  });
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { errors } = formState;
  const classes = useStyles();
  const [password, setPassword] = useState("");
  const [imageUpload, setImageUpload] = useState("");
  const [loading, setLoading] = useState(false);
  const [phoneNo, setPhoneNo] = useState();
  const [showPopup, setshowPopup] = useState(false);
  const [signupCondition, setsignupCondition] = useState(false);
  const [month, setMonth] = useState("");
  const monthss = "";
  const [day, setDay] = useState("");
  const dayss = "";
  const [dataReplicate, setDataReplicate] = useState("");
  const [checkUserError, setCheckUserError] = useState("");
  const [title, setTitle] = useState();
  const [signUp, setSignUp] = useState(false);
  const [selectedCategoryImage, setSelectedCategoryImage] = useState("");
  const isSignInFromGoogle = useSelector(
    (state) => state.user.isSignInFromGoogle
  );
  const isAlreadyUserChecked = useSelector(
    (state) => state.user.isAlreadyUserChecked
  );
  const isUserFiveStarData = useSelector(
    (state) => state.user.isUserFiveStarData
  );
  const userGoogleData = useSelector((state) => state.user.userGoogleData);
  const userFiveStarData = useSelector((state) => state.user.userFiveStarData);
  const [leapyear, setLeapyear] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();
  const [fiveStarUserDetails, setFiveStarUserDetails] = useState("");

  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const [valuesCN, setValuesCN] = React.useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPasswordCN = () => {
    setValuesCN({ ...valuesCN, showPassword: !valuesCN.showPassword });
  };

  const handlePasswordChangeCN = (prop) => (event) => {
    setValuesCN({ ...valuesCN, [prop]: event.target.value });
  };
  useEffect(() => {
    if (isSignInFromGoogle) {
      const googleLogin = {
        email: userGoogleData.email,
        firstName: userGoogleData.firstName,
        lastName: userGoogleData.lastName,
        username: userGoogleData.firstName + " " + userGoogleData.lastName,
      };
      reset(googleLogin);
      setSelectedCategoryImage(userGoogleData?.profilePicURL);
    }
    if (isUserFiveStarData) {
      const fiveStarLogin = {
        email: userFiveStarData.email,
        firstName: userFiveStarData.name,
        lastName: userFiveStarData.name,
        username: userFiveStarData.name,
        phoneNumber: formatPhoneNumbers(userFiveStarData.phone),
        dob: userFiveStarData.birthday,
      };
      reset(fiveStarLogin);
    }
  }, []);
  useEffect(() => {
    const partnerId = "";
    const personId = "";

    if (getValues("phoneNumber") && getValues("phoneNumber").length >= 10) {
      getCustomerDetails(partnerId, personId, getValues("phoneNumber"))
        .then((res) => {
          setFiveStarUserDetails(res?.data);
        })
        .catch((error) => {
          console.error(error, "Error from Server");
        });
    }
  }, [phoneNumber]);

  useEffect(() => {
    if (fiveStarUserDetails != null) {
      let dob = fiveStarUserDetails?.account?.birthday?.split("-");
      if (dob != null) {
        setMonth(dob[1]);
        setDay(dob[2]);
      }
    } else {
      setMonth();
      setDay();
    }
  }, [fiveStarUserDetails]);
  const onSubmit = (data) => {
    setDataReplicate(data);
    let phoneNumber = data.phoneNumber.replace(/-/g, "");
    if (isAlreadyUserChecked) {
      setSignUp(true);
    } else {
      handleCheckUser(phoneNumber);
    }
    dispatch(setUserGoogleData(""));
  };
  useEffect(() => {
    let data = dataReplicate;
    if (signUp) {
      setLoading(true);
      if (isSignInFromGoogle) {
        data.password = userGoogleData?.id;
        data.deviceType = 0;
        data.isKioskReg = 0;
        data.username = data?.firstName + " " + data.lastName;
        if (month && day) {
          data.dob = `${"1990"}-${month}-${day}`;
        } else {
          data.dob = `${"1990"}-${monthss}-${dayss}`;
        }
        data.fileName = selectedCategoryImage;
        let fd = new FormData();
        if (imageUpload) {
          fd.append("file", imageUpload.target.files[0]);
        }

        Register(fd, data)
          .then((res) => {
            if (res?.responseCode == 1) {
              setLoading(false);
              toast.success("Registration Successful", {
                position: toast.POSITION.TOP_RIGHT,
                pauseOnFocusLoss: false,
                autoClose: 1500,
                pauseOnHover: false,
                hideProgressBar: false,
                closeOnClick: true,
                draggable: true,
              });
              dispatch(setLogin(true));
              dispatch(setUser(res.result));
              navigate(`/`);
              setSignUp(false);
            } else {
              toast.error(res.responseMessage, {
                position: toast.POSITION.TOP_RIGHT,
                pauseOnFocusLoss: false,
                autoClose: 1500,
                pauseOnHover: false,
                hideProgressBar: false,
                closeOnClick: true,
                draggable: true,
              });
              setLoading(false);
              setSignUp(false);
            }
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
            setSignUp(false);
          });
      } else if (isUserFiveStarData) {
        data.deviceType = 0;
        data.isKioskReg = 0;
        data.password = values.password;
        data.phoneNumber = data.phoneNumber.replace(/-/g, "");
        data.dob = `${"1990"}-${month}-${day}`;
        data.username = data?.firstName + " " + data.lastName;
        let fd = new FormData();
        if (imageUpload) {
          fd.append("file", imageUpload.target.files[0]);
        }
        if (month && day) {
          data.dob = `${"1990"}-${month}-${day}`;
        } else {
          data.dob = `${"1990"}-${monthss}-${dayss}`;
        }
        Register(fd, data)
          .then((res) => {
            if (res?.responseCode == 1) {
              setLoading(false);
              toast.success("Registration Successful", {
                position: toast.POSITION.TOP_RIGHT,
                pauseOnFocusLoss: false,
                autoClose: 1500,
                pauseOnHover: false,
                hideProgressBar: false,
                closeOnClick: true,
                draggable: true,
              });
              dispatch(setLogin(true));
              dispatch(setUser(res.result));
              setSignUp(false);
              dispatch(setIsUserFiveStarData(false));
              navigate(`/`);
            } else if (
              res?.response_code == 1 &&
              res?.responseMessage === "Profile Updated successfully"
            ) {
              setLoading(false);
              toast.success("Registration Successful", {
                position: toast.POSITION.TOP_RIGHT,
                pauseOnFocusLoss: false,
                autoClose: 1500,
                pauseOnHover: false,
                hideProgressBar: false,
                closeOnClick: true,
                draggable: true,
              });
              dispatch(setLogin(true));
              dispatch(setUser(res.result));
              dispatch(setIsUserFiveStarData(false));
              navigate(`/`);
              setSignUp(false);
            } else {
              toast.error(res.responseMessage, {
                position: toast.POSITION.TOP_RIGHT,
                pauseOnFocusLoss: false,
                autoClose: 1500,
                pauseOnHover: false,
                hideProgressBar: false,
                closeOnClick: true,
                draggable: true,
              });
              setLoading(false);
              setSignUp(false);
            }
          })
          .catch((err) => {
            console.log(err);
            setSignUp(false);
            setLoading(false);
          });
      } else {
        data.deviceType = 0;
        data.isKioskReg = 0;
        data.password = values.password;
        data.phoneNumber = data.phoneNumber.replace(/-/g, "");
        if (month && day) {
          data.dob = `${"1990"}-${month}-${day}`;
        } else {
          data.dob = `${"1990"}-${monthss}-${dayss}`;
        }

        data.username = data?.firstName + " " + data.lastName;
        let fd = new FormData();
        if (imageUpload) {
          fd.append("file", imageUpload.target.files[0]);
        }

        Register(fd, data)
          .then((res) => {
            if (res?.responseCode == 1) {
              setLoading(false);
              toast.success("Registration Successful", {
                position: toast.POSITION.TOP_RIGHT,
                pauseOnFocusLoss: false,
                autoClose: 1500,
                pauseOnHover: false,
                hideProgressBar: false,
                closeOnClick: true,
                draggable: true,
              });
              dispatch(setLogin(true));
              setSignUp(false);
              dispatch(setUser(res.result));
              navigate(`/`);
            } else {
              toast.error(res.responseMessage, {
                position: toast.POSITION.TOP_RIGHT,
                pauseOnFocusLoss: false,
                autoClose: 1500,
                pauseOnHover: false,
                hideProgressBar: false,
                closeOnClick: true,
                draggable: true,
              });
              setLoading(false);
              setSignUp(false);
            }
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
            setSignUp(false);
          });
      }
    }
  }, [signUp]);

  const handleImage = (e) => {
    setImageUpload(e);
  };

  const handleCheckUser = (phoneNumber) => {
    phoneNumber = phoneNumber.replace(/-/g, "");
    setLoading(true);
    setPhoneNo(phoneNumber);
    checkUser(phoneNumber, "+1")
      .then((res) => {
        setCheckUserError("");
        setLoading(false);
        if (res.response_code == 1 && res.result.otp == false) {
          setCheckUserError("This phone number already exists");
          setLoading(false);
          setSignUp(false);
        } else if (
          res?.response_code == "0" &&
          res?.response_message === "Membership does not exist at this business"
        ) {
          setTitle("Signup");
          setCheckUserError("");
          setshowPopup(true);
          setLoading(false);
        } else {
          setTitle("Signup");
          setCheckUserError("");
          resendOtp(phoneNumber, "+1")
            .then((resp) => {
              setshowPopup(true);
              setLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setSignUp(false);
              setLoading(false);
              errorToastMessage();
              // return false;
            });
        }
      })
      .catch((err) => {
        console.log(err);
        setSignUp(false);
        setLoading(false);
        errorToastMessage();
      });
  };

  const months = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];

  const days31 = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
  ];

  const days30 = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
  ];

  const days28 = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
  ];

  const days29 = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
  ];

  const handleMonth = (e) => {
    setMonth(e.target.value);
    setDay("");
    setLeapyear(isLeapYear(new Date().getFullYear()));
  };

  function isLeapYear(year = new Date().getFullYear()) {
    return new Date(year, 1, 29).getDate() === 29;
  }

  const handleDay = (e) => {
    setDay(e.target.value);
  };

  const getDaysForMonth = (month) => {
    if (month === "02") {
      return leapyear ? days29 : days28;
    }
    if (["04", "06", "09", "11"].includes(month)) {
      return days30;
    }
    return days31;
  };

  return (
    <div
      style={{
        backgroundColor: "#E7E7E7",
        paddingBottom: "3vh",
      }}
    >
      <Backdrop sx={{ color: "#fff", zIndex: 1000 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container style={{ marginTop: "3vh" }}>
        <Card sx={{ borderRadius: "1rem" }}>
          <CardContent>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <Grid container sx={{ margin: { lg: "1rem" } }}>
                <Grid
                  item
                  md={3}
                  xs={12}
                  sm={12}
                  lg={3}
                  marginBottom="1.5rem"
                  align="center"
                >
                  <FormControl fullWidth>
                    <Controller
                      name="file"
                      render={({ field }) => (
                        <>
                          <input
                            name="upload-image"
                            type="file"
                            {...field}
                            id="file"
                            className={classes.hide}
                            accept="image/*"
                            onChange={(e) => handleImage(e)}
                          />
                          <Upload
                            imageUpload={imageUpload}
                            selectedImage={selectedCategoryImage}
                          />
                        </>
                      )}
                      control={control}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12} sm={10} lg={6}>
                  <Box display="flex">
                    <Box sx={{ width: "18rem", display: "flex" }}>
                      <Typography variant="subtitle1" gutterBottom>
                        First Name
                      </Typography>
                      <Typography sx={{ color: "red" }}>*</Typography>
                    </Box>
                    <FormControl fullWidth>
                      <Controller
                        name="firstName"
                        rules={
                          ({
                            required: true,
                          },
                          {
                            validate: (value) => {
                              return validateUsername(value);
                            },
                          })
                        }
                        render={({ field }) => (
                          <TextField
                            {...field}
                            id="firstName"
                            type="text"
                            fullWidth
                            size="small"
                            variant="standard"
                            placeholder="Enter your first name"
                            inputProps={{
                              maxLength: 30,
                              autocomplete: "firstName",
                              form: {
                                autocomplete: "off",
                              },
                            }}
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(
                                /[^a-zA-Z ]/g,
                                ""
                              );
                            }}
                          />
                        )}
                        control={control}
                      />
                      {errors?.firstName ? (
                        <p className={classes.error}>
                          {errors.firstName.message}
                        </p>
                      ) : (
                        <p>
                          <br />
                        </p>
                      )}
                    </FormControl>
                  </Box>

                  <Box display="flex">
                    <Box sx={{ width: "18rem", display: "flex" }}>
                      <Typography variant="subtitle1" gutterBottom>
                        Last Name
                      </Typography>
                      <Typography sx={{ color: "red" }}>*</Typography>
                    </Box>
                    <FormControl fullWidth>
                      <Controller
                        name="lastName"
                        rules={
                          ({
                            required: true,
                          },
                          {
                            validate: (value) => {
                              return validateUsername(value);
                            },
                          })
                        }
                        render={({ field }) => (
                          <TextField
                            {...field}
                            id="lastName"
                            type="text"
                            fullWidth
                            size="small"
                            variant="standard"
                            placeholder="Enter your last name"
                            inputProps={{
                              maxLength: 30,
                              autocomplete: "lastName",
                              form: {
                                autocomplete: "off",
                              },
                            }}
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(
                                /[^a-zA-Z ]/g,
                                ""
                              );
                            }}
                          />
                        )}
                        control={control}
                      />
                      {errors?.lastName ? (
                        <p className={classes.error}>
                          {errors.lastName.message}
                        </p>
                      ) : (
                        <p>
                          <br />
                        </p>
                      )}
                    </FormControl>
                  </Box>

                  <Box display="flex">
                    <Box sx={{ width: "18rem", display: "flex" }}>
                      <Typography variant="subtitle1" gutterBottom>
                        Phone No.
                      </Typography>
                      <Typography sx={{ color: "red" }}>*</Typography>
                    </Box>
                    <FormControl fullWidth>
                      <Controller
                        name="phoneNumber"
                        rules={
                          ({
                            required: true,
                          },
                          {
                            validate: (phoneNumber) => {
                              return validatePhoneNumber(phoneNumber);
                            },
                          })
                        }
                        render={({ field }) => (
                          <TextField
                            {...field}
                            id="phoneNumber"
                            disabled={isUserFiveStarData ? true : false}
                            type="text"
                            fullWidth
                            size="small"
                            variant="standard"
                            placeholder="Your contact number"
                            inputProps={{
                              autocomplete: "phoneNumber",
                              form: {
                                autocomplete: "off",
                              },
                              maxLength: 12,
                              inputMode: "numeric",
                            }}
                            onInput={(e) => {
                              setPhoneNumber(e.target.value);
                              e.target.value = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );
                              e.target.value = e.target.value.replace(
                                /(\d{3})(\d{3})(\d{4})/,
                                "$1-$2-$3"
                              );
                              if (e.target.value.length < 12) {
                                setCheckUserError("");
                              }
                            }}
                          />
                        )}
                        control={control}
                      />
                      {errors?.phoneNumber || checkUserError ? (
                        <p className={classes.error}>
                          {errors?.phoneNumber?.message || checkUserError}
                        </p>
                      ) : (
                        <p>
                          <br />
                        </p>
                      )}
                    </FormControl>
                  </Box>

                  <Box display="flex">
                    <Box sx={{ width: "18rem", display: "flex" }}>
                      <Typography variant="subtitle1" gutterBottom>
                        Email
                      </Typography>
                      <Typography sx={{ color: "red" }}>*</Typography>
                    </Box>
                    <FormControl fullWidth>
                      <Controller
                        name="email"
                        rules={
                          ({
                            required: true,
                          },
                          {
                            validate: (value) => {
                              return validateEmail(value);
                            },
                          })
                        }
                        render={({ field }) => (
                          <TextField
                            {...field}
                            id="email"
                            type="text"
                            fullWidth
                            size="small"
                            variant="standard"
                            placeholder="Enter your mail"
                            inputProps={{
                              autocomplete: "email",
                              form: {
                                autocomplete: "off",
                              },
                            }}
                          />
                        )}
                        control={control}
                      />
                      {errors?.email ? (
                        <p className={classes.error}>{errors.email.message}</p>
                      ) : (
                        <p>
                          <br />
                        </p>
                      )}
                    </FormControl>
                  </Box>

                  <Box display="flex" alignItems="center">
                    <Box sx={{ width: "12rem", display: "flex", mt: "-20px" }}>
                      <Typography variant="subtitle1" gutterBottom>
                        Birthday
                      </Typography>
                      <Typography sx={{ color: "red" }}>*</Typography>
                    </Box>
                    <div>
                      <div style={{ display: "flex" }}>
                        <FormControl fullWidth>
                          <Controller
                            name="month"
                            rules={{
                              required: "Month is required",
                              validate: (value) => validateDob(value),
                            }}
                            render={({ field, fieldState: { error } }) => (
                              <div>
                                <InputLabel
                                  sx={{ display: { xs: "none", sm: "block" } }}
                                >
                                  Select Month
                                </InputLabel>
                                <InputLabel
                                  sx={{ display: { xs: "block", sm: "none" } }}
                                >
                                  Month
                                </InputLabel>
                                <Select
                                  {...field}
                                  value={month || ""}
                                  onChange={(e) => {
                                    field.onChange(e);
                                    handleMonth(e);
                                  }}
                                  IconComponent="none"
                                  MenuProps={MenuProps}
                                  label="Select Month"
                                  id="month"
                                  inputProps={{
                                    autocomplete: "off",
                                    form: { autocomplete: "off" },
                                  }}
                                  variant="outlined"
                                  sx={{
                                    width: {
                                      lg: "11vw",
                                      md: "13vw",
                                      sm: "20vw",
                                      xs: "20vw",
                                    },
                                  }}
                                >
                                  {months.map((month, index) => (
                                    <MenuItem key={index} value={month}>
                                      {month}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {error && (
                                  <p className={classes.error}>
                                    {error.message}
                                  </p>
                                )}
                              </div>
                            )}
                            control={control}
                          />
                        </FormControl>
                        <FormControl fullWidth>
                          <Controller
                            name="day"
                            rules={{
                              required: "Day is required",
                              validate: (value) => validateDob(value),
                            }}
                            render={({ field, fieldState: { error } }) => (
                              <>
                                <InputLabel
                                  sx={{ display: { xs: "none", sm: "block" } }}
                                >
                                  Select Day
                                </InputLabel>
                                <InputLabel
                                  sx={{ display: { xs: "block", sm: "none" } }}
                                >
                                  Day
                                </InputLabel>
                                <Select
                                  {...field}
                                  value={day || ""}
                                  onChange={(e) => {
                                    field.onChange(e);
                                    handleDay(e);
                                  }}
                                  MenuProps={MenuProps}
                                  label="Select Day"
                                  id="day"
                                  IconComponent="none"
                                  inputProps={{
                                    autocomplete: "off",
                                    form: { autocomplete: "off" },
                                  }}
                                  variant="outlined"
                                  sx={{
                                    width: {
                                      lg: "11vw",
                                      md: "13vw",
                                      sm: "20vw",
                                      xs: "20vw",
                                    },
                                    marginRight: "0.5rem",
                                  }}
                                >
                                  {getDaysForMonth(month).map((day, index) => (
                                    <MenuItem key={index} value={day}>
                                      {day}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {error && (
                                  <p className={classes.error}>
                                    {error.message}
                                  </p>
                                )}
                              </>
                            )}
                            control={control}
                          />
                        </FormControl>
                      </div>
                      {errors?.dob ? (
                        <p className={classes.error}>{errors.dob.message}</p>
                      ) : (
                        <p>
                          <br />
                        </p>
                      )}
                    </div>
                  </Box>
                </Grid>
                <Container
                  sx={{
                    marginTop: "2rem",
                    display: { xs: "none", sm: "none" },
                  }}
                >
                  <Box
                    sx={{
                      marginRight: {
                        xs: "0",
                        sm: "0",
                        md: "16rem",
                        lg: "16rem",
                      },
                    }}
                    display="flex"
                  >
                    <Box sx={{ width: "18rem", display: "flex" }}>
                      <Typography variant="subtitle1" gutterBottom>
                        Choose Password
                      </Typography>
                      <Typography sx={{ color: "red" }}>*</Typography>
                    </Box>
                    <FormControl fullWidth>
                      <Controller
                        name="password"
                        rules={{
                          required: true,
                          validate: (value) => {
                            setPassword(value);
                            if (!value) {
                              return validatePassword(value);
                            } else if (value.length > 0 && value.length < 8) {
                              return validatePassword(value);
                            } else if (value.length > 20) {
                              // Updated to 20 characters
                              return "More than 20 characters are not Allowed";
                            } else if (value.length >= 8) {
                              return validatePassword(value);
                            }
                          },
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            id="password"
                            size="small"
                            placeholder="Enter 8-20 digit password" // Updated placeholder
                            type={values.showPassword ? "text" : "password"}
                            onInput={handlePasswordChange("password")}
                            value={values.password}
                            variant="standard"
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton onClick={handleClickShowPassword}>
                                    {values.showPassword ? (
                                      <VisibilityIcon />
                                    ) : (
                                      <VisibilityOffIcon />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            inputProps={{
                              maxLength: 20,
                              autocomplete: "password",
                              form: {
                                autocomplete: "off",
                              },
                            }}
                          />
                        )}
                        control={control}
                      />
                      {errors?.password ? (
                        <p className={classes.errorP}>
                          {errors.password.message}
                        </p>
                      ) : (
                        <p>
                          <br />
                        </p>
                      )}
                    </FormControl>
                  </Box>

                  <Box
                    sx={{
                      marginRight: {
                        xs: "0",
                        sm: "0",
                        md: "16rem",
                        lg: "16rem",
                      },
                    }}
                    display="flex"
                  >
                    <Box sx={{ width: "18rem", display: "flex" }}>
                      <Typography variant="subtitle1" gutterBottom>
                        Confirm Password
                      </Typography>
                      <Typography sx={{ color: "red" }}>*</Typography>
                    </Box>
                    <FormControl fullWidth>
                      <Controller
                        name="confirmPassword"
                        rules={{
                          required: true,
                          validate: (value) => {
                            if (password) {
                              return validateConfirmPassword(value, password);
                            } else if (!value) {
                              return "Confirm password is required";
                            } else if (value.length > 20) {
                              // Updated to 20 characters
                              return "More than 20 characters are not Allowed";
                            }
                          },
                        }}
                        render={({ field }) => (
                          <TextField
                            id="confirmPassword"
                            {...field}
                            size="small"
                            placeholder="Re-enter password"
                            type={valuesCN.showPassword ? "text" : "password"}
                            onInput={handlePasswordChangeCN("password")}
                            value={valuesCN.password}
                            variant="standard"
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={handleClickShowPasswordCN}
                                  >
                                    {valuesCN.showPassword ? (
                                      <VisibilityIcon />
                                    ) : (
                                      <VisibilityOffIcon />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            inputProps={{
                              maxLength: 20, // Restrict input to 20 characters
                            }}
                          />
                        )}
                        control={control}
                      />
                      {errors?.confirmPassword ? (
                        <p className={classes.errorP}>
                          {errors.confirmPassword.message}
                        </p>
                      ) : (
                        <p>
                          <br />
                        </p>
                      )}
                    </FormControl>
                  </Box>
                </Container>
                <Grid
                  item
                  xs={12}
                  sm={10}
                  sx={{ display: { xs: "block", sm: "block" } }}
                >
                  <Box
                    sx={{
                      marginRight: {
                        xs: "0",
                        sm: "0",
                        md: "16rem",
                        lg: "16rem",
                      },
                    }}
                    display="flex"
                  >
                    <Box sx={{ width: "18rem", display: "flex" }}>
                      <Typography variant="subtitle1" gutterBottom>
                        Choose Password
                      </Typography>
                      <Typography sx={{ color: "red" }}>*</Typography>
                    </Box>
                    <FormControl fullWidth>
                      <Controller
                        name="password"
                        rules={{
                          required: true,
                          validate: (value) => {
                            setPassword(value);
                            if (!value) {
                              return validatePassword(value);
                            } else if (value.length > 0 && value.length < 8) {
                              return validatePassword(value);
                            } else if (value.length > 20) {
                              // Updated to 20 characters
                              return "More than 20 characters are not Allowed";
                            } else if (value.length >= 8) {
                              return validatePassword(value);
                            }
                          },
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            id="password"
                            size="small"
                            placeholder="Enter 8-20 digit password" // Updated placeholder
                            type={values.showPassword ? "text" : "password"}
                            onInput={handlePasswordChange("password")}
                            value={values.password}
                            variant="standard"
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton onClick={handleClickShowPassword}>
                                    {values.showPassword ? (
                                      <VisibilityIcon />
                                    ) : (
                                      <VisibilityOffIcon />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            inputProps={{
                              maxLength: 20, // Restrict input to 20 characters
                            }}
                          />
                        )}
                        control={control}
                      />
                      {errors?.password ? (
                        <p className={classes.errorP}>
                          {errors.password.message}
                        </p>
                      ) : (
                        <p>
                          <br />
                        </p>
                      )}
                    </FormControl>
                  </Box>

                  <Box
                    sx={{
                      marginRight: {
                        xs: "0",
                        sm: "0",
                        md: "16rem",
                        lg: "16rem",
                      },
                    }}
                    display="flex"
                  >
                    <Box sx={{ width: "18rem", display: "flex" }}>
                      <Typography variant="subtitle1" gutterBottom>
                        Confirm Password
                      </Typography>
                      <Typography sx={{ color: "red" }}>*</Typography>
                    </Box>
                    <FormControl fullWidth>
                      <Controller
                        name="confirmPassword"
                        rules={{
                          required: true,
                          validate: (value) => {
                            if (password) {
                              return validateConfirmPassword(value, password);
                            } else if (!value) {
                              return "Confirm password is required";
                            } else if (value.length > 20) {
                              // Updated to 20 characters
                              return "More than 20 characters are not Allowed";
                            }
                          },
                        }}
                        render={({ field }) => (
                          <TextField
                            id="confirmPassword"
                            {...field}
                            size="small"
                            placeholder="Re-enter password"
                            type={valuesCN.showPassword ? "text" : "password"}
                            onInput={handlePasswordChangeCN("password")}
                            value={valuesCN.password}
                            variant="standard"
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={handleClickShowPasswordCN}
                                  >
                                    {valuesCN.showPassword ? (
                                      <VisibilityIcon />
                                    ) : (
                                      <VisibilityOffIcon />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            inputProps={{
                              maxLength: 20, // Restrict input to 20 characters
                            }}
                          />
                        )}
                        control={control}
                      />
                      {errors?.confirmPassword ? (
                        <p className={classes.errorP}>
                          {errors.confirmPassword.message}
                        </p>
                      ) : (
                        <p>
                          <br />
                        </p>
                      )}
                    </FormControl>
                  </Box>
                </Grid>

                <Container
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "0",
                    marginTop: "-1rem",
                  }}
                >
                  <Checkbox
                    checked={signupCondition}
                    onChange={(e) => {
                      setsignupCondition(e.target.checked);
                    }}
                  />
                  <h6 style={{ marginTop: "0.7rem" }}>
                    By signing up, you agree to the
                    <a
                      href={"https://gongchausa.com/terms-conditions/"}
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        marginLeft: "0.1rem",
                        marginRight: "0.3rem",
                        textDecoration: "none",
                      }}
                    >
                      {" "}
                      Terms & Conditions
                    </a>
                    and
                    <a
                      href={"https://gongchausa.com/privacy-policy/"}
                      target="_blank"
                      rel="noreferrer"
                      style={{ marginLeft: "0.1rem", textDecoration: "none" }}
                    >
                      {" "}
                      Privacy Policy
                    </a>
                  </h6>
                </Container>
                <Container sx={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    type="submit"
                    disabled={signupCondition ? false : true}
                    sx={{
                      padding: "0.5rem 2rem",
                      backgroundColor: "#B7273B",
                      fontFamily: "Lusitana",
                      borderRadius: "3rem",
                      marginBottom: "2rem",
                    }}
                    variant="contained"
                    style={{ textTransform: "none" }}
                  >
                    Sign Up
                  </Button>
                </Container>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Container>
      {showPopup && (
        <OtpPopUp
          showPopup={showPopup}
          setshowPopup={setshowPopup}
          phoneNo={phoneNo}
          title={title}
          setSignUp={setSignUp}
        />
      )}
    </div>
  );
};

export default SignUp;
