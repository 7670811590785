import axios from "axios";

//Staging
//  let username ="gongchausa.staging.mobile";
//  let password ="R3a#T9q2w%6pL@1x7*Jh!4k8z^5s2f"; 

//QA
// let username ="gongchausa.qa.mobile";
// let password ="R3a#T9q2w%6pL@1x7*Jh!4k8z^5s2f"; 

//Prod
let username ="gongchausa.prod.mobile";
let password ="A2w&X7g#D5v@H3j6T1b*Z4m9pK8f^R5"; 

const instance = axios.create({
  // baseURL: `https://apistaging.aiprusdev.com`,
  //  baseURL: "https://api-staging-orders.gongchausa.com/",
  // baseURL: "http://3.132.42.41:8555/",
  // baseURL:"https://1mu38gt1a0.execute-api.us-east-2.amazonaws.com/QA",
  baseURL: "https://api.gongchausa.com",
  auth:{
   username:username,
   password:password
  }
  // baseURL: "https://api-staging-orders.gongchausa.com/",
  // baseURL: process.env.REACT_APP_baseUrl,
});
instance.defaults.headers.post["Content-Type"] =
  "application/json;charset=utf-8";
instance.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

export default instance;
