import { Box, Button, Dialog, DialogActions, DialogTitle, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useDispatch } from "react-redux";
import { setLocationPopup } from '../../../Redux/StoreSlice';
const useStyles = makeStyles(theme => ({
  paper: { borderRadius: 15 },
}))
const StorePopup = (props) => {
    const dispatch = useDispatch();
 const {showLocation, setState} = props;
 const classes = useStyles();

  return (
    <>
        <Dialog open={showLocation}   
        classes={{ paper: classes.paper}}
        // PaperProps={{sx:{bottom:'32vh',left:"29vw",background:"#E7E7E7"}}}
        sx={{bottom:'63vh',left:"59vw"}}
        >
        <Box sx={{background:"#E7E7E7"}}>
        <DialogTitle textAlign="center">
            <img src="./NewLogoG.png" alt="" width="50%" />
          </DialogTitle>
        <Typography textAlign="center" >Please confirm your location</Typography>
        <DialogActions  onClick={()=>{dispatch(setLocationPopup(false)) 
        setState(true)}} >
        <Button>OK</Button>
         </DialogActions>
         </Box>
        </Dialog>
    </>
  )
}

export default StorePopup