export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!email) {
    return `Email is required`;
  } else {
    return re.test(convertToString(email)) ? true : "Please enter valid Email";
  }
};

export const validatePhoneNumber = (number) => {
  const re = /^\(?(\d{3})\)?[- ](\d{3})[- ](\d{4})$/;

  if (!number) {
    return "Mobile Number is required";
  } else {
    return (
      re.test(convertToString(number)) == true ||
      "Please enter valid mobile number"
    );
  }
};

export const validateNumber = (number, fieldName) => {
  const re = /^([\s]*[\d]{1,10}[\s]*)+$/;
  if (!number) {
    return `*${fieldName} is required`;
  } else {
    return (
      re.test(convertToString(number)) == true ||
      `Please enter valid ${fieldName}`
    );
  }
};

export const validateName = (name, fieldName) => {
  let Name = name?.trim();
  const re = /^[a-zA-Z0-9'\s]{1,}$/;
  if (!Name) {
    return `${fieldName} is required`;
  } else {
    return (
      re.test(convertToString(Name)) == true ||
      `Please enter valid ${fieldName}`
    );
  }
};

export const validateDob = (name) => {
  if (!name) {
    return `Birthday is required`;
  }
  return true;
};

export const validateUsername = (name) => {
  const re = /[A-Za-z0-9_]{1,15}/;
  if (!name) {
    return `User Name is required`;
  } else {
    return (
      re.test(convertToString(name)) == true || "Please enter valid User Name"
    );
  }
};

export const validateGuestUsername = (name) => {
  const re = /[A-Za-z0-9_]{1,15}/;
  if (name) {
    return (
      re.test(convertToString(name)) == true || "Please enter valid User Name"
    );
  }
};
export const validatePassword = (password) => {
  const re = /^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{8,}$/;

  if (!password) {
    return "Password is required";
  } else {
    return password.length >= 8
      ? re.test(convertToString(password)) == true ||
          `Please enter a valid Password`
      : "Please enter at least 8 characters";
  }
};

export const validateConfirmPassword = (value, Password) => {
  if (value !== Password) {
    return "Confirm password do not match.";
  }
};
export const validateCardExpiry = (num) => {
  let thisYear = new Date().getFullYear() % 100;
  let thisMonth =
    new Date().getMonth() + 1 < 10
      ? `0${new Date().getMonth() + 1}`
      : new Date().getMonth() + 1;

  const re = /^(0[1-9]|1[0-2])\/?([0-9]{2})$/;
  const d = num?.split("/");
  let month = d[0];
  let year = d[1];
  let selectedDate = `${month}/${year}`;
  if (!num) {
    return "*Expiry date is required";
  } else if (re.test(convertToString(selectedDate)) == true) {
    if (thisYear >= year && month < thisMonth) {
      return `Card expired`;
    } else if (thisYear > year) {
      return `Card expired`;
    }
  } else {
    return "Please enter a valid expiry date";
  }
};

export const validateCardMobileExpiry = (number) => {
  let thisYear = new Date().getFullYear();

  let thisMonth =
    new Date().getMonth() + 1 < 10
      ? `0${new Date().getMonth() + 1}`
      : new Date().getMonth() + 1;

  const re = /^(0[1-9]|1[0-2])\/?([0-9]{4})$/;
  let month = number.month;
  let year = number.year;
  let selectedDate = `${number.month}/${number.year}`;
  if (!number) {
    return "*Expiry date is required";
  } else if (re.test(convertToString(selectedDate)) == true) {
    if (Number(thisYear) >= Number(year) && Number(month) < Number(thisMonth)) {
      return `Card expired`;
    } else if (Number(thisYear) > Number(year)) {
      return `Card expired`;
    }
  }
};

const convertToString = (value) => {
  return String(value).toLowerCase();
};

export const validateCreditCardNumber = (value, fieldName, length) => {
  let variableValue = 0;
  if (length && fieldName == "creditCard") {
    for (let i = 0; i < length.length; i++) {
      if (value.length <= length[i]) {
        variableValue = length[i];
        break;
      }
    }
  }
  let x = new RegExp("^[0-9]" + `{${variableValue}}` + `?$`);
  let re;
  if (fieldName == "creditCard") {
    re = x;
  } else if (fieldName == "givexCard") {
    re = /^[0-9]{18}(?:[0-9]{3})?$/;
  }
  if (!value) {
    return `${
      fieldName == "creditCard" ? "*Credit card" : "Givex card"
    } number is required`;
  } else {
    return (
      re.test(convertToString(value)) == true ||
      `Please enter valid card number`
    );
  }
};

export const validateCardHolderName = (value) => {
  const re = /^((?:[A-Za-z]+ ?){3,})$/;
  if (!value) {
    return "*Card holder name is required";
  } else {
    return (
      re.test(convertToString(value)) == true ||
      `Please enter valid card holder name`
    );
  }
};

export const validateZipCode = (number) => {
  const re = /[0-9-]{4}/;
  if (!number) {
    return `*Zip code is required`;
  } else {
    return (
      re.test(convertToString(number)) == true ||
      "*Zip code less than 4 char not allowed"
    );
  }
};
