import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CallIcon from "@mui/icons-material/Call";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { getAllOrderByCustomersec } from "../../Api/API";
import {
  AmPmFormat,
  errorToastMessage,
  pickupTime,
  uiDate,
} from "../../Common/utils";
import { setTabValue } from "../../Redux/CartSlice";
import { setCheckActive, setClickActiveOrders } from "../../Redux/UserSlice";
import "./OrderHistoryStyle.css";
import MapIcon from "@mui/icons-material/Map";
import TabBar from "../../Common/Components/Navbar/TabBar";
import giftIcon from "./gift_icon.png";

const useStyle = makeStyles((theme) => ({
  location: { display: "flex" },
  orderStatus: {
    marginTop: "1rem",
    display: "flex",
    justifyContent: "space-between",
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  previousOrderSpaceBetween: {
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  backgroundImg: {
    backgroundImage: `url("/cupsImage.png") !important`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "90%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  mainButton: {
    display: "flex",
    border: "2px solid #C60734",
    width: "15rem",
    height: "2.5rem",
    backgroundColor: "white",
    borderRadius: "12px",
    padding: "2px",
    "@media (min-width: 769px)": {
      fontSize: "18px",
      width: "25rem",
      height: "3.5rem",
    },
  },
  emptyCartMessage: {
    color: "#C60734",
    fontSize: "2rem",
    fontWeight: "bold",
    fontFamily: "Lusitana",
    textAlign: "center",
  },
  addItemsButton: {
    backgroundColor: "#C60734",
    color: "white",
    borderRadius: "20px",
    padding: "0.5rem 2rem",
    textTransform: "none",
    fontFamily: "Lusitana",
    marginLeft: "2.5rem",
  },
  activeButton: {
    flex: 1,
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    fontSize: "10px",
    "@media (min-width: 769px)": {
      fontSize: "18px",
    },
  },
  previousButton: {
    flex: 1,
    border: "none",
    borderRadius: "8px",
    fontSize: "10px",
    cursor: "pointer",
    "@media (min-width: 769px)": {
      fontSize: "18px",
    },
  },
  btnLogin: {
    borderRadius: 20,
    padding: "0.2rem 3rem",
    backgroundColor: "brown",
    color: "white",
    textTransform: "none",
  },
  root: {
    "& .Mui-disabled": {
      color: "green",
    },
  },
  tabBarContainer: {
    width: "100%",
    backgroundColor: "#fff",
  },
}));

const OrderItem = ({ orderDetail }) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={3} md={1}>
          <Box
            component="img"
            // paddingRight="10px"
            sx={{
              height: 70,
              paddingBottom: "10px",
              display: "flex",
              margin: "0 auto",
            }}
            alt="menuImage"
            src={orderDetail?.menu_image}
          />
        </Grid>
        <Grid
          item
          xs={7}
          md={9}
          display="flex"
          direction="column"
          justifyContent="center"
        // sx={{
        //   marginLeft: { md: "-45px", sm: "-50px" },
        // }}
        >
          <Typography
            fontWeight="600"
            // marginLeft="10px"
            sx={{ fontSize: { xs: "0.75rem", sm: "0.875rem", md: "1rem" } }}
          >
            {orderDetail?.menu_name}
          </Typography>
          <Typography variant="body2" sx={{ fontSize: { xs: "0.75rem", sm: "0.875rem", md: "1rem" } }}>
            {orderDetail?.order_details?.map(
              (elm, index) =>
                (elm?.option?.length > 0
                  ? elm?.option?.map((item) => {
                    return (
                      " " +
                      item?.modifier_name.trim() +
                      (item?.quantity > 1
                        ? " (" + item?.quantity + ") "
                        : "")
                    );
                  })
                  : " ") +
                (orderDetail?.order_details?.length - 1 !== index ? " | " : " ")
            )}
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          md={2}
          display="flex"
          direction="column"
          justifyContent="space-around"
          textAlign="right"
          alignItems="end"
        >
          <Typography
            variant="subtitle"
            sx={{
              fontSize: { xs: "0.75rem", sm: "0.875rem", md: "1rem" },
              // marginRight: { sm: "-50px", md: "-45px" },
            }}
          >
            x {orderDetail?.quantity_purchased}<br/>
            {`$${Number(orderDetail?.item_customized_price).toFixed(2)}`}
          </Typography>
          {/* <Typography
            style={{ fontWeight: "bold" }}
            sx={{
              fontSize: { xs: "0.75rem", sm: "0.875rem", md: "1rem" },
              // marginRight: { sm: "-50px", md: "-45px" },
            }}
            gutterBottom
          >{`$${Number(orderDetail?.item_customized_price).toFixed(
            2
          )}`}</Typography> */}
        </Grid>
      </Grid>
      {/* <Divider sx={{ marginTop: "0.5rem",borderBottomWidth: "3", width: '100%'}} /> */}
    </>
  );
};
const OrderHistory = () => {
  const classes = useStyle();
  let navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [previousLoading, setPreviousLoading] = useState(false);
  const [orderDetail, setOrderDetail] = useState();
  const [clickActive, setClickActive] = useState();
  const [savedCards, setSavedCard] = useState([]);
  const [pastOrderDetail, setPastOrderDetail] = useState();
  const [state, setState] = useState();
  const userDetail = useSelector((state) => state.user.user);
  const activeOrder = useSelector((state) => state.user.clickActiveOrders);
  const checkActive = useSelector((state) => state.user.checkActive);
  const dispatch = useDispatch();

  const [expandedOrders, setExpandedOrders] = useState({});
  const title = location.state?.title;
  const orderId = location.state?.id;

  useEffect(() => {
    if (!userDetail?.person_id && !userDetail?.customer_id) {
      dispatch(setTabValue(2));
      navigate('/');
    }
  }, [userDetail, navigate, dispatch]);

  useEffect(() => {
    return () => {
      localStorage.removeItem("orderHistoryTab");
    };
  }, []);

  const toggleOrderDetails = (orderId) => {
    setExpandedOrders((prev) => ({
      ...prev,
      [orderId]: !prev[orderId],
    }));
  };
  useEffect(() =>{
    setExpandedOrders((prev) => ({
      ...prev,
      [orderId]: !prev[orderId],
    }));
  },[orderId,title])

  const handleMapClick = (latitude, longitude) => {
    window.open(`https://maps.google.com?q=${latitude},${longitude}`, "_blank");
  };

  let data = {
    orderType: "app",
    type: "",
    userId: userDetail?.person_id
      ? userDetail?.person_id
      : userDetail?.customer_id,
  };
  const handleOrderHistory = () => {
    data.type = "live";
    setLoading(true);
    setPreviousLoading(true);
    getAllOrderByCustomersec(data, 0, 5, "created_at")
      .then((res) => {
        setLoading(false);
        const results = res?.result || [];
        results.map((e, index) => {
          return (e.status = false), res.result[index];
        });
        setOrderDetail(results);
        results.map((e) => {
          let imageUrl;
          switch (e?.card_type?.toLowerCase()) {
            case "visa":
              imageUrl = "./visa.png";
              break;
            case "mc":
              imageUrl = "./mastercard.png";
              break;
            case "mastercard":
              imageUrl = "./mastercard.png";
              break;
            case "amex":
              imageUrl = "./Amex.png";
              break;
            case "american-express":
              imageUrl = "./Amex.png";
              break;
            case "maestro":
              imageUrl = "./maestro.png";
              break;
            case "dinersclub":
              imageUrl = "./dinersclub.png";
              break;
            case "discover":
              imageUrl = "./discover.png";
              break;
            case "jcb":
              imageUrl = "./jcb.png";
              break;
            case "gift_card":
              imageUrl = "./giftcard.jpg";
              break;
            default:
              imageUrl = "";
          }
          return (e.imageUrl = imageUrl);
        });
        setSavedCard(results);
      })
      .catch((e) => {
        setLoading(false);
        setOrderDetail([]);
        errorToastMessage();
      });
    data.type = "past";
    getAllOrderByCustomersec(data, 0, 5, "created_at")
      .then((res) => {
        setPreviousLoading(false);
        const results = res?.result || [];
        setPastOrderDetail(results);
        results.map((e) => {
          let imageUrl;
          switch (e?.card_type?.toLowerCase()) {
            case "visa":
              imageUrl = "./visa.png";
              break;
            case "mc":
              imageUrl = "./mastercard.png";
              break;
            case "mastercard":
              imageUrl = "./mastercard.png";
              break;
            case "amex":
              imageUrl = "./Amex.png";
              break;
            case "american-express":
              imageUrl = "./Amex.png";
              break;
            case "maestro":
              imageUrl = "./maestro.png";
              break;
            case "dinersclub":
              imageUrl = "./dinersclub.png";
              break;
            case "discover":
              imageUrl = "./discover.png";
              break;
            case "jcb":
              imageUrl = "./jcb.png";
              break;
            case "gift_card":
              imageUrl = "./giftcard.jpg";
              break;
            default:
              imageUrl = "";
          }
          return (e.imageUrl = imageUrl);
        });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setPastOrderDetail([]);
        errorToastMessage();
      });
  };
  useEffect(() => {
    handleOrderHistory();
  }, []);

  const handleActiveOrder = (e) => {
    if (checkActive === false) {
      dispatch(setCheckActive(true));
    } else {
      dispatch(setCheckActive(false));
    }
    setClickActive(e);
  };
  const handlePreviousOrder = (e) => {
    if (checkActive === false) {
      dispatch(setCheckActive(true));
    } else {
      dispatch(setCheckActive(false));
    }
    setClickActive(e);
  };
  useEffect(() => {
    if (activeOrder === true && orderDetail && orderDetail?.length) {
      setClickActive(orderDetail[0]);
    }
  }, [orderDetail]);

  let newDate = new Date().toISOString();
  const [activeButton, setActiveButton] = useState(() => {
    return localStorage.getItem("orderHistoryTab") || "Active Order";
  });

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
    localStorage.setItem("orderHistoryTab", buttonName);
  };
  document.addEventListener("DOMContentLoaded", function () {
    if (window.location.pathname === "/orderHistory") {
      document.documentElement.classList.add("no-background-image");
    }
  });

  return (
    <>
      <div className={classes.tabBarContainer}>
        <TabBar setState={setState} />
      </div>
      <div className={classes.backgroundImg}>
        <div>
          <div
            style={{
              marginBottom: "1rem",
              cursor: "pointer",
              marginTop: "1rem",
            }}
            onClick={() => {
              dispatch(setTabValue(2));
              let path = `/`;
              navigate(path);
            }}
          >
            <Typography
              sx={{
                color: "#C60734",
                fontSize: { xs: "0.75rem", sm: "1rem", md: "1.5rem" },
                marginLeft: { xs: "-7px", sm: "-8px", md: "0px" },
                marginRight: { xs: "5px", sm: "-10px", md: "0px" },
                marginTop: { xs: "60px", sm: "-8px", md: "50px" },
                marginBottom: "-10px",
              }}
              variant="h5"
            >
              <ArrowBackIosIcon
                sx={{
                  marginLeft: { xs: "15px", sm: "5px", md: "0px" },
                  marginTop: { xs: "-6px", sm: "-8px", md: "-10px" },
                  fontSize: { xs: 12, sm: 16, md: 24 },
                  fontFamily: "Lusitana",
                  marginRight: "-4px",
                  fill: "#C60734",
                }}
              />
              Order History
            </Typography>
          </div>
          <Container
            sx={{
              backgroundImage: `url("backgrounds.png")`,
              backgroundColor: "white",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              height: "80vh",
              width: { xs: "95vw", sm: "95vw", md: "80vw", lg: "80vw" },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "10px",
              overflow: "auto",
              marginBottom: "50px",
              "&::-webkit-scrollbar": {
                width: "4px",
              },
              "&::-webkit-scrollbar-track": {
                background: "#f1f1f1",
                borderRadius: "10px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#C60734",
                borderRadius: "10px",
                maxHeight: "50px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#555",
              },
            }}
          >
            {
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                  backgroundColor: "white",
                  padding: "1rem 0",
                }}
              >
                <div className={classes.mainButton}>
                  <button
                    onClick={() => handleButtonClick("Active Order")}
                    className={classes.activeButton}
                    style={{
                      backgroundColor:
                        activeButton === "Active Order" ? "#C60734" : "white",
                      color:
                        activeButton === "Active Order" ? "white" : "black",
                      fontFamily: "Lusitana",
                    }}
                  >
                    Active Order
                  </button>
                  <button
                    onClick={() => handleButtonClick("Previous Order")}
                    className={classes.previousButton}
                    style={{
                      backgroundColor:
                        activeButton === "Previous Order" ? "#C60734" : "white",
                      color:
                        activeButton === "Previous Order" ? "white" : "black",
                      fontFamily: "Lusitana",
                    }}
                  >
                    Previous Order
                  </button>
                </div>
              </div>
            }
            <Grid
              container
              spacing={2}
              style={{ height: "80%", marginTop: "1rem", paddingRight: "1rem" }}
            >
              <Grid item md={12} xs={12} sm={12} lg={12}>
                {activeButton === "Active Order" && (
                  <div>
                    {loading ? <LinearProgress color="warning" /> : ""}
                    {orderDetail?.length === 0 ? (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "60vh", // Ensure full height for vertical centering
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#C60734",
                            fontSize: { xs: "1.5rem", sm: "2rem" },
                            fontWeight: "bold",
                            fontFamily: "Lusitana",
                            textAlign: "center",
                          }}
                        >
                          No Active Orders Available
                        </Typography>
                      </Box>
                    ) : (
                      <div>
                        {orderDetail?.map((e, index) => {
                          return (
                            <>
                              <Card
                                sx={{
                                  cursor: "default",
                                  borderRadius: "0.5rem",
                                  marginBottom: "0.5rem",
                                  border: "2px solid lightgray",
                                  marginLeft: "15px",
                                }}
                              >
                                <div
                                  className={classes.spaceBetween}
                                  style={{
                                    backgroundColor: "#D9D9D9",
                                    padding: "0.3rem 0.7rem",
                                  }}
                                >
                                  <Typography
                                    gutterBottom
                                    fontWeight="bold"
                                    fontFamily="Lusitana"
                                    margin="5px"
                                    sx={{
                                      fontSize: {
                                        xs: "0.75rem",
                                        sm: "0.875rem",
                                        md: "1rem",
                                      },
                                    }}
                                  >
                                    {uiDate(e?.pickup_date)}
                                  </Typography>
                                  <Typography
                                    gutterBottom
                                    fontWeight="bold"
                                    fontFamily="Lusitana"
                                    margin="5px"
                                    sx={{
                                      fontSize: {
                                        xs: "0.75rem",
                                        sm: "0.875rem",
                                        md: "1rem",
                                      },
                                    }}
                                  >
                                    # {e?.custom_order_number}
                                  </Typography>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "1rem 0.35rem",
                                    justifyContent: "space-between",
                                    cursor: "pointer",
                                  }}
                                  onClick={(event) => {
                                    // This will toggle the box expand state
                                    event.stopPropagation(); // Prevent event propagation from child elements
                                    toggleOrderDetails(e?.custom_order_number);
                                    handleActiveOrder(e);
                                    dispatch(setClickActiveOrders(false));
                                    dispatch(setCheckActive(true));
                                  }}
                                >
                                  <Box
                                    sx={{
                                      width: {
                                        lg: "70%",
                                        md: "70%",
                                        sm: "90%",
                                        xs: "90%",
                                      },
                                      marginLeft: {
                                        lg: "9rem",
                                        md: "8rem",
                                        sm: "2rem",
                                        xs: "0",
                                      },
                                      marginLeft: "1rem",
                                    }}
                                  >
                                    {!expandedOrders[e?.custom_order_number] ? (
                                      <div style={{ fontWeight: "bold" }}>
                                        <Typography
                                          sx={{
                                            fontSize: {
                                              xs: "0.85rem",
                                              sm: "0.875rem",
                                              md: "1rem",
                                            },
                                          }}
                                          style={{
                                            fontWeight: "bold",
                                            fontFamily: "Lusitana",
                                          }}
                                        >
                                          {e?.ordermenu?.length}{" "}
                                          {e?.ordermenu?.length > 1
                                            ? "Items"
                                            : "Item"}
                                        </Typography>
                                        <Container>
                                          {e?.ordermenu?.map((elm) => (
                                            <div style={{ display: "flex", alignItems: "flex-start" }}>
                                              <span
                                                style={{
                                                  marginRight: "4px",
                                                  alignSelf: "flex-start", // Aligns bullet to the top
                                                  lineHeight: "1", // Ensures the bullet doesn't get vertically centered
                                                  display: "inline-block",
                                                }}
                                              >
                                                •
                                              </span>
                                              <Typography
                                                sx={{
                                                  fontSize: {
                                                    xs: "0.75rem",
                                                    sm: "0.875rem",
                                                    md: "1rem",
                                                  },
                                                }}
                                              >
                                                {elm?.menu_name}
                                              </Typography>
                                            </div>

                                          ))}
                                        </Container>
                                      </div>
                                    ) : (
                                      ""
                                    )}

                                    <Box
                                      sx={{
                                        left: 0,
                                        right: 0,
                                        top: "100%",
                                        backgroundColor: "white",
                                        zIndex: 1,
                                        display: expandedOrders[
                                          e?.custom_order_number
                                        ]
                                          ? "block"
                                          : "none",
                                      }}
                                    >
                                      {expandedOrders[
                                        e?.custom_order_number
                                      ] && ( // Corrected this line
                                          <>
                                            <div
                                              className={classes.location}
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  flex: 1,
                                                  marginBottom: 5,
                                                  marginLeft: "2px",
                                                }}
                                              >
                                                <LocationOnIcon
                                                  style={{ fill: "#C60734" }}
                                                  sx={{
                                                    marginLeft: {
                                                      xs: "-10px",
                                                      sm: "4px",
                                                      md: "2px",
                                                    },
                                                  }}
                                                />
                                                <Typography
                                                  fontWeight="bold"
                                                  sx={{
                                                    marginRight: {
                                                      xs: "5px",
                                                      sm: "1rem",
                                                      md: "1rem",
                                                    },
                                                    marginLeft: {
                                                      sm: "5px",
                                                      md: "10px",
                                                    },
                                                    fontSize: {
                                                      xs: "0.75rem",
                                                      sm: "0.875rem",
                                                      md: "1rem",
                                                    },
                                                  }}
                                                  fontFamily="Lusitana"
                                                >
                                                  {`${(e?.store_address).replace(
                                                    "~",
                                                    " "
                                                  )} ${e?.zipCode}`}
                                                </Typography>
                                                <Button
                                                  variant="contained"
                                                  onClick={(event) => {
                                                    event.stopPropagation();
                                                    handleMapClick(
                                                      e?.latitude,
                                                      e?.longitude
                                                    );
                                                  }}
                                                  sx={{
                                                    backgroundColor: "#C60734",
                                                    color: "white",
                                                    padding: {
                                                      xs: "1px 6px",
                                                      sm: "2px 6px",
                                                      md: "2px 8px",
                                                    },
                                                    minWidth: "auto",
                                                    fontSize: {
                                                      xs: "0.625rem",
                                                      sm: "0.75rem",
                                                      md: "0.875rem",
                                                    },
                                                    marginRight: {
                                                      xs: "15px",
                                                      sm: "-5px",
                                                      md: "0px",
                                                    },
                                                    "&:hover": {
                                                      backgroundColor: "#9c052a",
                                                    },
                                                    fontFamily: "Lusitana",
                                                  }}
                                                >
                                                  MAP
                                                </Button>
                                              </div>
                                            </div>
                                            {e?.mobile_number && (
                                              <div className={classes.location}>
                                                <CallIcon
                                                  style={{
                                                    fill: "#C60734",
                                                    marginRight: "5px",
                                                  }}
                                                  sx={{
                                                    marginLeft: {
                                                      xs: "-4px",
                                                      sm: "5px",
                                                      md: "6px",
                                                    },
                                                  }}
                                                />
                                                <a
                                                  href={`tel:${e?.mobile_number}`}
                                                >
                                                  {" "}
                                                  {`${e?.mobile_number}`}
                                                </a>
                                              </div>
                                            )}
                                          </>
                                        )}
                                    </Box>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      // paddingTop: "0.5rem"
                                    }}
                                  >
                                    {expandedOrders[e?.custom_order_number] ? (
                                      <KeyboardArrowUpIcon
                                        sx={{ cursor: "pointer" }}
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          toggleOrderDetails(
                                            e?.custom_order_number
                                          );
                                          handleActiveOrder(e);
                                          dispatch(setClickActiveOrders(false));
                                          dispatch(setCheckActive(true));
                                        }}
                                      />
                                    ) : (
                                      <KeyboardArrowDownIcon
                                        sx={{ cursor: "pointer" }}
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          toggleOrderDetails(
                                            e?.custom_order_number
                                          );
                                          handleActiveOrder(e);
                                          dispatch(setClickActiveOrders(false));
                                          dispatch(setCheckActive(true));
                                        }}
                                      />
                                    )}
                                  </Box>
                                </div>
                                <Divider
                                  sx={{ borderBottomWidth: 2, width: "100%" }}
                                />

                                {!expandedOrders[e?.custom_order_number] ? (
                                  <Box
                                    sx={{
                                      padding: {
                                        lg: "0.7rem 0.5rem",
                                        xs: "0.7rem 0.5rem",
                                      },
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <AccessTimeIcon
                                      sx={{
                                        fill: "#C60734",
                                        fontSize: {
                                          xs: "1rem",
                                          sm: "1.25rem",
                                          md: "1.5rem",
                                        },
                                      }}
                                    />

                                    {e?.order_status &&
                                      e?.order_status === "pending" &&
                                      e?.pickup_type &&
                                      e?.pickup_type === "asap" && (
                                        <Typography
                                          color="#C60734"
                                          sx={{
                                            fontSize: {
                                              xs: "0.75rem",
                                              sm: "0.875rem",
                                              md: "1rem",
                                            },
                                          }}
                                        >
                                          Your order has not been accepted yet
                                        </Typography>
                                      )}
                                    {e?.order_status &&
                                      e?.order_status === "preparing" && (
                                        <Typography
                                          color="#C60734"
                                          sx={{
                                            fontSize: {
                                              xs: "0.75rem",
                                              sm: "0.875rem",
                                              md: "1rem",
                                            },
                                          }}
                                        >
                                          Your order will be ready in {e?.timer}{" "}
                                          min
                                        </Typography>
                                      )}
                                    {e?.order_status &&
                                      e?.order_status === "ready" && (
                                        <Typography
                                          color="#C60734"
                                          sx={{
                                            fontSize: {
                                              xs: "0.75rem",
                                              sm: "0.875rem",
                                              md: "1rem",
                                            },
                                          }}
                                        >
                                          Your order is ready
                                        </Typography>
                                      )}
                                    {e?.order_status &&
                                      e?.order_status === "pending" &&
                                      e?.pickup_type &&
                                      e?.pickup_type === "later" && (
                                        <Typography
                                          color="#C60734"
                                          sx={{
                                            fontSize: {
                                              xs: "0.75rem",
                                              sm: "0.875rem",
                                              md: "1rem",
                                            },
                                          }}
                                        >
                                          Your order is scheduled for pick up at{" "}
                                          {AmPmFormat(
                                            pickupTime(e?.schedule_time)
                                          )}
                                        </Typography>
                                      )}
                                    {e?.order_status &&
                                      e?.order_status === "new" &&
                                      e?.pickup_type &&
                                      e?.pickup_type === "later" && (
                                        <Typography
                                          color="#C60734"
                                          sx={{
                                            fontSize: {
                                              xs: "0.75rem",
                                              sm: "0.875rem",
                                              md: "1rem",
                                            },
                                          }}
                                        >
                                          Your order has been accepted and will
                                          be ready by{" "}
                                          {AmPmFormat(
                                            pickupTime(e?.schedule_time)
                                          )}
                                        </Typography>
                                      )}
                                  </Box>
                                ) : (
                                  ""
                                )}

                                {expandedOrders[e?.custom_order_number] && (
                                  <Box>
                                    <Container>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <Typography
                                          gutterBottom
                                          marginLeft="7px"
                                          sx={{
                                            fontSize: {
                                              xs: "0.75rem",
                                              sm: "0.875rem",
                                              md: "1rem",
                                            },
                                          }}
                                        >
                                          <br></br>
                                          Total Items: {e?.ordermenu?.length}
                                        </Typography>
                                        {/* <Typography
                                        gutterBottom
                                        sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem', md: '1rem' } }}
                                      >
                                        Order No: {e?.custom_order_number}
                                      </Typography> */}
                                      </Box>

                                      {e?.ordermenu?.map((elm) => {
                                        return <div style={{marginTop:".8rem"}}><OrderItem orderDetail={elm}/></div>;
                                      })}

                                      <Divider
                                        sx={{
                                          marginTop: "0.5rem",
                                          borderBottomWidth: 2,
                                          marginLeft: "-25px",
                                          marginRight: "-20px",
                                          width: "calc(100% + 50px)",
                                        }}
                                      />
                                      <br></br>
                                      <Grid container justifyContent="flex-end">
                                        <Grid
                                          item
                                          lg={6}
                                          md={6}
                                          sm={12}
                                          xs={12}
                                        >
                                          <Box
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Typography
                                              variant="subtitle1"
                                              gutterBottom
                                              sx={{
                                                fontSize: {
                                                  xs: "0.75rem",
                                                  sm: "0.875rem",
                                                  md: "1rem",
                                                },
                                                fontFamily: "Lusitana",
                                                color: "#969696",
                                                marginLeft: { md: "-15px" },
                                              }}
                                            >
                                              Sub-Total:
                                            </Typography>
                                            <Typography
                                              variant="subtitle1"
                                              gutterBottom
                                              sx={{
                                                fontSize: {
                                                  xs: "0.75rem",
                                                  sm: "0.875rem",
                                                  md: "1rem",
                                                },
                                                fontFamily: "Lusitana",
                                                color: "#969696",
                                              }}
                                            >{`$${Number(e?.subtotal).toFixed(2)
                                              ? Number(e?.subtotal).toFixed(2)
                                              : 0.0
                                              }`}</Typography>
                                          </Box>
                                          <Box
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Typography
                                              variant="subtitle1"
                                              gutterBottom
                                              sx={{
                                                fontSize: {
                                                  xs: "0.75rem",
                                                  sm: "0.875rem",
                                                  md: "1rem",
                                                },
                                                fontFamily: "Lusitana",
                                                color: "#969696",
                                                marginLeft: { md: "-15px" },
                                              }}
                                            >
                                              Tax:
                                            </Typography>
                                            <Typography
                                              variant="subtitle1"
                                              gutterBottom
                                              sx={{
                                                fontSize: {
                                                  xs: "0.75rem",
                                                  sm: "0.875rem",
                                                  md: "1rem",
                                                },
                                                fontFamily: "Lusitana",
                                                color: "#969696",
                                              }}
                                            >{`$${Number(e?.tax_value).toFixed(2)
                                              ? Number(e?.tax_value).toFixed(
                                                2
                                              )
                                              : 0.0
                                              }`}</Typography>
                                          </Box>
                                          <Box
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Typography
                                              variant="subtitle1"
                                              gutterBottom
                                              sx={{
                                                fontSize: {
                                                  xs: "0.75rem",
                                                  sm: "0.875rem",
                                                  md: "1rem",
                                                },
                                                fontFamily: "Lusitana",
                                                color: "#969696",
                                                marginLeft: { md: "-15px" },
                                              }}
                                            >
                                              Discount:
                                            </Typography>
                                            <Typography
                                              variant="subtitle1"
                                              gutterBottom
                                              sx={{
                                                fontSize: {
                                                  xs: "0.75rem",
                                                  sm: "0.875rem",
                                                  md: "1rem",
                                                },
                                                color: "#969696",
                                              }}
                                            >{`-$${e?.discount_total_price &&
                                              e?.loyalty_value
                                              ? Number(
                                                e?.discount_total_price +
                                                e?.loyalty_value
                                              ).toFixed(2)
                                              : e?.discount_total_price
                                                ? Number(
                                                  e?.discount_total_price
                                                ).toFixed(2)
                                                : e?.loyalty_value
                                                  ? Number(
                                                    e?.loyalty_value
                                                  ).toFixed(2)
                                                  : "0.00"
                                              }`}</Typography>
                                          </Box>
                                          <Divider
                                            sx={{
                                              marginTop: "1vh",
                                              marginBottom: "1vh",
                                              borderBottomWidth: 2,
                                              marginLeft: { md: "-20px" },
                                              width: "calc(100% + 20px)",
                                            }}
                                          />
                                          <Box
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Typography
                                              variant="subtitle1"
                                              gutterBottom
                                              sx={{
                                                fontSize: {
                                                  xs: "0.75rem",
                                                  sm: "0.875rem",
                                                  md: "1rem",
                                                },
                                                marginLeft: { md: "-15px" },
                                              }}
                                            >
                                              Total:
                                            </Typography>
                                            <Typography
                                              variant="subtitle1"
                                              gutterBottom
                                              sx={{
                                                fontSize: {
                                                  xs: "0.75rem",
                                                  sm: "0.875rem",
                                                  md: "1rem",
                                                },
                                                fontFamily: "Lusitana",
                                              }}
                                            >{`$${Number(
                                              e?.order_total_price
                                            ).toFixed(2)
                                              ? Number(
                                                e?.order_total_price
                                              ).toFixed(2)
                                              : 0.0
                                              }`}</Typography>
                                          </Box>
                                          <Divider
                                            sx={{
                                              marginTop: "1vh",
                                              marginBottom: "1vh",
                                              borderBottomWidth: 2,
                                              marginLeft: { md: "-20px" },
                                              width: "calc(100% + 20px)",
                                            }}
                                          />
                                        </Grid>
                                      </Grid>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "right",
                                          alignItems: "center",
                                          marginBottom: "0rem",
                                        }}
                                      >
                                        <Box
                                          component="img"
                                          sx={{
                                            width: "3.3rem",
                                            marginTop: "0.2rem",
                                            verticalAlign: "middle",
                                          }}
                                          src={e?.imageUrl}
                                        />
                                        {e?.card_last_no ? (
                                          <>
                                          {Number(e?.order_total_price).toFixed(2) == 0?'':
                                          <Typography
                                            sx={{
                                              pl: "1rem",
                                              fontSize: {
                                                xs: "0.75rem",
                                                sm: "0.875rem",
                                                md: "1rem",
                                              },
                                            }}
                                          >{` ****${e?.card_last_no} `}</Typography>}
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <Typography
                                        sx={{
                                          marginTop: ".5rem",
                                          marginBottom: "1rem",
                                          marginRight: "5px",
                                          textAlign: "right",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "flex-end"
                                        }}
                                      >
                                        {e?.accumulated_points > 0 && (
                                          <>
                                            <img
                                              src={giftIcon}
                                              alt="gift icon"
                                              style={{
                                                marginRight: "5px",
                                                height: "25px",
                                                marginTop: "-10px",
                                              }}
                                            />
                                            <Typography sx={{
                                              fontSize: {
                                                xs: "0.75rem",
                                                sm: "0.875rem",
                                                md: "1rem",
                                              }
                                            }}>{`You earned ${e?.accumulated_points} points on this order`}
                                            </Typography>
                                          </>
                                        )}
                                      </Typography>
                                    </Container>
                                  </Box>
                                )}
                              </Card>
                            </>
                          );
                        })}
                      </div>
                    )}
                  </div>
                )}

                {activeButton === "Previous Order" && (
                  <div>
                    {previousLoading ? <LinearProgress color="warning" /> : ""}
                    {pastOrderDetail?.length === 0 ? (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "60vh", // Ensure full height for vertical centering
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#C60734",
                            fontSize: { xs: "1.5rem", sm: "2rem" },
                            fontWeight: "bold",
                            fontFamily: "Lusitana",
                            textAlign: "center",
                          }}
                        >
                          No Previous Orders Available
                        </Typography>
                      </Box>
                    ) : (
                      <div>
                        {pastOrderDetail?.map((e) => (
                          <React.Fragment key={e?.custom_order_number}>
                            <Card
                              sx={{
                                cursor: "default",
                                borderRadius: "0.5rem",
                                marginBottom: "0.5rem",
                                border: "2px solid lightgray",
                                marginLeft: "15px",
                              }}
                            >
                              <div
                                className={classes.spaceBetween}
                                style={{
                                  backgroundColor: "#D9D9D9",
                                  padding: "0.3rem 0.7rem",
                                }}
                              >
                                <Typography
                                  gutterBottom
                                  fontWeight="bold"
                                  fontFamily="Lusitana"
                                  margin="5px"
                                  sx={{
                                    fontSize: {
                                      xs: "0.75rem",
                                      sm: "0.875rem",
                                      md: "1rem",
                                    },
                                  }}
                                >
                                  {uiDate(e?.pickup_date)}
                                </Typography>
                                <Typography
                                  gutterBottom
                                  fontWeight="bold"
                                  fontFamily="Lusitana"
                                  margin="5px"
                                  sx={{
                                    fontSize: {
                                      xs: "0.75rem",
                                      sm: "0.875rem",
                                      md: "1rem",
                                    },
                                  }}
                                >
                                  # {e?.custom_order_number}
                                </Typography>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  padding: "1rem 0.3rem",
                                  justifyContent: "space-between",
                                  cursor: "pointer",
                                }}
                                onClick={(event) => {
                                  // This will toggle the box expand state
                                  event.stopPropagation(); // Prevent event propagation from child elements
                                  toggleOrderDetails(e?.custom_order_number);
                                  handleActiveOrder(e);
                                  dispatch(setClickActiveOrders(false));
                                  dispatch(setCheckActive(true));
                                }}
                              >
                                <Box
                                  sx={{
                                    width: {
                                      lg: "70%",
                                      md: "70%",
                                      sm: "90%",
                                      xs: "90%",
                                    },
                                    marginLeft: {
                                      lg: "9rem",
                                      md: "8rem",
                                      sm: "2rem",
                                      xs: "0",
                                    },
                                    marginLeft: "1rem",
                                  }}
                                >
                                  {!expandedOrders[e?.custom_order_number] ? (
                                    <div style={{ fontWeight: "bold" }}>
                                      <Typography
                                        sx={{
                                          fontSize: {
                                            xs: "0.85rem",
                                            sm: "0.875rem",
                                            md: "1rem",
                                          },
                                        }}
                                        style={{
                                          fontWeight: "bold",
                                          fontFamily: "Lusitana",
                                        }}
                                      >
                                        {e?.ordermenu?.length}{" "}
                                        {e?.ordermenu?.length > 1
                                          ? "Items"
                                          : "Item"}
                                      </Typography>
                                      <Container>
                                        {e?.ordermenu?.map((elm) => (
                                          <div style={{ display: "flex", alignItems: "flex-start" }}>
                                            <span
                                              style={{
                                                marginRight: "4px",
                                                alignSelf: "flex-start", // Aligns bullet to the top
                                                lineHeight: "1", // Ensures the bullet doesn't get vertically centered
                                                display: "inline-block",
                                              }}
                                            >
                                              •
                                            </span>
                                            <Typography
                                              sx={{
                                                fontSize: {
                                                  xs: "0.75rem",
                                                  sm: "0.875rem",
                                                  md: "1rem",
                                                },
                                              }}
                                            >
                                              {elm?.menu_name}
                                            </Typography>
                                          </div>
                                        ))}
                                      </Container>
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  <Box
                                    sx={{
                                      left: 0,
                                      right: 0,
                                      top: "100%",
                                      backgroundColor: "white",
                                      zIndex: 1,
                                      display: expandedOrders[
                                        e?.custom_order_number
                                      ]
                                        ? "block"
                                        : "none",
                                    }}
                                  >
                                    {expandedOrders[e?.custom_order_number] && (
                                      <>
                                        <div
                                          className={classes.location}
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              flex: 1,
                                              marginBottom: 5,
                                              marginLeft: "2px",
                                            }}
                                          >
                                            <LocationOnIcon
                                              style={{ fill: "#C60734" }}
                                              sx={{
                                                marginLeft: {
                                                  xs: "-9px",
                                                  sm: "5px",
                                                  md: "2px",
                                                },
                                                marginRight: {
                                                  xs: "5px",
                                                  md: "6px",
                                                },
                                              }}
                                            />
                                            <Typography
                                              fontWeight="bold"
                                              sx={{
                                                marginRight: {
                                                  xs: "0px",
                                                  sm: ".5rem",
                                                  md: "1rem",
                                                },
                                                fontSize: {
                                                  xs: "0.75rem",
                                                  sm: "0.875rem",
                                                  md: "1rem",
                                                },
                                              }}
                                              fontFamily="Lusitana"
                                            >
                                              {`${(e?.store_address).replace(
                                                "~",
                                                " "
                                              )} ${e?.zipCode}`}
                                            </Typography>
                                            <Button
                                              variant="contained"
                                              onClick={(event) => {
                                                event.stopPropagation();
                                                handleMapClick(
                                                  e?.latitude,
                                                  e?.longitude
                                                );
                                              }}
                                              sx={{
                                                backgroundColor: "#C60734",
                                                color: "white",
                                                padding: {
                                                  xs: "1px 6px",
                                                  sm: "2px 6px",
                                                  md: "2px 8px",
                                                },
                                                minWidth: "auto",
                                                fontSize: {
                                                  xs: "0.625rem",
                                                  sm: "0.75rem",
                                                  md: "0.875rem",
                                                },
                                                marginRight: {
                                                  xs: "15px",
                                                  sm: "-5px",
                                                  md: "0px",
                                                },
                                                "&:hover": {
                                                  backgroundColor: "#9c052a",
                                                },
                                                fontFamily: "Lusitana",
                                              }}
                                            >
                                              MAP
                                            </Button>
                                          </div>
                                        </div>

                                        {e?.mobile_number && (
                                          <div className={classes.location}>
                                            <CallIcon
                                              style={{
                                                fill: "#C60734",
                                                marginRight: "5px",
                                              }}
                                              sx={{
                                                marginLeft: {
                                                  xs: "-3px",
                                                  sm: "5px",
                                                  md: "6px",
                                                },
                                              }}
                                            />
                                            <a href={`tel:${e?.mobile_number}`}>
                                              {" "}
                                              {`${e?.mobile_number}`}
                                            </a>
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </Box>
                                </Box>

                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  {expandedOrders[e?.custom_order_number] ? (
                                    <KeyboardArrowUpIcon
                                      sx={{ cursor: "pointer" }}
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        toggleOrderDetails(
                                          e?.custom_order_number
                                        );
                                        handleActiveOrder(e);
                                        dispatch(setClickActiveOrders(false));
                                        dispatch(setCheckActive(true));
                                      }}
                                    />
                                  ) : (
                                    <KeyboardArrowDownIcon
                                      sx={{ cursor: "pointer" }}
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        toggleOrderDetails(
                                          e?.custom_order_number
                                        );
                                        handleActiveOrder(e);
                                        dispatch(setClickActiveOrders(false));
                                        dispatch(setCheckActive(true));
                                      }}
                                    />
                                  )}
                                </Box>
                              </div>

                              <Divider
                                sx={{ borderBottomWidth: 2, width: "100%" }}
                              />

                              {!expandedOrders[e?.custom_order_number] ? (
                                <Box
                                  sx={{
                                    padding: {
                                      lg: "0.7rem 0.5rem",
                                      xs: "0.7rem 0.5rem",
                                    },
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <AccessTimeIcon
                                    sx={{
                                      fill: "#C60734",
                                      fontSize: {
                                        xs: "1rem",
                                        sm: "1.25rem",
                                        md: "1.5rem",
                                      },
                                    }}
                                  />
                                  <Typography
                                    color="#C60734"
                                    sx={{
                                      fontSize: {
                                        xs: "0.75rem",
                                        sm: "0.875rem",
                                        md: "1rem",
                                      },
                                    }}
                                  >
                                    Your order is ready for pick up
                                  </Typography>
                                </Box>
                              ) : (
                                ""
                              )}

                              {expandedOrders[e?.custom_order_number] && (
                                <Box>
                                  <Container>
                                    <Container>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          marginLeft: "-1.2rem",
                                        }}
                                      >
                                        <Typography
                                          gutterBottom
                                          sx={{
                                            fontSize: {
                                              xs: "0.75rem",
                                              sm: "0.875rem",
                                              md: "1rem",
                                            },
                                            marginLeft: {
                                              xs: "10px",
                                              sm: "2px ",
                                              md: "2px",
                                            },
                                          }}
                                        >
                                          <br></br>
                                          Total Items: {e?.ordermenu?.length}
                                        </Typography>
                                      </Box>

                                      <Box
                                        sx={{
                                          marginLeft: {
                                            xs: "-16px",
                                            sm: "-25px",
                                            md: "-25px",
                                          },
                                          marginRight: {
                                            md: "-25px",
                                            sm: "-25px",
                                            xs: "-15px",
                                          },
                                        }}
                                      >
                                        {e?.ordermenu?.map((elm) => {
                                          return (
                                            <OrderItem orderDetail={elm} />
                                          );
                                        })}
                                      </Box>
                                      <Divider
                                        sx={{
                                          marginTop: "0.5rem",
                                          borderBottomWidth: 2,
                                          marginLeft: "-45px",
                                          marginRight: "-45px",
                                          width: "calc(100% + 90px)",
                                        }}
                                      />

                                      <br></br>

                                      <Grid container justifyContent="flex-end">
                                        <Grid
                                          item
                                          lg={6}
                                          md={6}
                                          sm={12}
                                          xs={12}
                                        >
                                          <Box
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Typography
                                              variant="subtitle1"
                                              gutterBottom
                                              sx={{
                                                fontSize: {
                                                  xs: "0.75rem",
                                                  sm: "0.875rem",
                                                  md: "1rem",
                                                },
                                                marginLeft: {
                                                  md: "-15px",
                                                  sm: "-25px",
                                                },
                                                fontFamily: "Lusitana",

                                                color: "#969696",
                                              }}
                                            >
                                              Sub-Total:
                                            </Typography>
                                            <Typography
                                              variant="subtitle1"
                                              gutterBottom
                                              sx={{
                                                fontSize: {
                                                  xs: "0.75rem",
                                                  sm: "0.875rem",
                                                  md: "1rem",
                                                },
                                                fontFamily: "Lusitana",
                                                color: "#969696",
                                                marginRight: {
                                                  md: "-25px",
                                                  sm: "-25px",
                                                  xs: "-15px",
                                                },
                                              }}
                                            >
                                              {`$${Number(e?.subtotal).toFixed(
                                                2
                                              ) || 0.0
                                                }`}
                                            </Typography>
                                          </Box>
                                          <Box
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Typography
                                              variant="subtitle1"
                                              gutterBottom
                                              sx={{
                                                fontSize: {
                                                  xs: "0.75rem",
                                                  sm: "0.875rem",
                                                  md: "1rem",
                                                },
                                                marginLeft: {
                                                  md: "-15px",
                                                  sm: "-25px",
                                                },
                                                fontFamily: "Lusitana",

                                                color: "#969696",
                                              }}
                                            >
                                              Tax:
                                            </Typography>
                                            <Typography
                                              variant="subtitle1"
                                              gutterBottom
                                              sx={{
                                                fontSize: {
                                                  xs: "0.75rem",
                                                  sm: "0.875rem",
                                                  md: "1rem",
                                                },
                                                color: "#969696",
                                                marginRight: "-10px",
                                                fontFamily: "Lusitana",
                                                marginRight: {
                                                  md: "-25px",
                                                  sm: "-25px",
                                                  xs: "-15px",
                                                },
                                              }}
                                            >
                                              {`$${Number(e?.tax_value).toFixed(
                                                2
                                              ) || 0.0
                                                }`}
                                            </Typography>
                                          </Box>
                                          <Box
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Typography
                                              variant="subtitle1"
                                              gutterBottom
                                              sx={{
                                                fontSize: {
                                                  xs: "0.75rem",
                                                  sm: "0.875rem",
                                                  md: "1rem",
                                                },
                                                marginLeft: {
                                                  md: "-15px",
                                                  sm: "-25px",
                                                },
                                                fontFamily: "Lusitana",

                                                color: "#969696",
                                              }}
                                            >
                                              Discount:
                                            </Typography>
                                            <Typography
                                              variant="subtitle1"
                                              gutterBottom
                                              sx={{
                                                fontSize: {
                                                  xs: "0.75rem",
                                                  sm: "0.875rem",
                                                  md: "1rem",
                                                },
                                                color: "#969696",
                                                marginRight: "-10px",
                                                fontFamily: "Lusitana",
                                                marginRight: {
                                                  md: "-25px",
                                                  sm: "-25px",
                                                  xs: "-15px",
                                                },
                                              }}
                                            >
                                              {`-$${(
                                                e?.discount_total_price +
                                                e?.loyalty_value
                                              )?.toFixed(2) || "0.00"
                                                }`}
                                            </Typography>
                                          </Box>

                                          <Divider
                                            style={{
                                              marginTop: "1vh",
                                              marginBottom: "1vh",
                                              borderBottomWidth: 2,
                                              width: "calc( 100% + 60px)",
                                              marginLeft: "-25px",
                                            }}
                                          />
                                          <Box
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Typography
                                              variant="subtitle1"
                                              gutterBottom
                                              sx={{
                                                fontSize: {
                                                  xs: "0.75rem",
                                                  sm: "0.875rem",
                                                  md: "1rem",
                                                },
                                                marginLeft: {
                                                  md: "-15px",
                                                  sm: "-25px",
                                                },
                                                fontFamily: "Lusitana",
                                              }}
                                            >
                                              Total:
                                            </Typography>
                                            <Typography
                                              variant="subtitle1"
                                              gutterBottom
                                              sx={{
                                                fontSize: {
                                                  xs: "0.75rem",
                                                  sm: "0.875rem",
                                                  md: "1rem",
                                                },
                                                fontFamily: "Lusitana",
                                                marginRight: "-10px",
                                                marginRight: {
                                                  md: "-25px",
                                                  sm: "-25px",
                                                  xs: "-15px",
                                                },
                                              }}
                                            >
                                              {`$${Number(
                                                e?.order_total_price
                                              ).toFixed(2) || 0.0
                                                }`}
                                            </Typography>
                                          </Box>
                                          <Divider
                                            style={{
                                              marginTop: "1vh",
                                              marginBottom: "1vh",
                                              borderBottomWidth: 2,
                                              width: "calc( 100% + 60px)",
                                              marginLeft: "-25px",
                                            }}
                                          />
                                        </Grid>
                                      </Grid>

                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "right",
                                          alignItems: "center",
                                          marginBottom: "0rem",
                                          marginRight: "-20px",
                                        }}
                                      >
                                        {e?.imageUrl ? (
                                          <Box
                                            component="img"
                                            sx={{
                                              width: "3.3rem",
                                              marginTop: "0.2rem",
                                              verticalAlign: "middle",
                                            }}
                                            src={e.imageUrl}
                                            alt="Card Logo"
                                          />
                                        ) : (
                                          <Typography
                                            sx={{
                                              pl: "1rem",
                                              fontSize: {
                                                xs: "0.75rem",
                                                sm: "0.875rem",
                                                md: "1rem",
                                              },
                                            }}
                                          >
                                            {" "}
                                          </Typography>
                                        )}

                                        {e?.card_last_no ? (
                                          <>
                                          {Number(e?.order_total_price).toFixed(2) == 0?'':
                                          <Typography
                                            sx={{
                                              pl: "1rem",
                                              fontSize: {
                                                xs: "0.75rem",
                                                sm: "0.875rem",
                                                md: "1rem",
                                              },
                                            }}
                                          >
                                            {` ****${e.card_last_no}`}
                                          </Typography>}
                                          </>
                                        ) : null}
                                      </div>
                                      {/* {e?.accumulated_points > 0 && ( */}
                                      <Typography
                                        sx={{
                                          marginTop: ".5rem",
                                          marginBottom: "1rem",
                                          marginRight: "5px",
                                          textAlign: "right",
                                          display: "flex",
                                          alignItems: "right",
                                          justifyContent: "flex-end",
                                          marginRight: "-20px",
                                        }}
                                      >
                                        {e?.accumulated_points > 0 && (
                                          <>
                                            <img
                                              src={giftIcon}
                                              alt="gift icon"
                                              style={{
                                                marginRight: "5px",
                                                height: "25px",
                                                marginTop: "-10px",
                                              }}
                                            />
                                            <Typography sx={{
                                              fontSize: {
                                                xs: "0.75rem",
                                                sm: "0.875rem",
                                                md: "1rem",
                                              }
                                            }}>{`You earned ${e?.accumulated_points} points on this order`}
                                            </Typography>
                                          </>
                                        )}
                                      </Typography>
                                      {/* )} */}
                                      {e?.order_status != 'completed' ? <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "right",
                                          marginTop: "-30px",
                                          marginRight: "-20px",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            paddingTop: "2px",
                                            paddingBottom: "2px",
                                            paddingLeft: "6px",
                                            paddingRight: "6px",

                                            textAlign: "center",
                                            display: "flex",
                                            borderRadius: "0.5rem",
                                            marginTop: "2rem",
                                            marginBottom: "15px",
                                            fontWeight: "100",
                                            fontSize: {
                                              xs: "0.75rem",
                                              sm: "0.875rem",
                                              md: "1rem",
                                            },
                                            border:
                                              e?.order_status === "completed"
                                                ? "1px solid green"
                                                : "1px solid #C60734",
                                          }}
                                        >
                                          {"Status:\u00A0"}
                                          <span
                                            style={{
                                              color:
                                                e?.order_status === "completed"
                                                  ? "green"
                                                  : e?.order_status ===
                                                    "refunded"
                                                    ? "#C60734"
                                                    : "black",
                                            }}
                                          >
                                            {e?.order_status?.toUpperCase()}
                                          </span>
                                        </Typography>
                                      </Box> : ""}
                                    </Container>
                                  </Container>
                                </Box>
                              )}
                            </Card>
                          </React.Fragment>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    </>
  );
};

export default OrderHistory;
