import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GetAllMenu } from "../../Api/API";
import Card from "../../Common/Card";
import { setOpenPopUps, setTabValue } from "../../Redux/CartSlice";
import Popup from "./CategoryPopUp";
const MenuScreen = ({ setState }) => {
  const dispatch = useDispatch();
  const [menu, setMenu] = useState([]);
  const [loading, setLoading] = useState(false);
  const store = useSelector((state) => state.store.nearestStore);
  const CategoriesSet = useSelector((state) => state.cart.CategoriesSet);
  const valueSet = useSelector((state) => state.cart.valueSet);
  const customizeOpen = useSelector((state) => state.cart.customizeOpen);
  const [initialLoadDone, setInitialLoadDone] = useState(false);
  const [updateBtnFlag, setUpdateBtnFlag] = useState(false)
  const [imageLoaded, setImageLoaded] = useState({}); 
  const [initialLoading, setInitialLoading] = useState(false); 
  let navigate = useNavigate();

  useEffect(() => {
    if (menu?.length > 0) {
      menu.forEach(item => {
        const img = new Image();
        img.onload = () => {
          setImageLoaded(prev => ({
            ...prev,
            [item.id]: true
          }));
        };
        img.src = item?.image || "./logos.png";
      });
    }
  }, [menu]);


  const handleLeftArrow = () => {
    if (customizeOpen) {
      dispatch(setOpenPopUps(false));
    }
    dispatch(setTabValue(2));
    window.location.replace("/");
  };

  useEffect(() => {
    if (!store?.id && !CategoriesSet?.length) {
      dispatch(setTabValue(2));
      navigate("/");
    }
  }, [store]);

  useEffect(() => {
    const handlePopState = () => {
      handleLeftArrow();
    };
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [customizeOpen]);

  useEffect(() => {
    if (CategoriesSet?.length > 0 && !initialLoadDone) {
      let data = {
        requestDate: "",
        categoryId: CategoriesSet[valueSet]?.category_id,
        storeId: store?.id ? store?.id : 1,
        appType: "web",
        customerId: 0,
      };
      setInitialLoading(true);
      return GetAllMenu(data)
        .then((res) => {
          if (res.result?.length > 0) {
            res.result.forEach(item => {
              const img = new Image();
              img.onload = () => {
                setImageLoaded(prev => ({
                  ...prev,
                  [item.id]: true
                }));
              };
              img.src = item?.image || "./logos.png";
            });
          }
          setInitialLoading(false);
          setMenu(res.result);
          setInitialLoadDone(true);
        })
        .catch((err) => {
          setInitialLoading(false);
          console.log(err);
        });
    }
    dispatch(setTabValue(2));
  }, [CategoriesSet, store, valueSet]);

  useEffect(() => {
    setInitialLoadDone(false);
  }, [valueSet, CategoriesSet]);

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: 1000 }} open={initialLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        sx={{
          paddingLeft: { lg: "13vw", xs: "5vw" },
          paddingRight: { lg: "13vw", xs: "5vw" },
          paddingBottom: { lg: "3vh", xs: "69px" },
          paddingTop: "2vh",
        }}
      >
        <div style={{ display: "flex", padding: "1rem 0rem" }}>
          <div
            style={{ display: "flex", cursor: "pointer" }}
            onClick={() => {
              dispatch(setTabValue(2));
              let path = `/`;
              navigate(path);
            }}
          >
            <ArrowBackIosIcon style={{ fill: "#C60734" }} />
            <Typography sx={{ color: "#C60734" }} variant="h5">
              {CategoriesSet[valueSet]?.category_name}
            </Typography>
          </div>
        </div>
        <Grid container spacing={3}>
          {menu?.map((e) => {
            return (
              <Grid item xs={12} sm={6} md={6} lg={6} key={e?.id}>
                <Card
                  key={e?.id}
                  props={{
                    ...e,
                    imageLoaded: imageLoaded[e.id]  // Pass loading state to Card
                  }}
                  fav={false}
                  categoryId={CategoriesSet[valueSet]?.category_id}
                  categoryName={CategoriesSet[valueSet]?.category_name}
                  setLoading={setLoading}
                  setState={setState}
                  title="MenuPage"
                />
              </Grid>
            );
          })}
        </Grid>
        {customizeOpen && (
          <Popup
            customizeOpen={customizeOpen}
            // setCustomizeOpen={setCustomizeOpen}
            setUpdateBtnFlag = {setUpdateBtnFlag}
            updateBtnFlag = {updateBtnFlag}
          />
        )}
      </Box>
    </>
  );
};
export default MenuScreen;
