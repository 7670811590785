import { Box, Button, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AlertPopup from "../../Common/AlertPopup";
import ButtonLoader from "../../Common/ButtonLoader";
import {
  customizeOpen,
  setAdvCheck,
  setCartDetailes,
  setCustomizeOpen,
  setEditTitles,
  setIsLoading,
  setOpenPopUps,
  setSelectedCupSizes,
  setTabValue,
  setTitles,
  setValueSet,
} from "../../Redux/CartSlice";
import { GetAllCategories, GetAllMenu, getCustomizationOption } from "../../Api/API";
import { toast } from "react-toastify";

const New = ({ deals, setState, setAdv }) => {
  const store = useSelector((state) => state.store.nearestStore);
  const userDetail = useSelector((state) => state.user.user);
  const isLoading = useSelector((state) => state.cart.isLoading); 
  const customizeOpen = useSelector((state) => state.cart.customizeOpen); // Add this selector
  const [openAlert, setOpenAlert] = useState(false);
  const [view, setView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const isProcessing = useRef(false);
  let navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!customizeOpen) {
      setLoading(false);
      setIsButtonDisabled(false); 
      dispatch(setIsLoading(false));
      isProcessing.current = false; 
    }
  }, [customizeOpen, dispatch]);

  const handleOrder = async () => {
    if (isLoading || isButtonDisabled || isProcessing.current) return; 
    
    setIsButtonDisabled(true); 
    
    if (deals?.buttonType === "Order Now") {
      if (!store?.id) {
        setState(true);
        setLoading(false);
        setIsButtonDisabled(false);
        dispatch(setIsLoading(false));
        return;
      }
      isProcessing.current = true; 
      setAdv(deals);
      setLoading(true);
      dispatch(setIsLoading(true));

      try {
        const catData = {
          storeId: store?.id,
          appType: "web",
        };

        const categoriesResponse = await GetAllCategories(catData);
        const categoryExist = categoriesResponse?.result?.filter(
          (element) => Number(element?.category_id) === Number(deals?.categoryId)
        );

        if (!categoryExist?.length) {
          toast.error(`This category is not available for selected store`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
          setIsButtonDisabled(false);
          dispatch(setIsLoading(false));
          return;
        }

        if (deals?.menuId == null) {
          dispatch(setValueSet(categoriesResponse?.result.indexOf(categoryExist[0])));
            dispatch(setTabValue(0));
            navigate(`/menu`);
            window.scrollTo(0, 0);
          setIsButtonDisabled(false);
          return;
        }
        const menuItem = {
              appType: "web",
              categoryId: deals?.categoryId,
              customerId: 0,
              requestDate: "",
              storeId: store?.id,
            };
        const menuResponse = await GetAllMenu(menuItem);
        const menuExist = menuResponse?.result.filter(
          (element) => Number(element?.id) === Number(deals?.menuId)
        );
        if (!menuExist?.length) {
          toast.error(`This menu item is not available for selected store`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
          setIsButtonDisabled(false);
          dispatch(setIsLoading(false));
          return;
        }

        const customizationData = {
                  customerId: userDetail?.customer_id
                    ? userDetail?.customer_id
                    : userDetail?.person_id,
                  menuId: deals?.menuId,
                  storeId: store?.id ? store?.id : 1,
                  appType: "web",
                };
        const customizationResponse = await getCustomizationOption(customizationData);

        const cartDetail = {
                    id: menuExist[0]?.id,
                    price: menuExist[0]?.price,
                    image: menuExist[0]?.image,
                    title: menuExist[0]?.title,
                    sku_code: customizationResponse?.result[0][0]?.sku_code||customizationResponse?.result[0][0]?.skuCode,
                    h_sku_code:customizationResponse?.result[0][0]?.h_sku_code,
                    categoryId: customizationResponse?.result[0][0].category_id,
                    quantity: 1,
                    selectedCupSize: [customizationResponse?.result[0][0]?.menu_serving_detail[0]],
                    is_favourite: customizationResponse?.result[0][0].is_favourite,
                    customizationOpt: customizationResponse?.result[0][0].customizationOpt,
                    categoryName: categoryExist[0]?.category_name,
                    btnModifierId: [],
                    btnModifier: [],
                    custom: [],
                    menuServingDetail: customizationResponse?.result[0][0].menu_serving_detail,
                    cold: customizationResponse?.result[0][0]?.caffeine,
                    caffine: customizationResponse?.result[0][0]?.cold,
                    max_calories: customizationResponse?.result[0][0]?.max_calories,
                    min_calories: customizationResponse?.result[0][0]?.min_calories,
                    description: customizationResponse?.result[0][0]?.description,
                  };
                  let toppingCounterLimit = customizationResponse?.result[0][0]?.customizationOpt?.filter((e)=>e?.modifier_group_name === "Toppings")
                  cartDetail.toppings_counter_limit = toppingCounterLimit?.length>0?toppingCounterLimit[0]?.counter_limit:0
                  dispatch(
                    setSelectedCupSizes(
                      cartDetail?.selectedCupSize?.length > 1
                        ? cartDetail?.selectedCupSize?.filter(
                            (e) => e.tier_id > 0
                          )
                        : cartDetail?.selectedCupSize
                    )
                  );
                  dispatch(setCartDetailes(cartDetail));
                  dispatch(setOpenPopUps(true));
                  dispatch(setTitles(""));
                  dispatch(setEditTitles(""));
                  dispatch(setAdvCheck(true));
                  dispatch(setTabValue(3));
                  dispatch(setCustomizeOpen(true));
        }catch(error)  {
          console.error(error);
        setLoading(false);
        setIsButtonDisabled(false);
        dispatch(setIsLoading(false));
      } finally {
        isProcessing.current = false;  
      }
    } else if (deals?.buttonType === "View Details") {
      setView(!view);
      setIsButtonDisabled(false);
    }
  };
  const handleView = () => {
    setView(false);
  };

  return (
    <>
      <Box
        sx={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: { lg: "row", md: "row", sm: "row", xs: "column" },
        }}
      >
        <Box
          sx={{
            width: { lg: "23rem", xs: "20rem" },
            height: { lg: "23rem", xs: "20rem" },
            overflow: "hidden",
          }}
        >
          <Box
            component="img"
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            alt="advImage"
            src={deals?.adImage}
          />
        </Box>

        <Box
          sx={{
            textAlign: "center",
            backgroundColor: deals?.backgroundColor
              ? deals?.backgroundColor
              : "white",
            height: { lg: "23rem", xs: "20rem" },
            width: { lg: "23rem", xs: "20rem" },
            overflowY: "auto",
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            "@media (max-width: 600px)": {
              height: "20rem",
              width: "20rem",
            },
          }}
        >
          <Container style={{ margin: "2rem 0 2rem 0" }}>
            <Typography
              variant="h6"
              sx={{
                color: deals?.headerTextColor,
                fontWeight: "bold",
                lineHeight: "1.6rem",
                "@media (max-width: 600px)": {
                  fontSize: "1.3rem",
                },
              }}
            >
              {deals?.headerText}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                color: deals?.descriptionTextColor,
                marginTop: "1rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 4,
                width: "20rem",
                textAlign: "center",
                "@media (max-width: 1024px)": {
                  fontSize: "1rem",
                  width: "16rem",
                },
              }}
            >
              {deals?.descriptionText}
            </Typography>
            <div style={{ textAlign: "center" }}>
              {view && (
                <Typography
                  variant="subtitle2"
                  sx={{ color: deals?.descriptionTextColor }}
                >
                  {deals?.details.split("\n").map((line) => (
                    <div key={line}>{line}</div>
                  ))}
                </Typography>
              )}
              {view && (
                <Button
                  style={{
                    color: deals?.buttonTextColor,
                    backgroundColor: deals?.buttonColor,
                    borderRadius: "1rem",
                    padding: "0.3rem 2rem",
                    marginTop: "1.5rem",
                    borderColor: deals?.outlinedTextColor,
                    borderWidth: "2px",
                    borderStyle: "solid",
                  }}
                  onClick={handleView}
                >
                  Hide Details
                </Button>
              )}
              {!view && (
                <Button
                  style={{
                    color: deals?.buttonTextColor,
                    backgroundColor: deals?.buttonColor,
                    borderRadius: "1rem",
                    padding: "0.3rem 2rem",
                    marginTop: "1.5rem",
                    borderColor: deals?.outlinedTextColor,
                    borderWidth: "2px",
                    borderStyle: "solid",
                    pointerEvents: loading || isButtonDisabled ? "none" : "auto", 
                    opacity: loading || isButtonDisabled ? 0.7 : 1 
                  }}
                  onClick={handleOrder}
                  disabled={isLoading || isButtonDisabled} 
                >
                  {loading ? (
                    <ButtonLoader props={"4.5rem"} />
                  ) : (
                    deals?.buttonType
                  )}
                </Button>
              )}
            </div>
          </Container>
        </Box>
      </Box>
      <AlertPopup
        open={openAlert}
        setOpen={setOpenAlert}
        setState={setState}
        title={"noStoreBestSeller"}
        displayTitle={"Please select store to order from here"}
        buttonType1={true}
        buttonType2={true}
      />
    </>
  );
};
export default New;
