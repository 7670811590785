import { Box, Card, Modal, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { fivestarReward, LoyalityPoint, RewardList } from "../../Api/API";
import AlertPopup from "../../Common/AlertPopup";
import { errorToastMessage } from "../../Common/utils";
import { setCartData } from "../../Redux/CartSlice";
import ClearIcon from '@mui/icons-material/Clear';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  setFiveStarIndex,
  setFiveStarResponse,
  setFiveStarRewardDetail,
  setRemoveReward,
} from "../../Redux/UserSlice";
import { grey } from "@mui/material/colors";
toast.configure();

// Styled components
const RewardCard = styled(Card)(({ theme, isExpiring, selectedAvailableCardIndex, index}) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  // padding: "16px",
  backgroundImage: isExpiring ? `url(./Expiry_Soon_Background.png)` : `url(./RewardBg2.png)`,
  backgroundSize: "cover",
  margin: "10px",
  borderRadius: "8px",
  backgroundColor: "#fff",
  boxShadow: "0px 2px 4px rgba(0,0,0,0.1)",
  border:
  selectedAvailableCardIndex === index
    ? "2px solid #C60734"
    : "none",
}));

const ModalContent = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: "400px",
  backgroundColor: "#fdfdfd",
  borderRadius: "8px",
  padding: "16px",
}));

const RewardsPopup = (props) => {
  const {
    open,
    handleClose,
    setTotalFivestar,
    disc,
    fivestarDiscount,
    setfivestarDiscount,
    promoApply,
    setAutoDisc,
    showCombineDiscountPopup,
    setShowCombineDiscountPopup,
    applyPromo,
    alwaysPromoApply,
    setAlwaysPromoApply,
    handleRemoveFiveStarLoyalty,
    showCombinePopUp,
    setShowCombinePopUp,
  } = props;
  const dispatch = useDispatch();
  const isLogin = useSelector((state) => state.user.isLogin);
  const userDetail = useSelector((state) => state.user.user);
  const discountDetail = useSelector((state) => state.user.discountDetail);
  const cartItem = useSelector((state) => state.cart.cart);
  const cartData = useSelector((state) => state.cart.cartData);
  const user = useSelector((state) => state.user.user);
  const loyalityPoints = useSelector((state) => state.user.loyalityPoints);
  const [loyalityPnt, setLoyalityPnt] = useState();
  const [rewardlst, setrewardlst] = useState([]);
  const [autoPilotReward, setAutoPilotReward] = useState([]);
  const [isRewardListError, setIsRewardListError] = useState(false);
  const [fiveStarData, setFiveStarData] = useState();
  const [rewardObject, setRewardObject] = useState({});
  const [selectedExpiringCardIndex, setSelectedExpiringCardIndex] =
    useState(null);
  const [selectedAvailableCardIndex, setSelectedAvailableCardIndex] =
    useState(null);
  const [selectedAutopilotCardIndex, setSelectedAutopilotCardIndex] =
    useState(null);
  const storeName = useSelector((state) => state.store.nearestStore);
  // let acutalReward = res.result.filter((rew) => rew.point_cost <= loyalityPoints)
  const selectRef = useRef(null);
  useEffect(() => {
    if (!fivestarDiscount || fivestarDiscount === 0) {
      setSelectedExpiringCardIndex(null);
      setSelectedAvailableCardIndex(null);
      setSelectedAutopilotCardIndex(null);
    }
  }, [fivestarDiscount]);

  useEffect(() => {
    if (!open) return; 
    
    if (!fivestarDiscount || fivestarDiscount === 0) {
      setSelectedExpiringCardIndex(null);
      setSelectedAvailableCardIndex(null);
      setSelectedAutopilotCardIndex(null);
    }
  }, [open, fivestarDiscount]);
  
  useEffect(() => {
    if (isLogin) {
      let orderDetail = cartItem.map((e) => {
        let selectedMenuServingDetail = [
          {
            created_at: e?.selectedCupSize[0]?.created_at,
            price: e?.selectedCupSize[0]?.price?.toString(),
            serving_actual_id: e?.selectedCupSize[0]?.serving_actual_id,
            serving_description: e?.selectedCupSize[0]?.serving_description,
            serving_icon: e?.selectedCupSize[0]?.serving_icon,
            serving_id: e?.selectedCupSize[0]?.serving_id,
            serving_name: e?.selectedCupSize[0]?.serving_name,
            status: e?.selectedCupSize[0]?.status,
            updated_at: e?.selectedCupSize[0]?.updated_at,
          },
        ];
        let options = e?.selectedCupSize?.map((element) => {
          let detail = {
            modifier_group_name: "Cup Size",
            option: [
              {
                id: element?.serving_actual_id?.toString(),
                isSelected: false,
                modifier_id: "",
                modifier_name: element?.serving_name,
                modifier_prefix_overide_mode: "No",
                modifier_prefix_overideMode: "",
                modifier_prefix_price: 0,
                modifier_prefixname: "",
                modifier_prefix_name: 0,
                modifier_price: element?.price?.toString(),
                prefix_name: "",
                price: element?.price?.toString(),
                quantity: 1,
                selected_modifier_serving: {
                  price: "0",
                  serving_name: "",
                },
                serving_id: element?.serving_actual_id?.toString(),
                serving_name: element?.serving_name,
                serving_price: element?.price?.toString(),
                sub_modifier_id: 0,
                title: "",
              },
            ],
            required: true,
            selection_time: "Single",
            title: "Cup Size",
          };
          return detail;
        });
        let obj = Object.assign({}, e);
        obj.selectedOptions = [...options, ...e.selectedOptions];
        e = obj;
        let detail = {
          apply_option: "-1",
          category_id: e?.categoryId?.toString(),
          category_name: e?.categoryName,
          costToDisplay: e?.orderItemPrice?.toString(),
          customLoyaltyId: "",
          custom_loyalty_id: "",
          customizationOpt: e?.selectedOptions,
          discountId: "",
          discount_price: "0",
          discount_rate: "0",
          discount_title: "",
          discount_type: "",
          fivestar_discount: "",
          fivestar_discount_id: "",
          gift_card_no: "",
          gift_card_type: "",
          id: 1372,
          is_giftcard: "",
          itemAliasName: "",
          itemCustomizedPrice: "",
          loyaltyDetails: "New Order",
          loyalty_id: "",
          loyalty_points: "0",
          loyalty_value: "0",
          menu_id: e?.id?.toString(),
          menu_image: e?.image,
          menu_name: e?.title,
          originalCost: "",
          quantity: e?.quantity,
          selected_menu_serving_detail: selectedMenuServingDetail,
          serving_id: e?.selectedCupSize[0]?.serving_actual_id?.toString(),
          serving_name: e?.selectedCupSize[0]?.serving_name,
          serving_price: e?.selectedCupSize[0]?.price?.toString(),
        };
        return detail;
      });

      const data = {
        customer_address: "",
        customer_email: userDetail ? userDetail?.email : "",
        customer_id: userDetail?.person_id
          ? userDetail?.person_id?.toString()
          : userDetail?.customer_id?.toString(),
        customer_mobile_no: userDetail?.phone_no
          ? userDetail?.phone_no
          : userDetail?.phone_number,
        customer_name: userDetail?.first_name
          ? userDetail?.first_name
          : userDetail?.username,
        employee_id: 1,
        employee_name: "",
        givex_code: "",
        givex_num: "",
        is_emv: 0,
        loyalty_point: userDetail?.loyalty_point
          ? userDetail?.loyalty_point
          : 0,
        loyalty_request: {
          checkin: "",
          phone: "",
          points: [],
          rewards: [],
        },
        loyalty_reward_json: [
          {
            reward_label: "",
            reward_points: 0,
            reward_uid: "",
          },
        ],
        loyalty_value: 0,
        order_details: [
          {
            discount: {
              discount: discountDetail[0]?.disArr?.discountRate
                ? discountDetail[0].disArr.discountRate
                : 0,
              id: discountDetail[0]?.disArr?.id
                ? discountDetail[0].disArr.id
                : -1,
              type: discountDetail[0]?.disArr?.discountType
                ? discountDetail[0].disArr.discountType
                : "",
            },
            discount_total_price: 0,
            loyalty_point: 0,
            loyalty_value: 0,
            orderDetails: orderDetail,
            pickup_date: cartData.pickupDate,
            pickup_type: cartData.pickupType,
            store_id: storeName?.id?.toString(),
            subTotal: cartData.subTotal?.toString(),
            tax: cartData?.taxRate?.toString(),
            taxValue: cartData?.taxValue?.toString(),
            timezone: -330,
            total: cartData.total,
            total_fivestar_discount: 0,
          },
        ],
        order_status: "pending",
        order_total_price: "",
        order_type: "web",
        payment_method: [
          {
            amount: "",
            amt: "",
            auth_code: "",
            calculationAmt: "",
            card_last_no: 0,
            givex_code: 0,
            givex_num: 0,
            givex_trans_num: "",
            method: "credit_card",
            payId: 0,
            payType: "",
            paymentMethod: "credit_card",
            trans_num: 0,
          },
        ],
        store_current_time: new Date().toISOString(),
        store_id: storeName?.id?.toString(),
        store_name: storeName.store_name,
        timezone: -330,
        user_id: userDetail?.person_id
          ? userDetail?.person_id?.toString()
          : userDetail?.customer_id?.toString(),
        formattedPickUpLaterDate: "",
        weekDay: "",
        discountAmount: disc,
      };
      setFiveStarData(data);
    }
  }, [isLogin, cartItem]);

  let phoneNumber = user?.phone_no ? user?.phone_no : user?.phone_number;
  let personId = user?.person_id ? user?.person_id : user?.customer_id;
  useEffect(() => {
    if (isLogin && phoneNumber && personId) {
      LoyalityPoint(phoneNumber, personId)
        .then((res) => {
          setLoyalityPnt(res?.userDetails);
        })
        .catch((err) => {
          console.log(err);
          errorToastMessage();
        });
    }
  }, [isLogin, phoneNumber, personId]);

  useEffect(() => {
    if (isLogin && personId && storeName && storeName.id) {
      setIsRewardListError(false);
      RewardList("", personId, storeName.id)
        .then((res) => {
          setrewardlst(res?.result);
          setAutoPilotReward(res?.resultAutopilot);
        })
        .catch((err) => {
          console.log(err);
          setIsRewardListError(true);
          errorToastMessage();
          handleClose();
        });
    }
  }, [isLogin, personId, loyalityPoints]);

  useEffect(() => {
    if (open && isRewardListError) {
      toast.error("Failed to fetch rewards. Please try again later.", {
        position: toast.POSITION.TOP_RIGHT,
        pauseOnFocusLoss: false,
        autoClose: 1500,
        pauseOnHover: false,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
      });
      handleClose();
    }
  }, [open, isRewardListError]);

  const handleFivestarReward = (val, elm, index) => {
    dispatch(setRemoveReward(""));
    if (selectRef.current) {
      selectRef.current.blur();
    }
    // Comment: Changes are here:
    const isAlreadySelected = 
    (selectedExpiringCardIndex === index && elm.leftdays <= 7) ||
    (selectedAvailableCardIndex === index && elm.leftdays > 7) ||
    (selectedAutopilotCardIndex === index && elm.shortname);

    if (isAlreadySelected) {
      handleRemoveFiveStarLoyalty();
      setSelectedExpiringCardIndex(null);
      setSelectedAvailableCardIndex(null);
      setSelectedAutopilotCardIndex(null);
      toast.success("Reward removed successfully", {
        position: toast.POSITION.TOP_RIGHT,
        pauseOnFocusLoss: false,
        autoClose: 1500,
        pauseOnHover: false,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
      });
      return;
    }

    const rewardValue = elm.shortname ? elm.shortname : val;

    if ((promoApply || alwaysPromoApply) && !showCombineDiscountPopup) {
      setShowCombinePopUp(true);
      setRewardObject({
        shortname: elm?.shortname,
        elm: elm,
        index: index,
      });
    } else {
      handleFivestarRewardFromPopup(rewardValue, elm, index);
    }
  };

  const handleFivestarRewardFromPopup = (val, elm, index) => {
    const hasEnoughPoints = elm.shortname
      ? true
      : elm.point_cost <= loyalityPnt.loyalityPoint;

    if (hasEnoughPoints) {
      if (selectRef.current) {
        selectRef.current.blur();
      }

      const resetCartData = {
        ...cartData,
        discount: 0,
        promoCodeDiscount: 0,
        total: cartData.subTotal + cartData.taxValue
      };
      dispatch(setCartData(resetCartData));

      const rewardPayload = {
        ...fiveStarData,
        order_details: fiveStarData.order_details.map(detail => ({
          ...detail,
          discount: {
            discount: 0,
            id: -1,
            type: ""
          },
          discount_total_price: 0
        }))
      };

      localStorage.setItem("rewardProcessing", "true");
      fivestarReward(window.btoa(val), rewardPayload)
        .then((res) => {
          if (res?.responseCode === 1) {
            const rewardDiscount = res?.updatedCart?.order_details[0]?.total_fivestar_discount || 0;
            const newTotal = cartData.subTotal + cartData.taxValue - rewardDiscount;
            const updatedCartData = {
              ...cartData,
              discount: rewardDiscount,
              promoCodeDiscount: 0,
              total: newTotal
            };
            setTimeout(() => {
              dispatch(setRemoveReward(""));
            }, 1500);

            // Update state and Redux store
            dispatch(setRemoveReward(""));
            setfivestarDiscount(rewardDiscount);
            setTotalFivestar(rewardDiscount);
            dispatch(setCartData(updatedCartData));
            dispatch(setFiveStarIndex(index));
            dispatch(setFiveStarRewardDetail(elm));
            dispatch(setFiveStarResponse(res?.updatedCart));
            handleClose();
            toast.success(res?.message, {
              position: toast.POSITION.TOP_RIGHT,
              pauseOnFocusLoss: false,
              autoClose: 1500,
              pauseOnHover: false,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: true,
            });
          } else {
            handleRemoveFiveStarLoyalty();
            dispatch(setRemoveReward(""));
            setSelectedExpiringCardIndex(null);
            setSelectedAvailableCardIndex(null);
            setSelectedAutopilotCardIndex(null);
            // Revert cart data to original state without any discount
            const updatedCartData = {
              ...cartData,
              discount: 0,
              promoCodeDiscount: 0,
              total: cartData.subTotal + cartData.taxValue
            };
            dispatch(setCartData(updatedCartData));
            toast.error(res.message, {
              position: toast.POSITION.TOP_RIGHT,
              pauseOnFocusLoss: false,
              autoClose: 1500,
              pauseOnHover: false,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          errorToastMessage();
          dispatch(setRemoveReward(""));
          setSelectedExpiringCardIndex(null);
          setSelectedAvailableCardIndex(null);
          setSelectedAutopilotCardIndex(null);
        })
        .finally(() => {
          localStorage.removeItem("rewardProcessing");
        });
    }
  };

  useEffect(() => {
    let totalFivestarDiscount = fivestarDiscount;
    setTotalFivestar(totalFivestarDiscount);
  }, [fivestarDiscount]);

  return (
    <>
      {!isRewardListError && (
        <Modal open={open} onClose={handleClose}>
          {rewardlst.length === 0 ? (
            <h3>No Rewards Available</h3>
          ) : (
            <ModalContent>
              <Typography
                variant="h6"
                align="center"
                sx={{ marginBottom: "16px", position: "relative", paddingRight: "24px"  }}
              >
                Redeem a reward?
                <ClearIcon 
                  onClick={handleClose} 
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    cursor: "pointer",
                    color: "#666",
                    padding: "4px",
                    transition: "color 0.2s",
                    "&:hover": {
                      color: "#C60734"
                    }
                  }}
                />
              </Typography>

              {/* Expiring Soon Section */}
              {/* {(rewardlst?.filter((elm) => elm.leftdays <= 7).length > 0 ||  */}
                {(autoPilotReward?.length > 0) && (
                <>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "bold", marginBottom: "8px" }}
                  >
                    Expiring Soon
                  </Typography>
                    {autoPilotReward?.map((elm, index) => (
                      <RewardCard
                      isExpiring={true}
                        key={`autopilot-${index}`}
                        sx={{
                          backgroundColor: "#ffe3e8",
                          p: 2,
                          cursor: "pointer",
                          border:
                            selectedAutopilotCardIndex === index
                              ? "2px solid #C60734"
                              : "none",
                          position: "relative",
                        }}
                        onClick={() => {
                          setSelectedAutopilotCardIndex(index);
                          setSelectedAvailableCardIndex(null);
                          setSelectedExpiringCardIndex(null);
                          handleFivestarReward(elm?.shortname, elm, index);
                          setRewardObject({
                            shortname: elm?.shortname,
                            elm: elm,
                            index: index,
                          });
                          if (selectRef.current) {
                            selectRef.current.blur();
                            setTimeout(() => {
                              selectRef.current.blur();
                              document.activeElement.blur();
                            }, 50);
                          }
                          document.activeElement.blur();
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: 2,
                          }}
                        >
                          <img
                            src="./RewardGif.png"
                            alt="image"
                            style={{ height: "auto", objectFit: "contain", marginRight: "10px", marginTop: "10px" }}
                          />
                          <Box
                          style={{ marginTop: '10px',  }}
                          >
                            <Typography
                              sx={{ fontWeight: "bold", color: "#C60734" }}
                            >
                              {elm.shortname}
                            </Typography>
                            <Typography variant="body2" color="#C60734">
                              Expires in {elm.leftdays} days
                            </Typography>
                            {selectedAutopilotCardIndex === index && (
                              <CheckCircleIcon 
                                sx={{ 
                                  position: "absolute",
                                  top: 8,
                                  right: 8,
                                  color: "#C60734",
                                  fontSize: "1.2rem"
                                }} 
                              />
                            )}
                          </Box>
                        </Box>
                      </RewardCard>
                    ))}
                </>
              )}

              {/* Available Rewards Section */}
              {rewardlst?.length > 0 && (
                <>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "bold", marginBottom: "8px" }}
                  >
                    Available Rewards
                  </Typography>
                  {rewardlst.map((elm, index) => (
                      <RewardCard
                      key={index}
                      selectedAvailableCardIndex={selectedAvailableCardIndex}
                      index={index}
                      elm={elm}
                      onClick={() => {
                        if(elm.point_cost <= loyalityPnt.loyalityPoint){
                          setSelectedAvailableCardIndex(index);
                          setSelectedExpiringCardIndex(null);
                          setSelectedAutopilotCardIndex(null);
                          handleFivestarReward(elm?.perk, elm, index);
                          setRewardObject({
                            perk: elm?.perk,
                            elm: elm,
                            index: index,
                          });
                          if (selectRef.current) {
                            selectRef.current.blur();
                            setTimeout(() => {
                              selectRef.current.blur();
                              document.activeElement.blur();
                            }, 50);
                          }
                          document.activeElement.blur();
                        }
                      }}
                      sx={{
                        cursor: elm.point_cost <= loyalityPnt?.loyalityPoint ? "pointer" : "not-allowed"
                      }}
                    >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "15px",
                            cursor: elm.point_cost <= loyalityPnt?.loyalityPoint?"pointer":"not-allowed",
                            borderRadius: "8px",
                            position: "relative",

                          }}
                        >
                          <Box>
                            <h4
                              style={{
                                color: elm.point_cost <= loyalityPnt?.loyalityPoint?"#C60734":"grey",
                                marginBottom: ".8rem",
                              }}
                            >
                              {elm.point_cost} pts
                            </h4>
                            <Typography
                              sx={{
                                fontWeight: "bold",
                                color: elm.point_cost <= loyalityPnt?.loyalityPoint?"#C60734":"GrayText",
                                fontSize: 14,
                                pr: 0.5,
                              }}
                            >
                              {elm.perk}
                            </Typography>
                          </Box>
                        </Box>
                        <Box sx={{}}>
                        {selectedAvailableCardIndex === index && (
                          <CheckCircleIcon
                            sx={{
                              color: "#C60734",
                              fontSize: "1.2rem",
                              marginLeft: "1.7rem"
                            }}
                          />
                        )}
                        <div style={{ width: "10%" }}>
                          <img
                            src="./RewardCup1.png"
                            alt="image"
                            style={{
                              width: "2rem",
                              height: "auto",
                              objectFit: "contain",
                              marginTop: "10px",
                              marginRight: "20px",
                            }}
                          />
                          </div>
                          </Box>
                      </RewardCard>
                    ))}
              {/* {autoPilotReward
                ?.filter((elm) => elm.leftdays > 7 && elm.leftdays > 0)
                .map((elm, index) => (
                  <RewardCard key={`autopilot-${index}`}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "15px",
                      cursor: "pointer",
                      borderRadius: "8px",
                      border:
                        selectedAutopilotCardIndex === index
                          ? "2px solid #C60734"
                          : "none",
                            position: "relative",
                    }}
                    onClick={() => {
                      setSelectedAutopilotCardIndex(index);
                      setSelectedAvailableCardIndex(null);
                      setSelectedExpiringCardIndex(null);
                      handleFivestarReward(elm?.shortname, elm, index);
                      setRewardObject({
                        shortname: elm?.shortname,
                        elm: elm,
                        index: index,
                      });
                      if (selectRef.current) {
                        selectRef.current.blur();
                        setTimeout(() => {
                          selectRef.current.blur();
                          document.activeElement.blur();
                        }, 50);
                      }
                      document.activeElement.blur();
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          color: "#C60734",
                          fontSize: 14,
                          pr: 0.5,
                        }}
                      >
                        {elm.shortname}
                      </Typography>
                        <Typography 
                          variant="body2" 
                          color="#C60734"
                          sx={{ 
                            fontSize: 12,
                            opacity: 0.8,
                            mt: 0.5 
                          }}
                        >
                          Expires in {elm.leftdays} days
                        </Typography>
                    </Box>
                    <img
                      src="./RewardCup1.png"
                      alt="image"
                      style={{
                        width: "12%",
                        height: "auto",
                        objectFit: "contain",
                              marginRight: "10px",
                              marginTop: "10px",
                      }}
                    />
                          {selectedAutopilotCardIndex === index && (
                            <CheckCircleIcon 
                              sx={{ 
                                position: "absolute",
                                top: 8,
                                right: 8,
                                color: "#C60734",
                                fontSize: "1.2rem"
                              }} 
                            />
                          )}
                  </Box>
                </RewardCard>
              ))} */}
                </>
              )}

              {showCombinePopUp && (
                <AlertPopup
                  open={showCombinePopUp}
                  setOpen={setShowCombinePopUp}
                  title={"combineDiscountPopup"}
                  buttonType1={true}
                  buttonType2={true}
                  displayTitle={
                    "You already have a discount applied. Would you like to use this discount instead?"
                  }
                  setAutoDisc={setAutoDisc}
                  rewardObject={rewardObject}
                  setRewardObject={setRewardObject}
                  handleFivestarReward={handleFivestarRewardFromPopup}
                  setShowCombineDiscountPopup={setShowCombineDiscountPopup}
                  applyPromo={applyPromo}
                  promoApply={promoApply}
                  setCombineDiscount={setShowCombineDiscountPopup}
                  setAlwaysPromoApply={setAlwaysPromoApply}
                  handleRemoveFiveStarLoyalty={handleRemoveFiveStarLoyalty}
                  setSelectedExpiringCardIndex={setSelectedExpiringCardIndex}
                  setSelectedAvailableCardIndex={setSelectedAvailableCardIndex}
                  setSelectedAutopilotCardIndex={setSelectedAutopilotCardIndex}
                />
              )}
            </ModalContent>
          )}
        </Modal>
      )}
    </>
  );
};

export default RewardsPopup;
