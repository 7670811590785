import {
  Backdrop,
  Box,
  Card,
  CircularProgress,
  Grid,
  Slide,
  Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setTabValue, setValueSet } from "../../../Redux/CartSlice";
const useStyle = makeStyles((theme) => ({
  root: {
    "& .MuiAvatar-img": {
      width: "auto",
    }
  },
  item: {
    boxShadow: "0 6px 20px 0 #dbdbe8",
    transition: "transform 0.15s ease-in-out",
    "&:hover": { transform: "scale3d(1.05, 1.05, 1)" },
  },
  imgTran: {
    width: "25%",
    objectFit: "contain",
    transition: "transform 0.15s ease-in-out",
    "&:hover": { transform: "scale3d(1.15, 1.15, 1)" },
  }
}));

const CategoryTabBar = ({ loading}) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const location = useLocation();
  const [imageLoaded, setImageLoaded] = useState({}); 
  const CategoriesSet = useSelector((state) => state.cart.CategoriesSet);

  useEffect(() => {
    if (CategoriesSet?.length > 0) {
      CategoriesSet.forEach(category => {
        const img = new Image();
        img.onload = () => {
          setImageLoaded(prev => ({
            ...prev,
            [category.category_id]: true
          }));
        };
        img.src = category?.category_image || "./logos.png";
      });
    }
  }, [CategoriesSet]);

  useEffect(() => {
    if (CategoriesSet?.length > 0 && location.pathname === '/') {
      dispatch(setTabValue(2));
    }
  }, [CategoriesSet]);


  const handleChange = (newValue) => {
    dispatch(setValueSet(newValue));
    dispatch(setTabValue(0));
      navigate('/menu', { replace: true });
  };

  return (
    <>
     <Backdrop
          sx={{ color: "#fff", zIndex: 1000 }}
          open={CategoriesSet?.length === 0?loading:false}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      {location.pathname === '/' && (
        <Box
          sx={{
            paddingLeft: { lg: "13vw", xs: "5vw" },
            paddingRight: { lg: "13vw", xs: "5vw" },
            paddingBottom: { lg: "3vh", xs: "10vh" },
            paddingTop: "5vh",
          }}
        >
          <Grid container spacing={4}>
            {CategoriesSet?.map((category, index) => {
              return (
                <Slide key={index} direction="right" in={true} mountOnEnter unmountOnExit timeout={400}>
                <Grid item xs={12} sm={6} md={6} lg={6} key={index}>
                  <Card className={classes.item}
                    onClick={() => handleChange(index)}
                    sx={{
                      borderRadius: "16px",
                      cursor: "pointer"
                    }}
                  >
                    <Box p={1} sx={{ display: "flex", alignItems: "center", }}>
                      <Box className={classes.imgTran}
                        component="img"
                        sx={{
                          height: { lg: "12rem", xs: "9rem" },
                          opacity: imageLoaded[category.category_id] ? 1 : 0.5,
                          transition: 'opacity 0.3s ease-in-out'
                        }}
                        loading="lazy"
                        alt="Image"
                        src={category?.category_image ? category?.category_image : "./logos.png"}
                      />
                      <Typography sx={{
                        marginLeft: { lg: "2.3rem", xs: "1rem" }, color: "#39302B", fontSize: { lg: "23px", xs: "18px" },
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                        width: "20rem",
                      }}>
                        {category?.category_name}
                      </Typography>
                    </Box>
                  </Card>
                </Grid>
                </Slide>
              );
            })}
          </Grid>
        </Box>
      )}
    </>
  );
};
export default CategoryTabBar;
