import FreeBreakfastOutlinedIcon from "@mui/icons-material/FreeBreakfastOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import StarsOutlinedIcon from "@mui/icons-material/StarsOutlined";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import {
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
} from "@mui/material";
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AlertPopup from "../../AlertPopup";
import MapContainer from "../StoreLocation";
import LangPopup from "./Languagepopup";
import "./Sidebar.css";
import { setTabValue } from "../../../Redux/CartSlice";
import Login from '../../../pages/Login/Login';
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { setIsUserFiveStarData } from "../../../Redux/UserSlice";
import { setUserFiveStarData } from "../../../Redux/UserSlice";


const useStyles = makeStyles ({
  item: {
    zIndex: 1,
    transition: '0.4s',
    '&:hover': {
      backgroundColor: '#E7E7E7',
    },
  },
  BackdropProps: {
    background: 'transparent'
  }
});
const Sidebar = () => {
  const classes=useStyles();
  const dispatch = useDispatch();
  const [openbutton, setopenbutton] = useState(false);
  const [open, setOpen] = useState(false);
  const [state, setState] = useState(false);
  const [storeState, setStoreState] = useState(false);
  const isLogin = useSelector((state) => state.user.isLogin);
  const isGuestLogin = useSelector((state) => state.user.isGuestLogin);
  const userDetail = useSelector((state) => state.user.user);
  const [showAd, setShowAd] = useState(false);

  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/`;
    navigate(path);
    dispatch(setTabValue(2));
  };
  const routeRewards = () => {
    let path = `/rewards`;
    navigate(path);
  };
  const routeSetting = () => {
    let path = `/setting`;
    navigate(path);
  };

  const routeOrderHistory = () => {
    let path = `/orderHistory`;
    navigate(path);
  };

  const handleLogout = () => {
    setState(false);
    setOpen(true); 
  };

  const toggleDrawer = (open) => (e) => {
    if (e && e.type === "keydown" && (e.key === "Tab" || e.key === "Shift")) {
      return;
    }

    setState(open);
  };

  const storeToggleDrawer = (open) => (e) => {
    if (e && e.type === "keydown" && (e.key === "Tab" || e.key === "Shift")) {
      return;
    }
    setStoreState(open);
  };

  const list = (anchor) => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {(isLogin || isGuestLogin) && (
          <ListItem button className={classes.item} onClick={isLogin ? routeSetting : undefined}>
            <ListItemAvatar>
              <Avatar src={userDetail?.profile_pic || userDetail?.file}>
              </Avatar>
            </ListItemAvatar>
            <ListItemText 
              // primary={userDetail?.username || `${userDetail?.first_name || ''} ${userDetail?.last_name || ''}`} //maybe this is becouse of extra space
              primary={userDetail?.username || `${(userDetail?.first_name || '')} ${(userDetail?.last_name || '')}`.trim()}
              secondary={userDetail?.phone_number || userDetail?.phone_no} 
            />
          </ListItem>
        )}
        <ListItem button className={classes.item} onClick={routeChange}>
          <ListItemIcon>
            <HomeOutlinedIcon style={{ color: "#C30E2F" }} />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        {isLogin ? <ListItem button className={classes.item} onClick={routeRewards}>
          <ListItemIcon>
            <StarsOutlinedIcon style={{ color: "#C30E2F" }} />
          </ListItemIcon>
          <ListItemText primary="Rewards" />
        </ListItem>: ""}
        {isLogin === true? <ListItem button className={classes.item} onClick={routeOrderHistory}>
          <ListItemIcon>
            <FreeBreakfastOutlinedIcon style={{ color: "#C30E2F" }} />
          </ListItemIcon>
          <ListItemText primary="Order History" />
        </ListItem> : ""}
        {!isLogin && !isGuestLogin && (
          <ListItem
            button
            className={classes.item}
            onClick={() => {
              setShowAd(true);
              dispatch(setIsUserFiveStarData(false));
              dispatch(setUserFiveStarData([]));
              dispatch(setTabValue(2));
            }}
          >
            <ListItemIcon>
              <AccountCircleIcon style={{ color: "#C30E2F" }} />
            </ListItemIcon>
            <ListItemText primary="Log in" />
          </ListItem>
        )}
      </List>
      <Divider />
      <List>
        <ListItem button
          className={classes.item}
        onClick={()=>{
            window.open(`https://gongchausa.com/contact-us/`)
          }}
        >
          <ListItemIcon>
            <SupportAgentOutlinedIcon style={{ color: "#C30E2F" }} />
          </ListItemIcon>
          <ListItemText primary="Contact Us" />
        </ListItem>
        {isLogin ? (
          <ListItem button className={classes.item} onClick={routeSetting}>
            <ListItemIcon>
              <SettingsOutlinedIcon style={{ color: "#C30E2F" }} />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItem>
        ) : (
          ""
        )}
      </List>

      <Divider />
      {isLogin || isGuestLogin ?
        <List>
          <ListItem button className={classes.item} onClick={handleLogout} key="Logout">
            <ListItemIcon>
              <LogoutOutlinedIcon style={{ color: "#C30E2F" }} />
            </ListItemIcon>
            <ListItemText primary= {isLogin === true ? "Logout" : " Guest Logout"} />
          </ListItem>
        </List>:""}
    </Box>
  );

  return (
    <>
      <Login showAd={showAd} setShowAd={setShowAd}></Login>
      <button
        className="btn d-inline offcanvas_btn"
        type="button"
        // data-bs-toggle="offcanvas"
        // data-bs-target="/offcanvasNavbar"
        // aria-controls="offcanvasNavbar"
        onClick={toggleDrawer(true)}
        style={{ outline: "none", boxShadow: "none" }}
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      {
        <SwipeableDrawer
          anchor="right"
          open={state}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          {list(true)}
        </SwipeableDrawer>
      }
      {
        <SwipeableDrawer
          anchor="right"
          PaperProps={{
            sx: { width: { xs: "80vw", sm: "55vw", md: "40vw", lg: "35vw" } },
          }}
          open={storeState}
          onClose={storeToggleDrawer(false)}
          onOpen={storeToggleDrawer(true)}
        >
          <Box sx={{ p: 1 }}>
            <MapContainer />
          </Box>
        </SwipeableDrawer>
      }

      {
        <LangPopup
          openbutton={openbutton}
          setopenbutton={setopenbutton}
        ></LangPopup>
      }
      <AlertPopup
        open={open}
        setOpen={setOpen}
        title= {"logout"}
        displayTitle = { "Are you sure you want to log out?"}
        buttonType1 = {true}
        buttonType2 = {true}
      ></AlertPopup>
    </>
  );
};

export default Sidebar;
