import {
  Backdrop,
  Box,
  Button,
  Card,
  CardActions,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import axios from "axios";
import { decode } from "base-64";
import Lottie from "lottie-react";
import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { LoyalityPoint } from "../../Api/API";
import KioskTable from "../../Common/KioskTable";
import Loader from "../../Common/Loader";
import { capitalizeFirstLetter, uiDateKiosk } from "../../Common/utils";
import fbIcon from "./fb.json";
import instaIcon from "./insta.json";
import "./style.css";
import twitterIcon from "./twitter.json";
import { useDispatch, useSelector } from "react-redux";
const useStyle = makeStyles((theme) => ({
  root: {
    width: { lg: "60vw", md: "60vw", sm: "80vw", xs: "10vw" },
  },
  cardActions: {
    bottom: "10%",
    position: "fixed",
  },
  cardActions1: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "1rem",
  },
  topTable: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #ECEAEA",
    margin: "10px 10px 0px 10px",
  },
  topTable2: {
    // marginTop:"10px",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#ECEAEA",
    height: "2.1rem",
    alignItems: "center",
    margin: "0px 10px",
  },
}));

const mapStyles = {
  // maxWidth: "50vw",
  height: "20vh",
  zIndex: "1",
  // borderRadius: "1rem",
};
const KioskReceipt2 = () => {
  const componentRef = useRef();
  const classes = useStyle();
  let search = useLocation().search;

  let orderId;
  const decodedOrderId = new URLSearchParams(search).get("orderId");
  if (decodedOrderId) {
    orderId = decodedOrderId;
  } else {
    orderId = new URLSearchParams(decode(search.substring(1))).get("orderId");
  }

  const [orderDetails, setOrderDetails] = useState();
  const [paymentName, setPaymentName] = useState();
  const [loyaltyPoints, setLoyalityPoints] = useState("--");
  const [feedbackUrl, setFeedbackLink] = useState();
  const [loading, setLoading] = useState(false);
  const [storeData, setStoreData] = useState([]);
  const [receiptNumber, setReceiptNumber] = useState("");
  const nearestStore = useSelector((state) => state.store.nearestStore);
  const orderDetailsColumns = useMemo(() => [
    {
      Header: " ",
      columns: [
        {
          Header: "Items",
          accessor: "menu_modifier_name",
          defaultCanSort: true,
        },
        {
          Header: "Quantity",
          accessor: "menu_modifier_quantity",
          defaultCanSort: true,
        },
        {
          Header: "Total",
          accessor: "menu_modifier_Total",
          defaultCanSort: true,
        },
      ],
    },
  ]);

  const getOrderDetailsById = () => {
    setLoading(true);
    axios
      .get(
        // `https://api-staging-cms.gongchapos.com/orders/getOrderDetailById?id=${orderId}`, // Staging
        `https://api-cms.gongchapos.com/orders/getOrderDetailById?id=${orderId}`, // Prod

        // `https://api.aiprusdev.com/orders/getOrderDetailById?id=${orderId}`, //qa
        {
          headers: { personId: null },
          auth: {
            // username: "gongchausa.staging.admin", // staging
            // password: "D5g#T9r2f%6pL@1w7*Jh!4k$8z^5s", // staging
            username: "gongchausa.prod.admin", // prod
            password: "#2K@9&5q4Fp!7L@8r%3j*1H^6m$5Z", // prod
          },
        }
      )
      .then((res) => {
        setPaymentName(res?.data[0]?.paymentMethod);
        setOrderDetails(res?.data[0]?.order[0]);
        setReceiptNumber(res?.data[0]?.order[0]?.id);
        getTotalFivestarPoints(res?.data[0]?.order[0]);
        getStoreDetails(res?.data[0]?.order[0]?.storeId);
        getFeedbackUrl(res?.data[0]?.order[0]?.storeId);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const orderDetailsData = () => {
    const allRowData =
      orderDetails &&
      orderDetails?.orderDetails[0] &&
      orderDetails?.orderDetails[0]?.orderDetails &&
      orderDetails?.orderDetails[0]?.orderDetails.map(
        (eachOrderDetail, index) => {
          const allRowsEachOrder = {};
          const headRow = {
            id: index + 1,
            menu_modifier_name: <b>{eachOrderDetail.menu_name}</b>,
            menu_modifier_quantity: eachOrderDetail.quantity,
            menu_modifier_price: `$${Number(
              parseFloat(
                eachOrderDetail.totalTextPrice
                  ? eachOrderDetail.totalTextPrice / eachOrderDetail.quantity
                  : eachOrderDetail.originalCost,
                10
              )
            )?.toFixed(2)}`,
            menu_modifier_Total: `\u00A0$${Number(
              parseFloat(eachOrderDetail.quantity, 10) *
                parseFloat(
                  eachOrderDetail.totalTextPrice
                    ? eachOrderDetail.totalTextPrice / eachOrderDetail.quantity
                    : eachOrderDetail.originalCost,
                  10
                )
            )?.toFixed(2)}`,
          };

          const eachRow = eachOrderDetail?.customizationOpt?.map(
            (eachCustomizedOption) => {
              return eachCustomizedOption.option.map((eachModifier) => {
                const row = {};
                row.menu_modifier_name = `\u00A0\u00A0\u00A0\u00A0${eachCustomizedOption?.modifier_group_name} - ${eachModifier?.modifier_name}`;
                row.menu_modifier_quantity = eachModifier?.quantity;
                // Number(eachModifier?.modifier_price) > 0
                //   ? eachModifier?.quantity
                //   : "";
                row.menu_modifier_price = isNaN(
                  `${Number(eachModifier?.modifier_price)}`
                )
                  ? "\u00A0\u00A0\u00A0\u00A0-"
                  : `$${Number(eachModifier?.modifier_price)?.toFixed(2)}`;
                row.menu_modifier_Total = isNaN(
                  `${Number(
                    parseFloat(eachModifier?.quantity, 10) *
                      parseFloat(eachModifier?.modifier_price, 10)
                  )}`
                )
                  ? "\u00A0\u00A0\u00A0\u00A0-"
                  : `\u00A0$${Number(
                      parseFloat(eachModifier?.quantity, 10) *
                        parseFloat(eachModifier?.modifier_price, 10)
                    )?.toFixed(2)}`;
                return row;
              });
            }
          );

          const footerRowDiscount = {
            menu_modifier_name: "\u00A0\u00A0\u00A0\u00A0Discount",
            menu_modifier_quantity: "",
            menu_modifier_price: "",
            menu_modifier_Total: `-$${Number(
              typeof eachOrderDetail?.discount_price == "string"
                ? eachOrderDetail?.discount_price.replace("$", "")
                : eachOrderDetail?.discount_price
            )?.toFixed(2)}`,
          };
          // }
          const footerRowLoyalty = {
            menu_modifier_name: "\u00A0\u00A0\u00A0\u00A0Loyalty Discount",
            menu_modifier_quantity: "",
            menu_modifier_price: "",
            menu_modifier_Total: `-$${Number(
              eachOrderDetail?.loyalty_points
            )?.toFixed(2)}`,
          };
          allRowsEachOrder.headRow = headRow;
          allRowsEachOrder.eachRow = eachRow;
          allRowsEachOrder.footerDiscountRow = footerRowDiscount;
          allRowsEachOrder.footerRowLoyalty = footerRowLoyalty;
          return allRowsEachOrder;
        }
      );
    const tableData = [];
    allRowData &&
      allRowData.map((eachItem) => {
        tableData.push(eachItem.headRow);
        for (let i = 0; i < eachItem?.eachRow?.length; i++) {
          tableData.push(...eachItem.eachRow[i]);
        }
        if (eachItem.footerDiscountRow.menu_modifier_Total != "-$0.00") {
          tableData.push(eachItem.footerDiscountRow);
        }
        if (eachItem.footerRowLoyalty.menu_modifier_Total != "-$0.00") {
          tableData.push(eachItem.footerRowLoyalty);
        }
      });
    const rowData = [...tableData];
    return rowData;
  };

  const getTotalFivestarPoints = (arg) => {
    LoyalityPoint(arg?.customerMobileno, Number(arg?.customerId))
      .then((res) => {
        setLoyalityPoints(res?.userDetails?.loyalityPoint);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getOrderDetailsById();
  }, []);

  const getStoreDetails = (storeId) => {
    axios
      .get(
        // `https://api-staging-cms.gongchapos.com/api/osposStore/findByStoreId?id=${storeId}`,
        `https://api-cms.gongchapos.com/api/osposStore/findByStoreId?id=${storeId}`,
        {
          headers: { personId: null },
          auth: {
            // username: "gongchausa.staging.admin",
            // password: "D5g#T9r2f%6pL@1w7*Jh!4k$8z^5s",
            username: "gongchausa.prod.admin",
            password: "#2K@9&5q4Fp!7L@8r%3j*1H^6m$5Z",
          },
        }
      )
      .then((res) => {
        setLoading(false);
        setStoreData(res.data?.osposStore);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const getFeedbackUrl = (storeId) => {
    axios
      .get(
        // `https://api.aiprusdev.com/receiptTemplate/findReceiptTemplateByStoreId?roleId=1&storeId=${storeId}`, //qa
        // `https://api-staging-cms.gongchapos.com/receiptTemplate/findReceiptTemplateByStoreId?roleId=1&storeId=${storeId}`,
        `https://api-cms.gongchapos.com/receiptTemplate/findReceiptTemplateByStoreId?roleId=1&storeId=${storeId}`,
        {
          headers: { personId: null },
          auth: {
            // username: "gongchausa.staging.admin",
            // password: "D5g#T9r2f%6pL@1w7*Jh!4k$8z^5s",
            // username: "gongchausa.staging.qa", //qa
            // password: "D5g#T9r2f%6pL@1w7*Jh!4k$8z^5s",
            username: "gongchausa.prod.admin",
            password: "#2K@9&5q4Fp!7L@8r%3j*1H^6m$5Z",
          },
        }
      )
      .then((res) => {
        setFeedbackLink(res?.data[0]?.qrCode);
      });
  };
  const handleMarkerClick = () => {
    window.open(
      `https://maps.google.com?q=${nearestStore.latitude},${nearestStore.longitude}`
    );
  };
  return (
    <>
      {loading ? (
        <Backdrop style={{ color: "#fff", zIndex: "0" }} open={loading}>
          {loading && (
            <img
              src="./BLACKPLAINLOADING.GIF"
              alt=""
              style={{ width: "4vw", margin: "auto" }}
            />
          )}
        </Backdrop>
      ) : (
        <div>
          <Card
            sx={{
              width: {
                xl: "30%",
                lg: "30%",
                md: "30%",
                sm: "30%",
                xs: "100%",
              },
              margin: "auto",
            }}
          >
            <Box ref={componentRef} sx={{ p: 2, pb: 0 }} dir="ltr">
              <Box className="center-div divider">
                <img
                  src="/NewLogoG.png"
                  alt=""
                  width="145px"
                  style={{ marginBottom: ".5rem" }}
                />
                <Typography className="red-color fs-14 bold">
                  Order # ${orderDetails?.customOrderNumber}
                </Typography>
                <Typography className="red-color fs-14 bold">
                  Receipt Number # ${receiptNumber}
                </Typography>
                <Typography className="grey-color fs-14">
                  {orderDetails?.createdAt &&
                    uiDateKiosk(String(orderDetails?.storeCurrentTime))}
                </Typography>
              </Box>
              <Box>
                <Typography className="fs-14">
                  {`Hi ${
                    orderDetails?.customerName === "null null"
                      ? "Guest"
                      : orderDetails?.customerName === "NA"
                      ? "N/A"
                      : orderDetails?.customerName
                  },`}
                </Typography>
                <Typography className="fs-14">
                  Thank you for your recent order at the{" "}
                  <span className="red-color">
                    {capitalizeFirstLetter(orderDetails?.storeName)}
                  </span>{" "}
                  location.
                </Typography>
                <div
                  // className="center-div-row fs-25 cursor-pointer p-10"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    cursor:"pointer",
                    marginTop:"10px"
                  }} // Ensures it takes full width and centers the content
                  onClick={() => feedbackUrl && window.open(feedbackUrl, "_blank")} 
                >
                  <img
                    src="/CUPS_Survey.png"
                    alt="hhhh"
                    style={{ width: "100%", height: "auto" }}
                  />
                </div>
              </Box>
              <Box
                sx={{ padding: "12px" }}
                className="center-div fs-25 divider red-color bold"
              >
                ${Number(orderDetails?.orderDetails[0]?.total)?.toFixed(2)}
              </Box>
              {orderDetails && orderDetailsData && (
                <div>
                  <div className="divider margin-bottom">
                    <KioskTable
                      columns={orderDetailsColumns}
                      data={orderDetailsData()}
                      onSort={orderDetailsData}
                      pageCount="0"
                      noData="orderDetails"
                      // orderDetailsPageSize={size}
                      kioskReceipt
                    />
                  </div>
                  <Box className="divider">
                    <div className="fs-12 space-between-div-row margin-bottom">
                      <span>Sub Total</span>
                      <span>
                        {`\u00A0$${Number(
                          orderDetails?.orderDetails[0]?.subTotal
                        )?.toFixed(2)}`}
                      </span>
                    </div>
                    <div className="fs-12 space-between-div-row margin-bottom">
                      <span>Discount</span>
                      <span>
                        {isNaN(
                          Number(
                            orderDetails?.orderDetails[0]?.discount_total_price
                          )
                        )
                          ? "-$0.00"
                          : `-$${Number(
                              orderDetails?.orderDetails[0]
                                ?.discount_total_price
                            )?.toFixed(2)}`}
                      </span>
                    </div>
                    <div className="fs-12 space-between-div-row margin-bottom">
                      <span>Tax</span>
                      <span>{`\u00A0$${Number(
                        orderDetails?.orderDetails[0]?.taxValue
                      )?.toFixed(2)}`}</span>
                    </div>
                    {orderDetails?.tips > 0 && (
                      <div className="fs-12 space-between-div-row margin-bottom">
                        <span>Tips</span>
                        <span>{`\u00A0$${Number(orderDetails?.tips)?.toFixed(
                          2
                        )}`}</span>
                      </div>
                    )}
                  </Box>
                </div>
              )}
              <Box className="divider">
                <div className="fs-14 space-between-div-row bold">
                  <span>Total</span>
                  <span>{`\u00A0$${Number(
                    orderDetails?.orderDetails[0]?.total
                  )?.toFixed(2)}`}</span>
                </div>
              </Box>
              <Box className="divider">
                <div className="center-div fs-14 red-color bold margin-bottom">
                  Payment Summary
                </div>
                <div className="fs-12 space-between-div-row margin-bottom">
                  <span>{paymentName}</span>
                  <span>{orderDetails?.cardLastNo}</span>
                </div>
                <div className="fs-12 space-between-div-row margin-bottom">
                  <span>Auth Code:</span>
                  <span>
                    {orderDetails?.cardLastNo}/{orderDetails?.authCode}
                  </span>
                </div>
                <div className="fs-12 space-between-div-row margin-bottom">
                  <span>Transaction no.:</span>
                  <span>{orderDetails?.transNum}</span>
                </div>
              </Box>
              <Box className="divider margin-bottom">
                <div className="center-div fs-14 red-color bold">
                  {`You've earned ${Number(
                    orderDetails?.accumulatedPoints
                  )?.toFixed(0)} points from this order.`}
                </div>
                <div className="center-div fs-14 red-color bold">
                  {`You now have a total of ${loyaltyPoints} loyalty points`}
                </div>
              </Box>
              <div
                style={{
                  marginTop: "2vh",
                  borderRadius: "1rem",
                  cursor: "pointer",
                }}
                onClick={() => handleMarkerClick()}
              >
                <GoogleMap
                  apiKey={"AIzaSyBVNeXhVJbD9mHKqwMRIWZuwR8tBhthIus"}
                  mapContainerStyle={mapStyles}
                  zoom={18}
                  options={{
                    clickableIcons: false,
                    gestureHandling: "none",
                    scrollwheel: false,
                    zoomControl: false,
                    streetViewControl: false,
                    scaleControl: false,
                    mapTypeControl: false,
                    fullscreenControl: false,
                  }}
                  center={{
                    lat: Number(nearestStore?.latitude),
                    lng: Number(nearestStore?.longitude),
                  }}
                >
                  <Marker
                    position={{
                      lat: Number(nearestStore?.latitude),
                      lng: Number(nearestStore?.longitude),
                    }}
                    defaultDraggable={true}
                    draggable={true}
                  >
                    <InfoWindow
                      position={{
                        lat: Number(nearestStore?.latitude),
                        lng: Number(nearestStore?.longitude),
                      }}
                    >
                      <div>{nearestStore?.storeName}</div>
                    </InfoWindow>
                  </Marker>
                </GoogleMap>
              </div>
              <Box className="center-div fs-12 margin-top">
                <div>{`Gong Cha - ${orderDetails?.storeName}`}</div>
                <div>{orderDetails?.address?.split(",")[0]}</div>
                {orderDetails?.address?.split(",")[1] && (
                  <div>
                    {orderDetails?.address?.split(",")[1] +
                      (orderDetails?.address?.split(",")[2]
                        ? "," + orderDetails?.address?.split(",")[2]
                        : "")}
                  </div>
                )}
                {/* <div>{orderDetails?.customerMobileno}</div> */}
                <a
                  href={`tel:${orderDetails?.mobileNo}`}
                  onClick={() =>
                    (window.location.href = `tel:${orderDetails?.mobileNo}`)
                  }
                >
                  {storeData?.mobileNo}
                </a>
                <a href="https://d66qze9tdwrtl.cloudfront.net/">
                  www.gongchausa.com
                </a>
                <div className="center-div-row">
                  <Lottie
                    animationData={instaIcon}
                    loop={true}
                    style={{ width: "3rem", cursor: "pointer" }}
                    onClick={() =>
                      (window.location.href =
                        "https://www.instagram.com/gongchatea/")
                    }
                  />
                  <Lottie
                    animationData={fbIcon}
                    loop={true}
                    style={{ width: "2.5rem", cursor: "pointer" }}
                    onClick={() =>
                      (window.location.href =
                        "https://www.facebook.com/gongchatea")
                    }
                  />
                  <Lottie
                    animationData={twitterIcon}
                    loop={true}
                    style={{
                      width: "1.9rem",
                      cursor: "pointer",
                      marginLeft: "8px",
                    }}
                    onClick={() =>
                      (window.location.href = "https://twitter.com/gongchatea")
                    }
                  />
                </div>
              </Box>
            </Box>
            <CardActions className={classes.cardActions1}>
              <ReactToPrint
                trigger={() => (
                  <Button
                    variant="outlined"
                    sx={{
                      color: "#ffff",
                      background: "#B02E25",
                      textTransform: "none",
                      borderRadius: ".5rem",
                      padding: "0.3rem 2rem",
                      fontWeight: 550,
                      transition: "transform 0.2s ease",
                      "&:active": {
                        transform: "scale(0.95)",
                      },
                    }}
                    style={{ backgroundColor: "#B7273B" }}
                  >
                    Print
                  </Button>
                )}
                content={() => componentRef.current}
              />
            </CardActions>
            {loading && <Loader />}
          </Card>
        </div>
      )}
    </>
  );
};

export default KioskReceipt2;
