import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { Backdrop, Badge, CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Login from "../../../pages/Login/Login";
import { setTabValue, setUpdateCart } from "../../../Redux/CartSlice";
import { setIsUserFiveStarData, setUserFiveStarData } from "../../../Redux/UserSlice";
import AlertPopup from "../../AlertPopup";
import Sidebar from "../Sidebar/Sidebar";
import Storebar from "./Storebar";
const HeaderBar = (props) => {
  const { state, setState, storeLoading, setStoreLoading } = props;
  let noOptions = props.noOptions;
  let url = props.url;
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [showAd, setShowAd] = useState(false);
  const cartItem = useSelector((state) => state.cart.cart);
  const isLogin = useSelector((state) => state.user.isLogin);
  const isGuestLogin = useSelector((state) => state.user.isGuestLogin);
  const [storeClose, setStoreClose] = useState(false);
  const nearestStore = useSelector((state) => state.store.nearestStore);

  const handleCart = () => {
    let totalItem = cartItem?.map((elm) => elm?.quantity);
    let totalCartItem = totalItem?.reduce((a, b) => a + b, 0);
    if (totalCartItem === 0) {
      let path = "/cart";
      navigate(path);
      dispatch(setTabValue(2));
    } else {
      if (nearestStore.store_name == null || nearestStore.store_name === "") {
        setStoreClose(true);
      } else{
        let path = "/cart";
        navigate(path)
      }
    }
  };
  const getTotalCartQuantity = () => {
    return cartItem.reduce((total, item) => total + (item.quantity || 0), 0);
  };

  useEffect(() => {
    if (location.pathname === "/" && !nearestStore?.id) {
      dispatch(setUpdateCart([]));
    }
  }, [location.pathname, dispatch, nearestStore]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const coords = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          sessionStorage.setItem("lastKnownLocation", JSON.stringify(coords));
        },
        (error) => console.error("Geolocation error:", error)
      );
    }
  }, []);

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: 1000 }} >
        <CircularProgress color="inherit" />
      </Backdrop>
      <nav
        className="navbar navbar-expand navbar-light bg-white border-bottom px-lg-5"
        style={{
          paddingTop: "2.5rem",
          paddingBottom: "2rem",
        }}
      >
        <div className="container-fluid">
          <Box
            sx={{ display: { xs: noOptions ? "Block" : "none", sm: "block" } }}
          >
            <ul className="navbar-nav">
              <NavLink
                className="nav-link active"
                exact
                to={!noOptions ? "/" : "/payment" + url}
                aria-current="page"
                onClick={() =>{if(location.pathname!=="/"){
                  dispatch(setTabValue(2))
                }}}
              >
                <img src="./NewLogo.png" alt="" height={35} />
              </NavLink>
            </ul>
          </Box>
          <Box
            sx={{ display: { xs: noOptions ? "none" : "block", sm: "none" } }}
          >
            <ul className="navbar-nav">
              <NavLink
                className="nav-link active"
                exact
                to="/"
                aria-current="page"
                onClick={() =>{if(location.pathname!=="/"){
                  dispatch(setTabValue(2))
                }}}
              >
                <img src="./NewLogo.png" alt="" width={100} />
              </NavLink>
            </ul>
          </Box>
          <form className="d-flex" variant="outlined">
            {!noOptions ? (
              <Storebar
                setState={setState}
                state={state}
                refreshTax={false}
                storeLoading={storeLoading}
                setStoreLoading={setStoreLoading}
              />
            ) : (
              ""
            )}
            {!noOptions ? (
              <>
                <ul className="navbar-nav me-auto mb-0 mb-lg-0">
                  <li className="nav-item">
                    {isLogin || isGuestLogin ? (
                      ""
                    ) : (
                      <NavLink
                        className="nav-link active"
                        aria-current="page"
                        exact
                        // to="/"
                        to={location.pathname}
                        onClick={() => {
                          window.scrollTo(0, 0);
                          // dispatch(setTabValue(2));
                          setShowAd(true);
                          dispatch(setIsUserFiveStarData(false));
                          dispatch(setUserFiveStarData([]));
                        }}
                      >
                        <AccountCircleIcon sx={{ marginLeft: "1.5rem" }} />
                        <Typography
                          sx={{
                            display: { xs: "none", sm: "block" },
                            marginLeft: "1.2rem",
                          }}
                          variant="subtitle2"
                        >
                          Log in
                        </Typography>
                      </NavLink>
                    )}
                  </li>
                  <li className="nav-item">
                    <div
                      style={{
                        padding: "8px",
                        cursor: "pointer",
                        marginLeft: ".5rem",
                      }}
                      onClick={()=>{
                        location.pathname!=="/cart"&&handleCart()
                      }}
                    >
                      <Badge
                        badgeContent={getTotalCartQuantity()}
                        color="primary"
                      >
                        <ShoppingCartOutlinedIcon
                          sx={{
                            marginLeft: isLogin || isGuestLogin ? "0.5rem" : 0,
                          }}
                        />
                      </Badge>
                      <Typography
                        sx={{
                          display: { xs: "none", sm: "block" },
                          marginLeft: isLogin || isGuestLogin ? ".5rem" : 0,
                        }}
                        variant="subtitle2"
                      >
                        Cart
                      </Typography>
                    </div>
                  </li>
                </ul>
                <Sidebar />
              </>
            ) : (
              ""
            )}
          </form>
        </div>
        <Login showAd={showAd} setShowAd={setShowAd}></Login>
        <AlertPopup
          open={storeClose}
          setOpen={setStoreClose}
          title={ "selectStore"}
          setState={setState}
          state={state}
          displayTitle={
            "Please select a store before continuing to the cart."
          }
          buttonType1={true}
        />
      </nav>
    </>
  );
};

export default HeaderBar;
