import {
    Box,
    Card,
    CardContent,
    Container,
    Typography
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";
import { getNotificationStatusFeedback, getPromotionsNotificationStatus, sendNotificationStatusFeedback, sendNotificationStatusPromotions } from '../../../Api/API';
import { IOSSwitch } from './constants';


toast.configure()
const useStyles = makeStyles((theme) => ({
    errorP: {
        color: "#bf1650",
        marginLeft: "18.7rem",
        " &::before": {
            display: "inline",
            content: "⚠ ",
        },
    },
}));
function Notifications() {

    const classes = useStyles();

    const [loyaltyCkeck, setLoyaltyCheck] = useState(false)
    const [productCheck, setProductCheck] = useState(false)
    const [status, setStatus] = useState("Block");
    const [proStatus,setProStatus] = useState()
    let phone1 = 6812174895
    let phone2 = "790-305-9893"
  
    const handleLoyaltyChange = () => {
        setLoyaltyCheck((prev) => {
            const updatedValue = !prev;
            let data = {
                phone: "790-305-9893",
                subscription_status: {
                    sms: { business: updatedValue},
                },
            };
            sendNotificationStatusPromotions(data).then((res) => {
                let prostatus = res.result.subscription_status.sms.business
                setProStatus(prostatus)
            });
            return updatedValue;
        });
    }

    useEffect(()=> {
        if (proStatus !== undefined) { 
            toast.success(proStatus === true ? "Loyalty Promotions and offers SMS Enable" : "Loyalty Promotions and offers SMS Disable", {
                position: toast.POSITION.TOP_RIGHT,
                pauseOnFocusLoss: false,
                autoClose: 1500,
                pauseOnHover: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
            });
        }
    }, [proStatus])

    useEffect(() => {
        if (productCheck) {
            setStatus("BLOCK"); // subscribe 
        } else {
            setStatus("unBLOCK"); //unsubscribe 
        }
    }, [productCheck]);

    const handleProductChange = () => {
        setProductCheck((prev) => !prev);
        let data = {
            From: phone1,
            Body: status,
            source: "UI"
        }
        sendNotificationStatusFeedback(data).then((res) => {
            toast.success(status == "BLOCK" ? "User UnSubscribed Successfully": "User Subscribed Successfully", {
                position: toast.POSITION.TOP_RIGHT,
                pauseOnFocusLoss: false,
                autoClose: 1500,
                pauseOnHover: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
            });
        })
    }

    useEffect(() => {
        getNotificationStatusFeedback(phone1).then((res) => {
            setProductCheck(res)
        })
        getPromotionsNotificationStatus(phone2).then((res)=>{
            setLoyaltyCheck(res.result.isFeedbackNotificationEnabled)
        })
    }, [])

    return (
        <div>
            <Container>
                <Card sx={{ borderRadius: "1rem", marginTop: "4vh", p: { xs: 2, sm: 5 } }}>
                    <CardContent >
                        <Box display={"flex"} justifyContent={"space-between"}>
                            <Box sx={{ width: { xs: '70%', sm: 'auto' } }}>
                                <Typography sx={{ 
                                    fontSize: { xs: '0.9rem', sm: '1rem' },
                                    fontWeight: 'bold'
                                }}>
                                    Loyalty Promotions and Offers
                                </Typography>
                                <Typography sx={{ 
                                    fontSize: { xs: '0.8rem', sm: '0.9rem' }
                                }}>
                                    I would like to receive exclusive loyalty promotions and offers via SMS. Message and data rates may apply.
                                </Typography>
                            </Box>
                            <Box sx={{ 
                                mr: { xs: -12, sm: -15 },
                                transform: { xs: 'scale(0.8)', sm: 'scale(1)' },
                                transformOrigin: 'right'
                            }}>
                                <FormControlLabel
                                    label="Customer Name"
                                    control={
                                        <IOSSwitch
                                            className={classes.switches}
                                            onChange={handleLoyaltyChange}
                                            checked={loyaltyCkeck}
                                        />
                                    }
                                />
                            </Box>
                        </Box>
                        <br />
                        <Box display={"flex"} justifyContent={"space-between"}>
                            <Box sx={{ width: { xs: '70%', sm: 'auto' } }}>
                                <Typography sx={{ 
                                    fontSize: { xs: '0.9rem', sm: '1rem' },
                                    fontWeight: 'bold'
                                }}>
                                    Product Updates and News
                                </Typography>
                                <Typography sx={{ 
                                    fontSize: { xs: '0.8rem', sm: '0.9rem' }
                                }}>
                                    I would like to receive product updates, surveys, and miscellaneous news via SMS. Message and data rates may apply.
                                </Typography>
                            </Box>
                            <Box sx={{ 
                                mr: { xs: -12, sm: -15 },
                                transform: { xs: 'scale(0.8)', sm: 'scale(1)' },
                                transformOrigin: 'right'
                            }}>
                                <FormControlLabel
                                    label="Customer Name"
                                    control={
                                        <IOSSwitch
                                            className={classes.switches}
                                            onChange={handleProductChange}
                                            checked={productCheck}
                                        />
                                    }
                                />
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Container>
        </div>
    )
}

export default Notifications