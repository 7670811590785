export const areCartItemsEquivalent = (item1, item2) => {    
  const item1Id = String(item1.id);
  const item2Id = String(item2.id);
  
  const basicPropsMatch = 
    item1Id === item2Id &&
    item1.selectedCupSize[0]?.serving_name?.toLowerCase() === item2.selectedCupSize[0]?.serving_name?.toLowerCase();
  
  if (!basicPropsMatch) return false;  
  if ((item1.selectedOptions?.length || 0) !== (item2.selectedOptions?.length || 0)) return false;
  if (!item1.selectedOptions?.length && !item2.selectedOptions?.length) return true;
  
  for (const group1 of item1.selectedOptions || []) {      
    const group2 = item2.selectedOptions.find(g => 
      g.modifier_group_name === group1.modifier_group_name
    );
    
    if (!group2) return false;
    
    if (group1.modifier_group_name === "Toppings" && 
        group1.counter_limit !== group2.counter_limit) {
      return false;
    }
    
    if (group1.option.length !== group2.option.length) return false;

    const sortedOptions1 = [...group1.option].sort((a, b) => 
      String(a.modifier_id).localeCompare(String(b.modifier_id))
    );
    const sortedOptions2 = [...group2.option].sort((a, b) => 
      String(a.modifier_id).localeCompare(String(b.modifier_id))
    );

    for (let i = 0; i < sortedOptions1.length; i++) {
      const option1 = sortedOptions1[i];
      const option2 = sortedOptions2[i];
      
      const optionsMatch = 
        String(option1.modifier_id) === String(option2.modifier_id) && 
        option1.quantity === option2.quantity;
      
      if (!optionsMatch) return false;
    }
  }
 
  return true;
};
  
  export const findMatchingCartItem = (cartDetail, cartItems) => {    
    for (let i = 0; i < cartItems.length; i++) {
      
      if (areCartItemsEquivalent(cartDetail, cartItems[i])) {
        return i;
      }
    }
    
    return -1;
  };
  
  export const updateCartWithItem = (cartDetail, cartItems, dispatch, setCart, setUpdateCart) => {
    const matchIndex = findMatchingCartItem(cartDetail, cartItems);
    
    if (matchIndex >= 0) {      
      const updatedCart = [...cartItems];
      const updatedItem = {...updatedCart[matchIndex]};
      
      const currentQuantity = Number(updatedItem.quantity) || 1;
      const addedQuantity = Number(cartDetail.quantity) || 1;
      
      updatedItem.quantity = currentQuantity + addedQuantity;
      updatedItem.totalPrice = updatedItem.orderItemPrice * updatedItem.quantity;
      updatedCart[matchIndex] = updatedItem;
            
      dispatch(setUpdateCart(updatedCart));
      return true;
    } else {
      if (cartDetail.quantity) {
        let arr = [cartDetail];
        dispatch(setCart(arr));
        return true;
      }
    }
    
    return false;
  };