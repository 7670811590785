import axios from "axios";

//Staging
// let username ="gongchausa.staging.payment";
// let password ="D5g#wwl%6pL@1wmdh!4k8z^5s";

//QA
// let username ="gongchausa.qa.payment";
// let password ="HGg#fvl%6pL@1wkkkh!4k\$8z^5s";

//prod
let username ="gongchausa.prod.payment";
let password ="D5g#fvl%6pL@1wdddh!4k8z^5s"; 
const instance = axios.create({
  // baseURL: "https://apistaging.aiprusdev.com",
  baseURL: "https://api.gongchausa.com",
  // baseURL: "https://api-staging-orders.gongchausa.com/",
  auth:{
    username:username,
    password:password
  }
  // baseURL: "https://api-staging-orders.gongchausa.com/",
  // baseURL: process.env.REACT_APP_baseUrl,
});
instance.defaults.headers.post["Content-Type"] =
  "application/json;charset=utf-8";
instance.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

export default instance;