import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CallIcon from "@mui/icons-material/Call";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import EastIcon from "@mui/icons-material/East";
import InfoIcon from "@mui/icons-material/Info";
import StorefrontIcon from "@mui/icons-material/Storefront";
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setTabValue } from "../../../Redux/CartSlice";
import {
  setActiveMarker,
  setMarkerSize,
  setNearestStore,
  setStoreCoordinate,
  setStoreData,
} from "../../../Redux/StoreSlice";
import AlertPopup from "../../AlertPopup";
import { AmPmFormat } from "../../utils";
const useStyles = makeStyles(() => ({
  root: {
    // height: "100%",
    margin: "3% 0%",
    transition: "0.3s",
    position: "relative",
    cursor: "pointer",
    "&:before": {
      transition: "0.2s",
      position: "absolute",
      width: "100%",
      // height: "100%",
      content: '""',
      display: "block",
      backgroundColor: "#d9daf1",
      borderRadius: "1rem",
      zIndex: 0,
      bottom: 0,
    },
    "&:hover": {
      "&:before": {
        bottom: -6,
      },
      "& $card": {
        boxShadow: "-12px 12px 64px 0 #bcc3d6",
      },
    },
  },
  card: {
    zIndex: 1,
    position: "relative",
    borderRadius: "1rem",
    boxShadow: "0 6px 20px 0 #dbdbe8",
    border: "1px solid #C60734",
    backgroundColor: "#fff",
    // transition: "0.4s",
    height: "100%",
    padding: "1rem 0.5rem 1rem 0.5rem",
    // paddingLeft: "1rem",
    transition: "transform 0.15s ease-in-out",
    // "&:hover": { transform: "scale3d(1.05, 1.05, 1)" },
  },
  selectedCard: {
    zIndex: 1,
    position: "relative",
    borderRadius: "1rem",
    border: "1px solid #C60734",
    boxShadow: "0 6px 20px 0 #dbdbe8",
    backgroundColor: "#E7E7E7",
    // transition: "0.4s",
    height: "100%",
    padding: "1rem 0.5rem 1rem 0.5rem",
    // paddingLeft: "1rem",
    transition: "transform 0.15s ease-in-out",
    // "&:hover": { transform: "scale3d(1.05, 1.05, 1)" },
  },
  logo: {
    width: 48,
    height: 100,
    borderRadius: "0.75rem",
    marginRight: "1rem",
  },
  avatar: {
    fontFamily: "Ubuntu",
    fontSize: "0.875rem",
    backgroundColor: "#6d7efc",
  },
  join: {
    background: "linear-gradient(to top, #638ef0, #82e7fe)",
    "& > *": {
      textTransform: "none !important",
    },
  },
}));

const CustomCard = ({
  thumbnail,
  title,
  subtitle,
  distance,
  item,
  setState,
  loadingState,
  activeMarkerName,
  activeMarkerId,
  setActiveMarkerName,
  setActiveMarkerId,
  selectedMarker,
  // joined = false,
}) => {
  const storeName = useSelector((state) => state.store.nearestStore);
  const dispatch = useDispatch();
  const styles = useStyles();
  let navigate = useNavigate();
  const location = useLocation();
  const storeData = useSelector((state) => state.store.storeData);
  const [loading, setLoading] = useState(loadingState);
  const [opens, setOpens] = useState(false);
  const [open, setOpen] = useState(false);
  const [tempStoreStatus, setTempStoreStatus] = useState([]);
  const [tempDay, setTempDay] = useState({});
  const [storeStatus, setStoreStatus] = useState([]);
  const [day, setDay] = useState({});
  const [timeRange, setTimeRange] = useState();
  const [timeTempRange, setTimeTempRange] = useState();
  const cartDetail = useSelector((state) => state.cart.cart);
  const [displayTitle, setDisplayTitle] = useState("");
  const [storeStartEndTime, setStoreStartEndTime] = useState(false);
  const [storeDetail, setStoreDetail] = useState(false);
  let timeZoneDate = new Date().toLocaleString("en-US", {
    timeZone: item?.time_zone,
  });
  let timeZoneDateHours = new Date(timeZoneDate)?.getHours();
  let timeZoneDateMinutes = new Date(timeZoneDate)?.getMinutes();
  let timeZoneDateDay = new Date(timeZoneDate)?.getDay();

  useEffect(() => {
    if (item !== "" && item != null) {
      setLoading(true);
      const fetchStoreData = async () => {
        setLoading(false);
        let res = item;
        setStoreStartEndTime(item);

        let status = [
          {
            Start: res.open_hrs?.sun_s,
            Close: res.close_hrs?.sun_e,
            storeDayStatus: res?.closed?.sun_c,
            onLineStatus: res?.online_order_status,
          },
          {
            Start: res.open_hrs?.mon_s,
            Close: res.close_hrs?.mon_e,
            storeDayStatus: res?.closed?.mon_c,
            onLineStatus: res?.online_order_status,
          },
          {
            Start: res.open_hrs?.tue_s,
            Close: res.close_hrs?.tue_e,
            storeDayStatus: res?.closed?.tue_c,
            onLineStatus: res?.online_order_status,
          },
          {
            Start: res.open_hrs?.wed_s,
            Close: res.close_hrs?.wed_e,
            storeDayStatus: res?.closed?.wed_c,
            onLineStatus: res?.online_order_status,
          },
          {
            Start: res.open_hrs?.thu_s,
            Close: res.close_hrs?.thu_e,
            storeDayStatus: res?.closed?.thu_c,
            onLineStatus: res?.online_order_status,
          },
          {
            Start: res.open_hrs?.fri_s,
            Close: res.close_hrs?.fri_e,
            storeDayStatus: res?.closed?.fri_c,
            onLineStatus: res?.online_order_status,
          },
          {
            Start: res.open_hrs?.sat_s,
            Close: res.close_hrs?.sat_e,
            storeDayStatus: res?.closed?.sat_c,
            onLineStatus: res?.online_order_status,
          },
        ];
        setTempStoreStatus(status);
        let currentTime = (
          (timeZoneDateHours < 10
            ? "0" + timeZoneDateHours
            : timeZoneDateHours) +
          ":" +
          (timeZoneDateMinutes >= 10
            ? timeZoneDateMinutes
            : "0" + timeZoneDateMinutes)
        )?.toString();

        let boolTime =
          currentTime >= status[timeZoneDateDay]?.Start?.slice(0, 5) &&
          status[timeZoneDateDay]?.Close?.slice(0, 5) >= currentTime;
        setTimeTempRange(boolTime);
        setTempDay(status[timeZoneDateDay]);
      };
      fetchStoreData();
    } else {
      setLoading(false);
    }
  }, [item]);
  useEffect(() => {
    if (tempStoreStatus?.length > 0 && tempDay?.Start) {
      setStoreStatus(tempStoreStatus);
      setDay(tempDay);
      setTimeRange(timeTempRange);
    }
  }, [tempDay]);
  const onClick = (item) => {

    dispatch(setMarkerSize({ width: 35, height: 52 }));
    dispatch(setActiveMarker(item.id));
    setActiveMarkerName(item.store_name);
    setActiveMarkerId(item.id)
    if (
      day?.storeDayStatus == false &&
      day?.onLineStatus === "on" &&
      timeRange == true
    ) {
      if (item.store_name === storeName.store_name) {
        dispatch(setStoreData(storeData));
        dispatch(setNearestStore(item));
        dispatch(
          setStoreCoordinate({
            lat: Number(item?.latitude),
            lng: Number(item?.longitude),
          })
        );
        setState(false);
      } else if (!storeName) {
        dispatch(setStoreData(storeData));
        dispatch(setNearestStore(item));
        dispatch(
          setStoreCoordinate({
            lat: Number(item?.latitude),
            lng: Number(item?.longitude),
          })
        );
        if (location.pathname === "/menu"){
          dispatch(setTabValue(2))
          navigate(`/`)
        }
      } else if (
        item.storeName !== storeName.store_name &&
        cartDetail.length === 0
      ) {

        dispatch(setStoreData(storeData));
        dispatch(setNearestStore(item));
        dispatch(
          setStoreCoordinate({
            lat: Number(item?.latitude),
            lng: Number(item?.longitude),
          })
        );
        if (location.pathname === "/menu"){
          dispatch(setTabValue(2))
          navigate(`/`)
        }
      } else {
        setOpen(true);
      }
    } else {
      if (
        day?.storeDayStatus == true &&
        day?.onLineStatus === "on" &&
        timeRange == true
      ) {
        setOpens(true);
        setDisplayTitle(
          "This store is currently closed.  Please select another store"
        );
      } else if (
        day?.storeDayStatus == false &&
        day?.onLineStatus !== "on" &&
        timeRange == true
      ) {
        setOpens(true);
        setDisplayTitle(
          "Online ordering is not available for this store. Please Select another store"
        );
      } else if (
        day?.storeDayStatus == false &&
        day?.onLineStatus == "on" &&
        timeRange == false
      ) {
        setOpens(true);
        setDisplayTitle(
          "This store is currently closed.  Please select another store."
        );
      }else if (
        day?.storeDayStatus == false &&
        day?.onLineStatus == "off" &&
        timeRange == false
      ) {
        setOpens(true);
        setDisplayTitle(
          "This store is currently closed.  Please select another store."
        );
      }
      else if (
        day?.storeDayStatus == true &&
        day?.onLineStatus == "off" &&
        timeRange == true
      ) {
        setOpens(true);
        setDisplayTitle(
          "This store is currently closed.  Please select another store."
        );
      }
      else if (
        day?.storeDayStatus == true &&
        day?.onLineStatus == "off" &&
        timeRange == false
      ) {
        setOpens(true);
        setDisplayTitle(
          "This store is currently closed.  Please select another store."
        );
      }
       else {
        dispatch(setStoreData(storeData));
        dispatch(setNearestStore(item));
        dispatch(
          setStoreCoordinate({
            lat: Number(item?.latitude),
            lng: Number(item?.longitude),
          })
        );
      }
    }
  };

useEffect(()=>{
  // onClick(item)
  if(selectedMarker.id==item.id){

  dispatch(setMarkerSize({ width: 35, height: 52 }));
    dispatch(setActiveMarker(item.id));
    setActiveMarkerName(item.store_name);
    setActiveMarkerId(item.id)
    if (
      day?.storeDayStatus == false &&
      day?.onLineStatus === "on" &&
      timeRange == true
    ) {
      if (item.store_name === storeName.store_name) {
        dispatch(setStoreData(storeData));
        dispatch(setNearestStore(item));
        dispatch(
          setStoreCoordinate({
            lat: Number(item?.latitude),
            lng: Number(item?.longitude),
          })
        );
        setState(false);
        // setStateS(false);
        dispatch(setActiveMarker("out"))
      } else if (!storeName) {
        dispatch(setStoreData(storeData));
        dispatch(setNearestStore(item));
        dispatch(
          setStoreCoordinate({
            lat: Number(item?.latitude),
            lng: Number(item?.longitude),
          })
        );
        // setState(false);
        // setOpen(false);
        dispatch(setActiveMarker("out"))
        if (location.pathname === "/menu"){
          dispatch(setTabValue(2))
          navigate(`/`)
        }
      } else if (
        item.storeName !== storeName.store_name &&
        cartDetail.length == 0
      ) {
        // let storeList =  handleStoreList(storeData, item);
        dispatch(setStoreData(storeData));
        dispatch(setNearestStore(item));
        dispatch(
          setStoreCoordinate({
            lat: Number(item?.latitude),
            lng: Number(item?.longitude),
          })
        );
        // setState(false);
        dispatch(setActiveMarker("out"))
        // setStateS(false);
        // setOpen(true);
        if (location.pathname === "/menu"){
          dispatch(setTabValue(2))
          navigate(`/`)
        }
      } else {
        setOpen(true);
      }
    } else {
      if (
        day?.storeDayStatus == true &&
        day?.onLineStatus === "on" &&
        timeRange == true
      ) {
        setOpens(true);
        setDisplayTitle(
          "This store is currently closed.  Please select another store"
        );
      } else if (
        day?.storeDayStatus == false &&
        day?.onLineStatus !== "on" &&
        timeRange == true
      ) {
        setOpens(true);
        setDisplayTitle(
          "Online ordering is not available for this store. Please Select another store"
        );
      } else if (
        day?.storeDayStatus == false &&
        day?.onLineStatus == "on" &&
        timeRange == false
      ) {
        setOpens(true);
        setDisplayTitle(
          "This store is currently closed.  Please select another store."
        );
      }else if (
        day?.storeDayStatus == false &&
        day?.onLineStatus === "off" &&
        timeRange == false
      ) {
        setOpens(true);
        setDisplayTitle(
          "This store is currently closed.  Please select another store."
        );
      } else if (
        day?.storeDayStatus == true &&
        day?.onLineStatus === "off" &&
        timeRange == false
      ) {
        setOpens(true);
        setDisplayTitle(
          "This store is currently closed.  Please select another store."
        );
      }
      else if (
        day?.storeDayStatus == true &&
        day?.onLineStatus === "off" &&
        timeRange == true
      ) {
        setOpens(true);
        setDisplayTitle(
          "This store is currently closed.  Please select another store."
        );
      }
       else {
        dispatch(setStoreData(storeData));
        dispatch(setNearestStore(item));
      }
    }
  }
},[selectedMarker])

  const handleStoreDetail = () => {
    if (storeDetail == false) {
      setStoreDetail(true);
    } else {
      setStoreDetail(false);
    }
  };

  return (
    <>
      {loading ? (
        <div className={styles.root}>
          <div style={{ display: "flex", padding: "1rem" }}>
            <CircularProgress />
          </div>
        </div>
      ) : (
        <div
          className={styles.root}
          onClick={() => {
            onClick(item);
          }}
        >
          <div
            className={
              item?.id != storeName?.id
                ? activeMarkerId == item.id &&
                  activeMarkerName == item.store_name
                  ? styles.selectedCard
                  : styles.card
                : styles.selectedCard
            }
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>
                <div style={{ fontSize: "0.9rem" }}>
                  {title}
                  {item?.id == storeName?.id &&
                  day?.storeDayStatus == false &&
                  day?.onLineStatus === "on" ? (
                    <CheckCircleRoundedIcon
                      sx={{
                        color: "green",
                        marginLeft: "1rem",
                        marginTop: "-0.6rem",
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>

                <div style={{ fontSize: "0.9rem" }}>
                  <b>{subtitle}</b>
                </div>
                <div>
                  <b>
                    {item.address.replace("~", " ")}
                    {item.zip_code}
                  </b>
                </div>
                <span style={{ fontSize: "0.9rem" }}>
                  <>Distance</>
                  {" :"}
                  {` ${distance}`}{" "}
                </span>
              </div>
              {/* { (activeMarker == item.id || activeMarker== item.store_name)|| */}
              {(activeMarkerId == item.id &&
                activeMarkerName == item.store_name) ||
              (item?.id == storeName?.id &&
                day?.storeDayStatus == false &&
                day?.onLineStatus === "on") ? (
                <ArrowForwardIosIcon
                  onClick={() => onClick(item)}
                  fontSize="large"
                />
              ) : (
                ""
              )}
            </div>
            <div style={{ display: "flex" }}>
              {item && day.Start && (
                <div>
                  {day?.storeDayStatus == true ? (
                    <>
                      <b>
                        {""}
                        {"Store closed"}
                      </b>
                      <InfoIcon
                        fontSize="small"
                        style={{
                          fill: "#C60734",
                          marginLeft: "0.5rem",
                          marginTop: "-0.6rem",
                        }}
                        onClick={(e) => {
                          handleStoreDetail();
                          e.stopPropagation();
                        }}
                      />
                    </>
                  ) : day?.storeDayStatus == false ? (
                    <div>
                      {timeRange ? (
                        <b style={{ color: "green" }}>
                          {""}
                          {"Open until " + AmPmFormat(day?.Close?.slice(0, 5))}
                        </b>
                      ) : (
                          (timeZoneDateHours < 10
                            ? "0" + timeZoneDateHours
                            : timeZoneDateHours) +
                          ":" +
                          (timeZoneDateMinutes < 10
                            ? "0" + timeZoneDateMinutes
                            : timeZoneDateMinutes)
                        ).toString() < day?.Start?.slice(0, 5) ? (
                        <b style={{ color: "red" }}>
                          {"Closed.  Opens at " +
                            AmPmFormat(day?.Start?.slice(0, 5))}
                        </b>
                      ) : (
                        <b>
                          {""}
                          {"Store closed"}
                        </b>
                      )}
                      <InfoIcon
                        fontSize="small"
                        style={{
                          fill: "#C60734",
                          marginLeft: "0.5rem",
                          marginTop: "-0.6rem",
                        }}
                        onClick={(e) => {
                          handleStoreDetail();
                          e.stopPropagation();
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {day?.onLineStatus == "off" &&
                  day?.storeDayStatus == false &&
                  timeRange == true ? (
                    <Box sx={{ display: "flex" }}>
                      {" "}
                      <CancelIcon sx={{ color: "yellow" }} />{" "}
                      <div>Web order currently unavailable</div>
                    </Box>
                  ) : (
                    ""
                  )}
                </div>
              )}
            </div>

            {storeDetail && (
              <div>
                <EastIcon
                  fontSize="small"
                  style={{ fill: "#C60734", marginTop: "-0.5rem" }}
                />
                <a
                  target="blank"
                  href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                    `${item.address.replace("~", " ")}, ${item.city}, ${
                      item.state
                    } ${item.zip_code}`
                  )}`}
                >
                  <a
                    target="blank"
                    href={`http://maps.apple.com/?address=${encodeURIComponent(
                      `${item.address.replace("~", " ")}, ${item.city}, ${
                        item.state
                      } ${item.zip_code} $`
                    )}`}
                  >
                    Directions
                  </a>
                </a>
                {item?.mobile_no && (
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <CallIcon fontSize="small" style={{ fill: "#C60734" }} />
                    <a href={`tel:${item?.mobile_no}`}>{item?.mobile_no}</a>
                  </div>
                )}
                <Typography
                  fontWeight="bold"
                  style={{ textDecoration: "underline" }}
                  sx={{
                    marginLeft: { lg: "29%", md: "29%", sm: "23%", xs: "17%" },
                  }}
                >
                  Store Hours
                </Typography>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    justifyContent: "center",
                    marginLeft: { lg: "2.6%", md: "14%", sm: "7%", xs: "1%" },
                  }}
                >
                  <Grid item lg={4} xs={4} justifyContent="left">
                    <Typography
                      sx={{
                        fontSize: { xs: "0.8rem", md: "" },
                        color: timeZoneDateDay === 1 ? "#C60734" : "inherit",
                        fontWeight: timeZoneDateDay === 1 ? "bold" : "normal",
                      }}
                    >
                      Monday
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: "0.8rem", md: "" },
                        color: timeZoneDateDay === 2 ? "#C60734" : "inherit",
                        fontWeight: timeZoneDateDay === 2 ? "bold" : "normal",
                      }}
                    >
                      Tuesday
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: "0.8rem", md: "" },
                        color: timeZoneDateDay === 3 ? "#C60734" : "inherit",
                        fontWeight: timeZoneDateDay === 3 ? "bold" : "normal",
                      }}
                    >
                      Wednesday
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: "0.8rem", md: "" },
                        color: timeZoneDateDay === 4 ? "#C60734" : "inherit",
                        fontWeight: timeZoneDateDay === 4 ? "bold" : "normal",
                      }}
                    >
                      Thursday
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: "0.8rem", md: "" },
                        color: timeZoneDateDay === 5 ? "#C60734" : "inherit",
                        fontWeight: timeZoneDateDay === 5 ? "bold" : "normal",
                      }}
                    >
                      Friday
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: "0.8rem", md: "" },
                        color: timeZoneDateDay === 6 ? "#C60734" : "inherit",
                        fontWeight: timeZoneDateDay === 6 ? "bold" : "normal",
                      }}
                    >
                      Saturday
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: "0.8rem", md: "" },
                        color: timeZoneDateDay === 0 ? "#C60734" : "inherit",
                        fontWeight: timeZoneDateDay === 0 ? "bold" : "normal",
                      }}
                    >
                      Sunday
                    </Typography>
                  </Grid>
                  <Grid item lg={6} xs={8}>
                    <Typography
                      sx={{
                        fontSize: { xs: "0.8rem", md: "" },
                        color: timeZoneDateDay === 1 ? "#C60734" : "inherit",
                        fontWeight: timeZoneDateDay === 1 ? "bold" : "normal",
                      }}
                    >
                      {AmPmFormat(
                        storeStartEndTime?.open_hrs?.mon_s?.slice(0, 5)
                      )}{" "}
                      {AmPmFormat(
                        storeStartEndTime?.open_hrs?.mon_s?.slice(0, 5)
                      ).length == 8
                        ? "-"
                        : `\u00A0-`}{" "}
                      {AmPmFormat(
                        storeStartEndTime?.close_hrs?.mon_e?.slice(0, 5)
                      )}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: "0.8rem", md: "" },
                        color: timeZoneDateDay === 2 ? "#C60734" : "inherit",
                        fontWeight: timeZoneDateDay === 2 ? "bold" : "normal",
                      }}
                    >
                      {AmPmFormat(
                        storeStartEndTime?.open_hrs?.mon_s?.slice(0, 5)
                      )}{" "}
                      {AmPmFormat(
                        storeStartEndTime?.open_hrs?.mon_s?.slice(0, 5)
                      ).length == 8
                        ? "-"
                        : `\u00A0-`}{" "}
                      {AmPmFormat(
                        storeStartEndTime?.close_hrs?.mon_e?.slice(0, 5)
                      )}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: "0.8rem", md: "" },
                        color: timeZoneDateDay === 3 ? "#C60734" : "inherit",
                        fontWeight: timeZoneDateDay === 3 ? "bold" : "normal",
                      }}
                    >
                      {AmPmFormat(
                        storeStartEndTime?.open_hrs?.mon_s?.slice(0, 5)
                      )}{" "}
                      {AmPmFormat(
                        storeStartEndTime?.open_hrs?.mon_s?.slice(0, 5)
                      ).length == 8
                        ? "-"
                        : `\u00A0-`}{" "}
                      {AmPmFormat(
                        storeStartEndTime?.close_hrs?.mon_e?.slice(0, 5)
                      )}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: "0.8rem", md: "" },
                        color: timeZoneDateDay === 4 ? "#C60734" : "inherit",
                        fontWeight: timeZoneDateDay === 4 ? "bold" : "normal",
                      }}
                    >
                      {AmPmFormat(
                        storeStartEndTime?.open_hrs?.mon_s?.slice(0, 5)
                      )}{" "}
                      {AmPmFormat(
                        storeStartEndTime?.open_hrs?.mon_s?.slice(0, 5)
                      ).length == 8
                        ? "-"
                        : `\u00A0-`}{" "}
                      {AmPmFormat(
                        storeStartEndTime?.close_hrs?.mon_e?.slice(0, 5)
                      )}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: "0.8rem", md: "" },
                        color: timeZoneDateDay === 5 ? "#C60734" : "inherit",
                        fontWeight: timeZoneDateDay === 5 ? "bold" : "normal",
                      }}
                    >
                      {AmPmFormat(
                        storeStartEndTime?.open_hrs?.mon_s?.slice(0, 5)
                      )}{" "}
                      {AmPmFormat(
                        storeStartEndTime?.open_hrs?.mon_s?.slice(0, 5)
                      ).length == 8
                        ? "-"
                        : `\u00A0-`}{" "}
                      {AmPmFormat(
                        storeStartEndTime?.close_hrs?.mon_e?.slice(0, 5)
                      )}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: "0.8rem", md: "" },
                        color: timeZoneDateDay === 6 ? "#C60734" : "inherit",
                        fontWeight: timeZoneDateDay === 6 ? "bold" : "normal",
                      }}
                    >
                      {AmPmFormat(
                        storeStartEndTime?.open_hrs?.mon_s?.slice(0, 5)
                      )}{" "}
                      {AmPmFormat(
                        storeStartEndTime?.open_hrs?.mon_s?.slice(0, 5)
                      ).length == 8
                        ? "-"
                        : `\u00A0-`}{" "}
                      {AmPmFormat(
                        storeStartEndTime?.close_hrs?.mon_e?.slice(0, 5)
                      )}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: "0.8rem", md: "" },
                        color: timeZoneDateDay === 0 ? "#C60734" : "inherit",
                        fontWeight: timeZoneDateDay === 0 ? "bold" : "normal",
                      }}
                    >
                      {AmPmFormat(
                        storeStartEndTime?.open_hrs?.mon_s?.slice(0, 5)
                      )}{" "}
                      {AmPmFormat(
                        storeStartEndTime?.open_hrs?.mon_s?.slice(0, 5)
                      ).length == 8
                        ? "-"
                        : `\u00A0-`}{" "}
                      {AmPmFormat(
                        storeStartEndTime?.close_hrs?.mon_e?.slice(0, 5)
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            )}

            <Divider sx={{ bgcolor: "#C60734", margin: "0.5rem 0rem" }} />
            {item?.driveThru == true && (
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <div style={{ display: "flex" }}>
                  <StorefrontIcon style={{ fill: "#C60734" }} />
                  <Typography sx={{ pl: 1 }}>In Store</Typography>
                </div>
                <div style={{ display: "flex" }}>
                  <DriveEtaIcon style={{ fill: "#C60734" }} />
                  <Typography sx={{ pl: 1 }}>Drive Thru</Typography>
                </div>
              </div>
            )}
            {item?.driveThru == false && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <StorefrontIcon style={{ fill: "#C60734" }} />
                {/* <div style={{marginRight:"1rem"}}> */}
                <Typography sx={{ pl: 1 }}>In Store</Typography>
                {/* </div> */}
              </div>
            )}
          </div>
        </div>
      )}

      {opens && (
        <AlertPopup
          open={opens}
          setOpen={setOpens}
          title={"storeUnavailable"}
          buttonType1={true}
          displayTitle={displayTitle}
        />
      )}
      {open && (
        <AlertPopup
          open={open}
          setOpen={setOpen}
          title={"storeChange"}
          buttonType1={true}
          buttonType2={true}
          displayTitle={"Changing store will empty the cart items"}
          store={item}
          setState={setState}
        />
      )}
      {/* {openPriceAlert && (
        <AlertPopup
          open={openPriceAlert}
          setOpen={setOpenPriceAlert}
          title={"priceChange"}
          buttonType1={true}
          buttonType2={true}
          displayTitle={"Price may be different. Do you want to continue?"}
          store={tempSelectedStore}
          setState={setState}
        />
      )} */}
    </>
  );
};

export const StoreDistance = React.memo(function TeamCard({
  setState,
  selectedMarker,
  activeMarkerName,
  activeMarkerId,
  setActiveMarkerName,
  setActiveMarkerId,
}) {

  const storeData = useSelector((state) => state.store.storeData);
  return (
    <>
      {storeData?.length > 0 &&
        storeData.map((item, index) => (
          <CustomCard
            key={item.id}
            activeMarkerId={activeMarkerId}
            activeMarkerName={activeMarkerName}
            setActiveMarkerId={setActiveMarkerId}
            setActiveMarkerName={setActiveMarkerName}
            selectedMarker={selectedMarker}
            thumbnail={"./logos.png"}
            title={item.store_name}
            distance={`${item.distance} miles (${item.duration} ride)`}
            item={item}
            setState={setState}
            loadingState={true}
            id={index}
          />
        ))}
    </>
  );
});
export default StoreDistance;
