import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import Upload from "../SignUp/FileUpload";
import { useSelector } from "react-redux";
import { errorToastMessage, formatPhoneNumbers } from "../../Common/utils";
import { useDispatch } from "react-redux";
import { setUser } from "../../Redux/UserSlice";
import {CircularProgress} from "@mui/material";
import { validateName } from "../../Common/FormVailidation";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Typography,
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  FormControl,
  Box,
  Backdrop,
  LinearProgress,
} from "@mui/material";
import {
  validateEmail,
  validatePhoneNumber,
  validateUsername,
} from "../../Common/FormVailidation";
import { UpdateUserProfile } from "../../Api/API";
import DeleteAccount from "./DeleteAccount";
import { toast } from "react-toastify";
import Loader from "../../Common/Loader"
import ButtonLoader from "../../Common/ButtonLoader";
import { setTabValue } from "../../Redux/CartSlice";
toast.configure();

const useStyles = makeStyles((theme) => ({
  error: {
    color: "#bf1650",
    marginLeft: "9.5rem",
    " &::before": {
      display: "inline",
      content: "⚠ ",
    },
  },
  hide: {
    display: "none",
  },
}));

const Personal = () => {
  const defaultValues = {
    deviceType: 0,
    dateOfBirth: "",
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    userId: 188729,
  };
  const { control, handleSubmit, formState , setValue } = useForm({
    defaultValues,
  });
  const { errors } = formState;
  let navigate = useNavigate();
  const userDetail = useSelector((state) => state.user.user);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [image , setImage] = useState(10);
  const [selectedCategoryImage , setSelectedCategoryImage] = useState("");
  const [showPopup, setshowPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageUpload, setImageUpload] = useState("");
  const userGoogleData = useSelector((state) => state.user.userGoogleData);
  const handleDelete = () => {
    setshowPopup(true);
  }
  useEffect(()=>{
    dispatch(setTabValue(2))
    let name  = userDetail?.username ?userDetail?.username : userDetail?.first_name;
    setValue("firstName", userDetail?.first_name );
    setValue("lastName", userDetail?.last_name);
    setValue("phoneNumber",userDetail?.phone_number?.length == 12 ? userDetail?.phone_number : userDetail?.phone_no?.length == 12 ? userDetail?.phone_no : formatPhoneNumbers(userDetail?.phone_number?userDetail?.phone_number : userDetail?.phone_no));
    setValue("email",userDetail?.email);
    let dob = userDetail?.dob?.length>10? userDetail?.dob.slice(5,10) : userDetail?.dob
    let dateOfBirth =  userDetail?.date_of_birth?.length>10? userDetail?.date_of_birth.slice(5,10) : userDetail?.date_of_birth
    setValue("dateOfBirth",userDetail?.dob ? dob:dateOfBirth);
    if(userDetail?.profile_pic || userDetail?.file)

      setSelectedCategoryImage(userDetail?.profile_pic ? userDetail?.profile_pic : userDetail?.file);
  },[])

  useEffect(()=>{
    setValue("firstName", userDetail?.first_name );
    setValue("lastName", userDetail?.last_name);
    setValue("phoneNumber",userDetail?.phone_number?.length == 12 ? userDetail?.phone_number : userDetail?.phone_no?.length == 12 ? userDetail?.phone_no : formatPhoneNumbers(userDetail?.phone_number?userDetail?.phone_number : userDetail?.phone_no));
    setValue("email",userDetail?.email);
    let dob = userDetail?.dob?.slice(5,10) 
    let dateOfBirth = userDetail?.date_of_birth?.slice(5,10)
    setValue("dateOfBirth",userDetail?.dob ? dob:dateOfBirth);
    // setValue("dateOfBirth",userDetail?.date_of_birth?.length>10? userDetail?.date_of_birth.slice(0,10) : userDetail?.date_of_birth);
    if(userDetail?.profile_pic || userDetail?.file)
      setSelectedCategoryImage(userDetail?.profile_pic ? userDetail?.profile_pic : userDetail?.file);
  },[userDetail])
  let dobP = userDetail?.dob?.slice(0,10) 
    let dateOfBirthP = userDetail?.date_of_birth?.slice(0,10)
  const onSubmit = (data) => {
    data.userId = userDetail.customer_id ?  userDetail?.customer_id  :  userDetail?.person_id
    data.dateOfBirth = userDetail?.dob ? dobP : dateOfBirthP
    setLoading(true)
    let fd = new FormData()
    if(imageUpload){ fd.append("file", imageUpload.target.files[0])}
    if(!data.dateOfBirth){data.dateOfBirth = "01-01"}
    UpdateUserProfile(fd,data)
      .then((res) => {
        setLoading(false)
        if (res?.response_code == 1) {
          dispatch(setUser(res.result));
          toast.success(res?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          navigate('/');
        } else {
          setLoading(false)
          toast.error(res?.responseMessage, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false)
        errorToastMessage();
      });
  };
  const handleImage = (e) => {
    setImageUpload(e);
    setSelectedCategoryImage(null);
  };



  return (
    <div

    >
      <Backdrop
        sx={{ color: '#fff', zIndex: 1000}}
        open={loading}
      // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    <Container style={{paddingBottom:"1rem"}}>
       <Card sx={{ borderRadius: "1rem", marginTop:"4vh" }}>
          {loading ? <LinearProgress color="warning" /> : ""}
          <CardContent>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Grid container sx={{ margin: {lg:"1rem"}}}>
                <Grid item md={3} xs={12} sm={12} lg={3} marginBottom="1.5rem" align="center" >
                  <FormControl fullWidth>
                    <Controller
                      name="file"
                      render={({ field }) => (
                        <>
                          <input
                            name="upload-image"
                            type="file"
                            {...field}
                            id="file"
                            className={classes.hide}
                            accept="image/*"
                            onChange={(e) => handleImage(e)}
                          />
                         <Upload imageUpload={imageUpload} selectedImage ={selectedCategoryImage}/>
                        </>
                      )}
                      control={control}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12} sm={10} lg={6}>
                  <Box display="flex" >
                    <Typography
                      sx={{ width: "13rem" }}
                      variant="subtitle1"
                      gutterBottom
                    >
                      First Name :
                    </Typography>
                    <FormControl fullWidth>
                      <Controller
                        name="firstName"
                        rules={
                          ({
                            required: true,
                          },
                          {
                            validate: (value) => {
                              return validateName(value, "First Name");
                            },
                          })
                        }
                        render={({ field }) => (
                          <TextField
                            {...field}
                            id="firstName"
                            type="text"
                            fullWidth
                            size="small"
                            variant="standard"
                            placeholder="Your name"
                            inputProps={{
                              maxLength: 30,
                              form: {
                                autocomplete: "off",
                              },
                            }}
                            onInput={(e) => {
                            e.target.value = e.target.value.replace(/[^a-zA-Z.' ]/g,"")
                            }}
                          />
                        )}
                        control={control}
                      />
                    </FormControl>
                  </Box>
                  {errors?.firstName ? (
                    <Box
                      sx={{
                        color: "#bf1650",
                      marginLeft: {lg:"9.5rem",md:"9.5rem",sm:"10rem"},
                        " &::before": {
                          display: "inline",

                      }}}
                    >{errors.firstName.message}</Box>
                  ) : (
                    <p><br/></p>
                  )}
                  <Box display="flex" >
                    <Typography
                      sx={{ width: "13rem" }}
                      variant="subtitle1"
                      gutterBottom
                    >
                      Last Name :
                    </Typography>
                    <FormControl fullWidth>
                      <Controller
                        name="lastName"
                        rules={
                          ({
                            required: false,
                          },
                          {
                            validate: (value) => {
                            return validateName(value,"Last Name");
                            },
                          })
                        }
                        render={({ field }) => (
                          <TextField
                            {...field}
                            id="lastName"
                            type="text"
                            fullWidth
                            size="small"
                            variant="standard"
                            placeholder="Your name"
                            inputProps={{
                              maxLength: 30,
                              form: {
                                autocomplete: "off",
                              },
                            }}
                            onInput={(e) => {
                            e.target.value = e.target.value.replace(/[^a-zA-Z.' ]/g,"")
                            }}
                          />
                        )}
                        control={control}
                      />
                    </FormControl>
                  </Box>
                  {errors?.lastName ? (
                    <Box
                      sx={{
                        color: "#bf1650",
                      marginLeft: {lg:"9.5rem",md:"9.5rem",sm:"10rem"},
                        " &::before": {
                          display: "inline",
                        marginBottom:"1rem"  
                      }}}
                    >{errors.lastName.message}</Box>
                  ) : (
                    <p><br/></p>
                  )}
                  <Box display="flex">
                    <Typography
                      sx={{ width: "13rem" }}
                      variant="subtitle1"
                      gutterBottom
                    >
                      Phone No. :
                    </Typography>
                    <FormControl fullWidth>
                      <Controller
                        name="phoneNumber"
                        rules={
                          ({
                            required: true,
                          },
                          {
                            validate: (value) => {
                              return validatePhoneNumber(value);
                            },
                          })
                        }
                        render={({ field }) => (
                          <TextField
                            {...field}
                            id="phoneNumber"
                            disabled
                            type="text"
                            fullWidth
                            size="small"
                            variant="standard"
                            placeholder="Your contact number"
                            inputProps={{ maxLength: 12 }}
                            onInput={(e) => {
                            e.target.value = e.target.value.replace(/[^0-9]/g,"")
                              e.target.value = e.target.value.replace(
                                /(\d{3})(\d{3})(\d{4})/,
                                "$1-$2-$3"
                              );
                            }}
                          />
                        )}
                        control={control}
                      />
                    </FormControl>
                  </Box>
                  {errors?.phoneNumber ? (
                    <Box  sx={{
                      color: "#bf1650",
                      marginLeft: {lg:"9.5rem",md:"9.5rem",sm:"10rem"},
                    }}>
                      {errors.phoneNumber.message}
                    </Box>
                  ) : (
                    <p><br/></p>
                  )}
                  <Box display="flex">
                    <Typography
                      sx={{ width: "13rem" }}
                      variant="subtitle1"
                      gutterBottom
                    >
                      Email :
                    </Typography>
                    <FormControl fullWidth>
                      <Controller
                        name="email"
                        rules={
                          ({
                            required: true,
                          },
                          {
                            validate: (value) => {
                              return validateEmail(value);
                            },
                          })
                        }
                        render={({ field }) => (
                          <TextField
                            {...field}
                            disabled={userGoogleData?.email ? true : false}
                            id="email"
                            type="text"
                            fullWidth
                            size="small"
                            variant="standard"
                            placeholder="Enter your mail"
                          />
                        )}
                        control={control}
                      />
                    </FormControl>
                  </Box>
                  {errors?.email ? (
                    <Box
                      sx={{
                        color: "#bf1650",
                      marginLeft: {lg:"9.5rem",md:"9.5rem",sm:"10rem"},
                      marginBottom:"1rem"
                      }}
                    >{errors.email.message}</Box>
                  ) : (
                    <p><br/></p>
                  )}
                  <Box display="flex">
                    <Typography
                      sx={{ width: "13rem" }}
                      variant="subtitle1"
                      gutterBottom
                    >
                      Birthday :
                    </Typography>
                    <FormControl fullWidth>
                      <Controller
                        name="dateOfBirth"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            id="dateOfBirth"
                            disabled
                            // type="date"
                            fullWidth
                            size="small"
                            variant="standard"
                          //   inputProps={{
                          //   max: new Date().toISOString().split("T")[0],
                          // }}
                          />
                        )}
                        control={control}
                      />
                    </FormControl>
                  </Box>
                </Grid>
                <Container sx={{
                  display: { xs: "block", sm: "block", md: "flex", lg: "flex" },
                  marginTop: "2rem",
                  marginBottom: { xs: "2rem" },
                  justifyContent: { md: "center", lg: "center" },
                  textAlign: "center",
                  "@media (max-width: 768px)": {
                  "& > button": {  
                    width: "70%"  
                  }
                }
                }}>
                  <Button
                    type="submit"
                    sx={{
                      padding: "0.5rem 2rem",
                      backgroundColor: "#B7273B",
                      fontFamily: "Lusitana",
                      borderRadius: "1rem",
                      marginTop: "1rem",
                      display: "block",  
                      margin: { xs: "1rem auto", md: "initial" }  
                    }}
                    variant="contained"
                    style={{ textTransform: "none" }}
                  >
                    Save Changes
                  </Button>
                  <Button
                    sx={{
                      padding: "0.5rem 2rem",
                      fontFamily: "Lusitana",
                      borderRadius: { xs: "0", sm: "0", md: "1rem" },
                      marginTop: "1rem",
                      backgroundColor: { xs: "white", sm: "white", md: "#B7273B" },
                      color: { xs: "#B7273B", sm: "#B7273B", md: "white" },
                      textDecoration: { xs: "underline", sm: "underline", md: "none" },
                      textDecorationColor: "#B7273B",
                      boxShadow: { xs: "none", sm: "none", md: undefined },
                      display: "block",  
                      margin: { xs: "1rem auto", md: "initial" },  
                      marginLeft: {md: "1.5rem"},
                      "&:hover": {
                        backgroundColor: "transparent", 
                        boxShadow: "none",
                      },
                    }}
                    variant="contained"
                    style={{ textTransform: "none" }}
                    onClick={handleDelete}
                  >
                    Delete Account
                  </Button>
                </Container>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Container>
      {showPopup && <DeleteAccount showPopup={showPopup} setshowPopup={setshowPopup}></DeleteAccount>}
    </div>
  );
};

export default Personal;
