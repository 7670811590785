import CancelIcon from "@mui/icons-material/Cancel";
import EmailIcon from "@mui/icons-material/Email";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import {
  Backdrop,
  Box,
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  Slide,
  TextField,
  Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  deleteAccount,
  expireOtp,
  resendOtp,
  UpdateUserProfile,
  verifyOtp,
} from "../../Api/API";
import { errorToastMessage } from "../../Common/utils";
import {
  setIsAlreadyUserChecked,
  setIsUserFiveStarData,
  setLogin,
  setUser,
} from "../../Redux/UserSlice";
import { convertPlusToBFormat } from "./EmailVerify";
import NewPassword from "./NewPassword";
toast.configure();
const useStyles = makeStyles((theme) => ({
  root: {},
  paper: { borderRadius: 15 },
  noBorder: {
    border: "none",
  },
  btnLogin: {
    padding: "0.2rem 5rem",
    backgroundColor: "#B7273B",
    margin: "auto",
    fontFamily: "Lusitana",
    textTransform: "none",
    marginTop: "1rem",
  },
  btnNew: {
    padding: "0.2rem 0.5rem",
    backgroundColor: "#B7273B",
    fontFamily: "Lusitana",
    textDecoration: "none",
    color: "white",
    textTransform: "none",
  },
  error: {
    color: "#bf1650",
    " &::before": {
      display: "inline",
      content: "⚠ ",
    },
  },
}));

 React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="down" 
      ref={ref}
      {...props}
    >
    </Slide>
  );
});
const OtpPopUp = (props) => {
  const defaultValues = {
    deviceToken: "",
    deviceType: 0,
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    socialId: "",
    socialType: "",
    userId: "",
    phoneNumber: "",
  };
  const { control, handleSubmit, setValue } = useForm({
    defaultValues,
  });

  const navigate = useNavigate();
  const {
    code,
    setCode,
    showPopup,
    setshowPopup,
    phoneNo,
    checkUserEmail,
    setShowAd,
    title,
    setValues,
    googleLoginData,
    setShowLoginPopup,
    setSignUp,
    birth,
    setTitle,
  } = props;
  const classes = useStyles();
  // const [intervals, setIntervals] = useState();
  const [btnDisabled, setBtnDisabled] = useState(true);
  const Ref = useRef(null);
  const [timer, setTimer] = useState("01:00");
  const [timeUp, setTimeUp] = useState(false);
  const dispatch = useDispatch();
  const [showNewPass, setshowNewPass] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasExpired, setHasExpired] = useState(false);
  const userGoogleData = useSelector((state) => state.user.userGoogleData);
  const updateProfileGoogleLoginData = {
    deviceType: 0,
    dateOfBirth: birth,
    email: googleLoginData?.email,
    firstName: userGoogleData?.firstName,
    lastName: userGoogleData?.lastName,
    phoneNumber: phoneNo,
    userId: googleLoginData?.customer_id,
    socialType: "google",
  };
  let fd = new FormData();
  const onSubmit = (data) => {
    if (!data.otp) {
      toast.error("Enter the verification code", {
        position: toast.POSITION.TOP_RIGHT,
        pauseOnFocusLoss: false,
        autoClose: 1500,
        pauseOnHover: false,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
      });
      return;
    }
    let phoneNumer = props.phoneNo;
    phoneNumer = phoneNumer.replace(/-/g, "");
    setLoading(true);
    verifyOtp(phoneNumer, data)
      .then((res) => {
        setLoading(false);
        if (res.result === "True" || res.result === "true") {
          if (title === "ForgetPassword") {
            setBtnDisabled(true);
            setshowNewPass(true);
            setValue("otp", "");
            setValues("phone", "");
            setCode("+1");
            setShowAd(false);
            setLoading(false);
            // clearInterval(intervals);
          } else if (title === "Signup") {
            setBtnDisabled(true);
            setshowPopup(false);
            // clearInterval(intervals);
            setLoading(false);
            setSignUp(true);
          } else if (title === "GoogleLogin") {
            setLoading(false);
            setBtnDisabled(true);
            setshowPopup(false);
            setShowLoginPopup(false);
            // clearInterval(intervals);
            setLoading(true);
            UpdateUserProfile(fd, updateProfileGoogleLoginData)
              .then((res) => {
                setLoading(false);
                if (res.responseCode === 1 || res.response_code === 1) {
                  setShowLoginPopup(false);
                  dispatch(setLogin(true));
                  dispatch(setUser(res.result));
                  setLoading(false);

                  toast.success("Login successful", {
                    position: toast.POSITION.TOP_RIGHT,
                    pauseOnFocusLoss: false,
                    autoClose: 1500,
                    pauseOnHover: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    draggable: true,
                  });
                } else if (res.response_code === 0) {
                  toast.error(res?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    pauseOnFocusLoss: false,
                    autoClose: 1500,
                    pauseOnHover: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    draggable: true,
                  });
                  deleteAccount("", googleLoginData?.email);
                  setValue("phoneNo", "");
                  setShowLoginPopup(false);
                  setShowAd(true);
                  setLoading(false);
                } else {
                  toast.error(res?.responseMessage, {
                    position: toast.POSITION.TOP_RIGHT,
                    pauseOnFocusLoss: false,
                    autoClose: 1500,
                    pauseOnHover: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    draggable: true,
                  });
                }
                setLoading(false);
              })
              .catch((err) => {
                console.log(err);
                setLoading(false);
              });
          } else {
            setBtnDisabled(true);
            dispatch(setIsUserFiveStarData(true));
            setshowPopup(false);
            setShowAd(false);
            dispatch(setIsAlreadyUserChecked(true));
            setLoading(false);
            navigate("/signup");
            // clearInterval(intervals);
          }
        } else {
          setLoading(false);
          setBtnDisabled(true);
          toast.error(res?.msg, {
            position: toast.POSITION.TOP_RIGHT,
            pauseOnFocusLoss: false,
            autoClose: 1500,
            pauseOnHover: false,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        errorToastMessage();
      });
  };

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    const { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    } else {
      if (Ref.current) {
        clearInterval(Ref.current);
        Ref.current = null;
      }

      setBtnDisabled(false);

      if (!hasExpired) {
        setHasExpired(true);
        expireOtp(phoneNo);
      }
    }
  };
  const clearTimer = (e) => {
    setTimer("01:00");
    setHasExpired(false);

    if (Ref.current) {
      clearInterval(Ref.current);
      Ref.current = null;
    }

    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };
  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 60);
    return deadline;
  };

  useEffect(() => {
    return () => {
      if (Ref.current) {
        clearInterval(Ref.current);
        Ref.current = null;
      }
    };
  }, []);

  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);

  const handleResendOtp = () => {
    if (btnDisabled) {
      return;
    }
    setBtnDisabled(true);

    toast.success("Your verification code has been resent", {
      position: toast.POSITION.TOP_RIGHT,
      pauseOnFocusLoss: false,
      autoClose: 1500,
      pauseOnHover: false,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      color: "green",
    });
    setTimeUp(false);
    setValue("otp", "");
    resendOtp(phoneNo, convertPlusToBFormat(code))
      .then((res) => {
        clearTimer(getDeadTime());
        setBtnDisabled(true);
      })
      .catch((err) => {
        console.log(err);
        errorToastMessage();
        setBtnDisabled(false);
      });
  };
  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: 1000 }} open={loading}></Backdrop>
      <Dialog
        open={showPopup}
        classes={{
          paper: classes.paper,
        }}
        // TransitionComponent={Transition}
      >
        <Box textAlign="right" sx={{ cursor: "pointer" }}>
          <CancelIcon
            fontSize="large"
            onClick={() => {
              // clearInterval(intervals);
              setBtnDisabled(true);
              setshowPopup(false);
              setTitle("");
            }}
          />
        </Box>
        {/* } */}
        <Box p={3}>
          <DialogTitle textAlign="center">
            <img src="./NewLogoG.png" alt="" width="70%" />
          </DialogTitle>
          <Typography
            style={{
              textAlign: "center",
              fontWeight: "600",
              marginBottom: "0.5rem",
            }}
          >
            Please enter the code sent to
          </Typography>
          <Box
            sx={{
              display: "flex",
              marginBottom: "0.5rem",
              justifyContent: "center",
            }}
          >
            <SmartphoneIcon
              style={{
                marginRight: "0.3rem",
              }}
            />
            <Typography> (***) *** {phoneNo.slice(6, 10)}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              marginBottom: "0.8rem",
              justifyContent: "center",
            }}
          >
            {checkUserEmail && (
              <>
                <EmailIcon
                  style={{
                    marginRight: "0.3rem",
                  }}
                />
                <Typography
                  style={{
                    textAlign: "center",
                    fontWeight: "600",
                    fontSize: ".8rem",
                  }}
                >
                  {checkUserEmail}
                </Typography>
              </>
            )}
          </Box>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Box dir="ltr">
              <FormControl fullWidth>
                <Controller
                  name="otp"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="otp"
                      disabled={timeUp}
                      type="text"
                      placeholder="Enter code"
                      InputProps={{
                        classes: { notchedOutline: classes.noBorder },
                        autocomplete: "new-password",
                      }}
                      inputProps={{ maxLength: 6, inputMode: "numeric" }}
                      onInput={(e) => {
                        if (e.target.value.length !== 0) {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                        }
                      }}
                      sx={{ backgroundColor: "#E7E7E7" }}
                      fullWidth
                      size="small"
                      variant="outlined"
                      autoFocus
                    />
                  )}
                  control={control}
                />
              </FormControl>
            </Box>
            <Box
              display={"flex"}
              justifyContent="space-between"
              alignItems="center"
            >
              {btnDisabled ? (
                <Box sx={{ fontSize: "0.9rem" }}>{`Expires in ${timer}`}</Box>
              ) : (
                ""
              )}
              <Button disabled={btnDisabled} onClick={handleResendOtp}>
                Resend Code
              </Button>
            </Box>
            <Box textAlign="center">
              <Button
                type="submit"
                className={classes.btnLogin}
                variant="contained"
                disabled={!btnDisabled}
              >
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Dialog>
      {showNewPass && (
        <NewPassword
          showNewPass={showNewPass}
          setshowNewPass={setshowNewPass}
          setshowPopup={setshowPopup}
          setTitle={setTitle}
          setShowAd={setShowAd}
        ></NewPassword>
      )}
    </>
  );
};
export default OtpPopUp;
