import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Box,
  Button,
  Card,
  Checkbox,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { favoriteUnfavoriteMenu } from "../../Api/API";
import AlertPopup from "../../Common/AlertPopup";
import {
  errorToastMessage,
  handleCartItemCheck,
  handlePriceOfSelectedModifier,
  handleSugarAndIceLevel,
} from "../../Common/utils";
import {
  setBestSellerCheck,
  setCart,
  setCartDetailes,
  setCustomizeOpen,
  setFavoriteMenuStatus,
  setOpenPopUps,
  setSelectedItem,
  setTabValue,
  setTitles,
  setUpdateCart,
  setUserFavItems
} from "../../Redux/CartSlice";
import { setRemoveReward } from "../../Redux/UserSlice";
import "./categoryPopUp.css";

toast.configure();

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: { borderRadius: 15 },
  cursor: { cursor: "pointer" },
  topTitleBar: {
    background:
      "linear-gradient(180.52deg, #B02E25 -462.45%, rgba(176, 46, 37, 0) 99.55%)",
    display: "flex",
    flexDirection: "column",
    // padding: "1rem",
    color: "rgba(51, 51, 51, 1) !important",
    "@media (max-width: 1700px) and (min-width: 900px) and (min-height: 450px) and (max-height: 600px)":
      {
        height: "10.5rem",
      },
  },
  contentDiv: {
    display: "flex",
    width: "100%",
    gap: 25,
    padding: "1rem",
    alignItems: "flex-start",
    color: "rgba(51, 51, 51, 1) !important",
    "@media (max-width: 412px)": {
      width: "90%",
    },
    "@media (max-width: 480px)": {
      gap: 15,
      marginTop: "0.25rem",
    },
    "@media (max-width: 820px)": {
      gap: 15,
      marginTop: "0.25rem",
    },
    "@media (max-width: 1700px) and (min-width: 900px) and (min-height: 450px) and (max-height: 600px)":
      {
        marginTop: "-1rem",
        marginBottom: "-1rem",
      },
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%",
    "@media (max-width: 480px)": {
      alignItems: "flex-start",
      marginTop: "-10px",
    },
    "@media (max-width: 1700px) and (min-width: 900px) and (min-height: 450px) and (max-height: 600px)":
      {
        marginTop: "-1rem",
      },
  },
  titleAndFavContainer: {
    display: "flex",
    alignItems: "flex-start",
    "@media (max-width: 480px)": {
      marginTop: "5px",
    },
  },
  closeButton: {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    color: "#C60734",
    padding: "8px",
    minWidth: "35px",
    height: "35px",
    marginLeft: "10px",
    "@media (max-width: 480px)": {
      minWidth: "30px",
      marginTop: "-18px",
      padding: "0.5rem",
      width: "25px",
      right: "0.5rem",
      position: "absolute",
      "& svg": {
        fontSize: "20px",
      },
    },
    "@media (max-width: 1700px) and (min-width: 900px) and (min-height: 450px) and (max-height: 600px)":
      {
        width: "25px",
        height: "35px",
      },
  },
  favoriteCheckbox: {
    marginRight: "20px",
    "@media (max-width: 480px)": {
      "& svg": {
        fontSize: "2px",
      },
      marginRight: "10px",
    },
    "@media (max-width: 820px)": {
      "& svg": {
        fontSize: "2px",
      },
    },
  },

  imagebox: {
    width: "7.5rem",
    height: "7.5rem",
    borderRadius: "50% !important",
    backgroundColor: "white",
    overflow: "hidden",
    padding: "5px",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)", // Adjust the shadow values as needed
    border: "1px solid rgba(255, 255, 255, 1)",
    background:
      "linear-gradient(180.52deg, #B02E25 -462.45%, rgba(176, 46, 37, 0) 99.55%)",
    "@media (max-width: 480px)": {
      width: "7rem",
      height: "7rem",
    },
    "@media (max-width: 1700px) and (min-width: 900px) and (min-height: 450px) and (max-height: 600px)":
      {
        width: "6rem",
        height: "6rem",
        marginTop: "-0.75rem",
      },
  },
  menuImage: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  menutitle: {
    fontWeight: "700 !important",
    color: "rgba(51, 51, 51, 1) !important",
    fontFamily: "Lusitana",
    fontSize: "25px",
    marginTop: "5px",
    marginBottom: "10px",
    flex: 1,
    "@media (max-width: 820px)": {
      fontSize: "16px",
    },
    "@media (max-width: 1700px) and (min-width: 900px) and (min-height: 450px) and (max-height: 600px)":
      {
        fontSize: "18px",
      },
  },
  backButton: {
    transition: "transform 0.2s ease",
    "&:active": {
      transform: "scale(0.95)",
    },
    display: "flex",
    padding: ".1rem 1rem !important",
    background: "white !important",
    borderRadius: "50px !important",
    marginBottom: "1rem !important",
    color: "rgba(51, 51, 51, 1) !important",
    alignSelf: "flex-start",
  },
  servingImage: {
    // width: "65px",
    // height: "65px",
    height: "65px",
    width: "65px",
    objectFit: "contain",
    padding: "5px",
    "@media (max-width: 769px)": {
      width: "50px",
      height: "50px",
    },
    "@media (max-width: 480px)": {
      width: "45px",
      height: "45px",
      padding: "5px",
    },
    "@media (max-width: 1700px) and (min-width: 900px) and (min-height: 450px) and (max-height: 600px)":
      {
        height: "50px",
        width: "50px",
      },
  },
  servingaNem: {
    "@media (max-width: 1700px) and (min-width: 900px) and (min-height: 450px) and (max-height: 600px)":
      {
        marginTop: "-0.15rem",
        marginBottom: "-0.15rem",
      },
  },
  modifierContainer: {
    "@media (max-width: 1700px) and (min-width: 900px) and (min-height: 450px) and (max-height: 600px)":
      {
        marginTop: "-0.70rem",
      },
    "@media (max-width: 480px)": {
      paddingLeft: "10px",
    },
  },
  footer: {
    backgroundColor: "#F5F5F5",
    borderTop: "2.5px solid #C60734",
    padding: "2.45px 0",
    "@media (max-width: 1700px) and (min-width: 900px) and (min-height: 450px) and (max-height: 600px)":
      {
        padding: "0.45px",
      },
  },
  typography: {
    fontWeight: "700 !important",
    fontSize: "15px !important",
    padding: "0 1rem",
    color: "rgba(51, 51, 51, 1) !important",
    marginTop: "1rem",
    fontFamily: "Lusitana",
    "@media (max-width: 1700px) and (min-width: 900px) and (min-height: 450px) and (max-height: 600px)":
      {
        fontSize: "13px",
      },
  },
  modifierImage: {
    width: "95%",
    objectFit: "contain",
    height: "40%",
  },
  buttonWithImage: {
    fontSize: "12px !important",
    fontFamily: "Lusitana",
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "center !important",
    gap: "10px",
    alignItems: "center !important",
    textAlign: "center",
    fontWeight: "400 !important",
    border: "1px solid rgba(182, 182, 182, 1) !important",
    borderRadius: "12px !important",
    margin: "2% !important",
    transition: "transform 0.2s ease",
    "&:active": {
      transform: "scale(0.95)",
    },
    "&:disabled": {
      borderColor: "#E3E3E3 !important",
      backgroundColor: "#E3E3E3",
    },
  },
  icons: {
    color: "white !important",
    width: "30px !important",
    minWidth: "30px !important",
    height: "30px !important",
    background: "rgba(255, 255, 255, 0.12) !important",
    borderRadius: "50% !important",
  },
  waffleIcons: {
    color: "white !important",
    width: "30px !important",
    minWidth: "30px !important",
    height: "30px !important",
    background: "rgba(255, 255, 255, 0.12) !important",
    borderRadius: "50% !important",
  },
  vectors: {
    fontSize: "1rem !important",
  },

  commonButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "10px !important",
    fontWeight: "400 !important",
    border: "1px solid rgba(182, 182, 182, 1) !important",
    borderRadius: "8px !important",
    margin: "2% !important",
    transition: "transform 0.2s ease",
    "&:active": {
      transform: "scale(0.95)",
    },
    "&:disabled": {
      borderColor: "#E3E3E3 !important",
      backgroundColor: "#E3E3E3",
    },
    "@media (max-width: 480px)": {
      fontSize: "10px !important",
    },
  },
  btn: {
    fontFamily: "Lusitana",
    color: "black",
    width: "100%",
    height: "8vh",
    lineHeight: "0.9rem",
    justifyContent: "space-between",
    textTransform: "none",
    minHeight: "6vh",
  },
  dialogPaper: {
    "@media (max-width: 600px)": {
      width: "100vw",
    },
  },
  cardContent: {
    height: "1280px", // or use maxHeight
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
  },
  btnCliked: {
    backgroundColor: "#E3E3E3",
    fontFamily: "Lusitana",
    justifyContent: "space-between",
    color: "Black",
    textTransform: "none",
    width: "90%",
    height: "8vh",
    lineHeight: "0.9rem",
    minHeight: "6vh",
    "&:hover": {
      background: "#E3E3E3",
    },
  },
  cardHeader: {
    backgroundColor: "#E7E7E7",
    color: "black",
    justifyContent: "space-between",
    marginBottom: "0.2rem",
  },
  modifierGrp: {
    paddingLeft: 2,
    backgroundColor: "#C30E2F",
    color: "white",
  },
  center: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  imgContainer: {
    position: "relative",
    textAlign: "center",
  },
  totalAmount: {
    fontFamily: "Lusitana",
    fontSize: "18px",
    lineHeight: "23.35px",
    textAlign: "center",
    // color: '#111111',
    color: "#333",
    margin: "7px 0px 7px 0px",
    fontSize: window.innerWidth <= 480 ? "13px" : "15px",
    fontWeight: "bold",
    "@media (max-width: 480px)": {
      fontSize: "16px",
      lineHeight: "20px",
      marginTop: "5px",
    },
    "@media (max-width: 1700px) and (min-width: 900px) and (min-height: 450px) and (max-height: 600px)":
      {
        marginTop: "2px",
        marginBottom: "4px",
        fontSize: "13px",
      },
  },
  xlSizePopupOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  xlSizePopupContent: {
    backgroundColor: "white",
    borderRadius: "15px",
    display: "flex",
    width: "90%",
    maxWidth: "500px",
    height: "280px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    overflow: "visible",
    position: "relative",
    "@media (max-width: 480px)": {
      width: "95%",
      height: "230px",
    },
  },
  xlSizePopupImageSection: {
    flex: "0 0 50%",
    display: "flex",
    flexDirection: "column",
    // justifyContent: 'flex-end',
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
    paddingBottom: "20px",
    "@media (max-width: 480px)": {
      flex: "0 0 45%",
    },
  },
  xlSizePopupImageWrapper: {
    width: "95%",
    height: "95%",
    position: "absolute",
    top: "-35%",
    left: "5%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "visible",
    zIndex: 2,
    "@media (max-width: 480px)": {
      width: "90%",
      height: "90%",
      top: "-30%",
    },
  },
  xlSizePopupImageBackground: {
    width: "95%",
    height: "85%",
    position: "absolute",
    borderRadius: "50%",
    background: "linear-gradient(to bottom, #FF375F, #640014)",
    zIndex: 1,
    "@media (max-width: 480px)": {
      height: "65%",
    },
  },
  xlSizePopupImage: {
    width: "90%",
    height: "90%",
    objectFit: "contain",
    position: "relative",
    zIndex: 3,
    mixBlendMode: "normal",
    top: "40px",
    "@media (max-width: 480px)": {
      width: "100%",
      height: "100%",
      top: "45px",
    },
  },
  xlSizePopupTextSection: {
    flex: "0 0 50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "20px",
    color: "#000000",
    "@media (max-width: 480px)": {
      padding: "5px",
    },
  },
  xlSizePopupTitle: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "10px",
    color: "#B02E25",
    "@media (max-width: 480px)": {
      fontSize: "20px",
    },
  },
  xlSizePopupText: {
    fontSize: "14px",
    marginBottom: "20px",
    "@media (max-width: 480px)": {
      fontSize: "13px",
      marginBottom: "15px",
    },
  },
  xlSizePopupButtonNo: {
    padding: "10px 20px",
    border: "1px solid #B02E25",
    borderRadius: "5px",
    backgroundColor: "white",
    color: "#B02E25",
    cursor: "pointer",
    width: "90%",
    marginBottom: "10px",
    position: "relative",
    zIndex: 3,
    marginTop: "auto",
    // marginRight:"auto",
    height:"40px",
    "@media (max-width: 480px)": {
      marginBottom: "-10px",
      padding: "8px 15px",
      fontSize: "14px",
    },
  },
  xlSizePopupButtonYes: {
    padding: "10px 20px",
    border: "none",
    borderRadius: "5px",
    backgroundColor: "#B02E25",
    marginLeft:"auto",
    color: "white",
    cursor: "pointer",
    width: "90%",
    // marginBottom: "10px",
    marginTop: "auto",
    height:"40px",
    "@media (min-width: 340px) and (max-width: 370px)": {
      marginBottom:"0.62vh",
      padding: "8px 15px",
      width: "90%",
      fontSize: "14px",
    },
    "@media (min-width: 371px) and (max-width: 380px)": {
      marginBottom:"0.8vh",
      padding: "8px 15px",
      width: "90%",
      fontSize: "14px",
    },
    "@media (min-width: 381px) and (max-width: 400px)": {
      marginBottom: "5px",
      padding: "8px 15px",
      width: "90%",
      fontSize: "14px",
    },
    "@media (min-width: 401px) and (max-width: 450px)": {
      marginBottom: "0.6vh",
      padding: "8px 15px",
      width: "90%",
      fontSize: "14px",
    },
    "@media (min-width: 451px) and (max-width: 750px)": {
      marginBottom: "10px",
      padding: "8px 15px",
      width: "90%",
      fontSize: "14px",
    },
    "@media (min-width: 750px) and (max-width: 900px)": {
      marginBottom: "10px",
      padding: "8px 15px",
      width: "90%",
      fontSize: "14px",
    },
    "@media (min-width: 900px) and (max-width: 1200px)": {
      marginBottom: "12px",
      padding: "8px 15px",
      width: "90%",
      fontSize: "14px",
    }, "@media (min-width: 1201px)": {
      marginBottom: "12px",
      padding: "8px 15px",
      width: "100%",
      fontSize: "14px",
    },

  },
   

  xlSizePopupImageMask: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: "-5%",
  },
  imageLoading: {
    opacity: 0,
    transition: "opacity 0.3s ease-in-out",
  },
  imageLoaded: {
    opacity: 1,
  },
  description: {
    textAlign: "left",
    fontSize: 14,
    width: "50%",
    lineHeight: "1.4em",
    marginTop: "-7px",
    marginBottom: "7px",
    justifyContent: "justify",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    maxHeight: "2.4em",
    position: "relative",
    transition: "all 0.3s ease",
    "@media (max-width: 820px)": {
      WebkitLineClamp: 2,
      maxHeight: "2.4em",
      overflow: "hidden",
      fontSize: 12,
      width: "90%",
      cursor: "pointer",
      top: "2px",
      bottom: "2px",
      lineHeight: "1.4em",
    },
    "@media (min-width: 821px)": {
      WebkitLineClamp: "unset",
      maxHeight: "none",
      overflow: "visible",
    },
    "@media (max-width: 1700px) and (min-width: 900px) and (min-height: 450px) and (max-height: 600px)":
      {
        marginTop: "-0.5rem",
        fontSize: "12px",
      },
    },
    expandedDescription: {
      "@media (max-width: 820px)": {
        WebkitLineClamp: "unset",
        maxHeight: "none",
      },
    },

  expandButton: {
    position: "absolute",
    right: -25,
    top: "50%",
    transform: "translateY(-50%)",
    color: "#B02E25",
    cursor: "pointer",
    padding: "4px",
    display: "none",
    "@media (max-width: 820px)": {
      display: "block",
    },
  },

  expandedDescription: {
    "@media (max-width: 820px)": {
      WebkitLineClamp: "unset",
      maxHeight: "none",
    },
  },

  ellipsis: {
    "@media (max-width: 820px)": {
      color: "#B02E25",
      cursor: "pointer",
      marginLeft: "4px",
      display: "inline",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    "@media (min-width: 821px)": {
      display: "none",
    },
  },

  viewButton: {
    display: "none",
    "@media (max-width: 820px)": {
      display: "block",
      color: "#B02E25",
      fontSize: "12px",
      cursor: "pointer",
      fontWeight: "bold",
      position: "absolute",
      bottom: 0,
      right: -40,
      padding: "0 5px",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    "@media (max-width: 480px)": {
      fontSize: "11px",
    },
  },
  favIcon: {
    top: 0,
    "@media (max-width: 480px)": {
      top: "-5px",
      position: "relative",
    },
    "@media (max-width: 1700px) and (min-width: 900px) and (min-height: 450px) and (max-height: 600px)":
      {
        top: "-5px",
      },
  },
  iconText: {
    fontSize: "12px",
    color: "black",
    fontFamily: "Lusitana",
    display: "flex",
    alignItems: "center",
    gap: "5px",
    "@media (max-width: 480px)": {
      fontSize: "11px",
    },
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    marginTop: "0.7rem",
    "@media (max-width: 1700px) and (min-width: 900px) and (min-height: 450px) and (max-height: 600px)":
      {
        marginTop: "0.3rem",
      },
  },
  servingContainer: {
    display: "flex",
    justifyContent: "center",
    width: "90%",
    margin: "auto",
    gap: "5px",
    "@media (max-width: 480px)": {
      gap: "10px",
      width: "90%",
    },
    "@media (max-width: 1700px) and (min-width: 900px) and (min-height: 450px) and (max-height: 600px)":
      {
        marginTop: "-0.85rem",
      },
  },
  servingSizeImageContainer: {
    display: "flex",
    justifyContent: "space-between !important",
    flexDirection: "column",
    alignItems: "center",
    width: "20%",
    cursor: "pointer",
    padding: "10px",
    "@media (max-width: 480px)": {
      width: "20%",
      padding: "5px",
    },
    "@media (max-width: 1700px) and (min-width: 900px) and (min-height: 450px) and (max-height: 600px)":
      {
        marginTop: "-0.85rem",
        width: "15%",
      },
  },
}));
const Popup = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { favFromCard, minCalories, maxCalories, customizeOpen, flag, setUpdateBtnFlag, updateBtnFlag } = props;

  const location = useLocation();
  const isLogin = useSelector((state) => state.user.isLogin);
  const bestSellerCheck = useSelector((state) => state.cart.bestSellerCheck);
  const userDetail = useSelector((state) => state.user.user);
  const cartItem = useSelector((state) => state.cart.cart);
  const menuItem = useSelector((state) => state.cart.cartDetailes);
  const menuServingDetail = menuItem.menuServingDetail;
  const selectedCupSize = useSelector((state) => state.cart.selectedCupSizes);
  const openPopup = useSelector((state) => state.cart.openPopUps);
  const title = useSelector((state) => state.cart.titles);
  const EditTitle = useSelector((state) => state.cart.editTitles);
  const cartItemIndex = useSelector((state) => state.cart.cartItemIndexs);
  const nearestStore = useSelector((state) => state.store.nearestStore);
  const [selectedModifier, setSelectedModifier] = useState(0);
  const [toppingImage, setToppingImage] = useState([]);
  const [toppingEnable, setToppingEnable] = useState({});
  const [counter, setCounter] = useState(0);
  const [sugarLevelModifier, setSugarLevelModifier] = useState();
  const [sugarLevelMarks, setSugarLevelMarks] = useState([]);
  const [iceCubeLevelMarks, setIceCubeLevelMarks] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isSm = useMediaQuery(theme.breakpoints.up("sm"));
  const abc = useRef(null);
  const [custmizationSomething, setCustmizationSomething] = useState([]);
  const [sugarLevelMarksLabel, setSugarLevelMarksLabel] = useState();
  const [iceCubeLevelMarksLabel, setIceCubeLevelMarksLabel] = useState();
  const [sliderState, setSliderState] = useState();
  const [servingDetail, setServingDetail] = useState({});
  const [modifierPriceSum, setModifierPriceSum] = useState(0);
  const [cupSizeState, setCupSizeState] = useState("");
  const [cartTotalPrice, setCartTotalPrice] = useState(0);
  const [iceCubeLevelModifier, setIceCubeLevelModifier] = useState([]);
  const [fav, setFav] = useState(false);
  const [modifierCounterLimits, setModifierCounterLimits] = useState([]);
  const [autoSelected, setAutoSelected] = useState([]);
  const [removeSelectedModifier, setRemoveSelectedModifier] = useState([]);
  const [cupSizeChnage, setCupSizeChange] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [changedServing, setChangedServing] = useState({
    cupSize: "",
    servingDetail: "",
  });
  const [showXLPopup, setShowXLPopup] = useState(false);
  const [fromXLPopup, setFromXLPopup] = useState(false);
  const [redirectToCart, setRedirectToCart] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showEllipsis, setShowEllipsis] = useState(false);
  const descriptionRef = useRef(null);
  const isFavoritesTab = useSelector((state) => state.cart.tabValue);
  const userFavItems = useSelector((state) => state.cart.userFavItems);
   const tabValue = useSelector((state) => state.cart.tabValue);
  const [pendingUnfavorite, setPendingUnfavorite] = useState(false);
  const [resetMessageShown, setResetMessageShown] = useState(false);

  let navigate = useNavigate();

  const countNonOreoCrumbToppings = (selectedOptions) => {
    const toppingsGroup = selectedOptions?.find(
      option => option.modifier_group_name === "Toppings"
    );

    if (!toppingsGroup || !toppingsGroup.option) return 0;

    return toppingsGroup.option.reduce((count, topping) => {
      if (topping?.name === 'Oreo Crumb' || topping?.modifier_name === 'Oreo Crumb') {
        return count;
      }
      return count + (topping.quantity > 0 ? 1 : 0);
    }, 0);
  };


  useEffect(() => {
    if (location.pathname === "/cart" && !title?.includes("editFromCart")) {
      setCustmizationSomething([]); 
      setToppingEnable(null); 
      setCupSizeState(""); 
      setModifierPriceSum(0); 
    }
  }, [menuItem]);

  useEffect(() => {
    return () => {
      dispatch(setOpenPopUps(false));
    };
  }, [dispatch]);

  useEffect(() => {
    const initializeServingSize = () => {
      if (!menuItem.menuServingDetail) return;
      const eachServing = menuItem.menuServingDetail.find(
        (serving) => serving.serving_name === "Each"
      );
      if (eachServing) {
        setCupSizeState("Each");
        setServingDetail(eachServing);
        return;
      }
      if (menuItem.menuServingDetail.length === 1) {
        setCupSizeState(menuItem.menuServingDetail[0].serving_name);
        setServingDetail(menuItem.menuServingDetail[0]);
      }
    };
    initializeServingSize();
  }, [menuItem.menuServingDetail]);

  useEffect(() => {
    const handleBackButton = (e) => {
      if (customizeOpen) {
        e.stopPropagation();
        e.preventDefault();
        
        if (location.pathname === "/cart" && title === "editFromCart") {
          dispatch(setCustomizeOpen(false));
          dispatch(setOpenPopUps(false));
          return;
        }

        Promise.resolve().then(() => {
          dispatch(setTabValue(2));
          dispatch(setCustomizeOpen(false));
          dispatch(setOpenPopUps(false));
          navigate("/", { replace: true });
        });

        return;
      }

      if (location.pathname === "/menu" || location.pathname === "/cart") {
        dispatch(
          setTabValue(location.pathname === "/cart" ? isFavoritesTab : 2)
        );
        navigate("/", { replace: true });

        if (bestSellerCheck) {
          dispatch(setBestSellerCheck(false));
        }
      }
    };

    const cleanup = () =>
      window.removeEventListener("popstate", handleBackButton);
    window.addEventListener("popstate", handleBackButton);
    window.history.pushState(null, "", window.location.href);

    return cleanup;
  }, [
    dispatch,
    navigate,
    location.pathname,
    bestSellerCheck,
    isFavoritesTab,
    customizeOpen,
    title,
  ]);

  useEffect(() => {
    const checkOverflow = () => {
      if (descriptionRef.current && window.innerWidth <= 820) {
        const element = descriptionRef.current;
        const lineHeight = parseFloat(getComputedStyle(element).lineHeight);
        const maxHeight = lineHeight * 2;
        const temp = document.createElement("div");
        temp.style.cssText = getComputedStyle(element).cssText;
        temp.style.height = "auto";
        temp.style.position = "absolute";
        temp.style.visibility = "hidden";
        temp.style.webkitLineClamp = "unset";
        temp.innerText = element.innerText;
        document.body.appendChild(temp);

        const fullHeight = temp.offsetHeight;
        document.body.removeChild(temp);

        setShowEllipsis(fullHeight > maxHeight);
      } else {
        setShowEllipsis(false);
      }
    };

    const timer = setTimeout(() => {
      checkOverflow();
    }, 100);

    window.addEventListener("resize", checkOverflow);
    setIsExpanded(false);

    return () => {
      window.removeEventListener("resize", checkOverflow);
      clearTimeout(timer);
    };
  }, [menuItem?.description, customizeOpen]);

  const handleOnYes = (e) => {
    const xlServing = menuItem?.menuServingDetail?.find(
      (serving) => serving.serving_name === "XL"
    );

    handleCupSize(e, xlServing);
    setFromXLPopup(true);
    setShowXLPopup(false);
    setOpenAlert(false);
  };

  const handleOnNo = () => {
    setShowXLPopup(false);
    hadleAddToCart(redirectToCart, "Skip XL Condition");
  };

  const checkForXLSize = () => {
    const hasXLSize = menuItem?.menuServingDetail?.some(
      (serving) => serving.serving_name === "XL"
    );
    const selectedMediumOrLarge =
      cupSizeState === "Medium" || cupSizeState === "Large";
    return hasXLSize && selectedMediumOrLarge;
  };

  const areAllModifiersAvailableInXL = () => {
    const xlServing = menuItem?.menuServingDetail?.find(
      (serving) => serving.serving_name === "XL"
    );
    if (!xlServing) return false;
    return custmizationSomething.every((group) => {
      return group.option.every((option) => {
        const xlOption = menuItem?.customizationOpt
          .find((g) => g.modifier_group_id === group.modifier_group_id)
          ?.option.find((o) => o.modifier_id === option.modifier_id);
        const isAvailable =
          xlOption &&
          xlOption.modifier_group_description.some(
            (d) => d.serving_name === "XL"
          );
        return isAvailable;
      });
    });
  };
  useEffect(() => {
    const img = new Image();
    img.src = "./XLPopupImage.png";
  }, []);

  const XLSizePopup = ({ onYes, onNo, menuItem }) => {
    const classes = useStyles();
    const [imageLoaded, setImageLoaded] = useState(false);

    useEffect(() => {
      const preloadedImage = new Image();
      preloadedImage.src = "./XLPopupImage.png";
      preloadedImage.onload = () => setImageLoaded(true);
    }, []);

    return (
      <div className={classes.xlSizePopupOverlay}>
        <div className={classes.xlSizePopupContent}>
          <div className={classes.xlSizePopupImageSection}>
            <div className={classes.xlSizePopupImageWrapper}>
              <div className={classes.xlSizePopupImageBackground}></div>
              <img
                src={"./XLPopupImage.png"}
                alt={menuItem.title}
                className={`${classes.xlSizePopupImage} ${
                  imageLoaded ? classes.imageLoaded : classes.imageLoading
                }`}
              />
            </div>
            <button className={classes.xlSizePopupButtonNo} onClick={onNo}>
              No
            </button>
          </div>
          <div className={classes.xlSizePopupTextSection}>
            <h2 className={classes.xlSizePopupTitle}>
              {cupSizeState === "Medium"
                ? "Go XL for Just $1.75 More!"
                : cupSizeState === "Large"
                ? "Go XL for Just $1 More!"
                : "Go XL for a Great Deal!"}
            </h2>
            <p className={classes.xlSizePopupText}>
              {cupSizeState === "Medium"
                ? "Special offer to upsize your drink for just $1.75 more, get more delicious, yummy, favorite Gong cha drink today!"
                : cupSizeState === "Large"
                ? "Special offer to upsize your drink for just $1 more, get more delicious, yummy, favorite Gong cha drink today!"
                : "Special offer to upsize your drink, get more delicious, yummy, favorite Gong cha drink today!"}
            </p>
            <button onClick={onYes} className={classes.xlSizePopupButtonYes}>
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (cupSizeState === "XL" && fromXLPopup) {
      hadleAddToCart(redirectToCart);
    }
  }, [cupSizeState, fromXLPopup]);

  useEffect(() => {
    const calculateTotal = () => {
      let total = 0;
      if (Object.keys(servingDetail ? servingDetail : {}).length > 0) {
        total += servingDetail?.price;
      }
      total += modifierPriceSum;
      return total?.toFixed(2);
    };
    setTotalAmount(calculateTotal());
  }, [servingDetail, modifierPriceSum]);

  useEffect(() => {
    let sugarLevel = menuItem?.customizationOpt?.filter(
      (item) =>
        item.modifier_group_name === "Sugar Level" ||
        item.modifier_group_id === 75
    );
    let iceCubeLevel = menuItem?.customizationOpt?.filter(
      (item) =>
        item.modifier_group_name === "Ice Cube Level" ||
        item.modifier_group_id === 76
    );
    let iceCubeLevelPartition = [];
    let iceCubeLevelPartitionForSmall = [];
    let length = iceCubeLevel[0]?.option.length;
    let partition = 100 / (length - 1);
    let initialPartition = 0;
    iceCubeLevel[0]?.option.forEach((item, index) => {
      iceCubeLevelPartition.push({
        value: initialPartition,
        label: item?.modifier_name,
        selectedImage: item?.selectedImage,
        unSelectedImage: item?.unSelectedImage,
      });

      iceCubeLevelPartitionForSmall.push({ value: initialPartition });
      initialPartition = initialPartition + Number(partition);
    });

    let sugarLevelPartition = [];
    let sugarLevelPartitionForSmall = [];
    length = sugarLevel[0]?.option?.length;

    partition = 100 / (length - 1);
    initialPartition = 0;
    sugarLevel[0]?.option.forEach((item, index) => {
      sugarLevelPartition.push({
        value: initialPartition,
        label: item?.modifier_name,
        selectedImage: item?.selectedImage,
        unSelectedImage: item?.unSelectedImage,
      });

      sugarLevelPartitionForSmall.push({ value: initialPartition });
      initialPartition = initialPartition + Number(partition);
    });
    setSugarLevelMarks(sugarLevelPartition);
    setSugarLevelModifier(sugarLevel[0]);
    setIceCubeLevelModifier(iceCubeLevel[0]);
    setIceCubeLevelMarks(iceCubeLevelPartition);
  }, []);

  useEffect(() => {
    if (sliderState || sliderState === 0) {
      if (sugarLevelMarksLabel) {
        let selectedModifierByCustomer = sugarLevelModifier?.option?.filter(
          (item) =>
            item.modifier_name ==
            sugarLevelMarks.filter((item) => item.value == sliderState)[0].label
        );
        setSliderState();
        handleCustomization(sugarLevelModifier, selectedModifierByCustomer[0]);
      }
    }
  }, [sugarLevelMarksLabel]);

  useEffect(() => {
    if (sliderState || sliderState === 0) {
      if (iceCubeLevelMarksLabel) {
        let selectedModifierByCustomer = iceCubeLevelModifier?.option?.filter(
          (item) =>
            item?.modifier_name ==
            iceCubeLevelMarks.filter((item) => item.value == sliderState)[0]
              .label
        );
        setSliderState();
        handleCustomization(
          iceCubeLevelModifier,
          selectedModifierByCustomer[0]
        );
      }
    }
  }, [iceCubeLevelMarksLabel]);

  useEffect(() => {
    if (
      openPopup &&
      menuItem?.selectedCupSize &&
      menuItem?.menuServingDetail?.length > 0 &&
      selectedCupSize &&
      (title === "editFromCart" || isFavoritesTab === 5)
    ) {
      const shouldResetModifiers = () => {
        const toppingsGroup = menuItem?.customizationOpt?.find(
          group => group.modifier_group_name === "Toppings"
        );
        
        if (!toppingsGroup) return false;
        
        const nonOreoCrumbCount = countNonOreoCrumbToppings(menuItem?.selectedOptions);
        return nonOreoCrumbCount > toppingsGroup.counter_limit;
      };
      
      if (isFavoritesTab === 5 && shouldResetModifiers() && !resetMessageShown) {      

        setCustmizationSomething([]);
        setSugarLevelMarksLabel(null);
        setIceCubeLevelMarksLabel(null);
        setResetMessageShown(true);
              
        toast.error(
          "Selected options have been reset as they exceeded the toppings limit",
          {
            position: "bottom-left",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
    } else if (!shouldResetModifiers()) {
      setCustmizationSomething(
        menuItem?.selectedOptions ? menuItem?.selectedOptions : []
      );
    }
    if (title === "editFromCart" || isFavoritesTab === 5) {
      setCupSizeState(
        menuItem?.selectedCupSize
          ? menuItem?.selectedCupSize[0]?.serving_name
          : ""
      );
      setCartTotalPrice(
        menuItem?.orderItemPrice ? menuItem?.orderItemPrice : 0
      );
      setSelectedModifier(
        menuItem?.selectedOptions
          ? menuItem?.selectedOptions[0]?.modifier_group_id
          : ""
      );
    }
     // setCounter(5);
    let sugarAndIceLevelObject = handleSugarAndIceLevel(
      menuItem.customizationOpt,
      menuItem
    );
    let sugarLevelIndicator = sugarAndIceLevelObject.sugarLevelIndicator;
    let sugarPartition = sugarAndIceLevelObject.sugarPartition;
    let partition = sugarAndIceLevelObject.partition;
    let iceCubeLevelIndicator = sugarAndIceLevelObject.iceCubeLevelIndicator;

    setServingDetail(menuItem?.selectedCupSize[0]);
    setSugarLevelMarksLabel(sugarLevelIndicator * sugarPartition);
    setIceCubeLevelMarksLabel(iceCubeLevelIndicator * partition);
    } else if (!openPopup) {
    setModifierPriceSum(0);
    setServingDetail();
    setCupSizeState(null);
    setResetMessageShown(false); 
    }
  }, [openPopup, menuItem, selectedCupSize, menuItem.customizationOpt, title, resetMessageShown]);

  useEffect(() => {
    if (
      cupSizeState &&
      custmizationSomething.length > 0 &&
      cupSizeChnage &&
      custmizationSomething[0].option[0].selected_modifier_serving
        .serving_name !== cupSizeState
    ) {
      setCustmizationSomething([]);
      setSugarLevelMarksLabel(null);
      setIceCubeLevelMarksLabel(null);
      // setCounter(555555);
    }
  }, [cupSizeChnage]);

  let something = {};

  const handleCustomization = (modifierGroup, modifier) => {
    // dispatch(setEditTitles(""));
    if (modifierGroup.selection_time === "Single") {
      const isAlreadySelected = custmizationSomething.some(
        item => 
          Number(item.modifier_group_id) === Number(modifierGroup.modifier_group_id) &&
          item.option.some(opt => Number(opt.modifier_id) === Number(modifier.modifier_id))
      );
  
      if (isAlreadySelected) {
        setCustmizationSomething(prev => 
          prev.filter(item => Number(item.modifier_group_id) !== Number(modifierGroup.modifier_group_id))
        );
        return;
      }
  
      setCustmizationSomething((prev) =>
        prev.filter(
          (item) => Number(item.modifier_group_id) !== Number(modifierGroup.modifier_group_id)
        )
      );
    }

    if (
      modifierCounterLimits?.findIndex(
        (item) => Number(item?.modifier_id) === Number(modifier?.modifier_id)
      ) === -1
    ) {
      let modifierCounterLimit = [];
      modifierCounterLimit.push({
        modifier_id: modifier?.modifier_id,
        counterLimit: modifier?.counterLimit,
      });
      setModifierCounterLimits([
        ...modifierCounterLimits,
        ...modifierCounterLimit,
      ]);
    }
    let modifierPrice = modifier?.modifier_group_description?.filter(
      (item) => item.serving_name === cupSizeState
    );
    let image = [];
    if (modifier?.modifier_image) {
      image.push(modifier?.modifier_image);
      setToppingImage([...toppingImage, ...image]);
    }
    setSelectedModifier(modifier?.modifier_id);
    something = {
      modifier_group_id: modifierGroup?.modifier_group_id,
      modifier_group_name: modifierGroup?.title,
      option: [
        {
          id: "",
          isSelected: false,
          modifier_id: modifier?.modifier_id,
          modifier_name: modifier?.title,
          modifier_prefix_overide_mode: "No",
          modifier_prefix_overideMode: "",
          modifier_prefix_price: 0,
          modifier_prefix_name: 0,
          sku_code:modifier?.sku_code,
          modifier_alias: modifier?.modifier_alias, 
          modifier_price: modifierPrice[0]
            ? modifierPrice[0]?.price.toString()
            : "0",
          prefix_name: "",
          price: modifierPrice[0] ? modifierPrice[0]?.price.toString() : "0",
          quantity: 1,
          selected_modifier_serving: {
            price: modifierPrice[0] ? modifierPrice[0]?.price.toString() : "0",
            serving_name: cupSizeState,
          },
          serving_id: "",
          serving_name: "",
          serving_price: "",
          sub_modifier_id: 0,
          title: modifier?.title,
          include_counter_limit:modifier.include_counter_limit,
        },
      ],
      required: modifierGroup?.is_required,
      selection_time: modifierGroup?.optionType,
      title: modifierGroup?.title,
      counter_limit:modifierGroup.counter_limit
    };

    if (modifierGroup.selection_time === "Single") {
      setCustmizationSomething((prev) => [
        ...prev.filter(
          (item) => Number(item.modifier_group_id) !== Number(modifierGroup.modifier_group_id)
        ),
        something,
      ]);
      return;
    }

    let individual = {
      id: "",
      isSelected: false,
      modifier_id: modifier.modifier_id,
      modifier_name: modifier.title,
      sku_code: modifier.sku_code,
      modifier_alias: modifier?.modifier_alias, 
      modifier_prefix_overide_mode: "No",
      modifier_prefix_overideMode: "",
      modifier_prefix_price: 0,
      modifier_prefix_name: 0,
      modifier_price: modifierPrice[0]
        ? modifierPrice[0]?.price.toString()
        : "0",
      prefix_name: "",
      price: modifierPrice[0] ? modifierPrice[0]?.price.toString() : "0",
      quantity: 1,
      selected_modifier_serving: {
        price: modifierPrice[0] ? modifierPrice[0]?.price.toString() : "0",
        serving_name: cupSizeState,
      },
      serving_id: "",
      serving_name: "",
      serving_price: "",
      sub_modifier_id: 0,
      title: modifier.title,
      include_counter_limit:modifier.include_counter_limit,
    };

    let modified = custmizationSomething.filter(
      (element) =>
        Number(element.modifier_group_id) ===
        Number(modifierGroup?.modifier_group_id)
    );
    let modifiedIndex = custmizationSomething.findIndex(
      (element) =>
        Number(element.modifier_group_id) ===
        Number(modifierGroup?.modifier_group_id)
    );
    if (modified.length === 0) {
      let x = autoSelected.filter((e) =>
        custmizationSomething.some(
          (el) => Number(el.modifier_group_id) === Number(e.modifier_group_id)
        )
      );
      setRemoveSelectedModifier(x);
      setCustmizationSomething((previousState) => [
        ...previousState,
        something,
      ]);
      setCounter(1);
    } else {
      if (modified[0]?.selection_time === "Single") {
        if (modifier.counterLimit === 1) {
          let newArr = [...custmizationSomething];
          newArr[modifiedIndex] = something;
          setCustmizationSomething(newArr);
          setCounter(1 + 6);
        } else {
          if (modified[0]?.option[0]?.quantity < modifier.counterLimit) {
            modified[0].option[0].quantity += 1;
            setCounter(modified[0].option[0].quantity);
            custmizationSomething.splice(modifiedIndex, 1, modified[0]);
          }
        }
      } else {
        let multipleModifier = modified[0]?.option.filter(
          (element) => Number(element.modifier_id) === Number(modifier.modifier_id)
        );
        let multipleModifierIndex = modified[0]?.option.findIndex(
          (element) => Number(element.modifier_id) === Number(modifier.modifier_id)
        );
        if (multipleModifier.length === 0) {
          let x = [...custmizationSomething[modifiedIndex].option, individual];
          let newState = Object.assign(
            {},
            custmizationSomething[modifiedIndex]
          );
          newState.option = x;
          let newArray = [...custmizationSomething];
          newArray[modifiedIndex] = newState;
          setCustmizationSomething(newArray);
          setCounter(1);
        } else {
          if (multipleModifier[0]?.quantity < modifier.counterLimit) {
            let multipleNewState = Object.assign({}, multipleModifier[0]);
            multipleNewState.quantity++;
            multipleModifier[0] = multipleNewState;
            let newCustomizationSomething = [...custmizationSomething];
            let newObject = Object.assign(
              {},
              custmizationSomething[modifiedIndex]
            );
            let y = [...custmizationSomething[modifiedIndex].option];
            let optionObject = Object.assign(
              {},
              custmizationSomething[modifiedIndex].option[multipleModifierIndex]
            );
            optionObject = multipleModifier[0];
            y[multipleModifierIndex] = optionObject;
            newObject.option = y;
            newCustomizationSomething[modifiedIndex] = newObject;
            setCustmizationSomething(newCustomizationSomething);
            setCounter(modifiedIndex + multipleModifierIndex + counter);
          }
        }
      }
    }
  };

  const getToppingCount = () => {
    const foundGroup = custmizationSomething?.find(
      (e) => e.modifier_group_name === "Toppings" || e.selection_time === "Multiple"
    );
  
    if (!foundGroup || !Array.isArray(foundGroup.option)) {
      return 0;
    };
  
    const totalQuantity = foundGroup.option.reduce((sum, option) => {  
      return sum + (option?.include_counter_limit===true? 0:(option?.quantity || 0));
    }, 0);
    return totalQuantity;
  };

  useEffect(() => {
    if (custmizationSomething.length > 0) {
      let price = handlePriceOfSelectedModifier(
        menuItem.customizationOpt,
        custmizationSomething,
        cupSizeState
      );
      setModifierPriceSum(price);
    } else {
      setModifierPriceSum(0);
    }
  }, [menuItem.customizationOpt, custmizationSomething, cupSizeState]);

  const handleRemove = (modifier, idx, index, singleModifierGroup, event) => {
    event.stopPropagation();
    let optionType = singleModifierGroup?.optionType;
    let modifierGroupId = singleModifierGroup?.modifier_group_id;
    let modifiedIndex = custmizationSomething.findIndex(
      (item) => Number(item.modifier_group_id) === Number(modifierGroupId)
    );
    let modified = custmizationSomething.filter(
      (element) => Number(element.modifier_group_id) === Number(modifierGroupId)
    );
    if (optionType === "Single") {
      setCustmizationSomething((previousState) => {
        return previousState.filter(
          (item) => Number(item?.modifier_group_id) !== Number(modifierGroupId)
        );
      });
      setCounter(idx + 2 + index);
    } else {
      let modifiedObj = Object.assign({}, modified[0]);
      const multipleModifierIndex = modifiedObj?.option.findIndex(
        (element) =>
          Number(element.modifier_id) === Number(modifier.modifier_id)
      );

      setCustmizationSomething((previousState) => {
        if (modifiedObj.option[multipleModifierIndex]?.quantity >= 1) {
          let x = [...custmizationSomething[modifiedIndex].option];
          let multipleNewState = Object.assign({}, x[multipleModifierIndex]);
          multipleNewState.quantity--;
          if (modifiedObj.option[multipleModifierIndex].quantity === 1) {
            if (modifiedObj.option.length === 1) {
              return previousState.filter(
                (item) =>
                  Number(item.modifier_group_id) !== Number(modifierGroupId)
              );
            }

            let newArrayOfObjects = modifiedObj.option.filter(
              (_, index) => index !== multipleModifierIndex
            );
            modifiedObj.option = newArrayOfObjects;
          } else {
            x[multipleModifierIndex] = multipleNewState;
            modifiedObj.option = x;
          }
          let newState = previousState.filter(
            (item) => Number(item.modifier_group_id) !== Number(modifierGroupId)
          );
          return [...newState, modifiedObj];
        } else {
          return previousState.filter(
            (item) => Number(item.modifier_group_id) !== Number(modifierGroupId)
          );
        }
      });
    }
  };

  useEffect(() => {
    if (menuItem.menuServingDetail[0]?.serving_name === "Base Price") {
      setServingDetail(menuItem.menuServingDetail[0]);
      setCupSizeState(menuItem.menuServingDetail[0].serving_name);
    }
  }, [menuItem.menuServingDetail]);

  const checkOptionsAvailability = (newSize) => {
    return custmizationSomething?.every((group) => {
      return group?.option?.every((option) => {
        const newSizeOption = menuItem?.customizationOpt
          ?.find(
            (g) =>
              Number(g?.modifier_group_id) === Number(group?.modifier_group_id)
          )
          ?.option?.find(
            (o) => Number(o?.modifier_id) === Number(option?.modifier_id)
          );
        return (
          newSizeOption &&
          newSizeOption?.modifier_group_description?.some(
            (d) => d?.serving_name === newSize
          )
        );
      });
    });
  };

  const updateServingSize = (newSize, newServingDetail) => {
    setServingDetail(newServingDetail);
    setCupSizeState(newSize);
    const newPrice = handlePriceOfSelectedModifier(
      menuItem?.customizationOpt,
      custmizationSomething,
      newSize
    );
    setModifierPriceSum(newPrice);
  };

  const handleCupSize = (e, elm) => {
    const currentCupSize = elm?.serving_name;
    if (currentCupSize !== cupSizeState) {
      const allOptionsAvailable = checkOptionsAvailability(currentCupSize);
      if (!allOptionsAvailable) {
        setChangedServing({ cupSize: currentCupSize, servingDetail: elm });
        setOpenAlert(true);
      } else {
        updateServingSize(currentCupSize, elm);
      }
    }
  };
  const cupNotSelected = () => {
    toast.error(`Please select cupsize for your drink`, {
      position: "bottom-left",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const hadleAddToCart = async (directToCart, skipCondition) => {
    setUpdateBtnFlag(true)
    if (title === "editFromCart") {
      skipCondition = "Skip XL Condition";
    }

    dispatch(setRemoveReward(""));
    dispatch(setTitles(""));

    const requiredModifiers = menuItem?.customizationOpt?.filter(
      (modifier) => modifier.is_required === 1
    );
    const requiredModifiersStatus = requiredModifiers?.map((requiredMod) => {
      return custmizationSomething.some(
        (selected) =>
          Number(selected.modifier_group_id) ===
          Number(requiredMod.modifier_group_id)
      );
    });

    const allRequiredModifiersSelected = requiredModifiersStatus.every(
      (status) => status === true
    );
    
  const toppingsModifier = menuItem?.customizationOpt?.find(
    (element) => element.modifier_group_name === "Toppings"
  );

  if (toppingsModifier) {
    const selectedToppings = custmizationSomething.find(
      (option) => option.modifier_group_name === "Toppings"
    );

    if (selectedToppings && selectedToppings.option) {
      const totalToppingsCount = selectedToppings.option.reduce((sum, topping) => {
        if (!topping?.name || topping.name === 'Oreo Crumb') {
          return sum;
        }
        return sum + (topping.quantity > 0 ? 1 : 0);
      }, 0);

      if (totalToppingsCount > toppingsModifier.counter_limit) {
        toast.error(
          `You can select up to ${toppingsModifier.counter_limit} toppings`,
          {
            position: "bottom-left",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
        setUpdateBtnFlag(false);
        return;
      }
    }
  }

    if (!cupSizeState || !allRequiredModifiersSelected) {
      if (!cupSizeState) {
        setUpdateBtnFlag(false)
        toast.error(`Please select cup size for your drink`, {
          position: "bottom-left",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        return;
      }
      if (!allRequiredModifiersSelected && requiredModifiers?.length > 0) {
        const missingModifier =
          requiredModifiers[
            requiredModifiersStatus.findIndex((status) => status === false)
          ];
          setUpdateBtnFlag(false)
        toast.error(
          `Please select at least one modifier from ${missingModifier.modifier_group_name}`,
          {
            position: "bottom-left",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
        return;
      }
    }

  try {
    if (fav !== menuItem.is_favourite && tabValue===4) {
      const favUnfavData = {
        cart_id: 0,
        category_id: menuItem?.categoryId,
        customer_id: userDetail?.customer_id
          ? userDetail?.customer_id
          : userDetail?.person_id,
        fav_id: 0,
        fav_type: "menujson",
        is_favourite: fav ? 1 : 0,
        selectedOptions: custmizationSomething,
        orderItemPrice: servingDetail
          ? Number(servingDetail?.price) + Number(modifierPriceSum)
          : selectedCupSize
          ? Number(selectedCupSize[0]?.price) + Number(modifierPriceSum)
          : Number(cartTotalPrice),
        menu_details: {
          ...menuItem,
          is_favourite: fav ? 1 : 0,
          customizationOpt: menuItem.customizationOpt || [],
          selectedOptions: custmizationSomething,
          menu_serving_detail: menuItem?.menuServingDetail,
        },
        menu_id: menuItem.id,
        order_id: "",
      };

      dispatch(setFavoriteMenuStatus({
        menuId: menuItem.id,
        isFavorite: fav
      }));

      await favoriteUnfavoriteMenu(favUnfavData);
    }

    if (
      checkForXLSize() &&
      skipCondition !== "Skip XL Condition" &&
      areAllModifiersAvailableInXL()
    ) {
      if (directToCart === true) {
        setRedirectToCart(true);
      }
      setOpenAlert(false);
      setShowXLPopup(true);
    } else {
      let sameCustomizationObject = handleCartItemCheck(
        cartItem,
        custmizationSomething,
        menuItem,
        cupSizeState
      );
      let cartCheckIndex = sameCustomizationObject.cartCheckIndex;
      let sameCustomization = sameCustomizationObject.sameCustomization;

      let arr = [];
      let servingDetails = menuItem?.menuServingDetail?.filter(
        (e) => e.serving_name === cupSizeState
      );
      let obj = Object.assign({}, menuItem);
      obj.btnModifierId = menuItem?.btnModifierId;
      obj.btnModifier = [];
      obj.customizationOpt = menuItem.customizationOpt || [];
      obj.selectedOptions =
        custmizationSomething.length > 0 ? custmizationSomething : [];
      obj.menuServingDetail = menuItem?.menuServingDetail;
      obj.sku_code = cupSizeState==="Hot"?menuItem?.h_sku_code:menuItem?.sku_code
      obj.selectedCupSize = servingDetails;
      obj.orderItemPrice = servingDetail
        ? Number(servingDetail?.price) + Number(modifierPriceSum)
        : selectedCupSize
        ? Number(selectedCupSize[0]?.price) + Number(modifierPriceSum)
        : Number(cartTotalPrice);
      arr.push(obj);
      dispatch(setCartDetailes(obj));

      if (
        !sameCustomization ||
        sameCustomization?.length !== custmizationSomething?.length
      ) {
        if (menuItem.quantity !== 0 && !title) {
          dispatch(setCart(arr));
          setUpdateBtnFlag(false)
          toast.success(`${menuItem.title} Added to the cart`, {
            position: "bottom-left",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        } else if (menuItem.quantity !== 0 && title === "editFromCart") {
          let itemToUpdate = [...cartItem];
          itemToUpdate.splice(cartItemIndex, 1, obj);
          dispatch(setUpdateCart(itemToUpdate));
        }
      } else {
        if (!title) {
          const updatedCart = [...cartItem];
          let obj = Object.assign({}, updatedCart[cartCheckIndex]);
          obj.quantity += menuItem.quantity;
          updatedCart?.splice(cartCheckIndex, 1, obj);
          dispatch(setUpdateCart(updatedCart));
          toast.success(`${menuItem.title} Added to the cart`, {
            position: "bottom-left",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        } else if (title === "editFromCart") {
          let itemToUpdate = [...cartItem];
          itemToUpdate.splice(cartItemIndex, 1, obj);
          dispatch(setUpdateCart(itemToUpdate));
        }
      }
      if (directToCart === true) {
        await new Promise(resolve => {
          window.scrollTo({
            top: 0,
            behavior: 'instant'
          });
          resolve();
        });
        dispatch(setOpenPopUps(false));
        navigate("/cart");
      } else {
        dispatch(setOpenPopUps(false));
        if (title === "editFromCart") {
          toast.success(`${menuItem.title} item is updated`, {
            position: "bottom-left",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });

          await new Promise(resolve => {
            window.scrollTo({
              top: 0,
              behavior: 'instant'
            });
            resolve();
          });
          navigate(`/cart`);
        } else {
          dispatch(setCustomizeOpen(false));
        }
      }
    }
  } catch (error) {
    console.error(error);
    errorToastMessage();
  }
};
  useEffect(() => {
    if (menuItem?.is_favourite == 1) {
      setFav(true);
    }
  }, [menuItem]);

  const handleFavUnfav = (val) => {
    if (!val && menuItem.is_favourite === 1) {
      setFav(false);
      setPendingUnfavorite(true);

      const favUnfavData = {
        cart_id: 0,
        category_id: menuItem?.categoryId,
        customer_id: userDetail?.customer_id
          ? userDetail?.customer_id
          : userDetail?.person_id,
        fav_id: 0,
        fav_type: "menujson",
        is_favourite: 0,
        selectedOptions: [],
        orderItemPrice: 0,
        menu_details: {
          ...menuItem,
          is_favourite: 0,
          customizationOpt: [],
          selectedOptions: [],
          menu_serving_detail: menuItem?.menuServingDetail,
        },
        menu_id: menuItem.id,
        order_id: "",
      };

      dispatch(setFavoriteMenuStatus({
        menuId: menuItem.id,
        isFavorite: false
      }));
  

      favoriteUnfavoriteMenu(favUnfavData)
        .then((res) => {
          if (res?.responseCode == 1) {
            toast.success("Removed from your favorites", {
              position: toast.POSITION.TOP_RIGHT,
              pauseOnFocusLoss: false,
              autoClose: 1500,
              pauseOnHover: false,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: true,
            });
          } else {
            dispatch(setFavoriteMenuStatus({
              menuId: menuItem.id,
              isFavorite: true
            }));
            errorToastMessage();
          }
        })
        .catch((e) => {
          dispatch(setFavoriteMenuStatus({
            menuId: menuItem.id,
            isFavorite: true
          }));
          errorToastMessage();
          console.log(e);
        });
        setFav(false);
      return;
    }

    if (!cupSizeState) {
      toast.error(`Please select cup size for your drink`, {
        position: "bottom-left",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

      setFav(false);
      return;
    }

    let selectedServingDetails = menuItem?.menuServingDetail?.filter(
      (e) => e.serving_name === cupSizeState
    );
    let requiredModifiers = menuItem?.customizationOpt?.filter(
      (modifier) => modifier.is_required === true
    );
    let requiredModifiersStatus = requiredModifiers.map((e) => {
      let requiredArray = custmizationSomething.filter(
        (element) => Number(element.modifier_group_id) === Number(e.modifier_group_id)
      );
      return requiredArray.length > 0;
    });

    const allRequiredModifiersSelected = requiredModifiersStatus.every(
      (status) => status === true
    );

    if (allRequiredModifiersSelected || val === false) {
      setFav(val);
      dispatch(setFavoriteMenuStatus({
        menuId: menuItem.id,
        isFavorite: true
      }));
  
      let favorite = val ? 1 : 0;
      let custom = [];
        
      if (custmizationSomething.length === 0 && val) {
        menuItem?.customizationOpt?.forEach((modifierGroup) => {
          let option = [];
          modifierGroup?.option?.forEach((modifier) => {
            const modifierPrice = modifier?.modifier_group_description?.find(
              (desc) => desc.serving_name === cupSizeState
            );
  
            if (modifierPrice) {
              let selectedOption = {
                counterLimit: modifier?.counterLimit || 0,
                id: modifier.modifier_id.toString(),
                modifier_description: "",
                selected: true,
                modifier_group_description: [{
                  modifier_price: modifierPrice.price || "0",
                  prefixData: [],
                  price: Number(modifierPrice.price || 0),
                  serving_id: servingDetail?.serving_id?.toString(),
                  serving_name: servingDetail?.serving_name,
                }],
                modifier_id: modifier.modifier_id,
                modifier_name: modifier.title,
                sku_code: modifier.sku_code,
                modifier_alias: modifier.modifier_alias,
                modifier_price: modifierPrice.price || "0",
                modifier_sort: 0,
                status: "Active",
                title: modifier.title,
                include_counter_limit: modifier.include_counter_limit,
              };
              option.push(selectedOption);
            }
          });
  
          if (option.length > 0) {
            custom.push({
              bar: 0,
              is_required: modifierGroup.is_required,
              modifier_group_description: "",
              modifier_group_id: modifierGroup.modifier_group_id,
              modifier_group_name: modifierGroup.modifier_group_name,
              option: option,
              optionType: modifierGroup.selection_time,
              selection_time: modifierGroup.selection_time,
              title: modifierGroup.title,
            });
          }
        });
      } else {
        custmizationSomething.forEach((item) => {
          let option = [];
          item?.option?.forEach((element) => {
            let counterLimit = modifierCounterLimits.filter(
              (e) => Number(e.modifier_id) === Number(element.modifier_id)
            );

            let selectedOption = {
              counterLimit:
                counterLimit.length > 0 ? counterLimit[0].counterLimit : 0,
              id: element.modifier_id.toString(),
              modifier_description: "",
              selected: val,
              modifier_group_description: [
                {
                  modifier_price: element.selected_modifier_serving.price,
                  prefixData: [],
                  price: Number(element.selected_modifier_serving.price),
                  serving_id: servingDetail?.serving_id.toString(),
                  serving_name: servingDetail?.serving_name,
                },
              ],
              modifier_id: element?.modifier_id,
              modifier_name: element?.modifier_name,
              sku_code:element?.sku_code,
              modifier_alias: element?.modifier_alias, 
              modifier_price: element.selected_modifier_serving.price,
              modifier_sort: 0,
              status: "Active",
              title: element?.modifier_name,
              include_counter_limit:element?.include_counter_limit,           
            };
            option.push(selectedOption);
          });

          let selectedFavUnfav = {
            bar: 0,
            is_required: item.required,
            modifier_group_description: "",
            modifier_group_id: item.modifier_group_id,
            modifier_group_name: item.modifier_group_name,
            option: option,
            optionType: item.selection_time,
            selection_time: item.selection_time,
            title: item.title,
          };
          custom.push(selectedFavUnfav);
        });
      }
      const favUnfavData = {
        cart_id: 0,
        category_id: menuItem?.categoryId,
        customer_id: userDetail?.customer_id
          ? userDetail?.customer_id
          : userDetail?.person_id,
        fav_id: 0,
        fav_type: "menujson",
        is_favourite: favorite,
        selectedOptions: custmizationSomething,
        orderItemPrice: parseFloat(
          isNaN(
            Number(
              servingDetail
                ? servingDetail.price + modifierPriceSum
                : selectedCupSize
                ? selectedCupSize[0]?.price + modifierPriceSum
                : cartTotalPrice
            )
          )
            ? 0
            : Number(
                servingDetail
                  ? servingDetail.price + modifierPriceSum
                  : selectedCupSize
                  ? selectedCupSize[0]?.price + modifierPriceSum
                  : cartTotalPrice
              ).toFixed(2)
        ),
        menu_details: {
          cart_id: 0,
          category_id: menuItem?.categoryId,
          category_name: menuItem?.categoryName,
          created_at: new Date().toISOString(),
          customizationOpt: custom,
          description: "",
          discount_details: [],
          id: 0,
          image: menuItem?.image,
          is_active: true,
          is_deleted: "1",
          is_favourite: favorite,
          menu_serving_detail: [
            {
              created_at: new Date().toISOString(),
              price: servingDetail?.price
                ? servingDetail?.price
                : selectedCupSize
                ? selectedCupSize[0]?.price
                : selectedServingDetails[0]?.price,
              serving_actual_id: servingDetail?.serving_actual_id
                ? servingDetail?.serving_actual_id
                : selectedCupSize
                ? selectedCupSize[0]?.serving_actual_id
                : selectedServingDetails[0]?.serving_actual_id,
              sku_code:servingDetail?.sku_code
              ? servingDetail?.sku_code
              : selectedCupSize
              ? selectedCupSize[0]?.sku_code
              : selectedServingDetails[0]?.sku_code,
              serving_alias_name:servingDetail?.serving_alias_name
              ? servingDetail?.serving_alias_name
              : selectedCupSize
              ? selectedCupSize[0]?.serving_alias_name
              : selectedServingDetails[0]?.serving_alias_name,
              serving_description: servingDetail?.serving_description
                ? servingDetail?.serving_description
                : selectedCupSize
                ? selectedCupSize[0]?.serving_description
                : selectedServingDetails[0]?.serving_description,
              serving_icon: servingDetail?.serving_icon
                ? servingDetail?.serving_icon
                : selectedCupSize
                ? selectedCupSize[0]?.serving_icon
                : selectedServingDetails[0]?.serving_icon,
              serving_id: servingDetail?.serving_id
                ? servingDetail?.serving_id
                : selectedCupSize
                ? selectedCupSize[0]?.serving_id
                : selectedServingDetails[0]?.serving_id,
              serving_name: servingDetail?.serving_name
                ? servingDetail?.serving_name
                : selectedCupSize
                ? selectedCupSize[0]?.serving_name
                : selectedServingDetails[0]?.serving_id,
              status: "Active",
              tier_id: servingDetail?.tier_id
                ? servingDetail?.tier_id
                : selectedCupSize
                ? selectedCupSize[0]?.tier_id
                : selectedServingDetails[0]?.tier_id,
              updated_at: new Date().toISOString(),
            },
          ],
          price: servingDetail
            ? servingDetail.price + modifierPriceSum
            : menuItem.totalPrice,
          status: "Active",
          store_id: 0,
          title: menuItem?.title,
          updated_at: new Date().toISOString(),
          min_calories: minCalories,
          max_calories: maxCalories,
        },
        menu_id: menuItem.id,
        order_id: "",
      };
      favoriteUnfavoriteMenu(favUnfavData)
        .then((res) => {
          if (val) {
            toast.success("Added to your favorites", {
              position: "bottom-left",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          } else {
            toast.success("Removed from your favorites", {
              position: "bottom-left",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          }
        })
        .catch((e) => {
          dispatch(setFavoriteMenuStatus({
            menuId: menuItem.id,
            isFavorite: !val  
          }));
          errorToastMessage();
          console.log(e);
        });
    } else {
      if (!cupSizeState) {
        toast.error(`Please select cupsize for your drink`, {
          position: "bottom-left",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else {
        const missingModifierName =
          requiredModifiers.length > 0
            ? requiredModifiers.map((e) => e.modifier_group_name)[
                requiredModifiersStatus.findIndex((e) => e == false)
              ]
            : "a required modifier";

        toast.success(
          `Please select at least one modifier from ${missingModifierName}`,
          {
            position: "bottom-left",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );

        setFav(false);
      }
    }
  };
  const handleLeftArrow = () => {
    if (bestSellerCheck == true) {
      dispatch(setTabValue(1));
      let path = `/`;
      navigate(path);
      dispatch(setOpenPopUps(false));
      dispatch(setBestSellerCheck(false));
    } else {
      dispatch(setCustomizeOpen(false));
      dispatch(setOpenPopUps(false));
    }
  };
  useEffect(() => {
    window.addEventListener("popstate", handleLeftArrow);
    return () => {
      window.removeEventListener("popstate", handleLeftArrow);
    };
  }, []);

  const scrollToBottom = () => {
    abc?.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (
      menuServingDetail?.length === 1 &&
      menuServingDetail[0]?.serving_name === "Each"
    ) {
      setCupSizeState("Each");
      setServingDetail(menuServingDetail[0]);
    }
  }, [menuServingDetail]);

  useEffect(() => {
    if (cupSizeState && custmizationSomething?.length == 0) {
      setToppingEnable(menuItem.customizationOpt[0]);
      // setCupSizeEnable(false);
    }
    let selectedIndex = menuItem?.customizationOpt?.findIndex(
      (e) => e.modifier_group_id === toppingEnable?.modifier_group_id
    );
    let customizedIndex = custmizationSomething?.findIndex(
      (e) => e.modifier_group_id === toppingEnable?.modifier_group_id
    );
    let selected = [];
    if (selectedIndex === customizedIndex) {
      selected = menuItem?.customizationOpt?.filter((el) => {
        return custmizationSomething.every((f) => {
          if (f.selection_time === "Single") {
            return f.modifier_group_id !== el.modifier_group_id;
          } else if (f.selection_time === "Multiple") {
            return true;
          }
        });
      });
    }
    if (selectedIndex !== customizedIndex) {
      selected = menuItem?.customizationOpt?.filter((el, Index) => {
        return Index > selectedIndex;
      });
    }
    setAutoSelected(selected);
    if (
      selected.length >= 1 &&
      custmizationSomething?.length > 0 &&
      EditTitle !== "FromCart" &&
      toppingEnable?.selection_time !== "Multiple" &&
      toppingEnable?.modifier_group_id !==
        menuItem?.customizationOpt[menuItem?.customizationOpt?.length - 1]
          ?.modifier_group_id
    ) {
      setToppingEnable(
        selected[
          removeSelectedModifier.length > 0
            ? selected.indexOf(removeSelectedModifier[0]) + 1
            : 0
        ]
      );
    }
    if (custmizationSomething?.length >= 2) {
      scrollToBottom();
    }
  }, [custmizationSomething, cupSizeState]);

  const handleClose = () => {
    if (pendingUnfavorite) {
      dispatch(
        setUserFavItems(
          userFavItems.filter((item) => item.menu_id !== menuItem.id)
        )
      );
    }
    dispatch(setSelectedItem(null));
    dispatch(setCustomizeOpen(false));
    dispatch(setTitles(""));
  };

  if (!openPopup) {
    return null;
  }

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={customizeOpen}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        classes={{ paper: classes.dialogPaper }}
        maxWidth="md" // You can adjust this value ("xs", "sm", "md", "lg", "xl") to set the maxWidth
        fullWidth //
      >
        <Card className={classes.cardContent}>
          <Box
            className={classes.topTitleBar}
            sx={{ padding: { xs: "1rem", sm: "1rem", md: "2rem" } }}
          >
            <div className={classes.contentDiv}>
              <div>
                <div className={classes.imagebox}>
                  <img
                    className={classes.menuImage}
                    src={menuItem?.image ? menuItem?.image : "./logos.png"}
                    alt="Menu Item Img"
                  />
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div className={classes.titleContainer}>
                  <div
                    className={classes.titleAndFavContainer}
                    style={{
                      
                      // width: menuItem?.title.length < 20 ? "10rem" : "auto",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        // "@media (max-width: 480px)": {
                        //   flex: 1,
                        // },
                      }}
                    >
                      <Typography className={classes.menutitle}>
                        {menuItem?.title}
                      </Typography>
                    </Box>
                    {nearestStore?.id && isLogin && (
                      <Checkbox
                        className={classes.favIcon}
                        icon={
                          menuItem.is_favourite == 1 && fav ? (
                            <FavoriteIcon style={{ fill: "#C30E2F" }} />
                          ) : (
                            <FavoriteBorderIcon />
                          )
                        }
                        checkedIcon={
                          <FavoriteIcon style={{ fill: "#C30E2F" }} />
                        }
                        checked={fav}
                        onChange={(e) => {
                          if (menuItem?.is_favourite == 1 && fav) {
                            handleFavUnfav(false);
                          } else {
                            handleFavUnfav(e.target.checked);
                          }
                        }}
                        sx={{
                          color: "#C30E2F",
                          "&.Mui-checked": {
                            color: "#C30E2F",
                          },
                          "@media (max-width: 480px)": {
                            marginRight:
                              menuItem?.cold && menuItem?.caffine
                                ? "20px"
                                : "-10px",
                          },
                        }}
                      />
                    )}
                  </div>
                  <IconButton
                    onClick={handleClose}
                    className={classes.closeButton}
                  >
                    <CloseIcon style={{ color: "#C60734" }} />
                  </IconButton>
                </div>

                {menuItem?.description && (
                  <div style={{ position: "relative" }}>
                    <Typography
                      ref={descriptionRef}
                      className={`${classes.description} ${isExpanded ? classes.expandedDescription : ""
                        }`}
                      onClick={() => setIsExpanded(!isExpanded)}
                      style={{ cursor: showEllipsis ? 'pointer' : 'default' }}
                    >
                      {menuItem?.description}
                      {showEllipsis && !isExpanded && (
                        <span style={{ color: '#B02E25' }}>&nbsp;...</span>
                      )}
                    </Typography>
                  </div>
                )}
                {menuItem?.min_calories !== 0 &&
                  menuItem?.max_calories !== 0 && (
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "black",
                        fontWeight: "bold",
                        fontFamily: "Lusitana",
                        "@media (max-width: 480px)": {
                          marginTop: "18px",
                        },
                        "@media (max-width: 1245px) and (min-width: 900px) and (min-height: 450px) and (max-height: 600px)":
                          {
                            fontSize: "12px",
                          },
                      }}
                    >
                      {`${menuItem?.min_calories}-${menuItem?.max_calories} calories`}
                    </Typography>
                  )}
                {(menuItem.cold || menuItem.caffine) && nearestStore?.id && (
                  <div className={classes.iconContainer}>
                    {menuItem.cold && (
                      <div className={classes.iconText}>
                        <Box
                          style={{ width: "1.2rem", height: "1.2rem" }}
                          component="img"
                          alt="Cold"
                          src={"./Gongcha 2.png"}
                        />
                        <span
                          style={{ fontSize: "10px", whiteSpace: "nowrap" }}
                        >
                          Served Cold
                        </span>
                      </div>
                    )}
                    {menuItem.cold && menuItem.caffine && " | "}
                    {menuItem.caffine && (
                      <div className={classes.iconText}>
                        <Box
                          style={{ width: "1.2rem", height: "1.2rem" }}
                          component="img"
                          alt="Caffeine Free"
                          src={"./newCoffee.png"}
                        />
                        <span
                          style={{ fontSize: "10px", whiteSpace: "nowrap" }}
                        >
                          Caffeine Free
                        </span>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Box>
          <Box
            sx={{
              overflowY: "auto",
              flex: 1,
              paddingLeft: { xs: ".3rem", sm: "1rem", md: "2.5rem" },
              paddingRight: { xs: ".3rem", sm: "1rem", md: "2.5rem" },
              "&::-webkit-scrollbar": {
                width: "5px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#C60734",
                borderRadius: "10px",
              },
              "&::-webkit-scrollbar-track": {
                background: "#f1f1f1",
              },
            }}
          >
            {cupSizeState !== "Each" &&
              menuItem?.menuServingDetail?.some(
                (serving) => serving.serving_name !== "Each"
              ) && (
                <div
                  style={{
                    margin: "0 10px",
                    borderBottom: "1px solid rgba(255, 229, 229, 1)",
                    marginTop: "-20px",
                  }}
                >
                  <Typography
                    className={classes.typography}
                    sx={{
                      "@media (max-width: 1245px) and (min-width: 900px) and (min-height: 450px) and (max-height: 600px)":
                        {
                          fontSize: "12px",
                        },
                    }}
                  >
                    Cup Size*
                  </Typography>
                  <div className={classes.servingContainer}>
                    {menuItem &&
                      menuItem?.menuServingDetail
                        ?.filter((serving) => serving.serving_name !== "Each") // Only filter out 'Each' serving size
                        .map((serving, index) => {
                          let servingUrl = serving?.serving_icon?.replace(
                            "https://gongchadev3.s3.us-east-2.amazonaws.com",
                            "https://dhhov7lv0jcc4.cloudfront.net"
                          );

                          return (
                            <div
                              key={serving?.serving_id}
                              id="serving-button"
                              onClick={(e) => handleCupSize(e, serving)}
                              className={classes.servingSizeImageContainer}
                            >
                              <div
                                style={{
                                  backgroundColor:
                                    cupSizeState === serving?.serving_name
                                      ? "#F5F5F5"
                                      : "transparent",
                                  border:
                                    cupSizeState === serving?.serving_name
                                      ? "2px solid #B02E25"
                                      : "none",
                                  borderRadius: "50%",
                                }}
                              >
                                <img
                                  src={servingUrl}
                                  className={classes.servingImage}
                                  onClick={(e) => handleCupSize(e, serving)}
                                  alt=""
                                />
                              </div>
                              <Typography
                                className={classes.servingaNem}
                                sx={{
                                  // fontSize: "15px",
                                  fontSize: {
                                    xs: "14px",
                                    sm: "14px",
                                    md: "14px",
                                  },
                                  fontFamily: "Lusitana",
                                  fontWeight: "400",
                                  color:
                                    cupSizeState === serving?.serving_name
                                      ? "#B02E25"
                                      : "#333",
                                  marginTop: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {serving?.serving_name}
                              </Typography>
                            </div>
                          );
                        })}
                  </div>
                </div>
              )}
            {menuItem?.customizationOpt?.map((singleModifierGroup, index) => {
              const availableModifiers = singleModifierGroup?.option.filter(
                (singleModifier) =>
                  !cupSizeState ||
                  singleModifier?.modifier_group_description?.some(
                    (item) => item?.serving_name === cupSizeState
                  )
              );

              const shouldShowGroup =
                singleModifierGroup?.is_required === 1 ||
                availableModifiers.length > 0;

              if (shouldShowGroup) {
                return (
                  <div
                    style={{
                      margin: "0 10px",
                      borderBottom: "1px solid rgba(255, 229, 229, 1)",
                    }}
                  >
                    <Typography className={classes.typography}>
                      {`${singleModifierGroup?.title}${
                        singleModifierGroup?.is_required === 1 ? "*" : ""
                      }`}
                    </Typography>
                    <div
                      className={classes.modifierContainer}
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "100%",
                        flexWrap: "wrap",
                      }}
                    >
                      {(singleModifierGroup?.is_required === 1
                        ? singleModifierGroup?.option
                        : availableModifiers
                      ).map((singleModifier, idx) => {
                        let modifierImage =
                          singleModifier?.modifier_image?.replace(
                            "https://gongchadev3.s3.us-east-2.amazonaws.com",
                            "https://dhhov7lv0jcc4.cloudfront.net"
                          );
                        let modified = custmizationSomething?.find(
                          (ele) =>
                            ele?.modifier_group_id ==
                            singleModifierGroup?.modifier_group_id
                        );
                        let count = selectedModifier
                          ? modified?.option?.filter(
                              (elx) =>
                                elx?.modifier_id == singleModifier?.modifier_id
                            )[0]
                          : null;

                        const isModifierAvailable =
                          !cupSizeState ||
                          singleModifier?.modifier_group_description?.some(
                            (item) => item?.serving_name === cupSizeState
                          );

                        const isDisabled =
                          !cupSizeState || !isModifierAvailable;

                        return (
                          <div
                            key={singleModifier.modifier_id}
                            name={singleModifier.title}
                            className={
                              singleModifierGroup?.modifier_group_name === "Toppings" ||
                                singleModifierGroup?.modifier_group_name === "Waffle" ||
                              singleModifierGroup?.selection_time === "Multiple"
                                ? classes.buttonWithImage
                                : classes.commonButton
                            }
                            onClick={(event) => {
                              if (!isDisabled) {
                                if (
                                  singleModifierGroup?.selection_time ===
                                  "Single"
                                ) {
                                  handleCustomization(
                                    singleModifierGroup,
                                    singleModifier
                                  );
                                  setCounter(counter + 1);
                                } else {
                                  if (
                                    count &&
                                    cupSizeState &&
                                    singleModifier?.modifier_group_description?.filter(
                                      (item) =>
                                        item?.serving_name === cupSizeState
                                    ).length !== 0 &&
                                    !(
                                      singleModifierGroup?.selection_time ===
                                        "Multiple" &&
                                      singleModifier?.counterLimit > 1 &&
                                      count &&
                                      count?.quantity > 0
                                    )
                                  ) {
                                    handleRemove(
                                      singleModifier,
                                      idx,
                                      0,
                                      singleModifierGroup,
                                      event
                                    );
                                  } else {
                                    if(getToppingCount()<singleModifierGroup?.counter_limit||(singleModifierGroup?.modifier_group_name !== "Toppings"&&singleModifierGroup?.selection_time !== "Multiple")||singleModifierGroup?.counter_limit===0||singleModifier?.include_counter_limit===true){
                                    cupSizeState &&
                                      singleModifier?.modifier_group_description?.filter(
                                        (item) =>
                                          item?.serving_name === cupSizeState
                                      ).length !== 0 &&
                                      handleCustomization(
                                        singleModifierGroup,
                                        singleModifier
                                      );
                                    setCounter(counter + 1);
                                  }
                                }
                                }
                              }
                            }}
                            style={{
                              height:
                                singleModifierGroup?.modifier_group_name === "Toppings" ||
                                  singleModifierGroup?.modifier_group_name === "Waffle"
                                  ? "10rem"
                                  : singleModifierGroup?.selection_time === "Multiple"
                                  ? "5rem"
                                  : "3.3rem",
                              width:
                                singleModifierGroup?.modifier_group_name === "Toppings" ||
                                  singleModifierGroup?.modifier_group_name === "Waffle"
                                  ? isSm
                                    ? 63 / 3 + "%"
                                    : 138 / 3 + "%"
                                  : singleModifierGroup?.option?.length === 5
                                  ? isSm
                                    ? 70 / 5 + "%"
                                    : 80 / 3 + "%"
                                  : isSm
                                  ? 80 / 5 + "%"
                                  : 50 / 2 + "%",
                              ...(count
                                ? {
                                    backgroundColor:
                                      "var(--primary-colour, #B02E25)",
                                    color: "white",
                                    border: "1px solid rgba(182, 182, 182, 1)",
                                  }
                                : {
                                    color: "black",
                                    backgroundColor: isDisabled
                                      ? "#E3E3E3"
                                      : (singleModifierGroup?.modifier_group_name === "Toppings"&&singleModifierGroup?.selection_time === "Multiple"&&getToppingCount()===singleModifierGroup?.counter_limit&&singleModifierGroup?.counter_limit!==0&&singleModifier.include_counter_limit===false)?"#E3E3E3":"",
                                  }),
                              opacity: isDisabled ? 0.5 : 1,
                              cursor: isDisabled ? "not-allowed" : "pointer",
                            }}
                          >
                            <div
                              style={{
                                height:
                                  singleModifierGroup.modifier_group_name === "Toppings" ||
                                    singleModifierGroup.modifier_group_name === "Waffle"
                                    ? "20%"
                                    : "auto",
                                textAlign: "center",
                                // padding: "5px",
                                lineHeight: "1",
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 4,
                                overflow: "visible",
                                wordBreak: "normal",         
                                overflowWrap: "normal",      
                                whiteSpace: "normal",        
                            
                                padding:"4px",
                                // fontSize: { xs: "7px", md: "8px", lg: "9px" },
                                minHeight:
                                  singleModifierGroup.modifier_group_name === "Toppings" ||
                                    singleModifierGroup.modifier_group_name === "Waffle"
                                    ? "3em"
                                    : "auto",
                              }}
                            >
                              {singleModifier.title.length > 100
                                ? `${singleModifier.title.substring(0, 40)}`
                                : singleModifier.title}
                            </div>
                            {(singleModifierGroup?.modifier_group_name === "Toppings" ||
                              singleModifierGroup?.modifier_group_name === "Waffle") &&
                              (singleModifier.modifier_image ? (
                                <img
                                  src={modifierImage}
                                  className={classes.modifierImage}
                                  alt=""
                                  loading="lazy"
                                />
                              ) : (
                                <div className={classes.modifierImage}></div>
                              ))}
                            {singleModifierGroup?.selection_time ===
                              "Multiple" &&
                            singleModifier?.counterLimit > 1 &&
                            count &&
                            count?.quantity > 0 ? (
                              <div style={{ height: "50%" }}>
                                <Stack
                                  spacing={modifierImage?1:0}
                                  direction="row"
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "100%",
                                    "@media (max-width: 480px)": {
                                      "& .MuiButton-root": {
                                        padding: "0",
                                        minWidth: "24px !important",
                                        width: "24px",
                                        height: "24px"
                                      },
                                      "& .MuiSvgIcon-root": {
                                        fontSize: "18px"
                                      },
                                      "& .MuiTypography-root": {
                                        fontSize: "14px",
                                        minWidth: "20px",
                                        padding: "0 2px"
                                      }
                                    }
                                  }}
                                >
                                  {count.quantity === 1 && (
                                    <Button
                                      variant="text"
                                      className={
                                        singleModifierGroup?.selection_time ===
                                          "Multiple" &&
                                        (singleModifierGroup?.modifier_group_name !==
                                          "Toppings" &&
                                            singleModifierGroup?.modifier_group_name !== "Waffle")
                                          ? classes.waffleIcons
                                          : classes.icons
                                      }
                                      onClick={(event) => {
                                        handleRemove(
                                          singleModifier,
                                          idx,
                                          0,
                                          singleModifierGroup,
                                          event
                                        );
                                      }}
                                      style={{ minWidth: "30px" }}
                                    >
                                      <DeleteIcon className={classes.vectors} />
                                    </Button>
                                  )}
                                  {count.quantity > 1 &&
                                    count.quantity <=
                                      singleModifier?.counterLimit && (
                                      <Button
                                        variant="text"
                                        className={
                                          singleModifierGroup?.selection_time ===
                                            "Multiple" &&
                                          singleModifierGroup?.modifier_group_name !==
                                            "Toppings"
                                            ? classes.waffleIcons
                                            : classes.icons
                                        }
                                        onClick={(event) => {
                                          handleRemove(
                                            singleModifier,
                                            idx,
                                            0,
                                            singleModifierGroup,
                                            event
                                          );
                                        }}
                                        style={{ minWidth: "30px" }}
                                      >
                                        <RemoveIcon
                                          className={classes.vectors}
                                        />
                                      </Button>
                                    )}
                                  <Typography
                                    sx={{
                                      px: 1,
                                      fontSize: "1.2rem",
                                      minWidth: "30px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {count.quantity}
                                  </Typography>
                                  {count.quantity <
                                  singleModifier?.counterLimit ? (
                                    <Button
                                      variant="text"
                                      style={{
                                        background: "rgba(255, 255, 255, 0.12)",
                                        minWidth: "30px",
                                      }}
                                      className={
                                        singleModifierGroup?.selection_time ===
                                          "Multiple" &&
                                        singleModifierGroup?.modifier_group_name !==
                                          "Toppings"
                                          ? classes.waffleIcons
                                          : classes.icons
                                      }
                                    >
                                      <AddIcon className={classes.vectors} />
                                    </Button>
                                  ) : (
                                    <div style={{ minWidth: "25px" }}></div>
                                  )}
                                </Stack>
                              </div>
                            ) : (
                              <div style={{ height: "20%" }}></div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              }
              return null;
            })}
          </Box>

          <div className={classes.footer}>
            <div className={classes.totalAmount} style={{}}>
              Total: ${totalAmount}
            </div>
            <div
              className="text-center"
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "15px",
                padding: window.innerWidth <= 480 ? "0 10px" : "0",
              }}
            >
              {isFavoritesTab === 5 || location.pathname === "/cart" ? (
                <Button
                  onClick={()=>{
                    cupSizeState ? hadleAddToCart() : cupNotSelected();
                   
                    }}
                    // disabled={updateBtnFlag}
                  sx={{
                    color: "#C60734",
                    textTransform: "none",
                    border: "2px solid #C60734",
                    borderRadius: "15px",
                    "@media (max-width: 480px)": {
                      fontSize:
                        window.innerWidth <= 480
                          ? "12px"
                          : window.innerWidth <= 950 && window.innerWidth > 600
                          ? "14px"
                          : "16px",
                    },
                    "@media (max-width: 1245px) and (min-width: 900px) and (min-height: 450px) and (max-height: 600px)":
                      {
                        height: "30px",
                        fontSize: "12px",
                      },
                    fontSize: "14px",
                    fontWeight: 550,
                    backgroundColor: "white",
                    marginBottom: "10px",
                    "&:hover": {
                      backgroundColor: "white",
                      border: "2px solid #C60734",
                    },
                  }}
                >
                    {(location.pathname === "/cart" && EditTitle === "FromCart")
                      ? `Update ${menuItem?.title}`
                      : title === "LikeItem" || 
                        (location.pathname === "/cart" && !EditTitle)
                        ? "Add to Cart"
                        : `Update ${menuItem?.title}`}
                </Button>
              ) : (
                <>
                  <Button
                  //  disabled={updateBtnFlag}
                    onClick={cupSizeState ? hadleAddToCart : cupNotSelected}
                    sx={{
                      color: "#C60734",
                      textTransform: "none",
                      border: "2px solid #C60734",
                      borderRadius: "15px",
                      height:
                        window.innerWidth <= 480
                          ? "24px"
                          : window.innerWidth <= 950 && window.innerWidth > 600
                          ? "28px"
                          : "30px",
                      width:
                        window.innerWidth <= 480
                          ? "45%"
                          : window.innerWidth <= 950 && window.innerWidth > 600
                          ? "40%"
                          : "30%",
                      fontSize:
                        window.innerWidth <= 480
                          ? "12px"
                          : window.innerWidth <= 950 && window.innerWidth > 600
                          ? "14px"
                          : "16px",
                      fontWeight: 550,
                      backgroundColor: "white",
                      marginBottom: "10px",
                      "&:hover": {
                        backgroundColor: "white",
                        border: "2px solid #C60734",
                      },
                      "@media (max-width: 1245px) and (min-width: 900px) and (min-height: 450px) and (max-height: 600px)":
                        {
                          height: "30px",
                          fontSize: "10px",
                        },
                    }}
                  >
                    {favFromCard || flag === "featureFlag" || flag === "BestSellerFlag"
                      ? `Add to Cart`
                      : selectedCupSize
                      ? `Update ${menuItem?.title}`
                      : `Add to Cart`}
                  </Button>
                  <Button
                    onClick={() => {
                      hadleAddToCart(true);
                    }}
                    disabled={updateBtnFlag}
                    sx={{
                      color: "#ffff !important",
                      textTransform: "none",
                      borderRadius: "15px",
                      height:
                        window.innerWidth <= 480
                          ? "24px"
                          : window.innerWidth <= 950 && window.innerWidth > 600
                          ? "28px"
                          : "30px",
                      width:
                        window.innerWidth <= 480
                          ? "45%"
                          : window.innerWidth <= 950 && window.innerWidth > 600
                          ? "40%"
                          : "30%",
                      fontSize:
                        window.innerWidth <= 480
                          ? "12px"
                          : window.innerWidth <= 950 && window.innerWidth > 600
                          ? "14px"
                          : "16px",
                      fontWeight: 550,
                      backgroundColor: "#B02E25",
                      marginBottom: "10px",
                      "&:hover": {
                        backgroundColor: "#B02E25",
                      },
                      "& .MuiButton-label": { 
                        color: "#ffff !important",
                      },
                      "@media (max-width: 1245px) and (min-width: 900px) and (min-height: 450px) and (max-height: 600px)":
                        {
                          height: "30px",
                          fontSize: "12px",
                        },
                    }}
                  >
                    {location.pathname === "/menu" ||
                    flag == "featureFlag" ||
                    flag == "BestSellerFlag"
                      ? `Add and Checkout`
                      : location.pathname === "/cart"
                      ? `Update ${menuItem?.title}`
                      : `Update and Checkout`}
                  </Button>
                </>
              )}
            </div>
          </div>
        </Card>

        {showXLPopup && (
          <XLSizePopup
            menuItem={menuItem}
            onYes={handleOnYes}
            onNo={handleOnNo}
          />
        )}

        <AlertPopup
          open={openAlert}
          setOpen={setOpenAlert}
          setCounter={setCupSizeChange}
          setCupSizeState={setCupSizeState}
          setServingDetail={setServingDetail}
          changedServing={changedServing}
          title={"customization"}
          buttonType1={true}
          buttonType2={true}
          displayTitle={"Changing cup size will remove selected options"}
          setCustmizationSomething={setCustmizationSomething}
        />
      </Dialog>
    </>
  );
};
export default Popup;
