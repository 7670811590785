import { Backdrop, Box, Button, CircularProgress, Grid, Slide, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import Card from '../../Common/Card';
import Popup from '../Categories/CategoryPopUp';
import Login from '../Login/Login';

const Favorites = ({ setState,loading }) => {
  let navigate = useNavigate();
  const [loader, setLoader] =  useState(false)
  const userFavItems = useSelector((state) => state.cart.userFavItems);
  const [showAd, setShowAd] = useState(false);
  const [favItem, setFavItem] = useState(true);
  const isLogin = useSelector((state) => state.user.isLogin);
  const customizeOpen = useSelector((state) => state.cart.customizeOpen);
  const [updateBtnFlag, setUpdateBtnFlag] = useState(false)
  const isLoading = useSelector((state) => state.cart.isLoading);

  return (
    <>
      {isLogin === true ?
        (
          <>
            <Backdrop
              sx={{ color: "#fff", zIndex: 1000 }}
              open={userFavItems?.length>0?false:isLoading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <Box
              sx={{
                paddingLeft: { lg: "13vw", xs: "5vw" },
                paddingRight: { lg: "13vw", xs: "5vw" },
                paddingBottom: { lg: "3vh", xs: "10vh" },
                paddingTop: "5vh",
              }}
            >
              {!isLoading && !loading && !loader && Array.isArray(userFavItems) && userFavItems?.length === 0 && (
                <div style={{ textAlign: "center", marginTop: "3rem" }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: {
                        lg: "3rem",
                        md: "3rem",
                        sm: "2rem",
                        xs: "1rem"
                      }
                    }}
                  >
                    There are no items in your favorite list!
                  </Typography>
                </div>
              )}
              {userFavItems?.length > 0 && (
                userFavItems?.map((e, index) => (
                  <Slide key={index} direction="right" in={true} mountOnEnter unmountOnExit timeout={400}>
                  <Grid item xs={12} lg={12} key={e?.id || index} style={{ marginBottom: "2rem" }}>
                    <Card
                      key={e?.id}
                      props={e.menu_details}
                      fav={true}
                      title="FavPage"
                      setFavItem={setFavItem}
                      favItem={favItem}
                      menuId={e.menu_id}
                      max_calories={e.max_calories}
                      min_calories={e.min_calories}
                      favItemsDetail={e}
                      setState={setState}
                      setLoading={setLoader}
                    />
                  </Grid>
                  </Slide>
                ))
              )}
            </Box>
          </>
        ) : (
          <div style={{ textAlign: "center", marginTop: "5rem", width: "100%" }}>
            <Typography variant="h6" sx={{ fontSize: "2rem" }}>
              Add your favorite drinks
            </Typography>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                sx={{
                  borderRadius: 20,
                  padding: "0.3rem 2rem",
                  backgroundColor: "brown",
                  textTransform: "none",
                  marginTop: "2rem",
                  marginRight: "3rem",
                }}
                variant="contained"
                onClick={() => {
                  let path = "/signup"
                  navigate(path);
                }}
              >
                Sign Up
              </Button>
              <Button
                sx={{
                  borderRadius: 20,
                  padding: "0.3rem 2rem",
                  backgroundColor: "brown",
                  textTransform: "none",
                  marginTop: "2rem",
                }}
                variant="contained"
                onClick={() => {
                  setShowAd(true);
                }}
              >
                Log in
              </Button>
            </div>
          </div>
        )}
      <Login showAd={showAd} setShowAd={setShowAd} />
      {customizeOpen && (
        <Popup
          customizeOpen={customizeOpen}
          setUpdateBtnFlag = {setUpdateBtnFlag}
          updateBtnFlag = {updateBtnFlag}
        />
      )}
    </>
  );
};

export default Favorites;